import React, { Suspense, useEffect, useState } from "react";
import { mainUiActions, useUiDispatch, useUiState } from "../../../reducers/mainui.reducer";
import classNames from "classnames";
import GoogleLogin, { useGoogleLogout } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { Spinner } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
import { ReactComponent as LogoSmallSVG } from "./logo-small.svg";
import { sendSignIn } from "../../../api/appProvider";
import { ismobileAndTabletCheck } from "../../../utils/utils";
import AlertWarning from "../../organisms/AlertWarning";
import GoogleLogin1 from "./../LoginApi/GoogleLogin";
import AtDialog from "../../molecules/AtDialog";
import Carousel from "./Carousel";
import AtIcon from "../../atoms/AtIcon";
import { usePrevious, useWindowSize } from "../../../hooks";
import axios from "axios";
// import SketchSpinner from "../../atoms/AtSpinner/SketchSpinner";

const staticAssetsPath = "https://cdn.virtualrugstudio.com/OnlineVPS/";

const bgImage = "previews/background-landing-light.jpg";
const logoImage = "InterfaceElements/logo-new.png";
window.logourl = `${staticAssetsPath}${logoImage}`;

window.globalCarpetScale = ismobileAndTabletCheck() ? 2 : 4;

const sampleBaseUrl = "https://cdn.virtualrugstudio.com/OnlineVPS/Previews/samples/";

const samples = [
  sampleBaseUrl + "1.jpg",
  sampleBaseUrl + "2.jpg",
  sampleBaseUrl + "3.jpg",
  sampleBaseUrl + "4.jpg",
  sampleBaseUrl + "5.jpg",
  sampleBaseUrl + "6.jpg",
  sampleBaseUrl + "7.jpg",
  sampleBaseUrl + "8.jpg",
  sampleBaseUrl + "9.jpg",
  sampleBaseUrl + "10.jpg",
  sampleBaseUrl + "11.jpg",
  sampleBaseUrl + "12.jpg",
  sampleBaseUrl + "13.jpg",
  sampleBaseUrl + "14.jpg",
  sampleBaseUrl + "15.jpg",
  sampleBaseUrl + "16.jpg",
  sampleBaseUrl + "17.jpg",
  sampleBaseUrl + "18.jpg",
  sampleBaseUrl + "19.jpg",
  sampleBaseUrl + "20.jpg",
  sampleBaseUrl + "21.jpg",
  sampleBaseUrl + "22.jpg",
  sampleBaseUrl + "23.jpg",
  sampleBaseUrl + "24.jpg",
  sampleBaseUrl + "25.jpg",
  sampleBaseUrl + "26.jpg",
  sampleBaseUrl + "27.jpg",
  sampleBaseUrl + "28.jpg",
  sampleBaseUrl + "29.jpg",
  sampleBaseUrl + "30.jpg",
  sampleBaseUrl + "31.jpg",
  sampleBaseUrl + "32.jpg",
  sampleBaseUrl + "33.jpg",
  sampleBaseUrl + "34.jpg",
  sampleBaseUrl + "35.jpg",
  sampleBaseUrl + "36.jpg",
  sampleBaseUrl + "37.jpg"
];

const VpsLanding = props => {
  const { onLoaded } = props;
  const uiState = useUiState();
  const dispatch = useUiDispatch();
  const [begin, setbegin] = useState(false);
  const [loadingPercent, setloadingPercent] = useState(0);
  const [signedIn, setsignedIn] = useState(false);
  const [showMobileWarning, setshowMobileWarning] = useState(false);
  const [activeSampleNode, setactiveSampleNode] = useState(2);
  const [prevactiveSampleNode, setprevactiveSampleNode] = useState(-1);
  const [sampleIndex, setsampleIndex] = useState(-1);
  const [showSampleLoadSpinner, setshowSampleLoadSpinner] = useState(false);
  const [loadedSamples, setloadedSamples] = useState(new Array(samples.length).fill(false));

  const { signOut, loaded } = useGoogleLogout({
    clientId: "347190499501-81i6k56m7ecihdlhj44j12i7u2m9j2gn.apps.googleusercontent.com",
    // clientId: "615600073809-05cev43iap6cb2u2e7hcbhint20rq9po.apps.googleusercontent.com",
    cookiePolicy: "single_host_origin"
  });

  const beginHandler = () => {
    if (signedIn) {
      const enterReady = window.enter && window.enter();
      if (enterReady) {
        setbegin(true);
      } else {
        setTimeout(() => {
          beginHandler();
        }, 100);
      }
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    const script1 = document.createElement("script");
    const script2 = document.createElement("script");

    script.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    script2.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    setTimeout(() => {
      script1.innerHTML = `AppleID.auth.init({
        clientId: "com.explorug.login",
        scope: "name email",
        usePopup: true,
        redirectURI: "https://virtualrugstudio.com/"
      });
      
      function decodeJwtResponse(data) {
        var tokens = data.split(".");
        return JSON.parse(atob(tokens[1]));
      }
      `;
      document.body.appendChild(script1);
    }, [2000]);
  }, []);
  useEffect(() => {
    if (!!begin) {
      return;
    }
    let interval = loadingPercent < uiState.loadingOverlayPercent ? 20 : 300;
    setTimeout(() => {
      if (loadingPercent < uiState.loadingOverlayPercent) {
        setloadingPercent(loadingPercent => loadingPercent + 2);
      } else if (loadingPercent < uiState.loadingOverlayPercent + 10) {
        setloadingPercent(loadingPercent => loadingPercent + 0.5);
      }
    }, interval);
  }, [loadingPercent]);

  useEffect(() => {
    if (signedIn) {
      onLoaded();
      beginHandler();
    }
  }, [signedIn]);

  const responseGoogle = response => {
    const profileObj = response.profileObj;
    sendSignIn({ type: "g", email: profileObj.email, name: profileObj.name });
    setsignedIn(true);
    signOut();

    // if (!response.tokenId) {
    //   console.error("Unable to get tokenId from Google", response)
    //   return;
    // }

    // const tokenBlob = new Blob([JSON.stringify({ tokenId: response.tokenId }, null, 2)], { type: 'application/json' });
    // const options = {
    //   method: 'POST',
    //   body: tokenBlob,
    //   mode: 'cors',
    //   cache: 'default'
    // };
    // fetch(googleConfig.GOOGLE_AUTH_CALLBACK_URL, options)
    //   .then(r => {
    //     r.json().then(user => {
    //       const token = user.token;
    //       console.log(user);
    //       // sendSignIn({ type: "g", email: profileObj.email, name: profileObj.name });
    //       setsignedIn(true);
    //       signOut();
    //     });
    //   })
  };

  const responseGoogleFail = response => {
    console.log(response);
    signOut();
  };

  const postUsageLog = (name, email, type) => {
    const formdata = new FormData();
    formdata.append("product", "vrs");
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("type", type);
    axios.post("https://explorug.com/archanastools/Utilities/WebsiteLog.aspx", formdata);
  };

  const responseFacebook = response => {
    sendSignIn({ type: "f", email: response.email, name: response.name });
    setsignedIn(true);
    postUsageLog(response.name, response.email, "Facebook");
  };
  const responseFacebookFail = response => {
    console.log(response);
  };
  useEffect(() => {
    window.handleSuccessGoogle = function(response) {
      const responsePayload = decodeJwtResponse(response.credential);
      sendSignIn({ type: "g", email: responsePayload.email, name: responsePayload.name });
      setsignedIn(true);
      postUsageLog(responsePayload.name, responsePayload.email, "Gmail");
      signOut();
    };

    function decodeJwtResponse(data) {
      var tokens = data.split(".");
      return JSON.parse(atob(tokens[1]));
    }

    document.addEventListener(
      "AppleIDSignInOnSuccess",
      event => {
        // Handle successful response.
        console.log("apple login success");
        console.log(event.detail.authorization.id_token);
        const responsePayload = window.decodeJwtResponse(event.detail.authorization.id_token);
        console.log(responsePayload);
        var name = "AppleUser";
        if (event.detail.user) {
          name = event.detail.user.name.firstName + " " + event.detail.user.name.lastName;
        }
        var email = responsePayload.email;
        sendSignIn({ type: "f", email: email, name: name });
        setsignedIn(true);
        postUsageLog(name, email, "Apple");
      },
      { once: true }
    );

    // Listen for authorization failures.
    document.addEventListener(
      "AppleIDSignInOnFailure",
      event => {
        // Handle error.
        console.log("apple login failed");
        console.log(event.detail.error);
      },
      { once: true }
    );
  }, []);

  useEffect(() => {
    if (ismobileAndTabletCheck()) {
      setshowMobileWarning(true);
    }
    // onLoaded();

    const images = [bgImage];
    let loaded = 0;
    images.forEach((image, index) => {
      let img = new Image();
      img.onload = () => {
        loaded++;
        if (loaded === images.length) {
          // onLoaded();
        }
      };
      img.src = `${staticAssetsPath}${image}`;
    });
  }, []);

  useEffect(() => {
    console.log(sampleIndex);
    let la = true;
    if (loadedSamples[sampleIndex]) {
      setshowSampleLoadSpinner(false);
      return;
    }
    setTimeout(() => {
      if (la) {
        setshowSampleLoadSpinner(true);
      }
    }, 1000);

    return () => {
      la = false;
    };
  }, [sampleIndex, loadedSamples]);

  const nextSample = () => {
    let newSampleIndex = sampleIndex + 1;
    if (newSampleIndex >= samples.length) newSampleIndex = 0;
    setsampleIndex(newSampleIndex);
    if (activeSampleNode === 3) {
      setactiveSampleNode(1);
    } else {
      setactiveSampleNode(activeSampleNode + 1);
    }
    setprevactiveSampleNode(activeSampleNode);
  };

  const prevSample = () => {
    let newSampleIndex = sampleIndex - 1;
    if (newSampleIndex < 0) newSampleIndex = samples.length - 1;
    setsampleIndex(newSampleIndex);
    if (activeSampleNode === 1) {
      setactiveSampleNode(3);
    } else {
      setactiveSampleNode(activeSampleNode - 1);
    }
    setprevactiveSampleNode(activeSampleNode);
  };

  const onSampleImageLoad = ind => {
    let temp = [...loadedSamples];
    temp[ind] = true;
    setloadedSamples([...temp]);
  };

  return (
    <div className={classNames("vps-landing", { begin })}>
      <div className={"background-wrapper"}>
        <img
          src={`${staticAssetsPath}${bgImage}`}
          alt="background"
          className="landing-background"
        />
      </div>
      <div className="landing-ui-overlay">
        <div className={"brand-area"}>
          {/* <div className={"brand-area" + (isReady ? " ready" : "")}> */}
          <div className="brand-logo-wrapper">
            {/* <LogoSmallSVG /> */}
            <img src={`${staticAssetsPath}${logoImage}`} alt="logo" />
          </div>
          <div className="brand-description">
            Capture studio quality photos of your rugs <br />
            without having to produce them
          </div>
          <div
            className={
              (signedIn && !begin ? "show" : "") + (begin ? " fadeOut" : "") + " spinner-wrapper"
            }
          >
            <Spinner value={!begin ? loadingPercent / 100 : 0.92} size={32} />
          </div>
          <div className={"signin-wrapper" + (signedIn ? " fadeOut" : "")}>
            <div className="signin-desc">Sign in to try our early access demo</div>
            {/* <button className="dummyGoogleBtn" onClick={beginHandler}>
              Sign in Google
            </button> */}
            <div>
              {/* <GoogleLogin1 onSuccess={responseGoogle} onFailure={responseGoogleFail} /> */}
              {/* <GoogleLogin
                // clientId="615600073809-05cev43iap6cb2u2e7hcbhint20rq9po.apps.googleusercontent.com"
                clientId={googleConfig.GOOGLE_CLIENT_ID}
                buttonText="Sign in with Google"
                onSuccess={responseGoogle}
                onFailure={responseGoogleFail}
                cookiePolicy={"single_host_origin"}
              /> */}
              <div
                id="g_id_onload"
                data-client_id={process.env.REACT_APP_ClientID}
                data-context="signin"
                data-ux_mode="popup"
                data-callback="handleSuccessGoogle"
                data-auto-prompt="false"
              ></div>
              <div
                class="g_id_signin signin-button "
                data-type="standard"
                data-shape="pill"
                data-size="medium"
                data-logo_alignment="center"
              ></div>
              <FacebookLogin
                appId="748319940380022"
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                onFailure={responseFacebookFail}
                cssClass="kep-login-facebook signin-button"
                icon="fa-facebook"
              />
              <div
                id="appleid-signin"
                class="signin-button"
                data-color="white"
                data-type="sign-in"
                data-logo-size="large"
                data-border-radius="50"
                data-border="true"
              ></div>
              <AtButton
                className="btn-sample-shots"
                intent="primary"
                tertiary={true}
                text="View Sample Shots"
                onClick={() => {
                  setsampleIndex(0);
                }}
              />
              <div className="legal-details-block">
                <a target="_blank" href="https://virtualrugstudio.com/legal/privacy-policy.html">
                  Privacy Policy
                </a>
                <span>|</span>
                <a target="_blank" href="https://virtualrugstudio.com/legal/terms-of-use.html">
                  Terms of Use
                </a>
              </div>
              {process.env.NODE_ENV === "development" && (
                <button
                  onClick={() => {
                    setsignedIn(true);
                  }}
                >
                  pass
                </button>
              )}
            </div>

            {/* 
            <button className="dummyFbBtn" onClick={beginHandler}>
              Login with Facebook
            </button> */}
            {/* <AtButton
              intent="primary"
              tertiary={true}
              text="Read Online Guide"
              onClick={beginHandler}
            /> */}
          </div>
        </div>
      </div>
      <AlertWarning
        message={"For a better experience, we recommend using laptops or desktops."}
        show={showMobileWarning}
        handleClose={() => {
          setshowMobileWarning(false);
        }}
      />
      <AtDialog
        isOpen={sampleIndex > -1}
        onClose={() => {
          setsampleIndex(-1);
        }}
        className=" samples-dialog"
        overlayClassName={sampleIndex > -1 ? "samples-dialog-show" : "samples-dialog-hide"}
        size="lg"
      >
        {
          <div className={"samples-loading-wrapper " + (!loadedSamples[sampleIndex] ? "show" : "")}>
            <Spinner size={32} />
          </div>
        }
        <AtIcon
          size={2}
          icon={"close"}
          title={"close"}
          className="btn-dialog-close"
          onClick={e => setsampleIndex(-1)}
        />
        <div>
          <AtButton
            className="fullscreen-btns left-arrow"
            title="Prev sample"
            intent={null}
            icon="chevron-left"
            onClick={prevSample}
          />
          {[1, 2, 3].map(nodeIndex => {
            let index =
              sampleIndex +
              (nodeIndex - activeSampleNode > 1
                ? (nodeIndex - activeSampleNode) / -2
                : nodeIndex - activeSampleNode);
            if (index === -1) index = samples.length - 1;
            if (index === samples.length) index = 0;
            return (
              <img
                className={
                  nodeIndex === activeSampleNode && loadedSamples[index]
                    ? "fadeIn"
                    : nodeIndex === prevactiveSampleNode && loadedSamples[index]
                    ? "fadeIn fadeOut"
                    : ""
                }
                src={samples[index]}
                alt="placeholder"
                onLoad={() => onSampleImageLoad(index)}
              />
            );
          })}
          <AtButton
            className="fullscreen-btns right-arrow"
            title="Next sample"
            intent={null}
            icon="chevron-right"
            onClick={nextSample}
          />
        </div>
      </AtDialog>
    </div>
  );

  // return (<SketchSpinner />)
};

export default VpsLanding;
