import React from "react";

const UiStateContext = React.createContext();
const UiDispatchContext = React.createContext();

const SET_SHOW_CUST_SCOUT = "SET_SHOW_CUSTOMIZATION_SCOUT";
const SET_SHOW_LOAD_OVERLAY = "SET_SHOW_LOAD_OVERLAY";
const SET_LOADING_OVERLAY_PERCENT = "SET_LOADING_OVERLAY_PERCENT";
const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
const SET_SHOW_RUGSHARE = "SET_SHOW_RUGSHARE";
const SET_SHOW_ORDERSHEET = "SET_SHOW_ORDERSHEET";
const SETMOUSE_HOVERED_ONUI = "SETMOUSE_HOVERED_ONUI";
const SET_SHOW_FAV_DIALOG = "SET_SHOW_FAV_DIALOG";
const SET_SHOW_VR_BTN = "SET_SHOW_VR_BTN";
const SET_SHOW_VR_DIALOG = "SET_SHOW_VR_DIALOG";
const SET_SHOW_QUICKLINK_DIALOG = "SET_SHOW_QUICKLINK_DIALOG";
const SET_ISBUSY = "SET_ISBUSY";
const SET_SHOW_RENAME_DIALOG = "SET_SHOW_RENAME_DIALOG";
const SET_TOAST_PROPS = "SET_TOAST_PROPS";
const SET_IS_IDLE = "SET_IS_IDLE";
const SET_PAGE_VIEW = "SET_PAGE_VIEW";
const SET_CONFIRMATION_DIALOG = "SET_CONFIRMATION_DIALOG";
export const pageViews = {
  HOME: "HOMEPAGE",
  FULL_PAGE_THUMB: "FULL_PAGE_THUMB",
  VPS: "VPS",
};
const mainUiActions = {
  SET_SHOW_CUST_SCOUT,
  SET_SHOW_LOAD_OVERLAY,
  SET_LOADING_OVERLAY_PERCENT,
  SET_SHOW_SIDEBAR,
  SET_SHOW_RUGSHARE,
  SET_SHOW_ORDERSHEET,
  SETMOUSE_HOVERED_ONUI,
  SET_SHOW_FAV_DIALOG,
  SET_SHOW_VR_BTN,
  SET_SHOW_VR_DIALOG,
  SET_SHOW_QUICKLINK_DIALOG,
  SET_ISBUSY,
  SET_SHOW_RENAME_DIALOG,
  SET_TOAST_PROPS,
  SET_IS_IDLE,
  SET_PAGE_VIEW,
  SET_CONFIRMATION_DIALOG
};
const initialUiState = {
  showRugShare: false,
  showOrderSheet: false,
  showCustoScout: false,
  showLoadingOverlay: true,
  loadingOverlayPercent: 0,
  showLeftSidebar: false,
  showRightSidebar: false,
  mouseHoveredOnUi: false,
  showFavoritesDialog: false,
  showVrBtn: false,
  showVrDialog: false,
  showQuickLinkDialog: false,
  isBusy: false,
  showRenameDialog: false,
  toastProps: null,
  isIdle: false,
  pageView: pageViews.VPS,
  showConfirmationDialog: false
};
const mainUiReducer = (state, action) => {
  const { type, payload } = action;
  // console.log(type)
  switch (type) {
    case SET_SHOW_CUST_SCOUT:
      return { ...state, showCustoScout: payload };
    case SET_SHOW_LOAD_OVERLAY:
      return { ...state, showLoadingOverlay: payload };
    case SET_LOADING_OVERLAY_PERCENT:
      return { ...state, loadingOverlayPercent: payload };
    case SET_SHOW_SIDEBAR:
      return {
        ...state,
        showLeftSidebar: payload.left === undefined ? state.showLeftSidebar : payload.left,
        showRightSidebar: payload.right === undefined ? state.showRightSidebar : payload.right
      };
    case SET_SHOW_RUGSHARE:
      return { ...state, showRugShare: payload };
    case SET_SHOW_ORDERSHEET:
      return { ...state, showOrderSheet: payload };
    case SETMOUSE_HOVERED_ONUI:
      return { ...state, mouseHoveredOnUi: payload };
    case SET_SHOW_FAV_DIALOG:
      return { ...state, showFavoritesDialog: payload };
    case SET_SHOW_VR_BTN:
      return { ...state, showVrBtn: payload };
    case SET_SHOW_VR_DIALOG:
      return { ...state, showVrDialog: payload };
    case SET_SHOW_QUICKLINK_DIALOG:
      return { ...state, showQuickLinkDialog: payload };
    case SET_ISBUSY:
      return { ...state, isBusy: payload };
    case SET_SHOW_RENAME_DIALOG:
      return { ...state, showRenameDialog: payload };
    case SET_TOAST_PROPS:
      return { ...state, toastProps: payload };
    case SET_IS_IDLE:
      return { ...state, isIdle: payload };
    case SET_PAGE_VIEW:
      return { ...state, pageView: payload };
    case SET_CONFIRMATION_DIALOG:
      return { ...state, showConfirmationDialog: payload };
    default:
      return state;
  }
};
function UiStateProvider({ children, initPage }) {
  let uiState = initialUiState;
  switch (initPage) {
    case true:
      uiState.pageView = pageViews.FULL_PAGE_THUMB;
      break;

    default:
      break;
  }
  const [state, dispatch] = React.useReducer(mainUiReducer, uiState);
  return (
    <UiStateContext.Provider value={state}>
      <UiDispatchContext.Provider value={dispatch}>{children}</UiDispatchContext.Provider>
    </UiStateContext.Provider>
  );
}
function useUiState() {
  const context = React.useContext(UiStateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useUiDispatch() {
  const context = React.useContext(UiDispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export { UiStateProvider, useUiState, useUiDispatch, mainUiActions };
