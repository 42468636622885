import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import AppProvider from "../../../api/appProvider";
import Thumbnail from "../../molecules/Thumbnail";
import { useDispatchDesignList, designListActions } from "../../../reducers/designstree.reducer";

const LazyThumbnail = props => {
  const { node, ...otherProps } = props;
  const dispatch = useDispatchDesignList();
  const [ref, inView, entry] = useInView({
    triggerOnce: true,
    threshold: 0.3
  });
  useEffect(() => {
    if (inView === true && !node.Thumb) {
      AppProvider.getDesignThumbnails({ designs: [node] }).then(thumbs => {
        dispatch({
          type: designListActions.UPDATE_SINGLE_FILE_PROP,
          payload: { ...node, Thumb: thumbs[0].Thumb, Props: thumbs[0].Props }
        });
      });
    }
  }, [inView]);
  return (
    <div className="thumb-item" ref={ref}>
      <Thumbnail {...otherProps} thumb={node} />
    </div>
  );
};

export default LazyThumbnail;
