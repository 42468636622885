import convert from "convert-units";
import { colorNumberModes } from "../components/pages/EntryPage/defaultflags";
import React from "react";
import { MD5 } from "../utils/md5";
import { getPathOffile } from "../utils/arrayUtils";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_DETAILS = "SET_DETAILS";
const SET_TILE_DETAILS = "SET_TILE_DETAILS";
const SET_DETAILS_ONLY = "SET_DETAILS_ONLY";
const SELECT_DESIGN_COLOR = "SELECT_DESIGN_COLOR";
const CHANGE_COLOR = "CHANGE_COLOR";
const SET_DESIGN_PATH = "SET_DESIGN_PATH";
const CHANGE_TEXTURE = "CHANGE_DESIGN_TEXTURE";
const CHANGE_COLOR_TEXTURE = "CHANGE_DESIGN_COLOR_TEXTURE";
const CHANGE_COLOR_PALETTE = "CHANGE_COLOR_PALETTE";
const UNDO_COLOR_SELECTION = "UNDO_COLOR_SELECTION";
const REDO_COLOR_SELECTION = "REDO_COLOR_SELECTION";
const RESET_COLOR_SELECTION = "RESET_COLOR_SELECTION";
const SET_ORDER_PROPERTIES = "SET_ORDER_PROPERTIES";
const CHANGE_PILE = "CHANGE_PILE";
const CHANGE_COLOR_SCHEME = "CHANGE_COLOR_SCHEME";
const SET_LOADING = "SET_LOADING";
const SET_CUSTOMIZATION_FLAG = "SET_CUSTOMIZATION_FLAG";
const TOGGLE_COLOR_CARVING = "TOGGLE_COLOR_CARVING";
const MIX_PLY = "MIX_PLY";
const CHANGE_YARN_COLOR = "CHANGE_YARN_COLOR";
const SELECT_YARN = "SELECT_YARN";
const CHANGE_YARN_MATERIAL = "CHANGE_YARN_MATERIAL";
const SET_CUSTOSCOUT_COORDS = "SET_CUSTOSCOUT_COORDS";
const TOOGLE_CUSTOSCOUT = "TOOGLE_CUSTOSCOUT";
const SET_AVAIL_SIZES = "SET_AVAIL_SIZES";
const SELECT_ILLU_SIZE = "SELECT_ILLU_SIZE";

const designDetailActions = {
  SET_DETAILS,
  SET_DETAILS_ONLY,
  CHANGE_COLOR,
  SELECT_DESIGN_COLOR,
  SET_DESIGN_PATH,
  CHANGE_TEXTURE,
  CHANGE_COLOR_PALETTE,
  UNDO_COLOR_SELECTION,
  REDO_COLOR_SELECTION,
  RESET_COLOR_SELECTION,
  SET_ORDER_PROPERTIES,
  CHANGE_PILE,
  CHANGE_COLOR_TEXTURE,
  CHANGE_COLOR_SCHEME,
  SET_LOADING,
  SET_TILE_DETAILS,
  SET_CUSTOMIZATION_FLAG,
  TOGGLE_COLOR_CARVING,
  MIX_PLY,
  CHANGE_YARN_COLOR,
  CHANGE_YARN_MATERIAL,
  SELECT_YARN,
  SET_CUSTOSCOUT_COORDS,
  TOOGLE_CUSTOSCOUT,
  SET_AVAIL_SIZES,
  SELECT_ILLU_SIZE,
};
const designDetailState = {
  loading: true,
  designDetails: {},
  designName: null,
  currentDesignPath: null,
  fullpath: null,
  selectedColor: -1,
  customizationFlag: false,
  history: [],
  undoHistory: [],
  totalKnots: null,
  tileDetails: null,
  hash: null,
  updateDesignTiles: null,
  updateNormapTiles: null,
  yarnIndex: -1,
  custoScout: {
    show: false,
    designPoint: null,
    containerPoint: null
  },
  carvingColorsIndex: []
};
const designDetailReducer = (state, action) => {
  // console.log(action.type, action.payload)
  switch (action.type) {
    case SET_DETAILS:
      const details = normalizeUnit({
        ...action.payload.designDetails,
        defaultUnit: window.flags.defaultUnit
      });
      let designColorsPalette = action.payload.designDetails.DesignColors || [];
      if (window.flags.applyColorPaletteinVariations) {
        window.sessionStorage.removeItem("ParentFolder");
        window.sessionStorage.removeItem("DesignColors");
      }
      //if session storage ma xa vane designdetains.DesignColors && getPathOffile (state.fullpath)=== (session ko path)
      if (window.flags.applyColorPaletteinSameFolder && state.fullpath) {
        const ParentFolder = window.sessionStorage.getItem("ParentFolder");
        const DesignColors = window.sessionStorage.getItem("DesignColors");
        if (getPathOffile(state.fullpath) === ParentFolder && DesignColors) {
          designColorsPalette = JSON.parse(DesignColors);
        }
      }
      const designDetails = {
        ...action.payload.designDetails,
        ...details,
        OrderProperties: {
          QualityIndex: window.flags.defaultQualityIndex
        },
        DesignColors: designColorsPalette
      };
      return {
        ...state,
        designDetails,
        history: [],
        totalKnots: designDetails.DesignColors.map(c => c.Knots).reduce((a, b) => a + b, 0),
        colorNumbers: updateColorNumbers(designDetails.DesignColors),
        selectedColor: -1,
        currentDesignPath: action.payload.customizationFlag ? null : "",
        fullpath: action.payload.fullpath,
        designName: action.payload.label,
        updateDesignTiles: null,
        updateNormapTiles: null,
        customizationFlag: action.payload.customizationFlag,
        hash: action.payload.hash
          ? action.payload.hash
          : generateHash(designDetails, action.payload.fullpath),
        carvingColorsIndex: getCarvingColorNumbers(designDetails.DesignColors)
      };
    case SET_TILE_DETAILS:
      return setTileDetails(state, action.payload);
    case SELECT_DESIGN_COLOR:
      return { ...state, selectedColor: action.payload };
    case CHANGE_COLOR:
      return changeDesignColor(state, action.payload);
    case CHANGE_COLOR_PALETTE:
      return changeColorPalette(state, action.payload);

    case UNDO_COLOR_SELECTION:
      if (!state.history.length) return state;
      return undoDesignColor(state);
    case REDO_COLOR_SELECTION:
      return state;
    case RESET_COLOR_SELECTION:
      if (!state.history.length) return state;
      return {
        ...state,
        designDetails: state.history[0].designDetails,
        updateDesignTiles: null,
        updateNormapTiles: null,
        hash: state.history[0].hash,
        history: []
      };
    case SET_DESIGN_PATH:
      return { ...state, currentDesignPath: action.payload };
    case CHANGE_TEXTURE:
      return {
        ...state
      };
    case CHANGE_COLOR_TEXTURE:
      return changeColorMaterial(state, action.payload);
    case CHANGE_PILE:
      return changeColorPile(state, action.payload);
    case CHANGE_COLOR_SCHEME:
      return changeColorScheme(state, action.payload);
    case SET_LOADING:
      if (state.loading === action.payload) return state;
      return { ...state, loading: action.payload };
    case SET_CUSTOMIZATION_FLAG:
      return { ...state, customizationFlag: action.payload };
    case TOGGLE_COLOR_CARVING:
      return toggleCarving(state, action.payload);
    case MIX_PLY:
      return mixPly(state, action.payload);
    case CHANGE_YARN_COLOR:
      return changeYarnColor(state, action.payload);
    case CHANGE_YARN_MATERIAL:
      return changeYarnMaterial(state, action.payload);
    case SELECT_YARN:
      return {
        ...state,
        yarnIndex: action.payload.yarnIndex,
        selectedColor: action.payload.colorIndex
      };
    case SET_CUSTOSCOUT_COORDS:
      return {
        ...state,
        custoScout: {
          ...state.custoScout,
          designPoint: action.payload.designPoint,
          containerPoint: action.payload.containerPoint
        }
      };
    case TOOGLE_CUSTOSCOUT:
      return {
        ...state,
        custoScout: {
          ...state.custoScout,
          show: action.payload
        }
      };
    default:
      return state;
  }
};

const changeDesignColor = (state, payload) => {
  const { designDetails, selectedColor, updateDesignTiles, updateNormapTiles, hash } = state;
  const colorDetail = payload;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  const colorMaterial = parseInt(colorDetail.Texture);
  d.DesignColors[selectedColor].Color = colorDetail.Color;
  d.DesignColors[selectedColor].ColorName = colorDetail.ColorName;
  d.DesignColors[selectedColor].AssociatedMat = colorDetail.Texture;
  if (colorMaterial !== -1) {
    d.DesignColors[selectedColor].Material = colorMaterial;
    d.DesignColors[selectedColor].YarnDetails.forEach(yarn => {
      yarn.Material = colorMaterial;
    });
  }
  d.DesignColors[selectedColor].YarnDetails[0].Color = colorDetail.Color;
  d.DesignColors[selectedColor].YarnDetails[0].ColorName = colorDetail.ColorName;
  d.DesignColors[selectedColor].YarnDetails[0].AssociatedMat = colorDetail.Texture;

  //set in session storage
  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    const DesignColors = d.DesignColors;
    const ParentFolder = getPathOffile(state.fullpath);
    window.sessionStorage.setItem("ParentFolder", ParentFolder);
    window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
  }
  if (window.flags.colorAreaSwatch.sameColorLock) {
    const origDesignColors = state.history.length
      ? state.history[0].designDetails.DesignColors
      : designDetails.DesignColors;
    origDesignColors.forEach((color, index) => {
      if (index === selectedColor) return;
      if (color.YarnDetails[0].Color === origDesignColors[selectedColor].YarnDetails[0].Color) {
        console.log(
          "changeDesignColor -> ",
          color.YarnDetails[0].Color,
          origDesignColors[selectedColor].YarnDetails[0].Color
        );
        d.DesignColors[index].Color = colorDetail.Color;
        d.DesignColors[index].ColorName = colorDetail.ColorName;
        d.DesignColors[index].YarnDetails[0].Color = colorDetail.Color;
        d.DesignColors[index].YarnDetails[0].ColorName = colorDetail.ColorName;
        d.DesignColors[index].YarnDetails[0].AssociatedMat = colorDetail.Texture;

        if (colorMaterial !== -1) {
          d.DesignColors[index].Material = colorMaterial;
          d.DesignColors[index].YarnDetails.forEach(yarn => {
            yarn.Material = colorMaterial;
          });
        }
      }
    });
  }
  return {
    ...state,
    designDetails: d,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    customizationFlag: true,
    updateDesignTiles: { colorIndex: selectedColor },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};

const changeColorMaterial = (state, { material, materialName, colorIndex }) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[colorIndex].Material = material;
  d.DesignColors[colorIndex].materialName = materialName;
  d.DesignColors[colorIndex].YarnDetails.forEach(yarn => {
    yarn.Material = material;
    yarn.MaterialName = materialName;
  });
  // d.DesignColors[colorIndex].YarnDetails[0].Material = material;

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    const DesignColors = d.DesignColors;
    const ParentFolder = getPathOffile(state.fullpath);
    window.sessionStorage.setItem("ParentFolder", ParentFolder);
    window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
  }
  return {
    ...state,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    designDetails: d,
    selectedColor: colorIndex,
    customizationFlag: true,
    updateDesignTiles: { colorIndex },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};
const changeYarnColor = (state, payload) => {
  const { designDetails, yarnIndex, selectedColor, updateDesignTiles } = state;
  const { Color, ColorName } = payload;

  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[selectedColor].YarnDetails[yarnIndex] = {
    ...d.DesignColors[selectedColor].YarnDetails[yarnIndex],
    Color,
    ColorName
  };

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    const DesignColors = d.DesignColors;
    const ParentFolder = getPathOffile(state.fullpath);
    window.sessionStorage.setItem("ParentFolder", ParentFolder);
    window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
  }
  const updatedDesignTiles = { colorIndex: selectedColor };
  return {
    ...state,
    designDetails: d,
    updateDesignTiles: updatedDesignTiles,
    updateNormapTiles: null,
    history: [...state.history, { designDetails, updateDesignTiles }],
    customizationFlag: true,
    hash: generateHash(d)
  };
};
const changeYarnMaterial = (state, payload) => {
  const { designDetails, yarnIndex, selectedColor, updateNormapTiles, hash } = state;
  const { material, materialName, colorIndex } = payload;

  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  d.DesignColors[selectedColor].YarnDetails[yarnIndex] = {
    ...d.DesignColors[selectedColor].YarnDetails[yarnIndex],
    Material: material
  };

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    const DesignColors = d.DesignColors;
    const ParentFolder = getPathOffile(state.fullpath);
    window.sessionStorage.setItem("ParentFolder", ParentFolder);
    window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
  }
  const updateDesignTiles = { colorIndex: selectedColor };
  return {
    ...state,
    designDetails: d,
    updateDesignTiles,
    updateNormapTiles: null,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    customizationFlag: true,
    hash: generateHash(d)
  };
};
const mixPly = (state, payload) => {
  const { designDetails } = state;
  const { colorIndex, plyNumber } = payload;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  const { YarnDetails } = d.DesignColors[colorIndex];
  const { Color: firstColor } = YarnDetails[0];
  let updateDesignTiles = null;
  let yarnDetailArr = Array(plyNumber);

  for (let i = 0; i < plyNumber; i++) {
    //Check if all colors are same, if same no need to render
    if (YarnDetails[i] && YarnDetails[i].Color !== firstColor) {
      updateDesignTiles = true;
    }
    yarnDetailArr[i] = YarnDetails[i] ? YarnDetails[i] : YarnDetails[0];
  }

  if (plyNumber === 1) {
    //check if all the mix ply contains color 1, if yes dont render
    for (let i = 0; i < YarnDetails.length; i++) {
      if (YarnDetails && YarnDetails[i].Color !== firstColor) {
        updateDesignTiles = true;
      }
    }
  }
  d.DesignColors[colorIndex].YarnDetails = yarnDetailArr;
  if (updateDesignTiles) {
    updateDesignTiles = { colorIndex, YarnDetails };
    const hash = generateHash(d);
    return { ...state, designDetails: d, customizationFlag: true, updateDesignTiles, hash };
  }

  if (window.flags.applyColorPaletteinSameFolder || window.flags.applyColorPaletteinVariations) {
    const DesignColors = d.DesignColors;
    const ParentFolder = getPathOffile(state.fullpath);
    window.sessionStorage.setItem("ParentFolder", ParentFolder);
    window.sessionStorage.setItem("DesignColors", JSON.stringify(DesignColors));
  }
  return { ...state, designDetails: d, customizationFlag: true };
};
const toggleCarving = (state, payload) => {
  const { designDetails, selectedColor, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));

  if (payload && payload.length > 0) {
    payload.forEach(element => {
      d.DesignColors[element].Carving = !d.DesignColors[element].Carving;
    });
  } else if (payload && payload.length === 0) {
    let firstColorCarving = d.DesignColors[0].Carving;
    d.DesignColors.forEach((designColor, i) => {
      designColor.Carving = !firstColorCarving;
    });
  } else {
    d.DesignColors[selectedColor].Carving = !d.DesignColors[selectedColor].Carving;
  }
  let tilesToUpdate = payload ? null : { colorIndex: selectedColor };

  return {
    ...state,
    designDetails: d,
    customizationFlag: true,
    updateDesignTiles: null,
    updateNormapTiles: tilesToUpdate,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    hash: generateHash(d)
  };
};
const changeColorPile = (state, { colorIndex, pileHeight }) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...state.designDetails }));
  d.DesignColors[colorIndex].PileHeight = pileHeight;
  const colorNumbers = updateColorNumbers(d.DesignColors);
  return {
    ...state,
    designDetails: d,
    customizationFlag: true,
    colorNumbers: colorNumbers,
    selectedColor: colorIndex,
    updateDesignTiles: null,
    updateNormapTiles: { colorIndex },
    hash: generateHash(d),
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }]
  };
};
const changeColorScheme = (state, payload) => {
  const { fullpath, designName } = payload;
  const details = normalizeUnit({
    ...payload.designDetails,
    defaultUnit: window.flags.defaultUnit
  });
  let designColorsPalette = payload.designDetails.DesignColors || [];
  if (window.flags.applyColorPaletteinVariations) {
    const DesignColors = window.sessionStorage.getItem("DesignColors");

    if (DesignColors) {
      designColorsPalette = JSON.parse(DesignColors);
    }
  }
  const designDetails = {
    ...payload.designDetails,
    ...details,
    OrderProperties: {
      QualityIndex: window.flags.defaultQualityIndex
    },
    DesignColors: designColorsPalette
  };
  return {
    ...state,
    history: [],
    designDetails,
    hash: generateHash(designDetails, fullpath),
    updateDesignTiles: null,
    updateNormapTiles: null,
    designName: designName,
    currentDesignPath: "",
    totalKnots: designColorsPalette.map(c => c.Knots).reduce((a, b) => a + b, 0),
    colorNumbers: updateColorNumbers(designColorsPalette),
    fullpath,
    selectedColor: -1,
    customizationFlag: true
  };
};
const changeColorPalette = (state, colorPalette) => {
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = state;
  const d = JSON.parse(JSON.stringify({ ...designDetails }));
  colorPalette.forEach((item, index) => {
    if (d.DesignColors[index] && d.DesignColors[index].YarnDetails.length === 1) {
      d.DesignColors[index].Color = item.Value;
      d.DesignColors[index].ColorName = item.Name;
      d.DesignColors[index].YarnDetails[0].Color = item.Value;
      d.DesignColors[index].YarnDetails[0].ColorName = item.Name;
    }
  });
  return {
    ...state,
    customizationFlag: true,
    history: [...state.history, { designDetails, updateDesignTiles, updateNormapTiles, hash }],
    designDetails: d,
    updateDesignTiles: { colorIndex: -1 },
    updateNormapTiles: null,
    hash: generateHash(d)
  };
};
const convertUnit = (from, to, valueArr) => {
  return valueArr.map(val =>
    Number(
      convert(val)
        .from(from)
        .to(to)
        .toFixed(2)
    )
  );
};
const normalizeUnit = ({ PhysicalWidth, PhysicalHeight, defaultUnit, Unit }) => {
  let width = PhysicalWidth,
    height = PhysicalHeight,
    defUnit = defaultUnit;
  if (!defaultUnit) {
    if (Unit === "in") defUnit = "ft";
    else defUnit = Unit;
  }
  [width, height] = convertUnit(Unit, defUnit, [PhysicalWidth, PhysicalHeight]);
  if (
    defUnit === "cm" ||
    window.flags.ordersheet.roundOffToFtOrCm ||
    window.flags.ordersheet.roundOffToNearestHalf
  ) {
    width = window.flags.ordersheet.roundOffToNearestHalf
      ? Math.round(width * 2) / 2
      : Math.round(width);
    height = window.flags.ordersheet.roundOffToNearestHalf
      ? Math.round(height * 2) / 2
      : Math.round(height);
  }
  return { PhysicalWidth: width, PhysicalHeight: height, Unit: defUnit };
};
const updateColorNumbers = designColors => {
  const { colorNumberMode } = window.flags.colorAreaSwatch;
  var map = {};
  var result = [];
  var cnt = 1;
  var element, temp;
  designColors.forEach((designColor, i) => {
    element = designColor.ColorName + "-" + designColor.PileHeight;
    if (!map[element]) {
      map[element] = [cnt++];
    }
    map[element].push(i);
  });
  for (element in map) {
    var indices = map[element];
    if (colorNumberMode === colorNumberModes.ALPHA) temp = String.fromCharCode(64 + indices[0]);
    else temp = indices[0];
    if (indices.length === 2) {
      result[indices[1]] = temp;
    } else {
      for (var j = 1; j < indices.length; ++j) {
        result[indices[j]] = temp + String.fromCharCode(96 + j);
      }
    }
  }
  return result;
};

function undoDesignColor(state) {
  const his = [...state.history];
  if (!his.length) return state;
  const { designDetails, updateDesignTiles, updateNormapTiles, hash } = his.pop();
  return { ...state, updateNormapTiles, designDetails, updateDesignTiles, hash, history: his };
}

const setTileDetails = (state, payload) => {
  // const { props1x, props2x, props4x } = payload;
  return {
    ...state,
    tileDetails: payload
  };
};
export const generateHash = (details, fullpath = "") => {
  const des = JSON.stringify(details);
  // const key = sessionStorage.getItem("apikey")
  return MD5(des + fullpath);
};
const getCarvingColorNumbers = designColors => {
  let carvingColorNumbers = [];
  if (designColors && designColors.length > 0) {
    if (checkPartialCarving(designColors)) {
      designColors.forEach((designColor, i) => {
        if (designColor.Carving) {
          carvingColorNumbers.push(i);
        }
      });
    } else {
    }
  }
  return carvingColorNumbers;
};
const checkPartialCarving = designColors => {
  let partialCarving = false;
  let firstColorCarving = designColors[0].Carving;
  designColors.forEach((designColor, i) => {
    if (firstColorCarving !== designColor.Carving) {
      partialCarving = true;
    }
  });
  return partialCarving;
};
function DesignDetailStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(designDetailReducer, designDetailState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}
function useDesignDetailState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useCountState must be used within a CountProvider");
  }
  return context;
}
function useDispatchDesignDetail() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a CountProvider");
  }
  return context;
}
export {
  DesignDetailStateProvider,
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
};
