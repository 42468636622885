import React, { useState, useEffect } from 'react';
import RoomViewNew from './RoomViewNew';
import { useMount } from 'react-use';
import { readJSON, readImage } from './../utils/utils';
import "./style.scss"
import { AtSpinnerOverlay } from './../components/atoms/AtSpinner';
import { ReactComponent as Tutorial0 } from "./../components/organisms/VpsStage/tutorial0.svg";



function getJsonFromUrl(url) {
  if (!url) url = window.location.search;
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function (part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

const defaultRoomdata = {
  Dir: "RomanPassage",
  Name: "Roman Passage",
  Files: [
    "Shot_1.bg.jpg",
    "Shot_1.sh.jpg",
    "Shot_1.m.png"
  ]
};

function App(props) {
  const { design, designName, width, height, onRendering, showLoaderonInit } = props;
  const [roomData, setRoomData] = useState()
  const [designImageProps, setDesignImageProps] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bgImage, setbgImage] = useState(null);
  const [showTutorial, setshowTutorial] = useState(true);
  const [showRoomView, setshowRoomView] = useState(false);


  useMount(() => {
    const baseUrl = defaultRoomdata.Dir
    readJSON(`${baseUrl}/config.json`).then(config => {
      setRoomData({ ...defaultRoomdata, config, baseUrl })
    })
    const jsonUrl = getJsonFromUrl();
    const design = jsonUrl.design;
    if (design) {
      setIsLoading(true);
      setDesignImageProps({
        designName: jsonUrl.designName,
        designImagePath: jsonUrl.design,
        physicalWidthCms: jsonUrl.width,
        physicalHeightCms: jsonUrl.height
      });
    }
  })

  useEffect(() => {
    if (design) {
      setshowRoomView(true);
      if (design !== designImageProps?.designImagePath)
        setIsLoading(true);
      setDesignImageProps({
        designName,
        designImagePath: design,
        physicalWidthCms: width,
        physicalHeightCms: height
      });
    }
    else {
      // setDesignImageProps(null);
      if (!showLoaderonInit)
        setIsLoading(false);
      setshowRoomView(false);

    }
  }, [design, width, height])


  useEffect(() => {
    if (showLoaderonInit) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [showLoaderonInit])

  useEffect(() => {
    onRendering && onRendering(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (roomData) {
      const { Files: files, baseUrl, config } = roomData;
      preload({ baseUrl, config, files });
    }

  }, [roomData])
  function createName() {
    let res = ""
    Array.from(arguments).forEach(argument => {
      if (argument) {
        res = `${res}${argument}.`;
      }
    });
    return res
  }
  function makeUrl() {
    let res = ""
    Array.from(arguments).forEach((argument, index) => {
      if (argument) {
        res = `${res}${index === 0 ? "" : "/"}${argument}`;
      }
    });
    return res
  }
  const preload = ({ baseUrl, config, files }) => {

    const x = { shot: config.shots[0], light: config.lights ? config.lights[0] : null };
    const bgUrl = `${createName(x.shot, x.light)}bg.jpg`;
    const bgPatchUrl = `${createName(x.shot, x.light)}pch.png`;
    const bgPatchShadowUrl = `${createName(x.shot, x.light)}pch.sh.jpg`;
    const maskUrl = `${createName(x.shot, x.light)}m.png`;
    const shadowUrl = `${createName(x.shot, x.light)}sh.jpg`;
    const highlightUrl = `${createName(x.shot, x.light)}hl.jpg`;
    const glowUrl = `${createName(x.shot, x.light)}gl.jpg`;

    if (files.includes(bgUrl)) {
      const fullBgUrl = makeUrl(baseUrl, bgUrl);
      readImage(fullBgUrl).then((image) => {
        setbgImage(image);
      })
    }
    if (files.includes(bgPatchUrl)) readImage(makeUrl(baseUrl, bgPatchUrl)).then(() => { })
    if (files.includes(bgPatchShadowUrl)) readImage(makeUrl(baseUrl, bgPatchShadowUrl)).then(() => { })
    if (files.includes(maskUrl)) readImage(makeUrl(baseUrl, maskUrl)).then(() => { })
    if (files.includes(shadowUrl)) readImage(makeUrl(baseUrl, shadowUrl)).then(() => { })
    if (files.includes(highlightUrl)) readImage(makeUrl(baseUrl, highlightUrl)).then(() => { })
    if (files.includes(glowUrl)) readImage(makeUrl(baseUrl, glowUrl)).then(() => { })
  }

  return (
    <div
      className="App upload-preview"
      style={{
        width: "100%", height: "100%", position: "absolute"
        // ,
        // backgroundImage: `url("${bgImage}")`
      }}
    // onDragEnter={(e) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }}
    // onDragOver={(e) => {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }}
    >
      {bgImage && <img src={bgImage.src}></img>}
      <AtSpinnerOverlay show={isLoading} />
      <input
        type="file"
        accept=".png,.jpg"
        id="upload-room"
        className="cameraInputs"
        style={{ display: "none" }}
        onInput={() => { }}
      />
      {showTutorial && designImageProps && <div className="at-tutorial-content-row">
        <Tutorial0 />
        <span>Drag the carpet to move it</span>
      </div>}
      {roomData && designImageProps && (
        <RoomViewNew
          show={showRoomView}
          onRendered={() => {
            setIsLoading(false);
          }}
          onRoomLoaded={() => {
            window.parent.postMessage("success", "*");
          }}
          roomData={roomData}
          designImageProps={designImageProps}
          onInteracted={() => setshowTutorial(false)}
        />
      )}
    </div>
  );
}

export default App;
