import LocalizedStrings from "react-localization";
import deepmerge from "deepmerge";
//categories: Design, visualization, Favorites, COlors, copyright,
const defaultStrings = {
  en: {
    copyrightText:
      "The designs shown here are copyright of the respective owners. Copying is strictly prohibited. Colors are approximate and need to be verified with actual pom samples before ordering.",
    tagline: "",
    design: {
      designs: "Designs",
      designViews: "Design Views",
      designVariations: "Design Variations",
      design: "Design",
      original: "Original",
      showAllDesigns: "Show all designs",
      searchDesigns: "Search Designs"
    },
    visualization: {
      visualizations: "Visualizations",
      illustrationViews: "Illustration Views",
      visualization: "Visualization",
      photorealistic: "Photorealistic",
      searchVisualizations: "Search Visualizations"
    },
    color: {
      colorsAndCons: "Colors & Construction",
      colorCustomization: "Color Customization",
      mixedColors: "Multiple Colors",
      mixedTextures: "Mixed Textures",
      infotext: "Final color to be verified with physical pom samples before ordering.",
      basicMaterialTitle: "Basic",
      mixedConstructionsTitle: "Mixed Constructions",
      similarColors: "Similar Colors",
      sortingBySequence: "By Sequence",
      sortingByName: "By Name",
      sortingByColor: "By Color",
      sortingBySharpness: "By Sharpness",
      sortingByBrightness: "By Brightness",
      searchColorByName: "Search for color by name",
      ascending: "Ascending",
      descending: "Descending",
      selectColor: "Select color"
    },
    favorite: {
      favorites: "Favorites",
      emptyFavText1: "It’s a bit lonely here.",
      emptyFavText2:
        "Click the heart on the top right for any design you would like to see here later.",
      addToFavorite: "Add to Favorites",
      saveAsFavorite: "Save Design As Favorite",
      dialogText: "Please provide a name for favorite design",
      designExistsTxt: "Design already exists, name should be changed to save"
    },
    ordersheet: {
      placeOrder: "Order Sheet",
      orderSheet: "Order Sheet",
      length: "Length",
      height: "Height",
      width: "Width",
      unit: "Unit",
      quality: "Quality",
      totalArea: "Total Area is",
      standardSizes: "Standard Sizes",
      requestQuote: "Request Quote",
      saveOrdersheet: "Save Ordersheet",
      orderEmail: "Order Email",
      yourName: "Your Name",
      yourEmailAddress: "Your email address",
      yourPhoneNumber: "Your phone number",
      notes: "Notes",
      sendEmail: "Send Email",
      orderUnits: {
        ft: "ft",
        cm: "cm"
      },
      emailMessages: {
        sending: "Sending email",
        sent: "Email sent successfully",
        fail: "There seems to be an issue. Please try in again in a while",
        incompleteInfo: "Please enter your name and email address",
        invalidEmail: "Please check your email address and try again"
      }
    },
    tools: {
      quicklinkTitle: "Quick Link",
      quicklinkText:
        "With this Quick Link you can access this customized design from your phone or tablet for reviewing and ordering.",
      undo: "Undo",
      redo: "Redo",
      reset: "Reset",
      autoRecolor: "Autocolor",
      addToCart: "Add to Cart",
      carving: "Carving",
      shuffleColors: "Shuffle Colors",
      mapColors: "Map Colors",
      showColorPercentage: "Show Color Percentage",
      hideColorPercentage: "Hide Color Percentage",
      saveAsImage: "Save this image",
      publishOnline: "Publish for online sharing",
      quickShare: "Quick Share",
      fileManager: "File Manager",
      downloadCTF: "Download CTF",
      clearCacheThisDesign: "Clear Cache for this Design",
      clearCacheAllDesign: "Clear Cache for all Designs",
      clearCacheTexture: "Clear texture cache",
      clearCacheColor: "Clear color cache",
      clearCacheOther: "Clear other cache",
      stripedDesign: "Create another Stripe Design",
      saveAndReplaceOnserver: "Save on Server and Replace",
      saveAndCopyOnServer: "Save on Server as Copy",
      saveOnServer: "Save on Server",
      designSaveSuccess: "Design saved successfully",
      designSaveFail: "Could not save design",
      save: "Save",
      cancel: "Cancel",
      close: "Close",
      renameDesign: "Rename Design"
    },
    search: {
      found: "Found",
      noDesignFound: {
        firstLine: "Sorry, we could not find a design with that name.",
        secondLine: "Try a different name or search for Visualizations."
      },
      noVisualizationFound: {
        firstLine: "Sorry, we could not find a visualization with that name.",
        secondLine: "Try a different name or search for Designs."
      }
    },
    designViews: {
      deepZoom: "Top View",
      perspective: "Interactive Perspective View",
      deepZoomRx: "Top View RepeatX"
    },
    myRoom: {
      uploadImage: "Upload an Image",
      takephoto: "Take Photo",
      openCam: "Open Camera",
      capture: "Capture",
      saveRoomText: "Give your room a name"
    }
  },
  fr: {
    copyrightText:
      "Les dessins présentés ici sont la propriété de leurs propriétaires respectifs. La copie est strictement interdite. Les couleurs sont approximatives et doivent être vérifiées avec des échantillons de pom réels avant de commander.",
    tagline: "",
    design: {
      designs: "Dessins",
      designViews: "Vues de conception",
      designVariations: "Variations de conception",
      design: "Conception",
      original: "Original",
      showAllDesigns: "Afficher tous les designs",
      searchDesigns: "Rechercher des designs"
    },
    visualization: {
      visualizations: "Visualisations",
      illustrationViews: "Vues d'illustration",
      visualization: "Visualisation",
      photorealistic: "Photoréaliste",
      searchVisualizations: "Rechercher des visualisations"
    },
    color: {
      colorsAndCons: "Couleurs et construction",
      colorCustomization: "Personnalisation des couleurs",
      mixedColors: "Couleurs multiples",
      mixedTextures: "Textures mixtes",
      infotext:
        "La couleur finale doit être vérifiée avec des échantillons de pom physiques avant la commande.",
      basicMaterialTitle: "De base",
      mixedConstructionsTitle: "Constructions mixtes",
      similarColors: "Couleurs similaires",
      sortingBySequence: "Par séquence",
      sortingByName: "De nom",
      sortingByColor: "Par couleur",
      sortingBySharpness: "Par netteté",
      sortingByBrightness: "Par luminosité",
      searchColorByName: "Recherche de couleur par nom",
      ascending: "Ascendant",
      descending: "Descendant",
      selectColor: "Choisissez la couleur"
    },
    favorite: {
      favorites: "Favoris",
      emptyFavText1: "C'est un peu solitaire ici.",
      emptyFavText2:
        "Cliquez sur le cœur en haut à droite pour tout motif que vous aimeriez voir plus tard.",
      addToFavorite: "Ajouter aux Favoris",
      saveAsFavorite: "Enregistrer le design comme favori",
      dialogText: "Veuillez fournir un nom pour le design préféré",
      designExistsTxt: "Le design existe déjà, le nom doit être changé pour enregistrer"
    },
    ordersheet: {
      placeOrder: "Bon de commande",
      orderSheet: "Bon de commande",
      length: "Longueur",
      height: "la taille",
      width: "Largeur",
      unit: "Unité",
      quality: "Qualité",
      totalArea: "La superficie totale est",
      standardSizes: "Tailles standard",
      requestQuote: "Citation requise",
      saveOrdersheet: "Enregistrer la feuille de commande",
      orderEmail: "E-mail de commande",
      yourName: "votre nom",
      yourEmailAddress: "Votre adresse email",
      yourPhoneNumber: "Votre numéro de téléphone",
      notes: "Remarques",
      sendEmail: "Envoyer un e-mail",
      orderUnits: {
        ft: "pi",
        cm: "cm"
      }
    },
    tools: {
      quicklinkTitle: "Lien rapide",
      quicklinkText:
        "Avec ce lien rapide, vous pouvez accéder à cette conception personnalisée à partir de votre téléphone ou tablette pour examen et commande.",
      undo: "annuler",
      redo: "Refaire",
      reset: "Réinitialiser",
      autoRecolor: "Autocolor",
      addToCart: "Ajouter au chariot",
      carving: "Sculpture",
      shuffleColors: "Mélanger les couleurs",
      mapColors: "Couleurs de la carte",
      showColorPercentage: "Afficher le pourcentage de couleur",
      hideColorPercentage: "Masquer le pourcentage de couleur",
      saveAsImage: "Sauvegarder cette image",
      publishOnline: "Publier pour le partage en ligne",
      quickShare: "Partage rapide",
      fileManager: "Gestionnaire de fichiers",
      downloadCTF: "Télécharger CTF",
      clearCacheThisDesign: "Vider le cache pour cette conception",
      clearCacheAllDesign: "Vider le cache pour tous les designs",
      clearCacheTexture: "Effacer le cache de texture",
      clearCacheColor: "Effacer le cache des couleurs",
      clearCacheOther: "Effacer l'autre cache",
      stripedDesign: "Créer un autre motif de rayures",
      saveAndReplaceOnserver: "Enregistrer sur le Serveur et remplacer",
      saveAndCopyOnServer: "Enregistrer sur le Serveur en tant que copie",
      saveOnServer: "Économisez sur le Serveur",
      designSaveSuccess: "Conception enregistrée avec succès",
      designSaveFail: "Impossible d'enregistrer le design",
      save: "sauver",
      cancel: "Annuler",
      close: "Fermer",
      renameDesign: "Renommer le design"
    },
    search: {
      found: "A trouvé",
      noDesignFound: {
        firstLine: "Désolé, nous n'avons pas trouvé de design portant ce nom.",
        secondLine: "Essayez un autre nom ou recherchez des visualisations."
      },
      noVisualizationFound: {
        firstLine: "Désolé, nous n'avons pas trouvé de visualisation portant ce nom.",
        secondLine: "Essayez un autre nom ou recherchez Designs."
      }
    },
    designViews: {
      deepZoom: "Vue de dessus",
      deepZoomRx: "Vue de dessus RepeatX"
    },
    myRoom: {
      uploadImage: "Télécharger une image",
      takephoto: "Prendre une photo",
      openCam: "Ouvrir la caméra",
      capture: "Capturer",
      saveRoomText: "Donnez un nom à votre chambre"
    },
    fit: "Vue par défaut"
  }
};

const strings = new LocalizedStrings(defaultStrings);
export const initializeStrings = customStrings => {
  const obj = defaultStrings;
  let newObj = {};
  const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
  // newObj = deepmerge(defaultStrings, customStrings, { arrayMerge: overwriteMerge });
  let flag = false;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (customStrings.hasOwnProperty(key)) {
        flag = true;
      }
    }
  }
  if (!flag) {
    newObj = deepmerge(obj, { en: customStrings }, { arrayMerge: overwriteMerge });
  } else {
    newObj = deepmerge(obj, customStrings, { arrayMerge: overwriteMerge });
  }
  strings.setContent(newObj);
  //console.log("TCL: newObj", JSON.stringify(defaultStrings));
};
export default strings;
