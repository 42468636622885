import * as THREE from "three";
import { OrbitControls } from "../../utils/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import {
  convertArrIntoRad,
  convertArrintoDeg,
  convertUnit,
  areaOfellipse,
  createVector,
  readImage
} from "../../utils/utils";
import { CanvasTexture } from "three";
import { createCanvas } from "../../utils/canvasutils";
import { makeUrl } from "../../utils/utils";

class ResourceTracker {
  constructor() {
    this.resources = new Set();
    this.resourceslocal = new Set();
  }
  track(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.track(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resources.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.track(resource.geometry);
      this.track(resource.material);
      this.track(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.track(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  tracklocal(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.tracklocal(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resourceslocal.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.tracklocal(resource.geometry);
      this.tracklocal(resource.material);
      this.tracklocal(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.tracklocal(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.tracklocal(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  untrack(resource) {
    this.resources.delete(resource);
  }
  untracklocal(resource) {
    this.resourceslocal.delete(resource);
  }
  async dispose() {
    return new Promise((resolve) => {
      const allresources = new Set([...this.resources, ...this.resourceslocal])
      let count = allresources.size;
      if (!count || count <= 0) resolve();
      for (const resource of allresources) {
        if (resource instanceof THREE.Object3D) {
          if (resource.parent) {
            resource.parent.remove(resource);
          }
        }
        if (resource.dispose) {
          resource.dispose();
        }
        count--;
        if (count <= 0) resolve();
      }
      this.resources.clear();
      this.resourceslocal.clear();
    })

  }
  disposelocal() {
    for (const resource of this.resourceslocal) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resourceslocal.clear();
  }
}


export default class ThreeViewHelper {
  constructor() {
    this.scene = new THREE.Scene();

    this.textureLoader = new THREE.TextureLoader();
    this.fbxLoader = new FBXLoader();
    this.raycaster = new THREE.Raycaster();
    this.offset = new THREE.Vector3();
    this.carpetLoaded = false;
    this.objectLoaded = false;
    this.objects3d = [];
    this.resTracker = new ResourceTracker();
    this.track = this.resTracker.track.bind(this.resTracker);
    this.tracklocal = this.resTracker.tracklocal.bind(this.resTracker);
  }
  init({ canvas, config = {}, shot, dims = {}, surfaceName = "surface1", resolution, roomType, baseUrl }) {
    if (this.resTracker) {
      this.carpetLoaded ? this.resTracker.disposelocal() : this.resTracker.dispose();
    }
    let { width = window.innerWidth, height = window.innerHeight } = dims;
    this.w = width;
    this.h = height;
    this.surfaceName = surfaceName;
    this.sceneConfig = config;
    this.objProps = config[surfaceName];
    this.baseUrl = baseUrl;
    this.roomType = roomType
    if (roomType === "illustration") {
      canvas.style.visibility = "hidden";
    } else {
      canvas.style.visibility = "inherit";
    }
    this.renderer = this.tracklocal(new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: false
    }));
    // window.renderer.push(this.renderer);
    this.renderer.setPixelRatio(resolution);
    this.renderer.setSize(width, height);
    const camConfig = config[shot];
    this.camera = this.tracklocal(perspectiveCamera({ ...camConfig, width, height }));
    this.scene.add(this.camera);
    window.scene = this.scene;
    this.orbit = addOrbitControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit.enabled = true;
    // this.orbit.screenSpacePanning = true;
  }
  setupLights() {
    this.directionalLight = this.track(new THREE.DirectionalLight(0xffffff, 0.9));
    this.scene.add(this.directionalLight);
    this.ambientLight = this.track(new THREE.AmbientLight(0xffffff, 0.5));
    this.scene.add(this.ambientLight);
  }
  removeLights() {
    if (this.directionalLight) {
      this.scene.remove(this.directionalLight);
    };
    if (this.ambientLight) {
      this.scene.remove(this.ambientLight);
    }
  }
  // setup3dObject({ fbxUrl }) {
  //   if (this.carpetLoaded) {
  //     this.scene.remove(this.carpetMesh);
  //     const tarObj = this.scene.getObjectByName("TargetObject");
  //     this.scene.remove(tarObj);

  //     this.carpetLoaded = false;
  //     this.removeLights();
  //   }
  //   return new Promise((resolve, reject) => {
  //     if (!this.sceneConfig) {
  //       reject();
  //       return;
  //     }
  //     const { objects3d, surfaces, availableSizes = [] } = this.sceneConfig;
  //     const setupObjects = () => {
  //       let objectsLoaded = 0;
  //       objects3d.forEach(object3d => {
  //         const object = this.objectFbx.getObjectByName(object3d);
  //         if (!object) {
  //           console.warn("PHOTOGRAPHIC VIEW: object", object3d, "does not exist");
  //           return;
  //         }

  //         const objectConfig = this.sceneConfig[object3d];
  //         const {
  //           position = [0, 0, 0],
  //           rotation = [90, 0, 0],
  //           scale = [1, 1, 1],
  //           preset = false
  //         } = objectConfig;
  //         object.position.fromArray(position);
  //         object.scale.fromArray(scale);
  //         object.rotation.fromArray(convertArrIntoRad(rotation));
  //         this.scene.add(object);
  //         if (!preset) {
  //           this.objProps = objectConfig;
  //           if (surfaces) {
  //             const { back, front } = surfaces;
  //             this.objects3d.push(object.getObjectByName(front));
  //             if (back) {
  //               this.objectBack = object.getObjectByName(back);
  //               this.hasBackSurface = true;
  //             } else {
  //               this.hasBackSurface = false;
  //             }
  //           } else {
  //             if (this.material) {
  //               object.material = this.material;
  //               object.material.needsUpdate = true;
  //               this.render();
  //             }
  //             this.objects3d.push(object);
  //           }

  //           objectsLoaded++;

  //           if (objectsLoaded === objects3d.length) resolve(availableSizes);
  //         } else {
  //           const { defaultTexture, defaultScale = [9, 12] } = objectConfig;
  //           const { width: texWidth = 9, height: texHeight = 12, path } = defaultTexture;
  //           const textureUrl = `${this.baseUrl}/${path}`;
  //           let repeat = [1, 1];
  //           const rx = defaultScale[0] / texWidth;
  //           const ry = defaultScale[1] / texHeight;
  //           repeat = [rx, ry];
  //           readImage(textureUrl).then(image => {
  //             const { width, height } = image;
  //             const canv = createCanvas(width, height);
  //             canv.getContext("2d").drawImage(image, 0, 0, width, height);

  //             const texture = this.track(new THREE.CanvasTexture(canv));
  //             texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
  //             texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

  //             texture.repeat.fromArray(repeat);

  //             let material = this.track(new THREE.MeshBasicMaterial({
  //               map: texture,
  //               transparent: true,
  //               side: THREE.DoubleSide,
  //               alphaTest: 0.5
  //             }));

  //             object.material = material;
  //             object.material.needsUpdate = true;
  //             this.render();

  //             objectsLoaded++;
  //             if (objectsLoaded === objects3d.length) {
  //               this.objectLoaded = true;
  //               resolve(availableSizes);
  //             }
  //           });
  //         }
  //       });
  //     };
  //     if (!this.objectLoaded)
  //       this.fbxLoader.load(
  //         fbxUrl,
  //         obj => {
  //           this.objectFbx = obj;
  //           setupObjects();
  //         },
  //         undefined,
  //         console.error
  //       );
  //     else setupObjects();
  //   });
  // }

  setupSceneObjects() {
    if (!this.sceneConfig.objects3d) this.floorObjectKey = null
    else
      this.floorObjectKey = this.sceneConfig.objects3d.find(item => item === "floor" || item === "Floor" || item === "FLOOR")
    const carpet = this.setupCarpet();

    const floor = this.scene.getObjectByName("floor") || this.scene.getObjectByName("Floor") || this.scene.getObjectByName("FLOOR")
    if (floor) {
      this.scene.remove(floor);
      this.render()
    }
    const promiseArr = [carpet];
    if (this.floorObjectKey) {
      const floor = this.setupFloor();
      promiseArr.push(floor)
    } else {
      this.floor = null;
      this.bounds = null;
    };
    return Promise.all(promiseArr);
  }
  setupFloor() {
    return new Promise((resolve, reject) => {
      const { modelUrl } = this.sceneConfig
      const fbxUrl = makeUrl(this.baseUrl, modelUrl);
      // const fbxUrl = "/untitledB.FBX"

      const setup = () => {
        const objKey = this.floorObjectKey;
        const objConf = this.sceneConfig[objKey]
        const { position = [0, 0, 0], rotation = [90, 0, 0], scale } = objConf;
        this.floor.position.fromArray(position);
        this.floor.scale.fromArray(scale);
        this.floor.rotation.fromArray(convertArrIntoRad(rotation));
        this.floor.material = this.tracklocal(new THREE.MeshBasicMaterial({ side: THREE.DoubleSide, transparent: true }))
        this.floor.material.needsUpdate = true;
        this.floor.visible = true;
      }
      this.fbxLoader.load(fbxUrl, obj => {
        this.floor = this.tracklocal(obj.getObjectByName("floor") || obj.getObjectByName("Floor") || obj.getObjectByName("FLOOR"))
        this.scene.add(this.floor);
        setup();
        this.floor.geometry.computeBoundingBox();
        this.bounds = this.tracklocal(new THREE.Box3().setFromObject(this.floor));
        this.setupRoomBounds();
        resolve();
      }, undefined, console.error);
    })
  }
  setupCarpet() {
    const fbxUrl = "v3assets/rug.fbx";
    this.objConf = this.sceneConfig[this.surfaceName];
    const { position = [0, 0, 0], rotation = [90, 0, 0] } = this.objConf;
    if (this.objectLoaded) {
      this.objects3d.forEach(object3d => {
        this.scene.remove(object3d);
      });
      const tarObj = this.scene.getObjectByName("TargetObject");
      if (tarObj) {
        this.scene.remove(tarObj);
      }
      this.removeLights();
      this.objectLoaded = false;
    }
    return new Promise((resolve, reject) => {
      const setup = () => {
        // this.originalMesh =
        this.carpetMesh.position.fromArray(position);
        const { flagged } = this.sceneConfig;
        let fact = flagged ? 10 : 1;
        this.directionalLight.position.set(
          position[0] - 3000 / fact,
          position[1] + 3000 / fact,
          position[2]
        );
        var targetObject = this.tracklocal(new THREE.Object3D());
        targetObject.name = "TargetObject";
        targetObject.position.set(...position);
        this.scene.add(targetObject);
        this.directionalLight.target = targetObject;
        // let helper = new THREE.DirectionalLightHelper(this.directionalLight, 100, 0xff0000);
        // this.scene.add(helper);

        this.carpetMesh.rotation.fromArray(convertArrIntoRad(rotation.slice(0, 3)));
        if (this.designDetails) this.setCarpetScale(this.designDetails);

        if (this.material) {
          this.carpetMesh.material = this.material;
          this.carpetMesh.material.needsUpdate = true;
          this.render();
        }
        this.carpetLoaded = true;
        this.render();
        resolve();
      };
      if (!this.carpetLoaded)
        this.fbxLoader.load(
          fbxUrl,
          obj => {
            this.carpetMesh = this.track(obj.getObjectByName(this.surfaceName));
            this.scene.add(this.carpetMesh);
            this.setupLights();
            setup();
          },
          undefined,
          console.error
        );
      else setup();
    });
  }
  setupRoomBounds() {
    if (!this.bounds || !this.carpetMesh) return
    let smallBoxHalfSize = new THREE.Vector3();
    let boxBox = this.tracklocal(new THREE.Box3().setFromObject(this.carpetMesh)); // small box's bounding box
    boxBox.getSize(smallBoxHalfSize);
    smallBoxHalfSize.multiplyScalar(0.5);
    let min = this.bounds.min.clone();
    let max = this.bounds.max.clone();
    min = min.add(smallBoxHalfSize);
    max = max.sub(smallBoxHalfSize);
    this.clampingBox = this.tracklocal(new THREE.Box3(min, max));
  }
  setCarpetRotation(rotation) {
    const rot = convertArrIntoRad(rotation)
    if (!this.carpetLoaded || !this.carpetMesh) return;
    this.carpetMesh.rotation.fromArray(rot);
    this.render();
  }
  setCarpetPositon(position) {
    if (!this.carpetLoaded || !this.carpetMesh) return;
    this.carpetMesh.position.fromArray(position)
    this.render()
  }
  setCarpetScale(designDetails) {
    const { PhysicalWidth, PhysicalHeight, Unit, IsIrregular, KLRatio } = designDetails;
    if (!PhysicalWidth || !PhysicalHeight || !Unit) {
      console.error("Could not set carept scale");
      return;
    }
    const { flagged } = this.sceneConfig;
    let fact = flagged ? 10 : 1;

    const wid = convertUnit(Unit, "ft", PhysicalWidth);
    const hgt = convertUnit(Unit, "ft", PhysicalHeight);
    this.carpetMesh.scale.set(wid * KLRatio / fact, hgt / fact, IsIrregular ? 1 : 2.5 / fact);
    this.setupRoomBounds()
  }
  set3dObjectScale(designDetails) {
    const { unit: sceneUnit, availableSizes = [] } = this.sceneConfig;
    const { PhysicalWidth, PhysicalHeight, Unit, IsIrregular } = designDetails;
    if (!PhysicalWidth || !PhysicalHeight || !Unit) {
      console.error("Could not set carept scale");
      return;
    }

    const wid = convertUnit(Unit, sceneUnit, PhysicalWidth);
    const hgt = convertUnit(Unit, sceneUnit, PhysicalHeight);
    const size = availableSizes.find(
      item => item.PhysicalWidth === wid && item.PhysicalHeight === hgt
    );
    if (size) {
      this.change3dObjectScalePhysical(size);
      return size;
    }
  }
  change3dObjectScalePhysical(selectedSize) {
    this.objects3d.forEach(object3d => {
      const { name } = object3d;
      const { availableSizes = {} } = this.sceneConfig[name];
      const transformData = availableSizes[selectedSize.key] || {};
      const { position, rotation, scale } = transformData;
      if (position) object3d.position.fromArray(position);
      if (rotation) object3d.rotation.fromArray(convertArrIntoRad(rotation));
      if (scale) object3d.scale.fromArray(scale);
    });
    this.render();
  }
  setCarpetVisibility(visibility) {
    this.carpetMesh.visible = visibility;
    this.render();
  }
  // setObjectTexture({ designDetails, designCanvas, surfaceName }) {
  //   return new Promise((resolve, reject) => {
  //     const { surfaceUnit = "in", doubleSide } = this.objProps;
  //     const PhysicalWidth = convertUnit(
  //       designDetails.Unit,
  //       surfaceUnit,
  //       designDetails.PhysicalWidth
  //     );
  //     const PhysicalHeight = convertUnit(
  //       designDetails.Unit,
  //       surfaceUnit,
  //       designDetails.PhysicalHeight
  //     );
  //     this.designDetails = {
  //       ...designDetails,
  //       PhysicalHeight,
  //       PhysicalWidth,
  //       Unit: surfaceUnit
  //     };
  //     const designTexture = new CanvasTexture(designCanvas);
  //     designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
  //     designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
  //     this.material = this.track(new THREE.MeshBasicMaterial({
  //       map: designTexture,
  //       transparent: true,
  //       side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
  //       alphaTest: 0.5
  //     }));
  //     if (!this.objects3d.length) {
  //       console.error("could not find the object");
  //       resolve();
  //       return;
  //     }
  //     const size = this.set3dObjectScale(this.designDetails);
  //     this.objects3d.forEach(object3d => {
  //       object3d.material = this.material;
  //       object3d.material.needsUpdate = true;
  //     });

  //     this.render();
  //     resolve(size);
  //   });
  // }
  setCarpetTexture({ designDetails, designCanvas, normapCanvas }) {
    if (!this.carpetMesh) return null;
    this.designDetails = designDetails;

    const designTexture = new CanvasTexture(designCanvas);
    const normalTexture = new CanvasTexture(normapCanvas);
    // designTexture.magFilter = THREE.LinearFilter;
    // designTexture.minFilter = THREE.LinearFilter;
    // normalTexture.magFilter = THREE.LinearFilter;
    // normalTexture.minFilter = THREE.LinearFilter;

    designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    normalTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    this.material = this.tracklocal(new THREE.MeshStandardMaterial({
      map: designTexture,
      normalMap: normalTexture,
      // alphaMap: alphaTexture,
      roughness: 1,
      metalness: 0.1,
      needsUpdate: true,
      transparent: true,
      side: THREE.FrontSide
    }));

    if (this.designDetails.IsIrregular) {
      this.carpetMesh.position.y += 200;
    }

    this.setCarpetScale(this.designDetails);
    this.carpetMesh.material = this.material;
    this.carpetMesh.material.needsUpdate = true;
    this.render();
    return true;
  }
  setFloorTexture(floorOption) {
    if (!this.floor || !this.floor.material || !this.renderer || !floorOption) return
    window.floor = this.floor;
    const { floorCanvas, width, height } = floorOption;
    if (!floorCanvas) {
      this.floor.visible = false;
      this.render()
      return;
    }
    const objConf = this.sceneConfig[this.floorObjectKey]
    const { scale, size } = objConf;
    const texture = new CanvasTexture(floorCanvas);
    texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
    // const repeat = [(size[0] * scale[0]) / (floorCanvas.width / 305), (size[1] * scale[1]) / (floorCanvas.height / 305)]

    if (size) {
      const repeat = [(size[0]) / width, (size[1]) / height]
      console.log(repeat);
      texture.repeat.set(repeat[0], repeat[1]);
      // texture.repeat.set(1, 1);
      // texture.repeat.set((size[0]) / (floorCanvas.width / 305), (size[1]) / (floorCanvas.height / 305));
    }
    this.floor.material.map = texture
    this.floor.material.map.needsUpdate = true;
    this.floor.material.needsUpdate = true;
    if (!this.floor.visible) this.floor.visible = true
    this.render()
  }

  changeFloorVisibility(visible) {
    if (!this.floor) return;
    this.floor.visible = visible;
    this.render()
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  setFov(value) {
    const { camera } = this;
    camera.fov = value;
    camera.updateProjectionMatrix();
    this.render();
  }
  mouseDownTouchStart(e) {
    if (!this.carpetMesh) return;
    let intersect = this.raycastMouseOnCarpet(e);
    if (!intersect) return;
    const objPos = this.carpetMesh.position.clone();
    this.offset.copy(intersect.point).sub(objPos);
    return intersect;
  }

  mouseTouchMove(e) {
    if (!this.carpetMesh) return;
    //TODO:instead of casting on carpet, cast on an infinite plane 
    let intersect = this.raycastMouseOnCarpet(e);
    if (!intersect) return
    const objPos = this.carpetMesh.position.clone();
    const sub = intersect.point.sub(this.offset);
    sub.y = objPos.y;
    const subClamped = sub.clone();
    if (this.bounds) subClamped.clamp(this.clampingBox.min, this.clampingBox.max)
    subClamped.y = objPos.y;
    this.carpetMesh.position.copy(subClamped);
    this.render();
  }
  raycastMouseOnCarpet(e) {
    const { x, y } = e;
    let { mouseX, mouseY } = this.convMouseCord(x, y);
    var mouse = new THREE.Vector3(mouseX, mouseY, 0.99);
    this.raycaster.setFromCamera(mouse, this.camera);
    var intersects = this.raycaster.intersectObject(this.carpetMesh);
    return intersects[0];
  }
  convMouseCord(x, y) {
    // const { offsetX, offsetY } = this.getRendererOffset();
    const vec = new THREE.Vector2();
    const { width, height } = this.renderer.getSize(vec);

    var mouseX = (x / width) * 2 - 1;
    var mouseY = -(y / height) * 2 + 1;
    return { mouseX, mouseY };
  }
  getCameraConfig() {
    const position = this.camera.position.toArray();
    const rotation = convertArrintoDeg(this.camera.rotation.toArray());
    const target = this.orbit.target.position.toArray();
    return { position, rotation, target };
  }
  rotateCarpet(rotationInDegrees, axis) {
    if (!this.carpetMesh) return;
    const { rotationFlag } = this.sceneConfig;
    if (!rotationFlag) this.carpetMesh.rotation[axis] += (rotationInDegrees * Math.PI) / 180;
    else this.carpetMesh.rotation[axis] -= (rotationInDegrees * Math.PI) / 180;
    this.render();
  }
  scaleObject(surfaceName, scaleFactor, axis) {
    let object = this.scene.getObjectByName(surfaceName);
    object.scale[axis] += scaleFactor;
    this.render();
  }
  attachTransformControls(surfaceName) {
    let object = this.scene.getObjectByName(surfaceName);

    this.transform.attach(object);
    this.scene.add(this.transform);
  }
  getRendererOffset() {
    var offsetY = this.renderer.domElement.offsetTop;
    var offsetX = this.renderer.domElement.offsetLeft;
    return { offsetX, offsetY };
  }
  clearScene() {
    return new Promise((resolve, reject) => {
      var objsToRemove = [...this.scene.children];
      let removeCount = objsToRemove.length;
      const resolveFunc = async () => {
        if (this.resTracker) {
          await this.resTracker.disposelocal();
        }
        resolve();
      }
      if (removeCount === 0) {
        this.carpetLoaded = false;
        this.objectLoaded = false;
        this.objects3d = [];
        resolveFunc();
      }
      objsToRemove.forEach((object,) => {
        this.scene.remove(object);
        removeCount--;
        if (removeCount === 0) {
          if (this.renderer) this.render();
          this.carpetLoaded = false;
          this.objectLoaded = false;
          this.objects3d = [];
          resolveFunc();
        }
      })


    });
  }

  toScreenXY(position, camera) {
    var pos = position.clone();
    let projScreenMat = new THREE.Matrix4();
    projScreenMat.multiply(camera.projectionMatrix, camera.matrixWorldInverse);
    projScreenMat.multiplyVector3(pos);
    const { offsetX, offsetY } = this.getRendererOffset();
    return {
      x: ((pos.x + 1) * this.w) / 2 + offsetX,
      y: ((-pos.y + 1) * this.h) / 2 + offsetY
    };
  }

  getCarpetPositions() {
    this.carpetMesh.geometry.computeBoundingBox();
    let box = this.carpetMesh.geometry.boundingBox;
    const widthheight = box.max.sub(box.min);

    const plane = this.tracklocal(new THREE.PlaneGeometry(widthheight.x, widthheight.y));
    const mat = this.tracklocal(new THREE.MeshBasicMaterial({ color: 0xff0000 }));
    const m = this.tracklocal(new THREE.Mesh(plane, mat));
    m.scale.copy(this.carpetMesh.scale);
    m.position.copy(this.carpetMesh.position);
    m.rotation.copy(this.carpetMesh.rotation);
    const a = [];
    plane.vertices.forEach(vertex => {
      const v = vertex.clone();
      v.applyMatrix4(this.carpetMesh.matrixWorld);
      a.push(v);
    });
    const b = a.map(vertex => createVector(vertex, this.camera, this.w, this.h));
    return [b[0], b[1], b[3], b[2]];
  }

  toggleOrbitControls(enable) {
    this.orbit.enabled = enable;
    this.orbit.update();
  }
  toggleScreenSpacePanning(enable) {
    this.orbit.screenSpacePanning = enable;
    this.orbit.update();
  }
  toggleOrbitLockAxis(axis, enable) {
    if (axis === 0) {
      this.orbit.lockVertical = enable;
      this.orbit.update();
    } else if (axis === 1) {
      this.orbit.lockHorizontal = enable;
      this.orbit.update();
    }
  }
  changeShot(shotConfig) {
    const { position = [0, 0, 0], rotation = [90, 0, 0], target, fov } = shotConfig;
    const { camera } = this;
    camera.fov = fov;
    camera.updateProjectionMatrix();
    camera.position.fromArray(position);
    camera.rotation.fromArray(rotation);
    // camera.lookAt(...target);
    this.orbit.target.fromArray(target);
    this.orbit.update();
    this.render();
  }
  updateMap() {

    // const mapCanvas = this.carpetMesh.material.map.image;

    // let alphaCanvasBlack = createCanvas(mapCanvas.width, mapCanvas.height);
    // let alphaBlackCtx = alphaCanvasBlack.getContext('2d');
    // alphaBlackCtx.beginPath();
    // alphaBlackCtx.rect(0, 0, mapCanvas.width, mapCanvas.height);
    // alphaBlackCtx.fillStyle = "black";
    // alphaBlackCtx.fill();
    // alphaBlackCtx.globalCompositeOperation = "destination-out";
    // alphaBlackCtx.drawImage(mapCanvas, 0, 0, mapCanvas.width, mapCanvas.height);

    // let alphaCanvas = createCanvas(mapCanvas.width, mapCanvas.height);
    // let alphaCtx = alphaCanvas.getContext('2d');
    // alphaCtx.beginPath();
    // alphaCtx.rect(0, 0, mapCanvas.width, mapCanvas.height);
    // alphaCtx.fillStyle = "white";
    // alphaCtx.fill();
    // alphaCtx.drawImage(alphaCanvasBlack, 0, 0, mapCanvas.width, mapCanvas.height);

    // const alphaTexture = new CanvasTexture(mapCanvas);
    // this.carpetMesh.material.alphaMap = alphaTexture;

    if (this.carpetMesh && this.carpetMesh.material.map) {
      this.carpetMesh.material.map.needsUpdate = true;
      this.carpetMesh.material.normalMap.needsUpdate = true;
      this.carpetMesh.material.needsUpdate = true;
    }
    this.objects3d.forEach(object3d => {
      if (object3d && object3d.material.map) {
        object3d.material.map.needsUpdate = true;
        object3d.material.needsUpdate = true;
      }
    });
    this.render();
  }
  resizeRenderer({ width, height }) {
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
  getObjectConfig() {
    if (this.objectLoaded) {
      return null;
    } else {
      return this.carpetMesh;
    }
  }
  calculateCarpetSize() {
    if (!this.carpetMesh) return;
    const carpetSize = new THREE.Vector3();
    var box = this.tracklocal(new THREE.Box3());
    box.setFromObject(this.carpetMesh);
    box.getSize(carpetSize);
    // this.carpetMesh.geometry.computeBoundingBox();
    // this.carpetMesh.geometry.boundingBox.getSize(carpetSize);
    return carpetSize;
  }
  distbetween2Vertices(vertex1, vertex2, axis) {
    const { camera, renderer } = this;
    const vec = new THREE.Vector2();
    renderer.getSize(vec);
    const { x: width, y: height } = vec;
    const v1 = createVector(vertex1, camera, width, height);
    const v2 = createVector(vertex2, camera, width, height);
    const xDist = Math.abs(Math.abs(v2.x) - Math.abs(v1.x));
    const yDist = Math.abs(Math.abs(v2.y) - Math.abs(v1.y));
    return { xDist: xDist, yDist: yDist };
  }
  getGizmoCordinates() {
    const carpetSize = this.calculateCarpetSize();
    if (!carpetSize) return;
    const smallerDim = carpetSize.x > carpetSize.y ? carpetSize.x : carpetSize.y;
    const carpetRadius = smallerDim / 5;
    const carpetCenter = this.carpetMesh.position.clone();

    const vertex1 = carpetCenter.clone();
    const vertex2 = new THREE.Vector3(
      carpetCenter.x,
      carpetCenter.y,
      carpetCenter.z + carpetRadius
    );

    const dist1 = this.distbetween2Vertices(vertex1, vertex2);
    const radYY = dist1.yDist;
    const radYX = dist1.xDist;

    const vertex3 = new THREE.Vector3(
      carpetCenter.x + carpetRadius,
      carpetCenter.y,
      carpetCenter.z
    );
    //TODO:this could be point of failure
    const vertex4 = carpetCenter.clone();
    const dist2 = this.distbetween2Vertices(vertex3, vertex4);
    const radXX = dist2.xDist;
    const radXY = dist2.yDist;

    const area1 = areaOfellipse(radYY, radXX);
    const area2 = areaOfellipse(radXY, radYX);
    let radX, radY;
    if (area1 > area2) {
      radX = radXX;
      radY = radYY;
    } else {
      radX = radXY;
      radY = radYX;
    }
    const canvasCenter = createVector(carpetCenter, this.camera, this.w, this.h);
    return { radX, radY, canvasCenter };
  }
  // downloadImageData() {
  //   const dataurl = this.renderer.domElement.toBlob(blob => {
  //     var strData = URL.createObjectURL(blob);
  //     var link = document.createElement("a");
  //     document.body.appendChild(link); //Firefox requires the link to be in the body
  //     link.setAttribute("download", "download.png");
  //     link.href = strData;
  //     link.click()
  //     document.body.removeChild(link); //remove the link when done
  //   })

  // }
}
export const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new THREE.Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};
export const addOrbitControl = function (renderer, scene, camera, config = {}) {
  let { target = [0, 0, 0] } = config;
  const control = new OrbitControls(camera, renderer.domElement);
  control.enableKeys = false;
  control.target = new THREE.Vector3(...target);
  control.addEventListener("change", () => {
    renderer.render(scene, camera);
  });
  control.update();
  return control;
};
export const loadFbx = url => {
  return new Promise((resolve, reject) => {
    new FBXLoader().load(url, resolve, undefined, reject);
  });
};
