import React from "react";
import { Overlay, H5, H2 } from "@blueprintjs/core";
import Thumbnail from "../molecules/Thumbnail";
import { useRoomConfigState, useDispatchRoomConfig, roomConfigActions } from "./roomconfig.reducer";
import AtDialog from "../molecules/AtDialog";
import AtIcon from "../atoms/AtIcon";

const FloorSelectionDialog = props => {
  const { floorOptions, handleClose, handleThumbClick, handleReset } = props;
  return (
    <>
      <AtDialog
        isOpen={true}
        onClose={() => {
          handleClose();
        }}
        className=" illustration-dialog"
        overlayClassName={floorOptions.show ? "floordialog-popup-show" : "floordialog-popup-hide"}
        size="lg"
        // portalContainerId="vps-main"
      >
        <div className="illustration-dialog-header-row">
          <div style={{ display: "flex" , alignItems: 'center'}}>
            {/* <img src={window.logourl} alt="logo" /> */}
            <H2>Please choose your floor</H2>
          </div>
          <AtIcon icon="close" size={2.5} onClick={handleClose} />
        </div>
        <div className={"category-container"}>
          {/* {scenesData &&
            scenesData.map((category, i) => {
              return ( */}
          <div className={"category-wrapper"}>
            <div className={"thumb-item category-heading"}>
              <div className={"thumb-image-container landscape "}>
                <span className="thumb-heading">Royal Mosa</span>
                <span className="thumb-subheading">
                  The floor tiles in this collection are used with the courtesy of{" "}
                  <a href="http://www.mosa.com/" target="_blank" style={{ display: "block" }}>
                    Royal Mosa
                  </a>
                </span>
              </div>
            </div>

            <div className="flooroptions-thumbs-wrapper">
              {handleReset && (
                <div className="thumb-item  reset-thumb" onClick={handleReset}>
                  <div
                    className="thumb-image-container landscape "
                    style={{ backgroundImage: "none", backgroundSize: "contain" }}
                  >
                    <span>Original</span>
                  </div>
                </div>
              )}
              {floorOptions.floors.map((floor, i) => {
                return (
                  <Thumbnail
                    thumb={{ Thumb: floor.thumb ? floor.thumb : floor.path, label: floor.name }}
                    active={
                      floorOptions.activeFloor && floor.path === floorOptions.activeFloor.path
                    }
                    infoThumbs={[]}
                    aspect="landscape"
                    key={i}
                    onThumbnailClick={() => {
                      handleThumbClick(floor);
                      // handleClose();
                    }}
                  />
                );
              })}
            </div>

            {/* {i < scenesData.length - 1 && */}
            {/* <div className="category-divider"></div> */}

            {/* } */}
          </div>
          {/* );
            })} */}
        </div>
      </AtDialog>
    </>
  );
};

export default FloorSelectionDialog;
