import React from "react";
const SET_VIEW_MODE = "SET_VIEW_MODE";
const SET_ILLUSTRATIONS = "SET_ILLUSTRATIONS";
const SET_ACTIVE_NODE = "SET_ACTIVE_ILLUSTRATION_NODE";
const SELECT_NEXT_ILLUSTRATION = "SELECT_NEXT_ILLUSTRATION";
const SELECT_PREV_ILLUSTRATION = "SELECT_PREV_ILLUSTRATION"
const visViewModes = {
  ROOM_VIEW: "ROOM_VIEW",
  MY_ROOM: "MY_ROOM",
  DESIGN_VIEW_DEEPZOOM: "DESIGN_VIEW_DEEPZOOM", //deep zoom view
  DESIGN_VIEW_DEEPZOOM_RX: "DESIGN_VIEW_DEEPZOOM_RX",  //deep zoom with repeat x
  DESIGN_VIEW_PERSPECTIVE: "DESIGN_VIEW_PERSPECTIVE",
  DESIGN_VIEW_PHOTOGRAPHIC: "DESIGN_VIEW_PHOTOGRAPHIC"
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const visualizationsActions = {
  SET_VIEW_MODE,
  SET_ILLUSTRATIONS,
  SET_ACTIVE_NODE,
  SELECT_NEXT_ILLUSTRATION,
  SELECT_PREV_ILLUSTRATION
}

const visualizationState = {
  viewMode: visViewModes.DESIGN_VIEW,
  illustrations: [],
  activeNode: {
    id: "0"
  }
}

const visualizationReducer = (state, action) => {
  //console.log(action.type)
  switch (action.type) {
    case SET_VIEW_MODE:
      return { ...state, viewMode: action.payload }
    case SET_ILLUSTRATIONS:
      return { ...state, illustrations: action.payload }
    case SET_ACTIVE_NODE:
      if (state.activeNode) {
        if (state.activeNode.id !== action.payload.id || state.viewMode !== visViewModes.ROOM_VIEW) {
          return { ...state, activeNode: action.payload };
        } else return state;
      } else
        return { ...state, activeNode: action.payload };
    case SELECT_PREV_ILLUSTRATION:
      return selPrevIll(state)
    case SELECT_NEXT_ILLUSTRATION:
      return selNextIll(state)
    default:
      return state;
  }
}
const selPrevIll = (state) => {
  const ind = state.illustrations.findIndex(item => item.id === state.activeNode.id)
  if (ind < 0 || ind === 0)
    return state
  return { ...state, activeNode: state.illustrations[ind - 1] }
}
const selNextIll = (state) => {
  const ind = state.illustrations.findIndex(item => item.id === state.activeNode.id)
  if (ind < 0 || ind >= state.illustrations.length - 1)
    return state
  return { ...state, activeNode: state.illustrations[ind + 1] }
}
function VisualizationStateProvider({ children }) {
  const { visualizations } = window.flags
  const initState = { ...visualizationState, viewMode: visualizations.defaultView }
  const [state, dispatch] = React.useReducer(visualizationReducer, initState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}
function useVisualizationState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}
function useDispatchVisualization() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}
export { VisualizationStateProvider, useVisualizationState, useDispatchVisualization, visualizationsActions, visViewModes }
