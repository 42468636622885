import React from "react";

const getSquareSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;

  return (
    <svg {...otherprops} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect onClick={e => handleClick(e, 0)} x="0.5" y="0.5" width="71" height="71" fill={yarns[0]} stroke={strokeColor} />
      <rect onClick={e => handleClick(e, 1)} x="0.5" y="0.5" width="47" height="71" fill={yarns[1]} stroke={strokeColor} />
      <rect onClick={e => handleClick(e, 2)} x="0.5" y="0.5" width="23" height="71" fill={yarns[2]} stroke={strokeColor} />
    </svg>
    
  );
};
const getCircleSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;
  return (
    <svg {...otherprops} viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        onClick={e => handleClick(e, 0)}
        d="M0.500009 35.9999C0.50001 29.7684 2.14031 23.6467 5.25606 18.25L5.25611 18.2499C8.31649 12.9516 14.0388 7.18504 19.7867 4.4711L37.4625 36.4841L19.7611 67.5604C12.7089 63.8293 8.41557 58.9217 5.25307 53.7447C2.13926 48.3493 0.500007 42.2294 0.500009 35.9999Z"
        fill={yarns[0]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 1)}
        d="M66.75 18.2603L66.7499 18.2604L66.7439 18.25C63.6281 12.8533 59.1467 8.37187 53.75 5.25609C48.3533 2.14032 42.2316 0.5 36 0.5C29.9329 0.5 23.9699 2.05487 18.6789 5.01246L36.2932 36.5H71.5096C71.5817 30.38 69.9435 23.4974 66.75 18.2603Z"
        fill={yarns[1]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 2)}
        d="M36 71.5C29.9352 71.5 23.9744 69.9463 18.6848 66.9908L36.2887 36.5H71.4965C71.4112 42.5587 69.7763 48.4977 66.7439 53.75C63.6281 59.1467 59.1467 63.6281 53.75 66.7439C48.3533 69.8597 42.2316 71.5 36 71.5Z"
        fill={yarns[2]}
        stroke={strokeColor}
      />
    </svg>

  );
};
const getSVGofShape = props => {
  const { yarns, handleClick, shape, ...otherprops } = props;
  switch (shape) {
    case "CIRCLE":
      return getCircleSVG(props);
      break;
    case "SQUARE":
      return getSquareSVG(props);
      break;
    default:
      return null
  }
};

const Mixply3 = props => {
  return getSVGofShape(props)
};

Mixply3.propTypes = {};

export default Mixply3;
