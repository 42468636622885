import React, { useEffect, useState } from "react";
import { Popover, Menu, MenuItem, Icon, MenuDivider } from "@blueprintjs/core";
import space from "color-space";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import ColorSelectionBox from "../../organisms/ColorSelectionBox";
import AtIcon from "../../atoms/AtIcon";
import CircularColorSVG from "../../atoms/CircularColorSVG";
import strings from "../../../strings";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";

const hextoLum = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);
  const hsl = space.rgb.hsl([r, g, b]);
  return hsl[2];
};

export const getMaterialName = ind => {
  // let materialName;
  if (ind < 4) return window.TextureOptions.DefaultTextureNames[ind];
  if (ind <= 30) return window.TextureOptions.AdditionalTextureNames[ind - 4];
  else {
    const custTexIndex = window.TextureOptions.CustomTextureIndices.findIndex(item => item === ind);
    return window.TextureOptions.CustomTextureNames[custTexIndex];
  }
};
const getPileheightText = ind => {
  if (ind <= 200) {
    let closest = window.TextureOptions.DefaultPileheightValues.reduce((prev, curr) =>
      Math.abs(curr - ind) < Math.abs(prev - ind) ? curr : prev
    );

    const index = window.TextureOptions.DefaultPileheightValues.findIndex(item => item === closest);
    return window.TextureOptions.DefaultPileheightNames[index];
  } else {
    const index = window.TextureOptions.CustomPileheightIndices.findIndex(item => item === ind);
    return window.TextureOptions.CustomPileheightNames[index];
  }
};
export const AreaSwatch = React.forwardRef((props, ref) => {
  const {
    className,
    colorIndex,
    onColorSelectionClose,
    onColorSelectionOpen,
    handleClick,
    onColorPieClick,
    style,
    designColor,
    colorNumber,
    totalKnots,
    popoverPosition = "left",
    showPile = true,
    showColorSelection = true,
    showColorPercent
  } = props;
  const dispatchDesignDetails = useDispatchDesignDetail();
  const designDetailState = useDesignDetailState();
  const { designDetails } = designDetailState;
  const { colorAreaSwatch: casFlags, colorSeparatedTexture } = window.flags;
  const showPlyDropdown = flaginCurrentMode(window.InterfaceElements.IsAdmin, casFlags.hasMixply); /// window.InterfaceElements.IsAdmin;
  const { mixPlyOptions } = casFlags;
  let colorPercentShown =
    showColorPercent === undefined ? casFlags.showColorPercent : showColorPercent;

  const {
    DefaultTextureVisible,
    DefaultTextureNames,
    AdditionalTextureNames,
    CustomTextureNames = [],
    CustomTextureIndices,
    CustomPileheightIndices,
    CustomPileheightNames,
    DefaultPileheightNames,
    DefaultPileheightValues,
    DefaultPileheightVisible
  } = window.TextureOptions;

  const origDesignColors = designDetailState.history.length
    ? designDetailState.history[0].designDetails.DesignColors
    : designDetails.DesignColors;

  const handleColorSwatchClick = (color, e) => {
    if (designColor.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_COLOR,
        payload: color
      });
    } else {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_YARN_COLOR,
        payload: color
      });
    }
  };
  const handleMaterialChange = ({ material, materialName }) => {
    dispatchDesignDetails({
      type: designDetailActions.CHANGE_COLOR_TEXTURE,
      payload: { material, materialName, colorIndex }
    });
    // if (designColor.YarnMaterials.length <= 1) {
    //   dispatchDesignDetails({
    //     type: designDetailActions.CHANGE_COLOR_TEXTURE,
    //     payload: { material, materialName, colorIndex }
    //   });
    // } else {
    //   dispatchDesignDetails({
    //     type: designDetailActions.CHANGE_YARN_MATERIAL,
    //     payload: { material, materialName, colorIndex }
    //   });
    // }
  };
  const handleYarnClick = (e, yarnIndex) => {
    if (designColor.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_DESIGN_COLOR,
        payload: colorIndex
      });
      if (onColorPieClick) onColorPieClick(designColor, e);
    } else {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_YARN,
        payload: { colorIndex, yarnIndex }
      });
      if (onColorPieClick) onColorPieClick(designColor.YarnDetails[yarnIndex], e);
    }
  };
  const handleMixPly = plyNumber => {
    dispatchDesignDetails({
      type: designDetailActions.MIX_PLY,
      payload: { colorIndex, plyNumber }
    });
  };
  const colorTitle =
    designColor.YarnDetails.length <= 1
      ? designColor.YarnDetails[0].ColorName
      : strings.color.mixedColors;
  const shouldShowDropDown = () => {
    const materials = designColor.YarnDetails.map(item => item.Material);
    return materials.every(item => item === materials[0]);
  };
  const allMaterialOptionsHidden = () => {
    return DefaultTextureVisible.every(item => item === false);
  };
  // const checkExclusiveMaterial = i => {
  //   const exclusiveMaterials = window.flags.colorAreaSwatch.exclusiveMatArr;
  //   const selectedYarnMaterial = designColor.YarnDetails[0].Material;
  //   if (!exclusiveMaterials.length) return true;
  //   //check if selected material is exclusive
  //   let isExclusive =
  //     exclusiveMaterials.indexOf(selectedYarnMaterial) >
  //     -1;

  //   let showThisMat = true;
  //   if (isExclusive) {
  //     //if exclusive, hide other exclusive textures
  //     showThisMat =
  //       exclusiveMaterials.indexOf(i) === -1 ||
  //       selectedYarnMaterial === i;
  //   }
  //   return showThisMat;
  // };
  const checkExclusiveMaterial = i => {
    const exclusiveMaterials = casFlags.exclusiveMatArr;
    const origColorMaterial = origDesignColors[colorIndex].YarnDetails[0].Material;
    let associatedYarnMaterial = parseFloat(designColor.YarnDetails[0].AssociatedMat);

    associatedYarnMaterial =
      associatedYarnMaterial || associatedYarnMaterial === 0
        ? associatedYarnMaterial
        : origColorMaterial;
    let showThisMat =
      associatedYarnMaterial === i ||
      !isAExclusiveMat(exclusiveMaterials, i) ||
      isExclusiveWithinArr(exclusiveMaterials, associatedYarnMaterial, i)
        ? true
        : false;
    return showThisMat;
  };
  const isAExclusiveMat = (exclusiveMaterials, matOption) => {
    let isExclusive = exclusiveMaterials.indexOf(matOption) !== -1 ? true : false;
    exclusiveMaterials.forEach(element => {
      if (Array.isArray(element) && element.indexOf(matOption) !== -1) {
        isExclusive = true;
      }
    });
    return isExclusive;
  };

  const isExclusiveWithinArr = (exclusiveMaterials, designColorMaterial, matOption) => {
    var showThisMat = false;
    for (var i = 0; i < exclusiveMaterials.length; i++) {
      if (Array.isArray(exclusiveMaterials[i])) {
        if (
          exclusiveMaterials[i].indexOf(designColorMaterial) !== -1 &&
          exclusiveMaterials[i].indexOf(matOption) !== -1
        ) {
          showThisMat = true;
        }
      }
    }
    return showThisMat;
  };

  const materialDropdown = (
    <div className="color-details-material">
      <Popover
        disabled={!shouldShowDropDown() || allMaterialOptionsHidden()}
        content={
          <Menu>
            {(DefaultTextureNames || AdditionalTextureNames) && (
              <>
                {CustomTextureNames && CustomTextureNames.length > 0 && (
                  <MenuDivider
                    className="color-details-material-menudivider"
                    title={strings.color.basicMaterialTitle}
                  />
                )}

                {DefaultTextureNames &&
                  DefaultTextureNames.map(
                    (textureName, i) =>
                      DefaultTextureVisible[i] &&
                      checkExclusiveMaterial(i) && (
                        <MenuItem
                          key={i}
                          text={textureName}
                          onClick={e => {
                            handleMaterialChange({ material: i, materialName: textureName });
                          }}
                        />
                      )
                  )}
                {AdditionalTextureNames &&
                  AdditionalTextureNames.map((textureName, i) => (
                    <MenuItem
                      key={i}
                      text={textureName}
                      onClick={e => {
                        handleMaterialChange({ material: 4 + i, materialName: textureName });
                      }}
                    />
                  ))}
              </>
            )}

            {CustomTextureNames && CustomTextureNames.length > 0 && (
              <>
                <MenuDivider
                  className="color-details-material-menudivider"
                  title={strings.color.mixedConstructionsTitle}
                />
                {CustomTextureNames.map((textureName, i) => (
                  <MenuItem
                    key={i}
                    text={textureName}
                    onClick={e => {
                      handleMaterialChange({
                        material: CustomTextureIndices[i],
                        materialName: textureName
                      });
                    }}
                  />
                ))}
              </>
            )}
          </Menu>
        }
        minimal
        position="bottom-left"
        portalContainer={document.getElementById("app-main")}
      >
        <div>
          {shouldShowDropDown() ? (
            <>
              <span>{getMaterialName(designColor.YarnDetails[0].Material)}</span>
              {!allMaterialOptionsHidden() && <Icon icon="caret-down" />}
            </>
          ) : (
            <span>{strings.color.mixedTextures}</span>
          )}
        </div>
      </Popover>
    </div>
  );
  return (
    <div
      ref={ref}
      className={classNames(
        "area-swatch bp3-text-small",
        {
          "no-custom": sessionStorage.getItem("customize") === "false"
        },
        className
      )}
      style={style}
      onClick={handleClick}
    >
      <span className="color-index">{colorNumber ? colorNumber : colorIndex + 1}</span>
      <Popover
        className="color-area-popover"
        content={<ColorSelectionBox onColorSwatchClick={handleColorSwatchClick} />}
        minimal
        position={popoverPosition}
        portalContainer={document.getElementById("app-main")}
        boundary="viewport"
        onClose={onColorSelectionClose}
        onOpened={onColorSelectionOpen}
        disabled={!showColorSelection}
        captureDismiss
      >
        <div className="color-pie">
          <CircularColorSVG
            className="color-pie__color"
            yarns={designColor.YarnDetails.map(item => item.Color)}
            handleClick={handleYarnClick}
            shape={casFlags.shape}
          />

          {totalKnots && (
            <span
              className={`color-pie__percentage ${colorPercentShown ? "" : "hidden"}`}
              style={{
                color: hextoLum(designColor.YarnDetails[0].Color) < 50 ? "#fff" : "#000",
                opacity: "0.7"
              }}
            >
              {((designColor.Knots / totalKnots) * 100).toFixed(1)}%
            </span>
          )}
        </div>
      </Popover>

      <div className="color-details-area">
        {designColor && (
          <Popover
            minimal
            disabled={!showPlyDropdown}
            position="bottom-left"
            portalContainer={document.getElementById("app-main")}
            content={
              <Menu>
                {mixPlyOptions.map(plyOption => (
                  <MenuItem
                    key={plyOption.ply}
                    onClick={() => handleMixPly(plyOption.ply)}
                    text={plyOption.text}
                  ></MenuItem>
                ))}
              </Menu>
            }
          >
            <div className="color-details-name">
              <span className="color-details-name__title" title={colorTitle}>
                {colorTitle}
              </span>
              {designColor.Carving && <AtIcon icon="carving" title="Carving" size={1} />}
              {showPlyDropdown && (
                <Icon className="color-details-name__mixplyDropdown" icon="caret-down" />
              )}
            </div>
          </Popover>
        )}

        {colorSeparatedTexture && (
          <>
            {materialDropdown}
            {showPile && (
              <div className="color-details-pileheight">
                <Popover
                  content={
                    <Menu>
                      {DefaultPileheightNames && DefaultPileheightNames.length > 0 && (
                        <>
                          {CustomPileheightNames && CustomPileheightNames.length > 0 && (
                            <MenuDivider
                              className="color-details-pileheight-menudivider"
                              title="Basic"
                            />
                          )}

                          {DefaultPileheightNames.map(
                            (pileHeightText, i) =>
                              (!DefaultPileheightVisible || DefaultPileheightVisible[i]) && (
                                <MenuItem
                                  key={i}
                                  text={pileHeightText}
                                  onClick={e => {
                                    dispatchDesignDetails({
                                      type: designDetailActions.CHANGE_PILE,
                                      payload: {
                                        pileHeight: DefaultPileheightValues[i],
                                        colorIndex
                                      }
                                    });
                                  }}
                                />
                              )
                          )}
                        </>
                      )}
                      {CustomPileheightNames && CustomPileheightNames.length > 0 && (
                        <>
                          <MenuDivider
                            className="color-details-pileheight-menudivider"
                            title="Mixed"
                          />
                          {CustomPileheightNames.map((pileHeightText, i) => (
                            <MenuItem
                              key={i}
                              text={pileHeightText}
                              onClick={e => {
                                dispatchDesignDetails({
                                  type: designDetailActions.CHANGE_PILE,
                                  payload: { pileHeight: CustomPileheightIndices[i], colorIndex }
                                });
                              }}
                            />
                          ))}
                        </>
                      )}
                    </Menu>
                  }
                  minimal
                  position="bottom-left"
                  portalContainer={document.getElementById("app-main")}
                >
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        wordBreak: "break-word"
                      }}
                    >
                      {getPileheightText(designColor.PileHeight)}
                    </span>
                    <Icon icon="caret-down" />
                  </div>
                </Popover>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
});
