import React from "react";
import AtIcon from "../AtIcon";

const CircularButton = props => {
  const { icon, onClick, className = "", size = "medium", children } = props;
  return (
    <div className={`circular-button ui ${className} ${size}`} onClick={onClick}>
      {icon && <AtIcon icon={icon} />}
      {children}
    </div>
  );
};

export default CircularButton;
