import space from "color-space";
export const calculateColorDelta = (color1, color2) => {
  const { R: r1, G: g1, B: b1 } = color1;
  const { R: r2, G: g2, B: b2 } = color2;
  // calculate differences between reds, greens and blues
  var r = 255 - Math.abs(r1 - r2);
  var g = 255 - Math.abs(g1 - g2);
  var b = 255 - Math.abs(b1 - b2);
  // limit differences between 0 and 1
  r /= 255;
  g /= 255;
  b /= 255;
  // 0 means opposit colors, 1 means same colors
  return (r + g + b) / 3;
};

export function compare(a, sortingKey, b, sortingOrder) {
  const item1 = a[sortingKey];
  const item2 = b[sortingKey];
  return item1 === item2 ? 0 : item1 < item2 ? -sortingOrder : sortingOrder;
}

export const hexToRGB = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    : {}; //handle the return value in function calling this function
};

export const hextoLum = hex => {
  const { r = 0, g = 0, b = 0 } = hexToRGB(hex)
  const hsl = space.rgb.hsl([r, g, b]);
  return hsl[2];
};