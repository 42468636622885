import React from "react";
import {
  updateElementOfTree,
  updateNode,
  expandTreeNode,
  findItemNested,
  findPrevNext,
  updateSimilarFilesOfNode,
  updateSingleFileProp,
} from "../utils/arrayUtils";
import { generateHash } from "./designdetails.reducer";

const StateContext = React.createContext();
const DispatchContext = React.createContext();

const SET_TREE = "SET_TREE";
const UPDATE_CHILD_NODES = "UPDATE_CHILD_NODES";
const UPDATE_NODE = "UPDATE_NODE";
const EXPAND_NODE = "EXPAND_NODE";
const COLLAPSE_NODE = "COLLAPSE_NODE";
const SET_ACTIVE_NODE = "SET_ACTIVE_NODE";
const SET_ACTIVE_SCHEME = "SET_ACTIVE_SCHEME";
const SET_SCHEMES = "SET_SCHEMES";
const EXPAND_NODE_BY_PATH = "EXPAND_NODE_BY_PATH";
const SELECT_PREV_DESIGN = "SELECT_PREV_DESIGN";
const SELECT_NEXT_DESIGN = "SELECT_NEXT_DESIGN";
const SET_FAVORITES = "SET_FAVORITES";
const ADD_FAVORITE = "ADD_FAVORITE";
const UPDATE_SIMILAR_DESIGNS = "UPDATE_SIMILAR_DESIGNS";
const UPDATE_SINGLE_FILE_PROP = "UPDATE_SINGLE_FILE_PROP"
const designListActions = {
  SET_TREE,
  UPDATE_CHILD_NODES,
  UPDATE_NODE,
  EXPAND_NODE,
  COLLAPSE_NODE,
  SET_ACTIVE_NODE,
  SET_ACTIVE_SCHEME,
  EXPAND_NODE_BY_PATH,
  SELECT_PREV_DESIGN,
  SELECT_NEXT_DESIGN,
  SET_SCHEMES,
  SET_FAVORITES,
  ADD_FAVORITE,
  UPDATE_SIMILAR_DESIGNS,
  UPDATE_SINGLE_FILE_PROP
};
const initialDesignListState = {
  loading: true,
  tree: null,
  activeNode: { id: "0" },
  selectedFolder: null,
  flatFileList: [],
  activeSchemeIndex: -1,
  schemes: [],
  favorites: []
};
const designListReducer = (state, action) => {
  console.log(action.type);
  switch (action.type) {
    case SET_TREE:
      return {
        ...state, tree: [...action.payload.tree],
        loading: false,
        selectedFolder: action.payload.openNode,
        flatFileList: [...action.payload.flatfileList]
      };
    case EXPAND_NODE:
      return {
        ...state, tree: expandTreeNode([...state.tree], action.payload),
        selectedFolder: action.payload
      };
    case COLLAPSE_NODE:
      return {
        ...state, tree:
          updateElementOfTree(state.tree, [action.payload.id], "isExpanded", false)
      };
    case UPDATE_CHILD_NODES:
      return {
        ...state, tree:
          updateElementOfTree(state.tree, [action.payload.id], "childNodes", action.payload.childNodes)
      };
    case UPDATE_NODE:
      return {
        ...state, tree:
          updateNode(state.tree, action.payload)
      };
    case SET_ACTIVE_NODE:
      return {
        ...state, activeNode: action.payload,
        activeSchemeIndex: -1
      };
    case SET_SCHEMES:
      return { ...state, schemes: action.payload }
    case SET_ACTIVE_SCHEME:
      return { ...state, activeSchemeIndex: action.payload }
    case UPDATE_SIMILAR_DESIGNS:
      return { ...state, tree: updateSimilarFilesOfNode(state.tree, action.payload) }
    case EXPAND_NODE_BY_PATH:
      const nodeToExpand = findItemNested([...state.tree], action.payload, "fullpath");
      if (!nodeToExpand) return { ...state }
      return { ...state, tree: expandTreeNode([...state.tree], nodeToExpand) };
    case UPDATE_SINGLE_FILE_PROP:
      return { ...state, tree: updateSingleFileProp(state.tree, action.payload, generateHash(action.payload.Props, action.payload.fullpath)) }

    case SELECT_PREV_DESIGN:
      findPrevNext(state.tree, state.activeNode)
      // let ind = state.flatFileList.findIndex(item => item.id === state.activeNode.id)
      // if (ind < 0 || ind === 0)
      //   return state
      // return {
      //   ...state, activeNode: state.flatFileList[ind - 1],
      //   activeSchemeIndex: -1
      // }
      return state
    case SELECT_NEXT_DESIGN:
      return state
    // let indN = state.flatFileList.findIndex(item => item.id === state.activeNode.id)
    // const lastIndex = state.flatFileList.length - 1
    // if (indN < 0 || indN >= lastIndex)
    //   return state
    // return {
    //   ...state, activeNode: state.flatFileList[indN + 1],
    //   activeSchemeIndex: -1
    // }
    case SET_FAVORITES:
      return { ...state, favorites: action.payload }
    case ADD_FAVORITE:
      return { ...state, favorites: [...state.favorites, action.payload] }
    default:
      return state;
  }
};

function DesignListStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(designListReducer, initialDesignListState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}
function useDesignListState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}
function useDispatchDesignList() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}
export { DesignListStateProvider, useDesignListState, useDispatchDesignList, designListActions }
