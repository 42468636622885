/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Suspense } from "react";
import AppProvider from "../../../api/appProvider";
import {
  designListActions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designstree.reducer";
import { useMount, useFullscreen, useToggle, useIdle } from "react-use";
import { ReactComponent as Icons } from "../../symbol-defs.svg";
import {
  designDetailActions,
  useDispatchDesignDetail
} from "../../../reducers/designdetails.reducer";
import { colorListActions, useDispatchColorList } from "../../../reducers/colors.reducer";
import uuid from "uuid";
import { useUiDispatch, useUiState, mainUiActions } from "../../../reducers/mainui.reducer";
import { getSimilarDesigns } from "../../../utils/arrayUtils";
import DesignList from "../../organisms/DesignList";
import VpsLanding from "../VpsLanding";
import {
  roomConfigActions,
  useDispatchRoomConfig,
  useRoomConfigState
} from "../../IllustrationView/roomconfig.reducer";
import VPS from "./../VPS"
const assetspath = "https://v3.explorug.com/modules/VirtualPhotoStudio/";
window.assetspath = assetspath;
const VpsHome = props => {
  const { initDesignPath, initDesignColors, eCatalogDetails } = window.initialData;
  const [isReady, setisReady] = useState(false);
  const [isLandingComplete, setisLandingComplete] = useState(false);

  const uiState = useUiState();
  const dispatchUiState = useUiDispatch();
  const designListState = useDesignListState();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchColorListState = useDispatchColorList();
  const dispathcDesignDetails = useDispatchDesignDetail();
  const roomConfigState = useRoomConfigState();
  const dispatchRoomConfig = useDispatchRoomConfig();
  const [isFullScreen, toggleFullscreen] = useToggle(false);
  const isIdle = useIdle(5e3, false, [
    "mousemove",
    "mousedown",
    "resize",
    "keydown",
    "touchstart",
    "wheel",
    uiState.showLoadingOverlay
  ]);
  useEffect(() => {
    dispatchUiState({ type: mainUiActions.SET_IS_IDLE, payload: isIdle });
  }, [isIdle]);
  const refHome = useRef(null);
  useMount(() => {
    AppProvider.fetchColorList({}).then(colors => {
      dispatchColorListState({ type: colorListActions.SET_COLORS, payload: colors });
    });
    const { TextureOptions } = window;
    const texArr = [];
    TextureOptions.DefaultTextureVisible.forEach((tex, i) => {
      if (tex) texArr.push(i);
    });
    TextureOptions.AdditionalTextureNames.forEach((item, i) => {
      texArr.push(4 + i);
    });
    TextureOptions.CustomTextureIndices.forEach(item => {
      texArr.push(item);
    });
    AppProvider.readTextureImages(texArr).then(colorTextures => {
      dispatchColorListState({ type: colorListActions.SET_COLOR_TEXTURES, payload: colorTextures });
    });
    if (eCatalogDetails && initDesignPath) {
      const p = initDesignPath.split("/").filter(item => item && item !== "");
      const designNamewithExt = p[p.length - 1];
      const darr = designNamewithExt.split(".");
      let designName = darr.slice(0, darr.length - 1).join(".");

      const name = initDesignPath.split("/").find(item => item.substr(0, 1) === ".");
      if (name && window.flags.designVariations.appendDesignName) {
        designName = `${name.substr(1)} ${designName}`;
      }

      p.pop();
      const path = "/" + p.join("/");
      AppProvider.fetchDesignList().then(designlist => {
        const similarDesigns = getSimilarDesigns(designlist, {
          label: designName,
          fullpath: initDesignPath
        });
        AppProvider.getDesignThumbnails({ designs: [{ fullpath: initDesignPath }] }).then(thum => {
          const thumbnail = thum[0];
          if (thumbnail.fullpath === initDesignPath) {
            dispatchDesignList({
              type: designListActions.SET_ACTIVE_NODE,
              payload: {
                id: uuid.v4(),
                label: designName,
                fullpath: initDesignPath,
                path: path,
                isExpanded: false,
                childNodes: [],
                hasCaret: false,
                type: "file",
                similarItems: similarDesigns,
                Thumb: thumbnail.Thumb,
                Props: thumbnail.Props
              }
            });
          }
        });
      });
    }
  });
  useFullscreen(refHome, isFullScreen, { onClose: () => toggleFullscreen(false) });

  useEffect(() => {
    let latest = true;
    const { fav, id, similarItems } = designListState.activeNode;
    if (id === "0" || fav) return;

    if (uiState.showLoadingOverlay)
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 50 });

    dispatchDesignList({
      type: designListActions.SET_SCHEMES,
      payload: similarItems ? similarItems : []
    });

    if (similarItems && similarItems.length) {
      AppProvider.getDesignThumbnails({ designs: similarItems.filter(item => !item.Thumb) }).then(
        schemes => {
          if (latest) dispatchDesignList({ type: designListActions.SET_SCHEMES, payload: schemes });
        }
      );
    }

    return () => (latest = false);
  }, [designListState.activeNode]); //eslint-disable-line

  useEffect(() => {
    const { fav, id, Props: designDetails } = designListState.activeNode;
    if (id === "0" || fav) return;
    if (initDesignColors) {
      dispathcDesignDetails({
        type: designDetailActions.SET_DETAILS,
        payload: {
          designDetails: { ...designDetails, DesignColors: initDesignColors },
          ...designListState.activeNode,
          customizationFlag: true
        }
      });
    } else {
      dispathcDesignDetails({
        type: designDetailActions.SET_DETAILS,
        payload: { designDetails, ...designListState.activeNode }
      });
    }
    dispatchRoomConfig({
      type: roomConfigActions.SET_CUSTOM_DESIGN_URL,
      payload: ""
    });
    if (uiState.showLoadingOverlay)
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 80 });
  }, [designListState.activeNode.id]);

  useEffect(() => {
    if (roomConfigState?.customDesignUrl?.url && uiState.showLoadingOverlay) {
      dispatchUiState({ type: mainUiActions.SET_LOADING_OVERLAY_PERCENT, payload: 80 });
    }
  }, [roomConfigState]);

  return (
    <React.Fragment>
      {/* {uiState.showLoadingOverlay && (
        <LoadingOverlay logo={renderLogo} dispatchUiState={dispatchUiState} />
      )} */}
      {/* {isLandingComplete && ( */}
      <React.Fragment>
        <div style={{ display: uiState.loadingOverlayPercent < 50 ? "none" : "block" }}>
          <Suspense fallback={<></>}>
            <Icons />
            <VPS handleReady={() => setisReady(true)} />
          </Suspense>
        </div>
      </React.Fragment>
      {/* )} */}
      {/* <VpsLanding isReady={isReady} onLoaded={() => setisLandingComplete(true)}></VpsLanding> */}
    </React.Fragment>
  );
};

export default VpsHome;
