import { H2 } from "@blueprintjs/core";
import React, { useState } from "react";
import AtButton from "../../atoms/AtButton";
import AtIcon from "../../atoms/AtIcon";
import AtDialog from "../../molecules/AtDialog";
import Thumbnail from "../../molecules/Thumbnail";

const CameraOptions = props => {
  const {
    show,
    filterOptions,
    activeFilterIndex,
    onFilterSelect,
    blurOptions,
    activeBlurIndex,
    onBlurSelect,
    onShot,
    handleClose
  } = props;

  const [seePreview, setseePreview] = useState(false);
  // const [activeBlurIndex, setactiveBlurIndex] = useState(0);
  return (
    <>
      <AtDialog
        isOpen={true}
        onClose={() => {
          setseePreview(true);
        }}
        className={"illustration-dialog camera-dialog"}
        overlayClassName={show && !seePreview ? "" : "hidden"}
        size="lg"
      >
        <div className="illustration-dialog-header-row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={window.logourl} alt="logo" />
            <H2>Camera Options</H2>
          </div>
          <AtIcon
            icon="close"
            size={2.5}
            onClick={() => {
              setseePreview(true);
            }}
          />
        </div>
        <div className={"category-container"}>
          <div className={"category-wrapper"}>
            <div className={"thumb-item category-heading"}>
              <div className={"thumb-image-container landscape "}>
                <span className="thumb-heading">Filters</span>
                <span className="thumb-subheading">
                  Enhance your studio shots with these filter options
                </span>
              </div>
            </div>

            <div className="filteroptions-thumbs-wrapper">
              {filterOptions.map((filter, index) => {
                return (
                  <Thumbnail
                    thumb={filter}
                    active={activeFilterIndex === index}
                    infoThumbs={[]}
                    aspect="landscape"
                    key={index}
                    onThumbnailClick={e => {
                      onFilterSelect(e, index);
                    }}
                  />
                );
              })}
            </div>

            <div className="category-divider"></div>
          </div>

          {/* blur */}
          {blurOptions && blurOptions.length > 0 && (
            <div className={"category-wrapper"}>
              <div className={"thumb-item category-heading"}>
                <div className={"thumb-image-container landscape "}>
                  <span className="thumb-heading">Camera blur</span>
                  <span className="thumb-subheading">
                    Add depth to your shots with these realistic camera blur options
                  </span>
                </div>
              </div>

              <div className="filteroptions-thumbs-wrapper">
                {blurOptions.map((option, index) => {
                  return (
                    <Thumbnail
                      thumb={option}
                      active={activeBlurIndex === index}
                      infoThumbs={[]}
                      aspect="landscape"
                      key={index}
                      onThumbnailClick={e => onBlurSelect(e, index)}
                    />
                  );
                })}
              </div>

            </div>
          )}
        </div>
      </AtDialog>
      <div className={(show ? "show" : "") + " camera-bottom-bar"}>
        <AtButton
          onClick={() => setseePreview(!seePreview)}
          text={"Camera Options"}
          className="btn-cam-opts"
        />
        <AtButton
          onClick={() => onShot()}
          text={"Capture Shot"}
          icon="double-chevron-camera"
          className="btn-cam-shot"
        />
        {/* <button onClick={() => handleClose()}>Exit</button> */}
      </div>
    </>
  );
};

export default CameraOptions;
