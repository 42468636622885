import React from "react";
import classNames from "classnames";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
const CameraButton1 = props => {
  const { onClick, className = "", size = "medium", clipped = false, disabled, nopointer } = props;
  return (
    <div
      className={classNames(
        "camera-button",
        { "camera-button--disabled": disabled },
        {
          small: size === "small",
          clipped: clipped
        },
        { "camera-button--nopointer": disabled || nopointer },
        "ui",
        size,
        className
      )}
      onClick={e => {
        if (!nopointer && !disabled && onClick) onClick(e);
      }}
    >
      <div className="camera-button-clicker"></div>
    </div>
  );
};

const CameraButton2 = props => {
  const { onClick, className = "", size = "medium", clipped = false, disabled, nopointer } = props;
  return (
    <div
      className={classNames(
        "camera-button camera-button-glow",
        { "camera-button--disabled": disabled },
        {
          small: size === "small",
          clipped: clipped
        },
        { "camera-button--nopointer": disabled || nopointer },
        "ui",
        size,
        className
      )}
      onClick={e => {
        if (!nopointer && !disabled && onClick) onClick(e);
      }}
    >
      <div className="camera-button-clicker"></div>
    </div>
  );
};

const CameraButton = props => {
  const {
    onClick,
    onClose,
    className = "",
    size = "medium",
    clipped = false,
    disabled,
    nopointer,
    cameraactive
  } = props;
  return (
    <>
      {!cameraactive && (
        <div
          className={classNames(
            "camera-button camera-button-glow-icon",
            { "camera-button--disabled": disabled },
            {
              small: size === "small",
              clipped: clipped
            },
            { "camera-button--nopointer": disabled || nopointer },
            "ui",
            size,
            className
          )}
          onClick={e => {
            if (!nopointer && !disabled && onClick) onClick(e);
          }}
        >
          <AtIcon icon="camera-filled" />
        </div>
      )}
      {cameraactive && <AtButton icon="close" onClick={onClose} className="btn-camera-close" />}
    </>
  );
};

export default CameraButton;
