import * as THREE from "three";
import { OrbitControls } from "../../utils/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { convertArrIntoRad, convertUnit, readImage } from "../../utils/utils";
import { clearCanvas, createCanvas, downloadImageData } from "../../utils/canvasutils";
import CameraControls from "./cameracontrols/camera-controls";

CameraControls.install({ THREE: THREE });


class ResourceTrackerPh {
  constructor () {
    this.resources = new Set();
    this.resourceslocal = new Set();
  }
  track(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.track(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resources.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.track(resource.geometry);
      this.track(resource.material);
      this.track(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.track(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  tracklocal(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.tracklocal(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resourceslocal.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.tracklocal(resource.geometry);
      this.tracklocal(resource.material);
      this.tracklocal(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.tracklocal(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.tracklocal(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  untrack(resource) {
    this.resources.delete(resource);
  }
  untracklocal(resource) {
    this.resourceslocal.delete(resource);
  }
  dispose() {
    const allresources = new Set([...this.resources, ...this.resourceslocal])
    for (const resource of allresources) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resources.clear();
    this.resourceslocal.clear();
  }
  disposelocal() {
    for (const resource of this.resourceslocal) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resourceslocal.clear();
  }
}
export default class PhotographicView {
  constructor () {
    this.scene = new THREE.Scene();

    this.textureLoader = new THREE.TextureLoader();
    this.fbxLoader = new FBXLoader();
    this.raycaster = new THREE.Raycaster();
    this.offset = new THREE.Vector3();
    this.objectLoaded = false;
    this.object = null;
    this.w = null;
    this.h = null;
    this.hasBackSurface = false;
    this.modelUrl = null;
    this.resTracker = new ResourceTrackerPh();
    this.track = this.resTracker.track.bind(this.resTracker);
    this.tracklocal = this.resTracker.tracklocal.bind(this.resTracker);
    this.ambientIntensity = undefined;
  }
  reset() {
    this.objectLoaded = false;
    this.object = null;
    this.w = null;
    this.h = null;
    this.hasBackSurface = false;
    this.modelUrl = null;
    this.material = null;
    this.materialBack = null;
  }
  async init({ canvas, config = {}, shot, dims = {}, resolution, roomType, baseUrl }) {
    if (this.resTracker) {
      this.resTracker.disposelocal();
    }
    window.scene = this.scene;
    if (!this.modelUrl || (this.modelUrl !== config.modelUrl)) await this.clearScene();
    let { width = window.innerWidth, height = window.innerHeight } = dims;
    this.w = width;
    this.h = height;
    this.sceneConfig = config;
    let { orbitControl = false } = config;
    this.baseUrl = baseUrl;
    if (roomType === "illustration") {
      canvas.style.visibility = "hidden";
    } else {
      canvas.style.visibility = "inherit";
    }
    this.renderer = this.tracklocal(new THREE.WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      alpha: true,
      antialias: false
    }));
    this.renderer.setPixelRatio(resolution);
    this.renderer.setSize(width, height);
    const camConfig = config[shot];
    this.camera = this.tracklocal(perspectiveCamera({ ...camConfig, width, height }));
    this.scene.add(this.camera);
    window.scene = this.scene;
    // this.orbit = addCameraControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit = addOrbitControl(this.renderer, this.scene, this.camera, camConfig);
    this.orbit.enabled = true;
    // this.orbit.screenSpacePanning = true;
  }
  setupLights() {
    this.directionalLight = this.track(new THREE.DirectionalLight(0xffffff, 0.9));
    this.scene.add(this.directionalLight);
    const intensity = this.sceneConfig.ambientIntensity || 0.5;
    this.ambientLight = this.track(new THREE.AmbientLight(0xffffff, intensity));
    this.scene.add(this.ambientLight);
  }
  removeLights() {
    if (this.directionalLight) {
      this.scene.remove(this.directionalLight);
    };
    if (this.ambientLight) {
      this.scene.remove(this.ambientLight);
    }
  }
  changedIntensity() {
    const changedIntensity = this.ambientIntensity !== this.sceneConfig.ambientIntensity;
    if (
      changedIntensity &&
      (this.ambientIntensity !== undefined || this.sceneConfig.ambientIntensity !== undefined)
    ) {
      return true;
    } else {
      return false;
    }
  }
  setup3dObject({ fbxUrl }) {
    return new Promise((resolve, reject) => {
      if (!this.sceneConfig) return;
      const { objects3d, surfaces } = this.sceneConfig;
      this.removeLights();
      const setupObjects = () => {
        let objectsLoaded = 0;
        objects3d.forEach(object3d => {
          console.log(object3d, this.objectFbx);
          const object = this.objectFbx.getObjectByName(object3d);
          if (!object) {
            console.warn("PHOTOGRAPHIC VIEW: object", object3d, "does not exist");
            return;
          }
          this.scene.add(object);
          const objectConfig = this.sceneConfig[object3d];
          const { flagged } = this.sceneConfig;
          let fact = flagged ? 10 : 1;
          const {
            position = [0, 0, 0],
            rotation = [90, 0, 0],
            scale = [1, 1, 1],
            preset = false
          } = objectConfig;
          object.position.fromArray(position);
          object.scale.fromArray(scale);
          object.rotation.fromArray(convertArrIntoRad(rotation));

          this.directionalLight.position.set(
            position[0] + 3000 / fact,
            position[1] + 3000 / fact,
            position[2]
          );
          var targetObject = this.tracklocal(new THREE.Object3D());
          targetObject.name = "TargetObject";
          targetObject.position.set(...position);
          this.scene.add(targetObject);
          this.directionalLight.target = targetObject;
          // let helper = new THREE.DirectionalLightHelper(this.directionalLight, 100, 0xff0000);
          // this.scene.add(helper);

          if (!preset) {
            this.objProps = objectConfig;
            if (surfaces) {
              const { back, front } = surfaces;
              this.object = object.getObjectByName(front);
              if (back) {
                this.objectBack = object.getObjectByName(back);
                this.hasBackSurface = true;
              } else {
                this.hasBackSurface = false;
              }
            } else {
              this.object = object;
            }
            if (this.object && this.material) {
              this.object.material = this.material;
              this.object.material.needsUpdate = true;
              this.render();
            }
            objectsLoaded++;

            if (objectsLoaded === objects3d.length) {
              const fbxPathArr = fbxUrl.split(/[\\/]+/);
              this.modelUrl = fbxPathArr[fbxPathArr.length - 1];
              resolve();
            }
          } else {
            const { defaultTexture, defaultScale = [9, 12] } = objectConfig;
            const { width: texWidth = 9, height: texHeight = 12, path } = defaultTexture;
            const textureUrl = `${this.baseUrl}/${path}`;
            let repeat = [1, 1];
            const rx = defaultScale[0] / texWidth;
            const ry = defaultScale[1] / texHeight;
            repeat = [rx, ry];
            console.log("setupObjects -> repeat", repeat);
            readImage(textureUrl).then(image => {
              const { width, height } = image;
              const canv = createCanvas(width, height);
              canv.getContext("2d").drawImage(image, 0, 0, width, height);

              const texture = this.tracklocal(new THREE.CanvasTexture(canv));
              texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
              texture.wrapS = texture.wrapT = THREE.RepeatWrapping;

              texture.repeat.fromArray(repeat);

              let material = this.tracklocal(new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                side: THREE.DoubleSide,
                alphaTest: 0.5
              }));

              object.material = material;
              object.material.needsUpdate = true;
              this.render();

              objectsLoaded++;
              if (objectsLoaded === objects3d.length) {
                const fbxPathArr = fbxUrl.split(/[\\/]+/);
                this.modelUrl = fbxPathArr[fbxPathArr.length - 1];
                resolve();
              }
            });
          }
        });
      };
      if (this.changedIntensity()) {
        this.ambientIntensity = this.sceneConfig.ambientIntensity;
        if (this.carpetLoaded) {
          this.removeLights();
          this.setupLights();
        }
      }
      if (!this.modelUrl || (this.modelUrl !== this.sceneConfig.modelUrl))
        this.fbxLoader.load(
          fbxUrl,
          obj => {

            // this.scene.add(obj);
            // this.render();
            // resolve();
            this.objectFbx = obj;
            this.setupLights();
            setupObjects();
          },
          undefined,
          console.error
        );
      else setupObjects();
    });
  }
  setObjectTexture({ designDetails, designCanvas, normapCanvas, backDesignCanvas }) {
    return new Promise((resolve, reject) => {
      const { surfaceUnit = "in", doubleSide, defaultScale, fitWidth, offset, textureRotation } = this.objProps;
      const PhysicalWidth = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalWidth
      );
      const PhysicalHeight = convertUnit(
        designDetails.Unit,
        surfaceUnit,
        designDetails.PhysicalHeight
      );
      this.designDetails = {
        ...designDetails,
        PhysicalHeight,
        PhysicalWidth,
        Unit: surfaceUnit
      };
      if (designCanvas) {
        const designTexture = this.tracklocal(new THREE.CanvasTexture(designCanvas));
        designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
        // designTexture.flipY = false;
        let repeat = [1, 1];
        if (defaultScale)
          if (!fitWidth) {
            repeat = [defaultScale[0] / PhysicalWidth, defaultScale[1] / PhysicalHeight];
          } else {
            repeat = [1, (defaultScale[1] / defaultScale[0]) * (PhysicalWidth / PhysicalHeight)];
          }
        if (offset) {
          designTexture.offset.fromArray(offset);
        } else {
          designTexture.offset.fromArray([0, 0]);
        }
        designTexture.repeat.fromArray(repeat);
        if (textureRotation) {
          designTexture.rotation = ((textureRotation * Math.PI) / 180);
        }
        if (normapCanvas) {
          const normalTexture = this.tracklocal(new THREE.CanvasTexture(normapCanvas));
          normalTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
          normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
          if (offset) {
            normalTexture.offset.fromArray(offset);
          } else {
            normalTexture.offset.fromArray([0, 0]);
          }
          normalTexture.repeat.fromArray(repeat);
          if (textureRotation) {
            normalTexture.rotation = ((textureRotation * Math.PI) / 180);
          }
          this.material = this.tracklocal(new THREE.MeshStandardMaterial({
            map: designTexture,
            normalMap: normalTexture,
            roughness: 1,
            metalness: 0.1,
            needsUpdate: true,
            transparent: true,
            side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
          }));
        }
        else {
          this.material = this.tracklocal(new THREE.MeshStandardMaterial({
            map: designTexture,
            roughness: 1,
            metalness: 0.1,
            needsUpdate: true,
            transparent: true,
            side: doubleSide ? THREE.DoubleSide : THREE.FrontSide,
          }));
        }

        if (!this.object) {
          console.error("could not find the object");
          resolve();
          return;
        }
        this.object.material = this.material;
        this.object.material.needsUpdate = true;
      }
      if (this.hasBackSurface && backDesignCanvas) {
        const designTextureBack = this.tracklocal(new THREE.CanvasTexture(backDesignCanvas));
        designTextureBack.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
        designTextureBack.wrapS = designTextureBack.wrapT = THREE.RepeatWrapping;
        this.materialBack = this.tracklocal(new THREE.MeshBasicMaterial({
          map: designTextureBack,
          transparent: true,
          side: THREE.DoubleSide,
          alphaTest: 0.5,
          needsUpdate: true
        }));
        this.objectBack.material = this.materialBack;
        this.objectBack.material.needsUpdate = true;
      }
      this.render();
      resolve();
    });
  }
  updateMap() {
    if (this.object && this.object.material.map) {
      this.object.material.map.needsUpdate = true;
      this.object.material.needsUpdate = true;
    }
    if (this.object && this.object.material.normalMap) {
      this.object.material.normalMap.needsUpdate = true;
      this.object.material.needsUpdate = true;
    }
    if (this.objectBack && this.objectBack.material.map) {
      this.objectBack.material.needsUpdate = true;
      this.objectBack.material.map.needsUpdate = true;
    }
    this.render();
  }
  clearScene() {
    return new Promise((resolve, reject) => {
      var objsToRemove = [...this.scene.children];
      let removeCount = objsToRemove.length;
      if (removeCount === 0) resolve()
      objsToRemove.map((object, index) => {
        this.scene.remove(object);
        removeCount--;
        if (removeCount === 0) {
          if (this.renderer) this.render();
          this.resTracker.dispose();
          resolve();
        }
      })
    });
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }
  resizeRenderer({ width, height }) {
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
}

export const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new THREE.Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};

export const addOrbitControl = function (renderer, scene, camera, config = {}) {
  let { target = [0, 0, 0] } = config;
  const control = new OrbitControls(camera, renderer.domElement);
  control.enableKeys = false;
  control.target = new THREE.Vector3(...target);
  control.addEventListener("change", () => {
    renderer.render(scene, camera);
  });
  control.update();
  return control;
};
const addCameraControl = function (renderer, scene, camera, config = {}) {
  let {
    target = [0, 0, 0],
    minDistance,
    maxDistance,
    enableRotate = true,
    enableDamping,
    autoRotate = false,
    autoRotateSpeed,
    panSpeed,
    boundingBox: bounds
  } = config;
  const clock = new THREE.Clock();

  const cameraControls = new CameraControls(camera, renderer.domElement);
  cameraControls.setTarget(target[0], target[1], target[2], false);
  const bb = new THREE.Box3(
    new THREE.Vector3(-1000, -500, -1500),
    new THREE.Vector3(500, 500, 1000)
  );
  if (bounds) {
    cameraControls.setBoundary(bb);
    cameraControls.verticalDragToForward = true;
    cameraControls.polarRotateSpeed = 0;
    cameraControls.azimuthRotateSpeed = 0;
    cameraControls.minDistance = 1200;
    cameraControls.maxDistance = 2000;
    cameraControls.boundaryFriction = 0.2;
  }
  console.log(cameraControls);

  renderer.render(scene, camera);

  (function anim() {
    const delta = clock.getDelta();
    const elapsed = clock.getElapsedTime();
    const updated = cameraControls.update(delta);
    requestAnimationFrame(anim);
    if (updated) {
      // cameraControls.getTarget(centerHelper.position);
      renderer.render(scene, camera);
      // console.log("rendered");
    }
  })();
  return cameraControls;
};
export const loadFbx = url => {
  return new Promise((resolve, reject) => {
    new FBXLoader().load(url, resolve, undefined, reject);
  });
};
