import React from "react";
import { Overlay, Classes } from "@blueprintjs/core";
import PropTypes from "prop-types";

const AtDialog = props => {
  const { onClose, isOpen, className, overlayClassName = "", portalContainerId, size, canOutsideClickClose = true } = props;
  return (
    <Overlay
      onClose={onClose}
      isOpen={isOpen}
      className={`${Classes.OVERLAY_SCROLL_CONTAINER} ${overlayClassName}`}
      hasBackdrop={true}
      lazy={false}
      canOutsideClickClose={canOutsideClickClose}
      // usePortal={false}
      portalContainer={document.getElementById(portalContainerId ? portalContainerId : "app-root")}
    >
      <div className="at-dialog-container">
        <div className={`at-dialog at-dialog-${size} ${className}`}>
          {/* {this.maybeRenderHeader()} */}
          {props.children}
        </div>
      </div>
    </Overlay>
  );
};
AtDialog.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(["lg", "md", "sm", "xs"])
};
AtDialog.defaultProps = {
  size: "md",
  className: "",
  isOpen: false
};
export default AtDialog;
