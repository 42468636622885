/* eslint-disable react-hooks/exhaustive-deps */
import { applyPresetOnCanvas, presetsMapping } from "instagram-filters";
import React, { useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";
import { useWindowSize } from "../../hooks";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../reducers/designdetails.reducer";
import { createCanvas, downloadImageData } from "../../utils/canvasutils";
import Thumbnail from "../molecules/Thumbnail";
import CloseupViewHelper from "./closeupviewhelper";
import CloseupViewOptions from "./CloseupViewOptions";
import FloorSelectionDialog from "../IllustrationView/FloorSelectionDialog";
import ViewpointDialog from "../IllustrationView/ViewpointDialog";
import {
  roomConfigActions,
  useDispatchRoomConfig,
  useRoomConfigState
} from "../IllustrationView/roomconfig.reducer";
import AppProvider, { getFloor } from "../../api/appProvider";
import { useDesignListState } from "../../reducers/designstree.reducer";
const viewHelper = new CloseupViewHelper();

const initialFloorOptions = {
  show: false,
  floors: [],
  activeFloor: {}
};

const CloseupView = props => {
  const {
    isIdle,
    handleRendering,
    filterData = null,
    roomData,
    floor,
    resetTrigger,
    setDefaultFloor
  } = props;
  const { Name: roomName, config, baseUrl } = roomData;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const designListState = useDesignListState();
  const windowsize = useWindowSize();

  const roomconfigstate = useRoomConfigState();
  const dispatchRoomConfig = useDispatchRoomConfig();
  const {
    designDetails,
    fullpath: designPath,
    hash,
    updateDesignTiles,
    updateNormapTiles
  } = designDetailState;
  const { customDesignUrl } = roomconfigstate;
  const [drawOnEffectsTrigger, setdrawOnEffectsTrigger] = useState(false);
  const [floorOptions, setFloorOptions] = useState(initialFloorOptions);
  const [showViewpointDialog, setShowViewpointDialog] = useState(false);
  const [activeShot, setActiveShot] = useState(null);
  const prevRoomData = usePrevious(roomData);
  const prevHash = usePrevious(hash);

  const perspectiveCanvasRef = useRef();
  const effectCanvasRef = useRef();

  useEffect(() => {
    return () => {
      viewHelper.cleanup();
    };
  }, []);


  const loadCustomDesign = async () => {
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
    try {
      await viewHelper.renderDesignFromCustomUrl({
        customUrl: customDesignUrl.url, physicalWidth: customDesignUrl.physicalWidCms,
        physicalHeight: customDesignUrl.physicalHgtCms
      });
      viewHelper.render();
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
    } catch (error) {
      console.log("Could not load image from the given url", error);
      dispatchRoomConfig({
        type: roomConfigActions.SET_CUSTOM_DESIGN_URL,
        payload: ""
      });
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    const loadView = async () => {
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: true });
      if (!prevRoomData) {
        viewHelper.init({ canvas: perspectiveCanvasRef.current });
      }
      let shouldrenderDesignBack = false;
      if (prevRoomData !== roomData) {
        viewHelper.initConfig({ config, baseUrl });
        const { activeShot } = config;
        let defaultFloor;
        if (config.defaultFloor) {
          defaultFloor = setDefaultFloor(config.defaultFloor);
        }
        setActiveShot(activeShot);
        if (floor || defaultFloor) {
          await viewHelper.setupFloor(defaultFloor || floor);
        }
        shouldrenderDesignBack = await viewHelper.setupObjects();
        await viewHelper.setupLights();
      }
      if (customDesignUrl?.url) {
        await loadCustomDesign();
        if (handleRendering) handleRendering(true);
        return () => { };
      }
      if (prevHash !== hash || shouldrenderDesignBack) {
        const tileData = await AppProvider.fetchTileDetails({
          file: designPath
        });
        dispatchDesignDetails({
          type: designDetailActions.SET_TILE_DETAILS,
          payload: tileData
        });
        viewHelper.tileDetails = tileData;
        await viewHelper.renderDesign({
          designDetails,
          designPath,
          hash,
          // designImagePath: designListState.activeNode.Thumb
        });
      }
      await viewHelper.setCarpetScale(designDetails);
      await viewHelper.render();
      if (handleRendering) handleRendering(true);
      dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
    };
    loadView();
    return () => { };
  }, [roomData, designDetails, customDesignUrl]);

  useEffect(() => {
    console.log(" designDetailState.loading", designDetailState.loading);
  }, [designDetailState.loading]);
  useEffect(() => {
    if (designDetailState.loading || !floor) return;
    if (viewHelper.floor) {
      viewHelper.updateFloor(floor);
    }
  }, [floor]);

  useEffect(() => {
    if (!effectCanvasRef || !effectCanvasRef.current) return;
    if (!filterData) return;
    setdrawOnEffectsTrigger(true);
  }, [filterData]);

  useEffect(() => {
    if (drawOnEffectsTrigger) {
      setdrawOnEffectsTrigger(false);
      drawOnEffects();
    }
  }, [drawOnEffectsTrigger]);

  const drawOnEffects = async () => {
    if (filterData) {
      let t1 = performance.now();
      const width = window.innerWidth;
      const height = window.innerHeight;
      effectCanvasRef.current.width = width;
      effectCanvasRef.current.height = height;
      effectCanvasRef.current.style.width = `${width}px`;
      effectCanvasRef.current.style.height = `${height}px`;
      const canvas = await viewHelper.getInstance();
      let canvas1 = document.createElement("canvas");
      canvas1.width = width;
      canvas1.height = height;
      canvas1.style.width = `${width}px`;
      canvas1.style.height = `${height}px`;
      canvas1
        .getContext("2d")
        .drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, width, height);
      await applyPresetOnCanvas(canvas1, presetsMapping[filterData]());
      effectCanvasRef.current.getContext("2d").drawImage(canvas1, 0, 0);
      let t2 = performance.now();
    }
  };

  const handleChangeShot = shotKey => {
    viewHelper.changeShot(shotKey);
    setActiveShot(shotKey);
  };

  useEffect(() => {
    if (!config || designDetailState.loading) return;
    viewHelper.resizeRenderer(windowsize);
  }, [windowsize]);
  window.downloadRenderedCloseupIll = async () => {
    if (designDetailState.loading) return;
    const mime = "jpg";
    const downloadName = `${designDetailState.designName} in ${roomName}.${mime}`;
    if (filterData) {
      const canvas = await viewHelper.getInstance();
      const newCanvas = createCanvas(window.innerWidth, window.innerHeight);
      newCanvas
        .getContext("2d")
        .drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          newCanvas.width,
          newCanvas.height
        );
      await applyPresetOnCanvas(newCanvas, presetsMapping[filterData]());
      downloadImageData(newCanvas, downloadName, mime);
    } else {
      viewHelper.downloadRendering(downloadName, mime);
    }
    return Promise.resolve();
  };

  const reset = () => {
    viewHelper.resetView();
  };

  useEffect(() => {
    if (resetTrigger > 0) {
      reset();
    }
  }, [resetTrigger]);

  return (
    <>
      <div className="perspective-view__container">
        <canvas
          className="perspective-view__canvas"
          ref={perspectiveCanvasRef}
          style={{ pointerEvents: roomName.includes("Folded") ? "none" : "all" }}
        />
        <canvas
          style={{
            zIndex: 15,
            display: filterData ? "block" : "none",
            position: "absolute",
            top: "0px"
          }}
          className={"closeupview__effectCanvas"}
          ref={effectCanvasRef}
        />
      </div>
      <ViewpointDialog
        baseUrl={baseUrl}
        shots={config.shots}
        activeshot={activeShot}
        showViewpointDialog={showViewpointDialog}
        handleClose={() => setShowViewpointDialog(false)}
        handleThumbClick={shot => {
          handleChangeShot(shot);
        }}
      />
      {/* <CloseupViewOptions
        isIdle={isIdle}
        shots={config.shots}
        floorOptions={floorOptions}
        handleShowVpDialog={() => setShowViewpointDialog(true)}
        handleShowFloorDialog={() => {
          setFloorOptions({ ...floorOptions, show: true });
        }}
      /> */}
    </>
  );
};

export default CloseupView;
