import React from "react";
import { Overlay, H5 } from "@blueprintjs/core";
import Thumbnail from "../molecules/Thumbnail";
import { useRoomConfigState, useDispatchRoomConfig, roomConfigActions } from "./roomconfig.reducer";

const ViewpointDialog = props => {
  const {  baseUrl, shots, showViewpointDialog, activeshot,handleChangeShot, handleClose, handleThumbClick } = props
  return <Overlay
    autoFocus={false}
    enforceFocus={false}
    className="at-illustration-popups-container"
    isOpen={showViewpointDialog}
    hasBackdrop={false}
    usePortal={false}
    onClose={handleClose}
  >
    <div className="at-illustration-popup viewpoints-popup">
      <H5>Select Viewpoint</H5>
      {shots && Object.entries(shots).map(([shotKey, shotProps], i) => (
        <Thumbnail
          thumb={{ Thumb: `${baseUrl}/${shotProps.thumbUrl}`, label: shotProps.label }}
          active={shotKey === activeshot}
          key={i}
          aspect="landscape"
          onThumbnailClick={() => handleThumbClick(shotKey)}
        />
      ))}
    </div>
  </Overlay>
}


export default ViewpointDialog