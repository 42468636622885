import React, { useRef, useState } from "react";

const BlurCircle = props => {
  const { onWheel, top, left, handleDontBLurRad, ...otherProps } = props;

  const [dragging, setdragging] = useState(false);
  const [circleRadius, setcircleRadius] = useState(100);
  const [distance, setdistance] = useState(0);
  const blurCircleRef = useRef(null);

  const getxy = e => {
    let isTouchEvent = e.nativeEvent.type === "touchstart" || e.nativeEvent.type === "touchmove";
    const { clientX, clientY } = isTouchEvent ? e.nativeEvent.targetTouches[0] : e;
    const { top, left } = blurCircleRef.current.getBoundingClientRect();
    const xabs = clientX - left;
    const yabs = clientY - top;
    return { x: xabs, y: yabs };
  };
  const handleMouseDown = e => {
    setdragging(true);
    const { x, y } = getxy(e);
    const newdistance = Math.round(
      Math.sqrt(Math.pow(x - circleRadius, 2) + Math.pow(y - circleRadius, 2))
    );
    setdistance(newdistance);
  };

  const handleMouseMove = e => {
    if (!dragging) return;
    const { x, y } = getxy(e);
    const newdistance = Math.round(
      Math.sqrt(Math.pow(x - circleRadius, 2) + Math.pow(y - circleRadius, 2))
    );
    const delta = newdistance - distance;
    setdistance(newdistance);
    setcircleRadius(circleRadius + delta / 4);
    // console.log(newdistance, circleRadius, delta)
    // if (delta > 0) {
    //   setcircleRadius(circleRadius + (delta/4))
    // }
    // else {
    //   setcircleRadius(circleRadius - (delta/4))
    // }
  };

  const handleMouseUp = e => {
    if (dragging) {
      handleDontBLurRad((circleRadius * 2) / 15);
      setdragging(false);
      setdistance(0);
    }
  };

  return (
    <div
      className="blur-outer-circle"
      style={{
        width: `${circleRadius * 2}px`,
        height: `${circleRadius * 2}px`,
        top: top,
        left: left
      }}
      {...otherProps}
      ref={blurCircleRef}
      onWheel={onWheel}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
    >
      {/* <div className="blur-middle-area"> */}
      <div className="blur-inner-circle"></div>
      {/* </div> */}
    </div>
  );
};

export default BlurCircle;
