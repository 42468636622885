import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CircularButton from "../../atoms/CircularButton";
import { flaginCurrentMode } from "../../pages/EntryPage/defaultflags";
import classNames from "classnames";
import { Tooltip } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
const Thumbnail = props => {
  const {
    className = "",
    thumb,
    thumbnailName,
    onRemoveClicked,
    onThumbnailClick,
    onEditClick,
    hasRemove,
    hasEdit,
    imageRotated,
    infoThumbs,
    aspect = "portrait",
    showTitle = true,
    active,
    thumbType
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const thumbImg = useRef(null);
  const [scrollTo, setScrollTo] = useState(true);
  const ref = useRef(null);

  useEffect(() => {
    let effectActive = true;

    setIsloading(true);
    if (!thumb.Thumb) return;
    const image = new Image();
    image.src = thumb.Thumb;
    image.onload = () => {
      if (!effectActive) return;
      try {
        if (thumbImg?.current) {
          thumbImg.current.src = image.src;
          thumbImg.current.backgroundImage = image.src;
        }
      } catch (error) {
        console.error(error);
      }
      setIsloading(false);
    };
    return () => { effectActive = false }
  }, [thumb.Thumb]);


  const rendername = name =>
    name &&
    name.split("~").map((item, i) => {
      return <p key={i}>{item}</p>;
    });

  const getBgImage = () => {
    if (
      !flaginCurrentMode(window.InterfaceElements.IsAdmin, window.flags.designThumbsAsBg) ||
      thumbType === "ROOM_THUMBS"
    )
      return "none";
    const thumbSrc = thumbImg.current !== null ? "url(" + thumbImg.current.src + ")" : "none";
    return thumbSrc;
  };
  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={`thumb-item ${active ? "active" : ""} ${className}`}
      onClick={e => {
        if (className === "disabled") return;
        setScrollTo(false);
        if (onThumbnailClick) onThumbnailClick(e);
      }}
      ref={ref}
    >
      <Tooltip position="bottom" content={thumb.tooltip || null}>
        <div
          className={`thumb-image-container ${aspect} ${!thumb.Thumb || isloading ? "bp3-skeleton" : ""
            }`}
          style={{
            backgroundImage: getBgImage(),
            backgroundSize: "contain"
          }}
        >
          <img
            className={classNames(`thumb-image fit-width`, {
              rotated: imageRotated,
              "thumb-hidden": getBgImage() !== "none"
            })}
            ref={thumbImg}
            alt="thumbnail"
          />
          {thumb.infoThumbs && thumb.infoThumbs.length > 0 && (
            <div className={"bottom-smallthumb-strip"}>
              {thumb.infoThumbs.map((item, index) => {
                return (
                  <>
                    {
                      thumb.infoTitles?.length ?
                        <Tooltip position="bottom" content={thumb.infoTitles ? thumb.infoTitles[index] : null}>
                          <div className={`circular-button ui  small`} onClick={e => { }} key={index}>
                            {typeof item === "string" ? <span>{item}</span> : item}
                          </div>
                        </Tooltip>
                        :
                        <div className={`circular-button ui  small`} onClick={e => { }} key={index}>
                          {typeof item === "string" ? <span>{item}</span> : item}
                        </div>
                    }
                  </>

                );
              })}
            </div>
          )}
        </div>
      </Tooltip>

      {
        hasEdit && (
          <AtButton
            icon="edit"
            small={true}
            className="edit-button"
            onClick={e => {
              e.stopPropagation();
              if (onEditClick) onEditClick(e);
            }}
          />
        )
      }

      {
        hasRemove && isHovering && (
          <CircularButton
            icon="close"
            size="small"
            className="close-button"
            onClick={e => {
              e.stopPropagation();
              if (onRemoveClicked) onRemoveClicked(e);
            }}
          />
        )
      }

      {
        showTitle && (
          <span className="thumb-title" title={thumbnailName ? thumbnailName : thumb.label}>
            {rendername(thumbnailName ? thumbnailName : thumb.label)}
          </span>
        )
      }
    </div >
  );
};

Thumbnail.propTypes = {
  className: PropTypes.string,
  thumb: PropTypes.object,
  hasRemove: PropTypes.bool,
  onRemoveClicked: PropTypes.func,
  onThumbnailClick: PropTypes.func,
  thumbnailType: PropTypes.oneOf(["portrait", "landscape"]),
  showTitle: PropTypes.bool
};
export default Thumbnail;
