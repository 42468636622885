export const colorNumberModes = {
  NONE: 0,
  NUMERIC: 1,
  ALPHA: 2
};
export const CurrencyFormat = {
  None: 0,
  US: 1,
  EU: 2
};
export const mode = {
  None: 0,
  Both: 1, //Both admin and the multiple account
  OnlyAdmin: 2
};
export const flaginCurrentMode = (isAdmin, flag) => {
  let flagStatus = false;
  if (isAdmin && flag !== mode.None) {
    flagStatus = true;
  } else if (!isAdmin && flag == mode.Both) {
    flagStatus = true;
  }
  return flagStatus;
};
export const defaultFlags = {
  colorSeparatedTexture: true, //individual texture for individual colors
  showColorPercentage: true, //show percentage of color in color swatch
  hasOrderSheet: true, //if false order sheet will not be shown
  defaultQualityIndex: 0, //index for rug quality
  defaultStandardSize: 0,
  defaultUnit: null, //default unit for rug (ft or cm).
  hasRugshare: false,
  hasHoverZoom: false, //zoom design/illustration on hover
  hasArp: false, //augmented rug presentation
  showCopyright: true, //show copyright text?
  hasVps: true,
  showAddToCart: false,
  showSaveImage: true, // download image feature
  applyColorPaletteinSameFolder: false, //color and material changes in a design is applied to other same folder designs (example in dalyn rugs)
  applyColorPaletteinVariations: false, //color and material changes in a design is applied to other same designs variations (example in dalyn rugs)
  hasMasterCarving: mode.Both,
  designThumbsAsBg: mode.None,
  fullPageThumb: mode.None, //true for showing as default screen, "SET" for loading from button null/false for hiding
  showAllDesignsButton: mode.None,
  showWarningInfoInFooter: false, //show warning text in footer when colors from different palette are selected (eg: flipo)
  designBrowser: {
    showCurrentOpenFolder: false
  },
  rugshare: {
    socialMediaShareDescription: "",
    shareCustomizedDesign: false
  },
  colorCustomizationTools: {
    showColorCustomizationTools: false, //for multiple accounts
    showMapColors: false, //mapcolors is shown in admin by default. Showmapcolors == true for multiple accounts
    addShuffleColors: false,
    addColorPercentToggle: false,
    addAutocolor: false
  },
  colorSelectionBox: {
    showColorName: false,
    showSimilarColors: true,
    showColorFilter: true,
    showCarving: mode.None, // individual carving
    customColorOptions: [], //to choose from color wheel,
    collectionFilter: [], // show color collection and width in ordersheet according to folder. Has folder, collection, width list
    swatchSize: 2,
    colorPaletteLabels: {
      colorLabelInfo: "", // appears inside dialog box
      labelTemplate: "",
      labels: [],
      colorLabelFilter: []
    }
  },
  visualizations: {
    defaultView: "DESIGN_VIEW_DEEPZOOM", //DESIGN_VIEW_DEEPZOOM or ROOM_VIEW
    designViews: ["DESIGN_VIEW_DEEPZOOM"],
    myRoomEnabled: true,
    showIcebreakerinMyroom: true
  },
  designView: {
    minZoom: 0.75,
    maxZoom: 3,
    defaultZoom: 2,
    wallToWallLockAxis: null, //null, x, y
    hasDesignWaterMark: false,
    dpi: 1
  },
  designDetailSection: {
    isCollapsible: false,
    showDesignPathInTitle: false,
    showSizeSelection: true,
    showDesignDimensions: true,
    showAddToFavoriteText: false
  },
  designVariations: {
    appendDesignName: true, //appends design name to variation name if true
    sayOriginal: true
  },
  colorAreaSwatch: {
    hasMixply: mode.None, // allow mixply in multiple accounts
    showColorPercentage: true, //show percentage of color in color swatch
    colorNumberMode: colorNumberModes.NONE,
    showRedoButton: false,
    shape: "CIRCLE", //"RECTANGLE_H", "SQUARE", "RECTANGLE_V",
    // mixPlyOptions: [
    //   { ply: 1, text: "Single Color" },
    //   { ply: 2, text: "2-Ply" },
    //   { ply: 3, text: "3-Ply" },
    //   { ply: 4, text: "4-Ply" }
    // ]
    mixPlyOptions: [
      { ply: 1, text: "Single Color" },
      { ply: 3, text: "Mix Ply" }
    ],
    sameColorLock: false,
    exclusiveMatArr: [] // [0, 1] if mat 0 and 1 are exclusive. [[0,1],2] if 0,1 can appear together but not with 2.
  },
  language: {
    langOptions: [], //languages to allow switching //{ val: "en", text: "EN" } format
    strings: {} //strings to overwrite
  },
  ordersheet: {
    showRugSizeInOrderForm: true,
    currencyFormat: CurrencyFormat.NONE,
    populateRugSize: true, // width and height is left blank when it's false
    changeableUnits: true, // unit is always set to defaultUnit when it's false
    strInPrice: "SILK", //String to show instead of price eg: in hollyhunt, silk % (100% SILK) is shown instead of price ($100)
    autoPopulateFields: [], //["Shape"] list name of ordersheet field you need to populate dynamically and add condition in getCustomFieldValue() function in ordersheet/index file
    roundOffToFtOrCm: false,
    roundOffToNearestHalf: false,
    addedCustomFields: []
  },
  email: {
    setCustomEmail: false,
    customerEmailBody: null,
    customerEmailSubject: null, //'test customer email subject',
    customerEmailFromAddress: null,
    companyEmailBody: null, //test company email body',
    companyEmailSubject: null, //'test company email subject',
    companyEmailFromAddress: null
  }
};
