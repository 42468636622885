import React from "react";
import { errorTypes } from "../../../utils/utils";
import { H1 } from "@blueprintjs/core";
import AtButton from "../../atoms/AtButton";
const ErrorTem = props => {
  const { title, content, buttonText, onButtonClick } = props;
  return (
    <div id="at-error-page">
      {title && <H1>{title}</H1>}
      {content && <span>{content}</span>}
      {buttonText && <AtButton intent="primary" text={buttonText} onClick={onButtonClick} />}
    </div>
  );
};
const ErrorPage = props => {
  const { errorType } = props;
  switch (errorType) {
    case errorTypes.INTERFACE_ERROR:
      return (
        <ErrorTem
          title="Oops..."
          content={
            <>
              It’s not you, it’s us. We’ve been notified of this issue.
              <br /> Please check again soon.
            </>
          }
          buttonText="Try Again"
        />
      );
    case errorTypes.INVALID_LINK:
      return (
        <ErrorTem
          title="We’re sorry."
          content={<>This link does not seem to be working.</>}
          buttonText="View other designs"
        />
      );
    case errorTypes.NO_CONNECTION:
      return (
        <ErrorTem
          title="Lost connection"
          content="Your internet seems to be slow."
          buttonText="Reconnect"
        />
      );
    default:
      return <ErrorTem />;
  }
};

export default ErrorPage;
