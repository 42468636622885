import React from "react";
import { useRoomConfigState, useDispatchRoomConfig, roomConfigActions } from "./roomconfig.reducer";
import classNames from "classnames";
import { Overlay, Popover } from "@blueprintjs/core";
import ColorSelectionBox from "../organisms/ColorSelectionBox";

const ColorSelection = props => {
  const { isIdle, removeTransState, handleColorSwatchClick } = props;
  const roomConfigState = useRoomConfigState();
  const dispatchRoomConfig = useDispatchRoomConfig();
  const { activedata } = roomConfigState;
  const handleClose = object => {
    dispatchRoomConfig({
      type: roomConfigActions.SET_SHOW_COLOR_BOX,
      payload: { object, isOpen: false }
    });
  };
  const handleOpen = object => {
    dispatchRoomConfig({
      type: roomConfigActions.SET_SHOW_COLOR_BOX,
      payload: { object, isOpen: true }
    });
  };
  const getPosition = objProps => ({
    left: `${objProps[0]}%`,
    top: `${objProps[1]}%`
  });

  const getPosition1 = objProps => ({
    left: `${objProps[0] - 15}%`
  });

  const renderSelectionBox = (objKey, objProps, onClose) => (
    <div style={getPosition(objProps)}>
      <ColorSelectionBox
        onColorSwatchClick={(color, e) => handleColorSwatchClick(color, objKey)}
        onClose={onClose}
        style={getPosition1(objProps)}
      />
    </div>
  );
  if (!activedata) return <></>;
  return Object.entries(activedata).map(
    ([objKey, objProps]) =>
      roomConfigState.objectstate[objKey].mystic !== "hide" && (
        <div
          key={objKey}
          className={classNames("optionscontainer__activetrigger", { "at-idle-fadeout": isIdle })}
          style={getPosition(objProps)}
          onMouseOver={() => removeTransState(objKey)}
        >
          <Popover
            minimal
            isOpen={roomConfigState.objectstate[objKey].isOpen}
            onClose={() => handleClose(objKey)}
            portalContainer={document.getElementById("photoscene-main")}
            content={renderSelectionBox(objKey, objProps, () => handleClose(objKey))}
          >
            <div
              className={"activetrigger__inelem"}
              style={{
                background: `${roomConfigState.objectstate[objKey].active}`
              }}
              onClick={() => handleOpen(objKey)}
            />
          </Popover>
        </div>
      )
  );
};

export default ColorSelection;
