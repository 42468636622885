import { readImage, resizeKeepingAspect, convertUnit, convertArrIntoRad } from "./../../utils/utils";
import { clearCanvas, createCanvas, downloadImageData } from "./../../utils/canvasutils";
import ThreeViewHelper from "./threeviewhelper";
// import TileCanvas from "../tilecanvasnew";
// const tileCanvas = new TileCanvas()

function createName() {
  let res = ""
  Array.from(arguments).forEach(argument => {
    if (argument) {
      res = `${res}${argument}.`;
    }
  });
  return res
}
function makeUrl() {
  let res = ""
  Array.from(arguments).forEach((argument, index) => {
    if (argument) {
      res = `${res}${index === 0 ? "" : "/"}${argument}`;
    }
  });
  return res
}
const rgbFromHex = hex => {
  let rgb = Array[3]
  rgb[0] = parseInt(hex.substring(1, 3), 16);
  rgb[1] = parseInt(hex.substring(3, 5), 16);
  rgb[2] = parseInt(hex.substring(5, 7), 16);
  return rgb
}

const patchRgb = [45, 24, 18];

export default class RoomViewHelper {
  constructor() {
    this.config = {};
    this.baseUrl = null;
    this.dimension = { width: null, height: null };
    this.dimensionPixels = { width: null, height: null };
    this.bgImage = null;
    this.maskImage = null;
    this.bgPatchImage = null;
    this.bgPatchShadowImage = null;
    this.shadowImage = null;
    this.highlightImage = null;
    this.canvasArray = Array(4);

    this.carpetURL = "";
    this.fbxLoaded = false;
    this.threeView = new ThreeViewHelper();

    this.zoom = 2;

  }

  initCanvas(options) {
    this.bgCanvas = options.bgCanvas
    this.threeCanvas = options.threeCanvas
    this.maskCanvas = options.maskCanvas
    this.shadowCanvas = options.shadowCanvas
    this.container = options.container
    this.inputCanvas = options.inputCanvas
  }
  clearAllCanvases() {
    const { width, height } = this.dimension;
    console.info("Clearing canvases", this.shadowCanvas)
    clearCanvas(this.bgCanvas, width, height);
    clearCanvas(this.maskCanvas, width, height);
    clearCanvas(this.shadowCanvas, width, height);
    clearCanvas(this.inputCanvas, width, height);
  }
  initConfig({ baseUrl, config, files }) {
    this.baseUrl = baseUrl
    this.config = config
    const illustrationDims = this.config.dims;
    const containerDims = {
      width: this.container.clientWidth,
      height: this.container.clientHeight
    }

    this.resolution = window.devicePixelRatio;
    this.dimensionPixels = resizeKeepingAspect(illustrationDims, containerDims, "fit_inside")

    this.dimension = {
      width: window.screen.width * this.resolution,
      height: window.screen.height * this.resolution
    };
    this.resolution = this.dimension.width / this.dimensionPixels.width
    this.inputCanvas.width = this.dimensionPixels.width
    this.inputCanvas.height = this.dimensionPixels.height
    // setCanvasDimensions(this.inputCanvas, this.dimension, this.dimensionPixels)

    const x = { shot: this.config.shots[0], light: this.config.lights ? this.config.lights[0] : null };
    const bgUrl = `${createName(x.shot, x.light)}bg.jpg`;
    const bgPatchUrl = `${createName(x.shot, x.light)}pch.png`;
    const bgPatchShadowUrl = `${createName(x.shot, x.light)}pch.sh.jpg`;
    const maskUrl = `${createName(x.shot, x.light)}m.png`;
    const shadowUrl = `${createName(x.shot, x.light)}sh.jpg`;
    const highlightUrl = `${createName(x.shot, x.light)}hl.jpg`;
    const glowUrl = `${createName(x.shot, x.light)}gl.jpg`;
    const promises = [];
    if (!files.includes(bgUrl))
      promises.push(Promise.reject("no background image"))
    else {
      promises.push(readImage(makeUrl(baseUrl, bgUrl)).then(img => this.bgImage = img))
      if (files.includes(bgPatchUrl))
        promises.push(readImage(bgPatchUrl).then(img => this.patchImage = img))
      else
        this.patchImage = null
      if (files.includes(bgPatchShadowUrl))
        promises.push(readImage(makeUrl(baseUrl, bgPatchShadowUrl)).then(img => this.patchShadow = img))
      else {
        this.patchShadow = null
      }
      if (files.includes(maskUrl))
        promises.push(readImage(makeUrl(baseUrl, maskUrl)).then(img => this.maskImage = img))
      else
        this.maskImage = null
      if (files.includes(shadowUrl))
        promises.push(readImage(makeUrl(baseUrl, shadowUrl)).then(img => this.shadowImage = img))
      if (files.includes(highlightUrl))
        promises.push(readImage(makeUrl(baseUrl, highlightUrl)).then(img => this.highlightImage = img))
      if (files.includes(glowUrl))
        promises.push(readImage(makeUrl(baseUrl, glowUrl)).then(img => this.glowImage = img))
    }
    return promises
  }
  updateBackground(options = {}) {
    const { clear = false, dominantColorHex, canvas = this.bgCanvas } = options
    const { width, height } = this.dimension;

    const bgCtx = canvas.getContext("2d");
    clearCanvas(canvas, width, height);
    setCanvasDimensions(canvas, this.dimension, this.dimensionPixels)
    if (clear) {
      return "clear";
    }
    bgCtx.drawImage(this.bgImage, 0, 0, width, height);
    if (this.patchImage && dominantColorHex) {

      const activeColor = rgbFromHex(dominantColorHex)
      const patchCanvas = createCanvas(width, height);
      const patchCtx = patchCanvas.getContext("2d");
      patchCtx.drawImage(this.patchImage, 0, 0, width, height)
      let patchData = patchCtx.getImageData(0, 0, width, height);

      for (let i = 0; i < patchData.data.length; i += 4) {
        if (patchData.data[i] === patchRgb[0] && patchData.data[i + 1] === patchRgb[1] && patchData.data[i + 2] === patchRgb[2]) {
          patchData.data[i] = activeColor[0];
          patchData.data[i + 1] = activeColor[1];
          patchData.data[i + 2] = activeColor[2];
        }
      }
      patchCtx.putImageData(patchData, 0, 0);

      bgCtx.globalCompositeOperation = "overlay";
      bgCtx.drawImage(patchCanvas, 0, 0, width, height);

      if (this.patchShadow) {
        bgCtx.globalCompositeOperation = "multiply";
        bgCtx.drawImage(this.patchShadow, 0, 0, width, height);
      }
    }
    return "successfully updated bg"
  }
  updatethreeCanvas() {
    if (!this.config.scenes) return
    const scene = this.config.scenes[0];
    const { roomType } = this.config;
    const sceneConfig = this.config[scene];
    this.threeView.init({
      canvas: this.threeCanvas,
      config: sceneConfig,
      shots: this.config.shots,
      dims: this.dimensionPixels,
      resolution: this.resolution
    })
    switch (roomType) {
      case "illustration":
        return this.threeView.setup3dObject({ fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl) });
      default:
        return this.threeView.setupCarpet({ fbxUrl: "https://v3.explorug.com/rug.fbx" });

    }
  }

  changeDimensions({ physicalWidthCms, physicalHeightCms }) {
    const { width, height } = this.designDetails;
    let PhysicalWidth, PhysicalHeight;
    if (!physicalWidthCms || !physicalHeightCms) {
      const { width: newWidth, height: newHeight } = resizeKeepingAspect({ width, height }, { width: 1200, height: 1500 }, "fit_inside");
      PhysicalWidth = convertUnit("in", "ft", newWidth / 10)
      PhysicalHeight = convertUnit("in", "ft", newHeight / 10)
    }
    else {
      PhysicalWidth = convertUnit("cm", "ft", physicalWidthCms)
      PhysicalHeight = convertUnit("cm", "ft", physicalHeightCms)
    }

    const designDetails = { Width: width, Height: height, PhysicalWidth, PhysicalHeight, Unit: "ft" }
    this.designDetails = designDetails;
    this.threeView.setCarpetScale(designDetails);
    this.threeView.carpetMesh.material.needsUpdate = true;
    this.threeView.render();
    return Promise.resolve()
  }

  async renderFromJpg({ designImagePath, physicalWidthCms, physicalHeightCms }) {
    let designImage = new Image();
    await new Promise((resolve) => {
      designImage.onload = () => {
        resolve();
      }
      designImage.crossOrigin = "anonymous";
      designImage.src = designImagePath;
    });

    this.threeView.setCarpetVisibility(false)
    // return readImage(designPath).then(designImage => {
    //  const  designImage = designPath;
    const { width, height } = designImage
    const canvas = createCanvas(width, height)
    const context = canvas.getContext("2d");
    context.drawImage(designImage, 0, 0
    )
    const canvasNorm = createCanvas(width, height)
    const ctxNorm = canvasNorm.getContext("2d")
    ctxNorm.fillStyle = "rgb(127,127,255)";
    ctxNorm.fillRect(0, 0, width, height);
    let PhysicalWidth, PhysicalHeight;
    if (!physicalWidthCms || !physicalHeightCms) {
      const { width: newWidth, height: newHeight } = resizeKeepingAspect({ width, height }, { width: 1200, height: 1500 }, "fit_inside");
      PhysicalWidth = convertUnit("in", "ft", newWidth / 10)
      PhysicalHeight = convertUnit("in", "ft", newHeight / 10)
    }
    else {
      PhysicalWidth = convertUnit("cm", "ft", physicalWidthCms)
      PhysicalHeight = convertUnit("cm", "ft", physicalHeightCms)
    }

    // if (PhysicalWidth > 12 || PhysicalHeight > 15) {
    //   console.log(PhysicalWidth, PhysicalHeight)
    //   const { width, height } = resizeKeepingAspect({ width: PhysicalWidth, height: PhysicalHeight }, { width: 12, height: 15 }, "fit_inside")
    //   // PhysicalWidth = width;
    //   // PhysicalHeight = height;
    // }
    const { position = [0, 0, 0], rotation = [90, 0, 0] } = this.threeView.objConf;
    this.threeView.carpetMesh.position.fromArray(position);
    this.threeView.carpetMesh.rotation.fromArray(convertArrIntoRad(rotation.slice(0, 3)));
    const designDetails = { Width: width, Height: height, PhysicalWidth, PhysicalHeight, Unit: "ft" }
    this.designDetails = designDetails;
    this.threeView.setCarpetTexture({
      designDetails,
      designCanvas: canvas,
      normapCanvas: canvasNorm
    })
    this.updateGizmo();
    this.threeView.setCarpetVisibility(true)
    return Promise.resolve()
    // })
  }
  renderDesign({ designDetails, designPath, hash }) {
    // this.designDetails = designDetails;
    // this.designPath = designPath;
    // const { roomType } = this.config;
    // const { designScale } = this.config[this.config.scenes[0]]
    // if (designScale)
    //   this.zoom = designScale
    // tileCanvas.init({ tileSize: 256, zoom: this.zoom, designDetails })

    // switch (roomType) {
    //   case "illustration":
    //     return new Promise((resolve, reject) => {
    //       this.threeView.setObjectTexture({
    //         designDetails,
    //         designCanvas: tileCanvas.canvas
    //       })
    //       // this.threeView.setObjectVisibility(false)

    //       tileCanvas.drawCanvasTiles({
    //         designPath,
    //         zoom: this.zoom,
    //         designDetails,
    //         hash,
    //         drawNormap: false
    //       }, () => {
    //         // this.threeView.updateMap()
    //       }, () => {
    //         this.threeView.updateMap()
    //         setTimeout(() => {
    //           resolve()
    //           // this.threeView.setObjectVisibility(true)
    //         }, 500);
    //       })
    //     })

    //   default:

    //     return new Promise((resolve, reject) => {
    //       this.threeView.setCarpetTexture({
    //         designDetails,
    //         designCanvas: tileCanvas.canvas,
    //         normapCanvas: tileCanvas.canvasNorm
    //       })
    //       this.threeView.setCarpetVisibility(false)

    //       tileCanvas.drawCanvasTiles({
    //         designPath,
    //         zoom: this.zoom,
    //         designDetails,
    //         hash
    //       }, () => {
    //         // this.threeView.updateMap()
    //       }, () => {
    //         this.threeView.updateMap()
    //         setTimeout(() => {
    //           resolve()
    //           this.threeView.setCarpetVisibility(true)
    //           this.updateGizmo()
    //         }, 500);
    //       })
    //     })

    // }

    // return new Promise((resolve, reject) => {
    //   readImage("temp/testcircular/diffuse.jpg").then(dif => {
    //     readImage("temp/testcircular/diffuse-norm.png").then(norm => {
    //       const desCanvas = createCanvas(4096, 4096)
    //       const nomCanvas = createCanvas(4096, 4096)
    //       desCanvas.getContext("2d").drawImage(dif, 0, 0, 4096, 4096)
    //       nomCanvas.getContext("2d").drawImage(norm, 0, 0, 4096, 4096)
    //       this.threeView.setCarpetTexture({
    //         designDetails,
    //         designCanvas: desCanvas,
    //         normapCanvas: nomCanvas
    //       })
    //       this.threeView.updateMap()
    //       resolve()
    //     })
    //   })
    // })
  }
  setTileDetails(tileDetails) {
    this.tileDetails = tileDetails
  }
  updateTiles({ designDetails, updateDesignTiles, updateNormapTiles, hash }) {
    // if (!this.tileDetails) {
    //   return Promise.resolve()
    // }
    // return new Promise((resolve, reject) => {
    //   let colorIndex;
    //   if (updateDesignTiles) colorIndex = updateDesignTiles.colorIndex
    //   if (updateNormapTiles) colorIndex = updateNormapTiles.colorIndex

    //   this.designDetails = designDetails

    //   let colorTileData = null
    //   if (colorIndex && colorIndex !== -1) {
    //     const tileData = this.tileDetails[`colorTileData${this.zoom}`]
    //     colorTileData = tileData[colorIndex].tiles
    //   } else {
    //     //all tiles
    //   }
    //   const props = {
    //     tiles: colorTileData,
    //     zoom: this.zoom,
    //     designDetails,
    //     designPath: this.designPath,
    //     hash
    //   }
    //   if (!updateNormapTiles) {
    //     tileCanvas.designTilesUpdated = true
    //     tileCanvas.updateDesignTiles(props, () => {
    //       this.threeView.updateMap()
    //     }, () => {
    //       this.threeView.updateMap()
    //       setTimeout(() => {
    //         resolve()

    //       }, 500);
    //     })
    //   } else {
    //     tileCanvas.normapTilesUpdated = true
    //     tileCanvas.updateNormapTiles(props, () => {
    //       this.threeView.updateMap()
    //     }, () => {
    //       this.threeView.updateMap()
    //       setTimeout(() => {
    //         resolve()

    //       }, 500);
    //     })
    //     // this.tileCanvas1x.updateNo(props, () => this.render())
    //   }
    // })

  }
  updateMask(options = {}) {
    const { clear = false } = options;
    const { width, height } = this.dimension;
    setCanvasDimensions(this.maskCanvas, this.dimension, this.dimensionPixels)
    clearCanvas(this.maskCanvas, width, height);
    if (clear) return "clear"
    if (!this.maskImage) return

    const tmpCanvas = createCanvas(width, height);
    tmpCanvas.getContext("2d").drawImage(this.bgCanvas, 0, 0, width, height);
    makeMask(tmpCanvas, width, height, this.maskImage);

    this.maskCanvas.getContext("2d").drawImage(tmpCanvas, 0, 0, width, height);
  }
  updateShadow(options = {}) {
    const { clear = false } = options
    const { width, height } = this.dimension;
    const tempCanvas = createCanvas(width, height);
    const tCtx = tempCanvas.getContext("2d");

    setCanvasDimensions(this.shadowCanvas, this.dimension, this.dimensionPixels)
    clearCanvas(this.shadowCanvas, width, height);

    if (clear) return "clear";
    // const ctx = this.canvas.getContext("2d");
    // tCtx.fillStyle = "rgb(0,0,0)";
    // tCtx.fillRect(0, 0, width, height)
    tCtx.drawImage(this.bgCanvas, 0, 0, width, height);
    tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
    tCtx.drawImage(this.maskCanvas, 0, 0, width, height);

    if (this.shadowImage) {
      tCtx.globalCompositeOperation = "multiply";
      tCtx.drawImage(this.shadowImage, 0, 0, width, height);
    }
    if (this.highlightImage) {
      tCtx.globalCompositeOperation = "screen";
      tCtx.drawImage(this.highlightImage, 0, 0, width, height);
    } if (this.glowImage) {
      tCtx.globalCompositeOperation = "overlay";
      tCtx.drawImage(this.glowImage, 0, 0, width, height);
    }
    tCtx.globalCompositeOperation = "destination-in";
    tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
    this.shadowCanvas.getContext("2d").drawImage(tempCanvas, 0, 0, width, height)
  }

  mouseDownTouchStart(e) {
    this.updateGizmo()
    this.intersectsGizmo = this.findGizmoIntersection(e)
    this.prev = { ...e }
    if (!this.intersectsGizmo) {
      const intersectsCarpet = this.threeView.mouseDownTouchStart(e)
      this.shadowCleared = false
      if (intersectsCarpet) {
        this.updateShadow({ clear: true });
        this.shadowCleared = true
      }
    } else {
      this.prev = { ...e }
      this.updateShadow({ clear: true });
      this.shadowCleared = true
    }
  }
  mouseDownTouchMove(e) {
    if (!this.intersectsGizmo) {
      this.threeView.mouseTouchMove(e)
      this.updateGizmo()
    } else {
      const difference = e.x - this.prev.x;
      this.threeView.rotateCarpet(difference, "z")
      this.prev = { ...e }
    }

  }
  mouseDownTouchEnd(e) {
    if (this.shadowCleared)
      this.updateShadow();
  }
  findGizmoIntersection(e) {
    const { x, y } = e
    if (!this.inputCanvas) return;
    var imgData = this.inputCanvas
      .getContext("2d")
      .getImageData(x - 10, y - 10, 20, 20);
    var ingizmo = false;
    for (var i = 0; i < imgData.data.length; i += 4) {
      if (imgData.data[i + 3] !== 0) {
        ingizmo = true;
        break;
      }
    }
    return ingizmo
  }
  updateGizmo() {
    const diamondHeight = 10;
    const context = this.inputCanvas.getContext("2d")
    const { width, height } = this.inputCanvas
    let { radX, radY, canvasCenter } = this.threeView.getGizmoCordinates()

    const rgb = {
      r: 250,
      g: 250,
      b: 250,
      a: 0.8
    };
    const colorStr =
      "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a + ")";
    var radiusX;
    var radiusY;
    if (radX > radY) {
      radiusX = radX;
      radiusY = radY;
    } else {
      radiusX = radY;
      radiusY = radX;
    }
    // Draw the ellipse
    context.strokeStyle = colorStr;
    context.fillStyle = colorStr;
    context.lineWidth = 1;
    context.shadowOffsetX = 0;
    context.shadowColor = "black";
    context.shadowOffsetY = 1;
    context.clearRect(0, 0, width, height);
    context.beginPath();
    context.ellipse(
      canvasCenter.x,
      canvasCenter.y,
      radiusX,
      radiusY,
      0,
      0,
      2 * Math.PI
    );
    context.stroke();
    context.beginPath();
    context.moveTo(canvasCenter.x, canvasCenter.y + radiusY - 5);
    context.lineTo(canvasCenter.x + diamondHeight, canvasCenter.y + radiusY);
    context.lineTo(canvasCenter.x, canvasCenter.y + radiusY + 5);
    context.lineTo(canvasCenter.x - diamondHeight, canvasCenter.y + radiusY);
    context.fill();
  }
  resize(newDims) {
    if (!this.config.dims || !this.threeView.renderer) return
    this.dimensionPixels = resizeKeepingAspect(this.config.dims, newDims, "fit_inside")
    setCanvasDimensionsStyle(this.bgCanvas, this.dimensionPixels)
    this.threeView.resizeRenderer(this.dimensionPixels)
    setCanvasDimensionsStyle(this.maskCanvas, this.dimensionPixels)
    setCanvasDimensionsStyle(this.shadowCanvas, this.dimensionPixels)
    this.inputCanvas.width = this.dimensionPixels.width
    this.inputCanvas.height = this.dimensionPixels.height
    this.updateGizmo()
  }
  renderInCanvas() {
    const { width: w, height: h } = this.dimensionPixels;
    const renderCanvas = createCanvas(w, h);
    const renderCtx = renderCanvas.getContext("2d");
    renderCtx.drawImage(this.bgCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.threeCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.maskCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.shadowCanvas, 0, 0, w, h);
    return renderCanvas;
  }
  downloadRendering(name, mime) {
    const renderCanvas = this.renderInCanvas();
    downloadImageData(renderCanvas, name, "image/" + mime);
  }

  rotatebybutton(deg) {
    this.threeView.rotateCarpet(deg, "z");
  }
}

function makeMask(canvas, w, h, maskImg, flag = false) {
  const tCanvas = createCanvas(w, h);

  const shCtx = canvas.getContext("2d");
  const tmpCtx = tCanvas.getContext("2d");
  tmpCtx.drawImage(maskImg, 0, 0, w, h);
  let imgData = shCtx.getImageData(0, 0, w, h);
  let maskData = tmpCtx.getImageData(0, 0, w, h);
  for (let i = 0; i < maskData.data.length; i += 4) {
    if (flag) {
      imgData.data[i + 3] = maskData.data[i];
    } else {
      imgData.data[i + 3] = maskData.data[i];
    }
  }
  shCtx.putImageData(imgData, 0, 0);
  return maskData;
}
const setCanvasDimensionsStyle = (canvas, dimensionPixels) => {
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
}
const setCanvasDimensions = (canvas, dimension, dimensionPixels) => {
  const { width, height } = dimension;
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.width = width
  canvas.height = height
  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
}