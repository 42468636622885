import { Classes, Overlay } from "@blueprintjs/core";
import React from "react";
import AtButton from "../../atoms/AtButton";

const AlertWarning = props => {
  const { message, show, handleClose } = props;

  return (
    <Overlay
      onClose={handleClose}
      isOpen={show}
      className={Classes.OVERLAY_SCROLL_CONTAINER}
      hasBackdrop={true}
      lazy={false}
      usePortal={false}
    >
      <div className="at-dialog-container">
        <div className={`at-dialog at-dialog-xs warning-dialog`}>
          <span>{message}</span>
          <AtButton intent="primary" tertiary={true} text="Okay" onClick={handleClose} />
        </div>
      </div>
    </Overlay>
  );
};

export default AlertWarning;
