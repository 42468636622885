import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import LoginPage from "./components/pages/Login";
import AppProvider from "./api/appProvider";
import "./style.scss";
// import ReactGA from 'react-ga';
import EntryPage from "./components/pages/EntryPage";
import ErrorPage from './components/pages/ErrorPage';
import { errorTypes } from './utils/utils';
import { BrowserRouter } from 'react-router-dom';
import VpsLanding from './components/pages/VpsLanding';
import { UiStateProvider } from './reducers/mainui.reducer';

// const version = process.env.REACT_APP_VERSION;


// ReactGA.initialize('UA-165307296-1');
// window.onerror = (msg, src, lineno, colno, error) => {
//   console.log(error)
//   var category = 'JavaScript error';
//   const page = sessionStorage.getItem("page");
//   ReactGA.event({
//     category: category,
//     action: msg,
//     label: page + ":" + src + ":" + lineno + ":" + colno
//   });
//   return false
// }
const App = props => {
  const { page } = props;
  if (page === "entry") return <EntryPage />
  if (page === "login") return <LoginPage />
  if (page === "error") return <ErrorPage errorType={errorTypes.INTERFACE_ERROR} />
  return
}
const baseUrl = "";
const root = document.getElementById("app-root")
ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <UiStateProvider>
      <App page="entry" />
      <VpsLanding isReady={false} onLoaded={() => { }}></VpsLanding>
    </UiStateProvider>

  </BrowserRouter>
  , root);


