import React from "react";
import Mixply4 from "./Mixply4";
import Mixply3 from "./Mixply3";
import Mixply2 from "./Mixply2";
import Mixply1 from "./Mixply1";

const hexToRgb = hex => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};
const isRGBsimilarToGray = colorHex => {
  const rgb = hexToRgb(colorHex);
  const isRGBsimilar = isSimilar(rgb.r) && isSimilar(rgb.g) && isSimilar(rgb.b);
  return isRGBsimilar;
};
const isSimilar = colorVal => {
  const similar = Math.abs(colorVal - 128) < 8;
  return similar;
};

const CircularColorSVG = props => {
  const { yarns } = props;
  let strokeColor;
  yarns.forEach(element => {
    if (isRGBsimilarToGray(element)) {
      strokeColor = "#333";
    }
  });
  const renderView = () => {
    switch (yarns.length) {
      case 1:
        return <Mixply1 strokeColor={strokeColor} {...props} />;
      case 2:
        return <Mixply2 strokeColor={strokeColor} {...props} />;
      case 3:
        return <Mixply3 strokeColor={strokeColor} {...props} />;
      case 4:
        return <Mixply4 strokeColor={strokeColor} {...props} />;
      default:
        return <></>;
    }
  };
  return <>{renderView()}</>;
};

export default CircularColorSVG;
