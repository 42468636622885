/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Overlay, Classes, Tooltip } from "@blueprintjs/core";
import { UiStateProvider } from "../../../reducers/mainui.reducer";
import AppProvider, { assetsDomain } from "../../../api/appProvider";
import { useDebouncedCallback } from "use-debounce/lib";
import { getExtension, readJSON } from "../../../utils/utils";
import Thumbnail from "../../molecules/Thumbnail";
import VpsStage from "./../../organisms/VpsStage";

import AtDialog from "../../molecules/AtDialog";
import { H2 } from "@blueprintjs/core";
import { localIlls } from "./vpsscenes";
import MMC from "../../MMC";

import { ReactComponent as ActiveSVG } from "./icon-active.svg";
import { ReactComponent as DayNightSVG } from "./icon-daynight.svg";
import { ReactComponent as MultishotSVG } from "./icon-multishot.svg";
import { ReactComponent as MysticSVG } from "./icon-mystic.svg";
import { ReactComponent as HDSVG } from "./icon-hd.svg";
import { ReactComponent as FourKSVG } from "./icon-4k.svg";
import { ReactComponent as Galaincha } from "./icon-galaincha.svg";
import AtIcon from "../../atoms/AtIcon";

let vpsfirstload = false;
let loadfromlocal = false;
const onlineDemoVer = true;

const infoThumbs_demo = {
  // "Perspective View": [<FourKSVG />],
  // "Folded Back View": [<FourKSVG />],
  // "Folded View": [<FourKSVG />],
  "Perspective View Sheen": [<FourKSVG />],
  "Bedroom Tesoft": [<ActiveSVG />, <MysticSVG />, <MultishotSVG />, <DayNightSVG />, <HDSVG />],
  "Bedroom Airepad": [<ActiveSVG />, <HDSVG />],
  "Bedroom Epimogh": [<MultishotSVG />, <DayNightSVG />, <HDSVG />],
  "Living Room Arieble": [<DayNightSVG />, <HDSVG />],
  "Living Room Exisize": [<DayNightSVG />, <HDSVG />],
  "Living Room Expemica": [<MultishotSVG />, <DayNightSVG />, <HDSVG />],
  // "Living Room Renoid": [<DayNightSVG />, <HDSVG />],
  weaving: [<HDSVG />],
  "Unfinished Carpet": [<HDSVG />],
  "Carpet Washing": [<HDSVG />],
  Carving: [<HDSVG />],
  "Stretched Carpet": [<HDSVG />],
  "Finished Carpet with Fringes": [<HDSVG />],
  "Finished Carpet with Binding": [<HDSVG />],
  "Act Body": [<HDSVG />],
  "Art Gallery": [<HDSVG />],
  "Baby Feet": [<HDSVG />],
  Bodypaint: [<HDSVG />],
  "Book Worm": [<HDSVG />],
  "Just Married": [<HDSVG />],
  "Three Ballerinas": [<HDSVG />],
  "Stair Pateduce": [<HDSVG />],
  "Stair Subway": [<HDSVG />],
  Stair: [<HDSVG />]
};

const mapInfoThumbs = (roomFeatures = {}) => {
  let arr = [];
  let arrInfo = [];
  if (roomFeatures["4k"]) {
    arr.push(<FourKSVG />);
    arrInfo.push("This shot is renderable in 4k");
  }
  // if (roomFeatures["hd"]) {
  //   arr.push(<HDSVG />);
  // }
  if (roomFeatures["multishot"]) {
    arr.push(<MultishotSVG />);
    arrInfo.push("This room has multiple views");
  }
  if (roomFeatures["multilight"]) {
    arr.push(<DayNightSVG />);
    arrInfo.push("This room has multiple lighting conditions");
  }
  if (roomFeatures["active"]) {
    arr.push(<ActiveSVG />);
    arrInfo.push("This room has color customizable furniture");
  }
  if (roomFeatures["mystic"]) {
    arr.push(<MysticSVG />);
    arrInfo.push("Some objects in this room can be hidden on click");
  }
  return [arr, arrInfo];
};

const VPS = props => {
  const [selectedViewInd, setselectedViewInd] = useState(null);
  const [scenesData, setscenesData] = useState(null);
  const [showIllustrationModal, setshowIllustrationModal] = useState(false);
  const [currentCatIllustration, setcurrentCatIllustration] = useState(0);
  const [allRooms, setallRooms] = useState({});
  const [selectedView, setselectedView] = useState(null);
  const [showMMC, setshowMMC] = useState(false);
  const [mmcActiveColor, setmmcActiveColor] = useState(null);
  const [nextIllustrations, setnextIllustrations] = useState({ next: null, prev: null });
  const [disableIllsforIrregular, setdisableIllsforIrregular] = useState(false);
  const [disableCarpetInMaking, setdisableCarpetInMaking] = useState(false);
  const vpsHome = useRef(null);
  vpsHome.current = document.getElementById("app-root");

  useEffect(() => {
    handleFetchRooms();
    return () => {
      vpsfirstload = false;
    };
  }, []);

  useEffect(() => {
    if (selectedViewInd && allRooms) {
      setselectedView({ ...allRooms[selectedViewInd.category][selectedViewInd.index] });

      let currentSceneIndex;
      let currentSceneData = scenesData.filter((item, i) => {
        let isCurrent = item.label === selectedViewInd.category;
        if (isCurrent) currentSceneIndex = i;
        return isCurrent;
      })[0];
      let nextCategory = selectedViewInd.category;
      let nextIndex = selectedViewInd.index + 1;
      if (currentSceneData.options.length - 1 <= selectedViewInd.index) {
        nextCategory = scenesData[currentSceneIndex + 1]?.label;
        if (disableCarpetInMaking && nextCategory.toLowerCase() === "carpet in the making") {
          nextCategory = scenesData[currentSceneIndex + 2]?.label;
        }
        nextIndex = nextCategory ? 0 : null;
      }

      let prevCategory = selectedViewInd.category;
      let prevIndex = selectedViewInd.index - 1;
      if (selectedViewInd.index === 0) {
        prevCategory = scenesData[currentSceneIndex - 1]?.label;
        prevIndex = prevCategory ? scenesData[currentSceneIndex - 1].options.length - 1 : null;
        if (disableCarpetInMaking && prevCategory.toLowerCase() === "carpet in the making") {
          prevCategory = scenesData[currentSceneIndex - 2]?.label;
          prevIndex = prevCategory ? scenesData[currentSceneIndex - 2].options.length - 1 : null;
        }
      }

      setnextIllustrations({
        next: { category: nextCategory, index: nextIndex },
        prev: { category: prevCategory, index: prevIndex }
      });
    }
  }, [selectedViewInd, allRooms]);

  useEffect(() => {
    if (disableCarpetInMaking) {
      let nextCategory, prevCategory, nextIndex, prevIndex;
      nextCategory = nextIllustrations?.next?.category;
      nextIndex = nextIllustrations?.next?.index;
      prevCategory = nextIllustrations?.prev?.category;
      prevIndex = nextIllustrations?.prev?.index;
      let currentSceneIndex;
      let currentSceneData = scenesData.filter((item, i) => {
        let isCurrent = item.label === selectedViewInd.category;
        if (isCurrent) currentSceneIndex = i;
        return isCurrent;
      })[0];
      if (
        nextIllustrations?.next?.category &&
        nextIllustrations.next.category.toLowerCase() === "carpet in the making"
      ) {
        nextCategory = scenesData[currentSceneIndex + 2]?.label;
        nextIndex = 0;
      }
      if (
        nextIllustrations?.prev?.category &&
        nextIllustrations.prev.category.toLowerCase() === "carpet in the making"
      ) {
        prevCategory = scenesData[currentSceneIndex - 2]?.label;
        prevIndex = scenesData[currentSceneIndex - 2].options.length - 1;
      }
      setnextIllustrations({
        next: { category: nextCategory, index: nextIndex },
        prev: { category: prevCategory, index: prevIndex }
      });
    }
  }, [disableCarpetInMaking]);

  const handleFetchRooms = async () => {
    const roomswithoutthumbs = await AppProvider.fetchRoomList({});
    let rooms = await AppProvider.getRoomThumbnails({ rooms: roomswithoutthumbs });
    if (loadfromlocal && process.env.NODE_ENV === "development") rooms = [...rooms, ...localIlls];
    const filteredRooms = rooms.filter(item => {
      const isonlinedemo = /Online Demo Rooms/i.test(item.FullPath);
      return (
        item.Type === "file" &&
        getExtension(item.FullPath) === "crf3d" &&
        (onlineDemoVer ? isonlinedemo : !isonlinedemo)
      );
    });

    let tempAllRooms = { ...allRooms };

    for (const room of filteredRooms) {
      let roomPathArr = room.FullPath.split("/");
      let roomCategory = roomPathArr[roomPathArr.length - 2];
      if (!roomCategory || roomCategory === "") roomCategory = "Rooms";
      room["category"] = roomCategory;
      room.label = room.label.replace(new RegExp("[0-9]", "g"), "").trim();
      room.Name = room.Name.replace(new RegExp("[0-9]", "g"), "").trim();
      room.Thumb = room.fromlocal ? room.Thumb : `${assetsDomain}${room.Thumb.split("Assets")[1]}`;
      const dirArr = room.Thumb.split("/");
      let roomname = dirArr.pop().split(".")[0];
      const baseUrl = dirArr.join("/") + "/" + roomname;
      await readJSON(`${baseUrl}/config.json`).then(config => {
        const infos = mapInfoThumbs(config.roomFeatures);
        room.infoThumbs = infos[0];
        room.infoTitles = infos[1];
      });
      if (Object.keys(tempAllRooms).includes(roomCategory)) {
        tempAllRooms[roomCategory].push(room);
      } else {
        tempAllRooms[roomCategory] = [room];
      }
    }

    //merge root rooms to Studio Rooms
    if (tempAllRooms["Rooms"] && tempAllRooms["Rooms"].length > 0) {
      // tempAllRooms["Studio Rooms"] = [...tempAllRooms["Studio Rooms"], ...tempAllRooms["Rooms"]];
      delete tempAllRooms["Rooms"];
    }
    // readJSON(`/roomorder1.json`)
    readJSON(`https://images.explorug.com/VRS/roomorder1.json`)
      .then(roomorder => {
        if (!roomorder) return;
        const roomorderentries = Object.entries(roomorder);
        if (!roomorderentries || roomorderentries.length <= 0) return;
        let orderedRooms = {};
        roomorderentries.forEach((itemObj, index) => {
          const item = [itemObj[1].label, itemObj[1].rooms ? itemObj[1].rooms : []];
          if (tempAllRooms[itemObj[0]] && !tempAllRooms[item[0]]) {
            tempAllRooms[item[0]] = tempAllRooms[itemObj[0]];
            delete tempAllRooms[itemObj[0]];
          }
          if (tempAllRooms[item[0]]) {
            orderedRooms[item[0]] = [];
            if (item[1].length > 0) {
              let filteringArr = [...tempAllRooms[item[0]]];
              item[1].forEach((element, index) => {
                let findElementIndex = -1;
                let findElement = filteringArr.filter((eachitem, index) => {
                  if (eachitem.Name.toLowerCase() === element.toLowerCase()) {
                    findElementIndex = index;
                    return true;
                  } else {
                    return false;
                  }
                });
                if (findElementIndex > -1) {
                  filteringArr.splice(findElementIndex, 1);
                  orderedRooms[item[0]].push(findElement[0]);
                }
                if (index === item[1].length - 1) {
                  if (filteringArr.length > 0) {
                    orderedRooms[item[0]] = [...orderedRooms[item[0]], ...filteringArr];
                  }
                }
              });
            } else {
              orderedRooms[item[0]] = [...tempAllRooms[item[0]]];
            }
          }
        });
        setallRooms({ ...orderedRooms });
        //default first room selection
        setselectedViewInd({ category: Object.keys(orderedRooms)[0], index: 0 });
      })
      .catch(err => {
        setallRooms({ ...tempAllRooms });
        //default first room selection
        setselectedViewInd({ category: Object.keys(tempAllRooms)[0], index: 0 });
      });
  };

  const [debouncedThumbnailClick] = useDebouncedCallback((roomCategory, roomIndex) => {
    handleThumbnailClick(roomCategory, roomIndex);
  }, 400);
  const handleThumbnailClick = (roomCategory, roomIndex) => {
    if (selectedViewInd.category !== roomCategory || selectedViewInd.index !== roomIndex) {
      setselectedViewInd({ category: roomCategory, index: roomIndex });
    }
    setshowIllustrationModal(false);
  };

  useEffect(() => {
    if (!allRooms || Object.keys(allRooms).length <= 0) return;
    let temp = [];
    Object.entries(allRooms).forEach((roomCatData, index) => {
      let tempObj = {
        label: roomCatData[0],
        options: [
          ...roomCatData[1].map((eachObj, i) => {
            return {
              label: eachObj.label,
              Thumb: eachObj.Thumb,
              infoThumbs: eachObj.infoThumbs,
              infoTitles: eachObj.infoTitles,
              onClick: () => debouncedThumbnailClick(roomCatData[0], i)
            };
          })
        ]
      };
      if (tempObj.label.toLowerCase() === "photographic shots") {
        tempObj = {
          label: roomCatData[0],
          options: [
            ...roomCatData[1].map((eachObj, i) => {
              return {
                label: eachObj.label,
                Thumb: eachObj.Thumb,
                infoThumbs: [<AtIcon icon={"interactive"} />],
                infoTitles: ["This room lets you position the camera freely in 3D space"],
                onClick: () => debouncedThumbnailClick(roomCatData[0], i)
              };
            })
          ]
        };
        tempObj.showName = "Photographic Shots";
        tempObj.story = [
          {
            text:
              "Frame the perfect shot of your carpet by moving the camera around freely in these well lit rooms and change the flooring."
          }
        ];
      } else if (tempObj.label.toLowerCase() === "studio rooms") {
        tempObj.story = [
          {
            text:
              "Place the carpet anywhere you like in these rooms, quickly change lighting and take shots from different angles."
          }
        ];
      } else if (tempObj.label.toLowerCase() === "carpet in the making") {
        tempObj.showName = "Carpet in the Making";
        tempObj.icon = (
          <Tooltip position="bottom" content={`Only available for .CTFX (Galaincha) designs`}>
            <Galaincha />
          </Tooltip>
        );
        tempObj.story = [
          { text: "Give your clients the experience of your " },
          // { text: "handknotted", color: "var(--brand-primary)" },
          { text: "handknotted" },
          { text: " carpets being produced from weaving, washing, stretching to carving." }
        ];
      } else if (tempObj.label.toLowerCase() === "catalog ready") {
        tempObj = {
          label: roomCatData[0],
          options: [
            ...roomCatData[1].map((eachObj, i) => {
              return {
                label: eachObj.label,
                Thumb: eachObj.Thumb,
                infoThumbs: [<AtIcon icon={"pdf"} />],
                infoTitles: ["This shot will save as a PDF file"],
                onClick: () => debouncedThumbnailClick(roomCatData[0], i)
              };
            })
          ]
        };
        tempObj.showName = "Catalog Ready";
        tempObj.story = [
          {
            text:
              "These illustrative shots can be readily displayed on your webpages, social media or sent straight to print."
          }
        ];
      } else if (tempObj.label.toLowerCase() === "staircase shots") {
        tempObj.showName = "Staircase Shots";
        tempObj.story = [{ text: "Show your carpets laid over staircases." }];
      } else if (tempObj.label.toLowerCase() === "fixed shots") {
        tempObj.showName = "Fixed Shots";
        tempObj.story = [{ text: "Show your carpets in these fixed shots." }];
      }
      temp.push(tempObj);
    });
    setscenesData([...temp]);
  }, [allRooms]);

  const refs =
    scenesData &&
    scenesData.reduce((acc, value, i) => {
      acc[i] = React.createRef();
      return acc;
    }, {});

  useEffect(() => {
    if (!refs) return;
    refs[currentCatIllustration].current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [currentCatIllustration]);

  const handleIllustrationModal = val => {
    if (val && !vpsfirstload) {
      vpsfirstload = true;
    }
    setshowIllustrationModal(val !== undefined ? !val : !showIllustrationModal);
  };

  const nextIllustration = () => {
    handleThumbnailClick(nextIllustrations.next?.category, nextIllustrations.next?.index);
  };

  const prevIllustration = () => {
    handleThumbnailClick(nextIllustrations.prev?.category, nextIllustrations.prev?.index);
  };

  const disableIllustrations = val => {
    setdisableIllsforIrregular(val);
  };

  return (
    <UiStateProvider>
      <div id="vps-main" className={`vps - main theme - darksuite`}>
        <AtDialog
          isOpen={true}
          onClose={() => {
            setshowIllustrationModal(false);
          }}
          className=" illustration-dialog"
          overlayClassName={
            showIllustrationModal ? "illustration-dialog-open" : "illustration-dialog-close"
          }
          size="lg"
          // portalContainerId="vps-main"
        >
          <div className="illustration-dialog-header-row">
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <img src={window.logourl} alt="logo" /> */}
              <H2>Please choose your shot</H2>
            </div>
            <AtIcon
              icon="close"
              size={2.5}
              onClick={() => {
                setshowIllustrationModal(false);
              }}
            />
          </div>

          <div className={"heading-index"}>
            {scenesData &&
              scenesData.map((category, i) => (
                <div
                  key={i}
                  className={currentCatIllustration === i ? "active" : ""}
                  onClick={() => setcurrentCatIllustration(i)}
                >
                  {category.label}
                </div>
              ))}
          </div>
          <div className={"category-container"}>
            {scenesData &&
              scenesData.map((category, i) => {
                return (
                  <div className={"category-wrapper"} key={i} ref={refs[i]}>
                    {/* <SectionHeading title={category.label}></SectionHeading> */}
                    <div className={"thumb-item category-heading"}>
                      <div className={"thumb-image-container landscape "}>
                        <span className="thumb-heading">
                          {category.showName ? category.showName : category.label}
                          {category.icon && category.icon}
                        </span>
                        <span className="thumb-subheading">
                          {category.story.map(story => {
                            return (
                              <span style={{ color: story.color || "var(--text-primary)" }}>
                                {story.text}
                              </span>
                            );
                          })}
                        </span>
                      </div>
                    </div>
                    <div>
                      {category.options &&
                        category.options.length > 0 &&
                        category.options.map((item, itemIndex) => {
                          return (
                            <Thumbnail
                              className={
                                (disableIllsforIrregular && category.label !== "Studio Rooms") ||
                                (disableCarpetInMaking &&
                                  category.label.toLowerCase() === "carpet in the making")
                                  ? "disabled"
                                  : ""
                              }
                              thumb={{
                                ...item,
                                tooltip:
                                  (disableIllsforIrregular &&
                                    category.label !== "Studio Rooms" &&
                                    `${disableIllsforIrregular} designs are not supported in this shot.`) ||
                                  (disableCarpetInMaking &&
                                    category.label.toLowerCase() === "carpet in the making" &&
                                    `Rug photos are not supported in this shot.`) ||
                                  null
                              }}
                              active={
                                selectedViewInd
                                  ? category.label === selectedViewInd.category &&
                                    itemIndex === selectedViewInd.index
                                  : false
                              }
                              infoThumbs={item.infoThumbs}
                              infoTitles={item.infoTitles}
                              aspect="landscape"
                              key={itemIndex}
                              onThumbnailClick={e =>
                                debouncedThumbnailClick(category.label, itemIndex)
                              }
                            />
                          );
                        })}
                    </div>

                    {i < scenesData.length - 1 && <div className="category-divider"></div>}
                  </div>
                );
              })}
          </div>
        </AtDialog>
        <VpsStage
          mainRef={vpsHome}
          showIllustrationModal={showIllustrationModal}
          handleCollapsePanel={handleIllustrationModal}
          setshowMMC={(index, color) => {
            setmmcActiveColor({ index: index, color: color });
            setshowMMC(true);
          }}
          selectedView={selectedView}
          showMMC={showMMC}
          nextIllustration={nextIllustrations?.next?.category && nextIllustration}
          prevIllustration={nextIllustrations?.prev?.category && prevIllustration}
          disableIllustrations={disableIllustrations}
          changeDesignType={val => setdisableCarpetInMaking(val === "jpeg")}
        />
        <Overlay
          onClose={() => {
            setshowMMC(false);
          }}
          isOpen={showMMC}
          className={"mmc-overlay " + Classes.OVERLAY_SCROLL_CONTAINER}
          hasBackdrop={false}
          usePortal={false}
          canOutsideClickClose={true}
          // portalContainer={document.getElementById("vps-main")}
        >
          <MMC
            isOpen={showMMC}
            colorData={mmcActiveColor}
            onClose={() => {
              setshowMMC(false);
            }}
          />
        </Overlay>
      </div>
    </UiStateProvider>
  );
};

export default React.memo(VPS);
