import React, { useEffect, useState } from 'react'
import axios from 'axios'
import AtIcon from "./../atoms/AtIcon"
import './_mmc.scss';
import AtButton from '../atoms/AtButton';
import { designDetailActions, useDesignDetailState, useDispatchDesignDetail } from '../../reducers/designdetails.reducer';
import AtDialog from '../molecules/AtDialog';
import { H2 } from '@blueprintjs/core';
function Intensity(rgb) {
  if (rgb[0] === "#") rgb = rgb.slice(1);

  rgb = parseInt(rgb, 16);
  var r = (rgb >> 16) & 0xff;
  var g = (rgb >> 8) & 0xff;
  var b = rgb & 0xff;
  return (r + g + b) / 3;
}



const MMC = (props) => {
  const { isOpen, onClose, colorData } = props;

  const [gridDisplayLevel, setgridDisplayLevel] = useState(-1);
  const [selectedGridColor, setselectedGridColor] = useState(null);
  const [colorGridInfo, setcolorGridInfo] = useState(null);
  const [counter, setcounter] = useState(-1);
  const [designColors, setdesignColors] = useState(null);
  const [selectedDesignColor, setselectedDesignColor] = useState(null);

  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const { designDetails } = designDetailState;


  useEffect(() => {
    if (gridDisplayLevel === 0) {
      getGrid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGridColor])

  useEffect(() => {
    console.log(designDetails)
    if (designDetails && designDetails.DesignColors.length > 0) {
      setdesignColors([...designDetails.DesignColors])
    }
  }, [designDetails])

  useEffect(() => {
    if (isOpen) {
      setgridDisplayLevel(0)
    }
    else {
      setgridDisplayLevel(-1)
    }
  }, [isOpen])



  function showColorCirclesWrapper(colorName) {
    setselectedGridColor(colorName);
    var color = colorName.replace('#', '');
    const formdata = new FormData();
    formdata.append('id', 'singleColorTextured');
    formdata.append('color', color);
    axios.post("https://explorug.com/matchmycolors/utility.aspx", formdata, { responseType: 'blob' }).then((data) => {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(data.data);
      document.getElementById('singleTextureCircleImg').src = imageUrl;
      setgridDisplayLevel(2);
    })
    return;
  }

  const getGrid = (counterarg = 1) => {
    if (!selectedGridColor) return;
    const color = selectedGridColor.replace('#', '');
    const formdata = new FormData();
    formdata.append('id', 'grid');
    formdata.append('color', color);
    formdata.append('counter', counterarg);
    if (gridDisplayLevel === 1) { document.getElementById('colorGrid').innerHTML = ''; }
    axios.post("https://explorug.com/matchmycolors/utility.aspx", formdata, {}).then((data) => {
      setcolorGridInfo({ ...data.data });
      setgridDisplayLevel(1);
      setcounter(counterarg);
    })
  }

  const onBack = () => {
    setgridDisplayLevel(1);
    setselectedGridColor(null);
  }
  const handleClose = () => {
    setgridDisplayLevel(-1);
    setselectedGridColor(null);
    setcolorGridInfo(null);
    onClose && onClose();
  }
  const handleColorMatch = () => {
    setgridDisplayLevel(-1);
    setselectedGridColor(null);
    setcolorGridInfo(null);
    let newColor = { ...selectedDesignColor };
    newColor.Color = selectedGridColor;
    newColor.Texture = '-1';
    if (selectedDesignColor.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_COLOR,
        payload: newColor
      });
    } else {
      dispatchDesignDetails({
        type: designDetailActions.CHANGE_YARN_COLOR,
        payload: newColor
      });
    }
    onClose();
  }
  const handleColorClick = (e, colorIndex, designColor, yarnIndex = 0) => {
    setselectedDesignColor({ ...designColor });
    if (designColor.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_DESIGN_COLOR,
        payload: colorIndex
      });
    } else {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_YARN,
        payload: { colorIndex, yarnIndex }
      });
    }
    setgridDisplayLevel(0);
    setcolorGridInfo(null);
    setselectedGridColor(designColor.Color);
  };

  useEffect(() => {
    if (!colorData) return;
    const yarnIndex = 0;
    setselectedDesignColor({ ...colorData.color });
    if (colorData.color.YarnDetails.length <= 1) {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_DESIGN_COLOR,
        payload: colorData.index
      });
    } else {
      dispatchDesignDetails({
        type: designDetailActions.SELECT_YARN,
        payload: { colorIndex: colorData.index, yarnIndex }
      });
    }
    setgridDisplayLevel(0);
    setcolorGridInfo(null);
    setselectedGridColor(colorData.color.Color);
  }, [colorData])
  return (
    <>
      <AtDialog
        isOpen={gridDisplayLevel > 0}
        onClose={() => {
          handleClose();
        }}
        className=" illustration-dialog mmc-dialog"
        size="lg"
      // portalContainerId="vps-main"
      >
        <div className="illustration-dialog-header-row">
          <div style={{ display: "flex", alignItems: 'center' }}>
            {/* <img src={window.logourl} alt="logo" /> */}
            {(gridDisplayLevel === 0) && <H2> Please choose the color to match your sample</H2>}
            {(gridDisplayLevel === 1) && <H2> Please select the color closest to your sample</H2>}
            {(gridDisplayLevel === 2) && <H2> Please click on color to fine tune</H2>}

          </div>
          <AtIcon icon="close" size={2.5} onClick={handleClose} />
        </div>
        <div className={"category-container"}>
          {/* {scenesData &&
          scenesData.map((category, i) => {
            return ( */}
          <div className={"category-wrapper mmc-wrapper"}>
            {/* <div className={"thumb-item category-heading"}>
              <div className={"thumb-image-container landscape "}>
                <span className="thumb-heading">Royal Mosa</span>
                <span className="thumb-subheading">
                  The floor tiles in this collection are created with the courtesy of{" "}
                  <a href="http://www.mosa.com/" target="_blank" style={{ display: "block" }}>
                    Royal Mosa
                </a>
                </span>
              </div>
            </div> */}

            <div className='design-color-wrapper' style={{ display: (gridDisplayLevel === 0) ? 'flex' : 'none' }}>
              {designColors && designColors.map((colorobj, index) => {
                return (
                  <div className="colorBox" key={index} style={{ backgroundColor: colorobj.Color }} onClick={(e) => handleColorClick(e, index, colorobj)}></div>
                )
              })}
            </div>

            <div id='colorGrid' style={{ display: (gridDisplayLevel === 1) ? 'block' : 'none' }}>
              {colorGridInfo && colorGridInfo.centers.map((eachCenter, i) => {
                return (
                  <div key={i} className="gridCircles" style={{
                    backgroundColor: colorGridInfo.colorList[i],
                    left: `${eachCenter.split(',')[0]}px`,
                    top: `${eachCenter.split(',')[1].trim()}px`
                  }}
                    onClick={() => showColorCirclesWrapper(colorGridInfo.colorList[i])}>
                  </div >
                )
              })}
            </div>

            <div className='colorCirclesWrapper' style={{ display: (gridDisplayLevel === 2) ? 'block' : 'none' }}>
              <div id="selectedGridCircle" className="gridCircles" style={{ backgroundColor: selectedGridColor }} > </div>
              {/* {counter !== 2 ?
                <> */}
              <div id="singleColorCircle" style={{ backgroundColor: selectedGridColor, cursor: 'pointer' }} onClick={() => getGrid(2)}></div>
              <div id="singleTextureCircle" style={{ cursor: 'pointer' }} onClick={() => getGrid(2)}>
                <img alt="texture of selected color" id="singleTextureCircleImg" />
              </div>
              {/* </>
                :
                <>
                  <div id="singleColorCircle" style={{ backgroundColor: selectedGridColor }}></div>
                  <div id="singleTextureCircle" >
                    <img alt="texture of selected color" id="singleTextureCircleImg" />
                  </div>
                </>
              } */}

            </div>

            {(gridDisplayLevel === 2) && <div className='action-button-row'>
              <div style={{ textAlign: counter === 2 ? 'center' : 'left' }}> <AtButton style={{ marginLeft: '0.25rem' }} intent='secondary' text='Apply' onClick={handleColorMatch}></AtButton></div>
              {/* <div >
                <AtButton style={{ marginRight: '0.25rem' }} intent='secondary' text='Go Back' onClick={onBack}></AtButton>
              </div> */}
              <div style={{ textAlign: counter === 2 ? 'center' : 'left' }}> <AtButton style={{ marginLeft: '0.25rem' }} intent='secondary' text='Cancel' onClick={handleClose}></AtButton></div>

            </div>}

            {/* {i < scenesData.length - 1 && */}
            {/* <div className="category-divider"></div> */}

            {/* } */}
          </div>
          {/* );
          })} */}
        </div>
      </AtDialog>
    </>
  )

  return (
    <React.Fragment>
      {(gridDisplayLevel === 0) &&
        <div className='mmc-init' >
          <div>Click on the color to match your sample</div>
          <AtIcon icon={'close'} size={2.5} className={'icon-close'} onClick={handleClose} />
          <div className='design-color-wrapper'>
            {designColors && designColors.map((colorobj, index) => {
              return (
                <div className="colorBox" key={index} style={{ backgroundColor: colorobj.Color }} onClick={(e) => handleColorClick(e, index, colorobj)}></div>
              )
            })}
          </div>

        </div>
      }
      <div className="mmc-wrapper" style={{ display: (gridDisplayLevel > 0) ? 'flex' : 'none' }}>
        <div className='top-action-row'>
          <div className={((gridDisplayLevel === 2) ? 'show' : 'hide') + ' back-btn'} tabIndex='0' onClick={onBack}>Back</div>
          <AtIcon icon={'close'} size={2.5} className={'icon-close'} onClick={handleClose} />
        </div>
        <div id='colorGrid' style={{ display: (gridDisplayLevel === 1) ? 'block' : 'none' }}>
          {colorGridInfo && colorGridInfo.centers.map((eachCenter, i) => {
            return (
              <div key={i} className="gridCircles" style={{
                backgroundColor: colorGridInfo.colorList[i],
                left: `${eachCenter.split(',')[0]}px`,
                top: `${eachCenter.split(',')[1].trim()}px`
              }}
                onClick={() => showColorCirclesWrapper(colorGridInfo.colorList[i])}>
              </div >
            )
          })}
        </div>
        <div className='colorCirclesWrapper' style={{ display: (gridDisplayLevel === 2) ? 'block' : 'none' }}>
          <div id="selectedGridCircle" className="gridCircles" style={{ backgroundColor: selectedGridColor }}> </div>
          <div id="singleColorCircle" style={{ backgroundColor: selectedGridColor }}></div>
          <div id="singleTextureCircle">
            <img alt="texture of selected color" id="singleTextureCircleImg" />
          </div>
        </div>
        {(gridDisplayLevel === 2) && <div className='action-button-row'>
          {counter !== 2 && <div style={{ textAlign: 'right' }}>
            <AtButton style={{ marginRight: '0.25rem' }} intent='secondary' text='Fine Tune' onClick={() => getGrid(2)}></AtButton>
          </div>}
          <div style={{ textAlign: counter === 2 ? 'center' : 'left' }}> <AtButton style={{ marginLeft: '0.25rem' }} intent='secondary' text='OK' onClick={handleColorMatch}></AtButton></div>
        </div>}

      </div>
    </React.Fragment >

  )
}

export default MMC
