// Load the required clients and packages
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity"
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity"
import { S3Client, PutObjectCommand, ListObjectsCommand, DeleteObjectCommand, DeleteObjectsCommand } from "@aws-sdk/client-s3";

// Set the AWS Region
const REGION = "us-east-1"; //REGION

// Initialize the Amazon Cognito credentials provider
const s3 = new S3Client({
    region: REGION,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: REGION }),
        identityPoolId: "us-east-1:525b68be-bd75-429c-9f72-1e262ecd82a4", // IDENTITY_POOL_ID
    }),
});

const albumBucketName = "virtualrugstudio"; //BUCKET_NAME

const hasAlbum = async (name) => {
    if (!name) return false;
    try {
        const data = await s3.send(
            new ListObjectsCommand({ Delimiter: "/", Bucket: albumBucketName })
        );

        if (data.CommonPrefixes === undefined) {
            console.log("no albums found");
            return false;
        } else {
            var albums = data.CommonPrefixes.map(function (commonPrefix) {
                var prefix = commonPrefix.Prefix;
                var albumName = decodeURIComponent(prefix.replace("/", ""));
                return albumName;
            });
            return albums.includes(name);
        }
    } catch (err) {
        return console.log("There was an error listing your albums: " + err.message);
    }
};

export const viewAlbum = async (albumName) => {
    const albumPhotosKey = encodeURIComponent(albumName) + "/";
    try {
        const data = await s3.send(
            new ListObjectsCommand({
                Prefix: albumPhotosKey,
                Bucket: albumBucketName,
            })
        );
        if (!data?.Contents?.length > 1) { //the first content is the album folder itself
            console.log("You don't have any photos in this album.");
        } else {
            const href = "https://s3." + REGION + ".amazonaws.com/";
            const bucketUrl = href + albumBucketName + "/";

            const photos = data.Contents.filter(function (photo) {
                return photo.Key !== albumPhotosKey;
            }).map(function (photo) {
                const photoKey = photo.Key;
                const photoUrl = bucketUrl + photoKey;
                return photoUrl;
            });
            return photos;
        }
    } catch (err) {
        console.log("There was an error viewing your album: " + err.message);
        return;
    }
};


// Create an album in the bucket
const createAlbum = async (albumName) => {
    albumName = albumName.trim();
    if (!albumName) {
        return console.log("Album names must contain at least one non-space character.");
    }
    if (albumName.indexOf("/") !== -1) {
        return console.log("Album names cannot contain slashes.");
    }
    var albumKey = encodeURIComponent(albumName);
    try {
        const key = albumKey + "/";
        const params = { Bucket: albumBucketName, Key: key };
        const data = await s3.send(new PutObjectCommand(params));
        console.log("Successfully created album.");
    } catch (err) {
        return console.log("There was an error creating your album: " + err.message);
    }
};


// Add a photo to an album
export const addPhoto = async ({ albumName, file, deleteExistingAlbum = false }) => {
    try {
        const albumExists = await hasAlbum(albumName);
        if (albumExists) {
            if (deleteExistingAlbum) {
                await deleteAlbum({albumName,deleteObjectsOnly:true});
            }
        }
        else {
            await createAlbum(albumName);
        }

        const albumPhotosKey = encodeURIComponent(albumName) + "/";
        const fileName = file.name;
        const photoKey = albumPhotosKey + fileName;
        const uploadParams = {
            Bucket: albumBucketName,
            Key: photoKey,
            Body: file
        };
        try {
            const data = await s3.send(new PutObjectCommand(uploadParams));
            console.log("Successfully uploaded photo.");

            const href = "https://s3." + REGION + ".amazonaws.com/";
            const bucketUrl = href + albumBucketName + "/";
            const photoUrl = bucketUrl + photoKey;
            return photoUrl;
        } catch (err) {
            return console.log("There was an error uploading your photo: ", err.message);
        }
    } catch (err) {
        if (!file) {
            return console.log("Choose a file to upload first.");
        }
    }
};

const deleteAlbum = async ({ albumName, deleteObjectsOnly = false }) => {
    //deleteObjectsOnly = true , only deletes the album contents not the album folder itself
    //deleteObjectsOnly = false , deletes the whole album along with the folder
    const albumKey = encodeURIComponent(albumName) + "/";
    try {
        const params = { Bucket: albumBucketName, Prefix: albumKey };
        const data = await s3.send(new ListObjectsCommand(params));
        const objects = data.Contents.filter(function (object) {
            return !deleteObjectsOnly || (object.Key !== albumKey);
        }).map(function (object) {
            return { Key: object.Key };
        });
        if (!objects?.length) return;
        try {
            const params = {
                Bucket: albumBucketName,
                Delete: { Objects: objects },
                Quiet: true,
            };
            const data = await s3.send(new DeleteObjectsCommand(params));
            return console.log("Successfully deleted album.");
        } catch (err) {
            return console.log("There was an error deleting your album: ", err.message);
        }
    } catch (err) {
        return console.log("There was an error deleting your album1: ", err.message);
    }
};


