import React, { useEffect, useState } from "react";
import { calculateColorDelta, hexToRGB } from "../../../utils/colorutils";
import strings from "../../../strings";
import { H6 } from "@blueprintjs/core";
import SwatchBox from "./SwatchBox";

const SimilarColors = props => {
  const { activeColor, colorList, onColorSwatchClick, numColors = 5 } = props;
  const [similarColors, setSimilarColors] = useState(null);
  useEffect(() => {
    if (!activeColor) return;
    let color = activeColor;
    if (!activeColor.R || !activeColor.G || !activeColor.B) {
      const { r = 0, g = 0, b = 0 } = hexToRGB(activeColor.Color);
      activeColor.R = r;
      activeColor.G = g;
      activeColor.B = b;
    }
    const deltaArr = colorList.map(item => {
      return { ...item, delta: calculateColorDelta(item, color) };
    });
    setSimilarColors(deltaArr.sort((a, b) => b.delta - a.delta).slice(1, 1 + numColors));
  }, [activeColor, colorList, numColors]);

  return (
    similarColors && (
      <div className="at-similar-color-area">
        <H6>{strings.color.similarColors}</H6>
        <div className="at-similar-color-content">
          {similarColors.map((color, index) => (
            <SwatchBox
              swatchSize={2.5}
              swatchSpace={0.15}
              colorRow={color}
              key={index}
              onColorSwatchClick={e => {
                onColorSwatchClick(color, e);
              }}
            />
          ))}
        </div>
      </div>
    )
  );
};
export default SimilarColors;
