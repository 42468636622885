/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { Popover, Card, Slider, Overlay, ButtonGroup, H4 } from "@blueprintjs/core";
import AppProvider, { getFloor } from "../../../api/appProvider";

import { useFullscreen, useToggle, useIdle } from "react-use";
import classNames from "classnames";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import CameraButton from "../../molecules/CameraButton";
import OptionsSidePanel from "../OptionsSidePanel";
import IllustrationView from "../../IllustrationView";
import { getExtension, readJSON } from "../../../utils/utils";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../../reducers/designdetails.reducer";
import { AtSpinner, AtSpinnerOverlay } from "../../atoms/AtSpinner";
import Thumbnail from "../../molecules/Thumbnail";
import DesignList, { jpegExtensions } from "../DesignList";
import CatalogView from "../../CatalogView";
import CloseupView from "../../InteractivePerspectiveView/CloseupView";
import {
  roomConfigActions,
  useDispatchRoomConfig
} from "../../IllustrationView/roomconfig.reducer";
import { config2Point1 } from "../../../configMatcher";
import Tutorial from "./Tutorial";
import CameraOptions from "../CameraOptions";
import FloorSelectionDialog from "../../IllustrationView/FloorSelectionDialog";
import { ReactComponent as MMC } from "./icon-mmc.svg";
import { designListActions, useDispatchDesignList } from "../../../reducers/designstree.reducer";
import UploadPlatform from "../UploadPlatform";
import { useUiState } from "../../../reducers/mainui.reducer";
const viewTypes = {
  perspective: "PerspectiveView",
  illustration: "illustration",
  catalog: "Catalog",
  roomview: "roomview",
  closeupview: "closeupview"
};

const filters = [
  { label: "Original", Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg` },
  {
    filterName: "Amaro",
    label: "Amaro",
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg`
  },
  {
    filterName: "Hefe",
    label: "Hefe",
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Aden.jpg`
  },
  {
    filterName: "Moon",
    label: "Moon",
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Moon.jpg`
  },
  {
    filterName: "Sutro",
    label: "Sutro",
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Hudson.jpg`
  },
  {
    filterName: "X-Pro II",
    label: "X-Pro II",
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Hudson.jpg`
  }
];

const blursDef = [
  { label: "Normal", Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg` },
  {
    label: "Near",
    centerAt: { x: 0.5, y: 0.9 },
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg`
  },
  {
    label: "Center",
    centerAt: { x: 0.5, y: 0.5 },
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg`
  },
  {
    label: "Far",
    centerAt: { x: 0.5, y: 0.1 },
    Thumb: `${window.assetspath}/v3assets/vps/Filters/Ludwig.jpg`
  }
];
export const unsupportedDesign = {
  IRREGULAR: "Irregular",
  RUNNER: "Runner"
};
let firstload = false;

export const isRunner = ({ width, height }) => {
  const ratio = width / height;
  if (ratio <= 1 / 1.75 || ratio >= 1.75) return true;
  return false;
};
const VpsStage = props => {
  const {
    selectedView,
    handleCollapsePanel,
    showIllustrationModal,
    setshowMMC,
    showMMC,
    // mainRef,
    prevIllustration,
    nextIllustration,
    disableIllustrations,
    changeDesignType
  } = props;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatchDesignList = useDispatchDesignList();
  const dispatchRoomConfig = useDispatchRoomConfig();
  const [activeCameraFilter, setactiveCameraFilter] = useState(0);
  const [activeCameraBlur, setactiveCameraBlur] = useState(0);
  const [roomdata, setRoomData] = useState(null);
  const [isRendering, setIsRendering] = useState(false);
  const [disableCamera, setdisableCamera] = useState(true);
  const [illviewRoomData, setillviewRoomData] = useState(null);
  const [catalogviewRoomData, setcatalogviewRoomData] = useState(null);
  const [closeviewRoomData, setCloseviewRoomData] = useState();
  const [currentRenderingView, setcurrentRenderingView] = useState({ prev: null, active: null });
  const [showFilterPanel, setshowFilterPanel] = useState(false);
  // const [showirregularDesignAlert, setshowirregularDesignAlert] = useState(false);
  const [showunsupportedDesignAlert, setshowunsupportedDesignAlert] = useState(false);
  // const [customUrl, setcustomUrl] = useState(null);
  const [showDesignPopOver, setshowDesignPopOver] = useState(false);
  const [showUploadRugDialog, setshowUploadRugDialog] = useState(false);
  const [showTutorial, setshowTutorial] = useState(false);
  const [showRugColors, setshowRugColors] = useState(false);

  const [hideActive, sethideActive] = useState(true);
  const [triggerHideActive, settriggerHideActive] = useState(false);
  const [blurData, setblurData] = useState({ blur: true, active: false, radius: 10 });
  const [blurs, setblurs] = useState(blursDef);

  const [tiltShiftFilter, settiltShiftFilter] = useState({
    active: false,
    options: { centerAt: { x: 0.5, y: 0.5 }, dontBlur: 20, maxRadius: 15 }
  });
  const [resetTrigger, setresetTrigger] = useState(0);
  const [nextDesigns, setnextDesigns] = useState({ next: null, prev: null });
  const [customUploadFolder, setcustomUploadFolder] = useState(null);
  const [editDesign, seteditDesign] = useState(null);

  const [floorOptions, setfloorOptions] = useState({
    show: false,
    floors: [],
    activeFloor: {}
  });

  const mainRef = useRef(null);

  const [fullscreenstatus, togglefullscreen] = useToggle(false);
  const isFullScreen = useFullscreen(mainRef, fullscreenstatus, {
    onClose: () => togglefullscreen(false)
  });

  const isIdle = useIdle(5e3);

  useEffect(() => {
    (async () => {
      let floorOptionsTemp = await getFloor();
      setfloorOptions(floorOptionsTemp);
    })();
    return () => {
      firstload = false;
    };
  }, []);

  useEffect(() => {
    if (showIllustrationModal || showDesignPopOver || showFilterPanel || showMMC) {
      window.dismissMenu && window.dismissMenu();
    }
  }, [showDesignPopOver, showIllustrationModal, showFilterPanel, showMMC]);

  useEffect(() => {
    if (!showFilterPanel && activeCameraFilter !== 0) {
      setactiveCameraFilter(0);
    }
  }, [showFilterPanel]);

  useEffect(() => {
    settiltShiftFilter({
      active: activeCameraBlur !== 0,
      options: {
        ...tiltShiftFilter.options,
        centerAt:
          blurs && blurs[activeCameraBlur] ? blurs[activeCameraBlur].centerAt : { x: 0.5, y: 0.5 }
      }
    });
  }, [activeCameraBlur]);

  useEffect(() => {
    if (!selectedView) return;
    let isSupported = true;
    if (selectedView.category !== "Studio Rooms") {
      if (designDetailState.designDetails.IsIrregular) {
        setLoading(false);
        setshowunsupportedDesignAlert(unsupportedDesign.RUNNER);
        isSupported = false;
        return;
      } else if (
        isRunner({
          width: designDetailState.designDetails.PhysicalWidth,
          height: designDetailState.designDetails.PhysicalHeight
        })
      ) {
        setshowunsupportedDesignAlert(unsupportedDesign.IRREGULAR);
        isSupported = false;
      } else setshowunsupportedDesignAlert(false);
    } else setshowunsupportedDesignAlert(false);
    if (!isSupported) return;
    if (selectedView.fromlocal) {
      const baseUrl = selectedView.Dir;
      readJSON(`${baseUrl}/config.json`).then(config => {
        const name = selectedView.Name.replace(new RegExp("[0-9]", "g"), "").trim();
        setRoomData({ ...selectedView, Name: name, config, baseUrl });
      });
    } else {
      setLoading(true);
      AppProvider.fetchRoomDetails({ file: selectedView.FullPath }).then(roomData => {
        if (!roomData && !roomData.dir) return;
        const s = roomData.Dir.split("/");
        let baseUrl;
        if (s[1] === "Assets") {
          const ss = s.slice(2);
          baseUrl = `${AppProvider.assetsDomain}/${ss.join("/")}`;
        } else {
          baseUrl = `${AppProvider.domain}${roomData.Dir}`;
        }
        readJSON(`${baseUrl}/config.json`).then(async config => {
          const name = selectedView.Name.replace(new RegExp("[0-9]", "g"), "").trim();
          if (config.version !== 2.1) {
            let mappedConfig = await config2Point1(name, baseUrl, config);
            setRoomData({
              ...roomData,
              Name: name,
              config: mappedConfig,
              baseUrl,
              category: "Studio Rooms"
            });
          } else {
            setRoomData({
              ...roomData,
              Name: name,
              config,
              baseUrl,
              category: selectedView.category
            });
          }
        });
      });
    }
  }, [selectedView]);

  useEffect(() => {
    if (!roomdata) return;
    if (!roomdata.config.blur) {
      setblurData({ ...blurData, blur: roomdata.config.blur, active: false });
      setblurs([]);
    } else {
      setblurData({ ...blurData, blur: roomdata.config.blur });
      setblurs([...blursDef]);
    }
    const config = roomdata.config;
    if (roomdata.config.roomType !== viewTypes.closeupview) {
      setshowTutorial(false);
    }
    if (currentRenderingView.prev || currentRenderingView.active) sethideActive(false);
    if (roomdata.config.roomType === viewTypes.closeupview) {
      setCloseviewRoomData(roomdata);
      if (
        !currentRenderingView.active ||
        (currentRenderingView.active && currentRenderingView.active !== viewTypes.closeupview)
      )
        sethideActive(true);
      setcurrentRenderingView({
        prev:
          currentRenderingView.active !== viewTypes.closeupview
            ? currentRenderingView.active
            : currentRenderingView.prev,
        active: viewTypes.closeupview
      });
    } else if (roomdata.category === viewTypes.catalog) {
      if (!catalogviewRoomData || roomdata.config.name !== catalogviewRoomData.config.name) {
        setcatalogviewRoomData({ ...roomdata });
        if (
          !currentRenderingView.active ||
          (currentRenderingView.active && currentRenderingView.active !== viewTypes.catalog)
        )
          sethideActive(true);
      }
      setcurrentRenderingView({
        prev:
          currentRenderingView.active !== viewTypes.catalog
            ? currentRenderingView.active
            : currentRenderingView.prev,
        active: viewTypes.catalog
      });
    } else if (roomdata.config.version > 2) {
      if (!illviewRoomData || roomdata.config.name !== illviewRoomData.config.name) {
        setillviewRoomData({ ...roomdata });
        if (
          !currentRenderingView.active ||
          (currentRenderingView.active && currentRenderingView.active !== viewTypes.illustration)
        )
          sethideActive(true);
      }
      setcurrentRenderingView({
        prev:
          currentRenderingView.active !== viewTypes.illustration
            ? currentRenderingView.active
            : currentRenderingView.prev,
        active: viewTypes.illustration
      });
    }
  }, [roomdata]);
  const setLoading = loading =>
    dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: loading });

  useEffect(() => {
    if (!designDetailState?.designDetails || !selectedView) return;
    if (showRugColors && !designDetailState.designDetails.DesignColors.length) {
      setshowRugColors(false);
    }
    if (!designDetailState.designDetails.DesignColors.length) {
      changeDesignType && changeDesignType("jpeg");
      if (
        selectedView?.category &&
        selectedView.category.toLowerCase() === "carpet in the making"
      ) {
        setTimeout(() => {
          dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
          setshowunsupportedDesignAlert("Rug photos ");
        }, 400);
      }
    } else {
      changeDesignType && changeDesignType("ctfx");
    }
    const irregular = designDetailState.designDetails.IsIrregular;
    const runner = isRunner({
      width: designDetailState.designDetails.PhysicalWidth,
      height: designDetailState.designDetails.PhysicalHeight
    });

    if (irregular || runner) {
      disableIllustrations(irregular ? unsupportedDesign.IRREGULAR : unsupportedDesign.RUNNER);
      if (roomdata.category !== "Studio Rooms") {
        setTimeout(() => {
          dispatchDesignDetails({ type: designDetailActions.SET_LOADING, payload: false });
          setshowunsupportedDesignAlert(
            (irregular ? unsupportedDesign.IRREGULAR : unsupportedDesign.RUNNER) + " designs "
          );
        }, 400);
      } else setshowunsupportedDesignAlert(false);
    } else {
      disableIllustrations(false);
      setshowunsupportedDesignAlert(false);
    }
  }, [designDetailState.designDetails]);

  useEffect(() => {
    if (!hideActive) {
      return;
    }
    let tim = setTimeout(() => {
      settriggerHideActive(true);
    }, 300);
    return () => {
      clearTimeout(tim);
    };
  }, [hideActive]);

  useEffect(() => {
    settriggerHideActive(false);
    if (triggerHideActive && !designDetailState.loading) {
      sethideActive(false);
    }
  }, [triggerHideActive]);

  useEffect(() => {
    if (!designDetailState.loading) {
      sethideActive(false);
    }
  }, [designDetailState.loading]);

  useEffect(() => {
    if (showRugColors) {
      document.getElementsByClassName("mmc-stickright-wrapper")[0].style.display = "block";
    } else {
      document.getElementsByClassName("mmc-stickright-wrapper")[0].style.display = "none";
    }
  }, [showRugColors]);

  window.enter = () => {
    if (!firstload) return false;
    setshowTutorial(true);
    setcustomUploadFolder(window.userEmail?.replace("@", "") || "default");
    return true;
  };

  const handleRendering = val => {
    if (val && !firstload) {
      handleCollapsePanel(true);
      firstload = true;
      // handleReady(true);
    }
    setdisableCamera(val);
    if (!val) return;
    switch (currentRenderingView.active) {
      case viewTypes.closeupview:
        setillviewRoomData(null);
        setcatalogviewRoomData(null);
        break;
      case viewTypes.illustration:
        setCloseviewRoomData(null);
        setcatalogviewRoomData(null);
        break;
      case viewTypes.catalog:
        setCloseviewRoomData(null);
        setillviewRoomData(null);
        break;

      default:
        break;
    }
  };
  const downloadIllustration = async () => {
    setIsRendering(true);

    if (!currentRenderingView.active) return;
    switch (currentRenderingView.active) {
      case viewTypes.closeupview:
        await window.downloadRenderedCloseupIll();
        break;
      case viewTypes.illustration:
        await window.downloadRendered3dIllinIllView();
        break;
      case viewTypes.catalog:
        await window.downloadRenderedCatalog();
        break;
      default:
        await window.downloadRendered3dIll();
        break;
    }
    setTimeout(() => {
      setIsRendering(false);
    }, 300);
  };

  const changeActiveFloor = floorOption => {
    setfloorOptions({ ...floorOptions, activeFloor: floorOption, show: false });
  };
  const setActiveFloor = floorName => {
    if (floorName) {
      let matchesName = floorOptions.floors.filter((el, i) => {
        return el.name === floorName;
      });
      if (matchesName.length > 0) {
        setfloorOptions({ ...floorOptions, activeFloor: matchesName[0], show: false });
        return matchesName[0];
      }
    }
    return null;
  };
  const handleDesignPopOverClose = useCallback(event => {
    setshowDesignPopOver(false);
  }, []);

  const handleUploadPlatformClose = useCallback(event => {
    seteditDesign(null);
    setshowUploadRugDialog(false);
  }, []);

  const handleSetNextDesigns = useCallback(val => {
    setnextDesigns(val);
  }, []);

  const nextDesign = () => {
    dispatchDesignList({
      type: designListActions.SET_ACTIVE_NODE,
      payload: nextDesigns.next
    });
  };
  const prevDesign = () => {
    dispatchDesignList({
      type: designListActions.SET_ACTIVE_NODE,
      payload: nextDesigns.prev
    });
  };

  return (
    <div className="at-photoscene-container">
      {showunsupportedDesignAlert && (
        <div className="cardalert-backdrop bp3-overlay-backdrop ">
          <Card
            className={"irregular-alert-card"}
            elevation={1}
            // onConfirm={() => { handleCollapsePanel();setshowirregularDesignAlert(false)}}
          >
            <div>
              {" "}
              {showunsupportedDesignAlert} are not supported in this shot. Please select a different
              shot.
            </div>
            <AtButton
              text={"Shot selection"}
              intent={"primary"}
              minimal
              onClick={() => {
                handleCollapsePanel();
              }}
            ></AtButton>
          </Card>
        </div>
      )}
      <ButtonGroup>
        {illviewRoomData && (
          <AtButton
            title={`Reset Rug`}
            intent={null}
            icon="reset"
            onClick={() => setresetTrigger(resetTrigger + 1)}
          ></AtButton>
        )}
        <AtButton
          title={`${isFullScreen ? "Exit" : "Enter"} Fullscreen`}
          intent={null}
          icon={`${isFullScreen ? "exit" : "enter"}-fullscreen`}
          onClick={() => togglefullscreen()}
        ></AtButton>
      </ButtonGroup>
      <div
        style={{
          position: "absolute",
          right: "4rem",
          top: "1rem",
          left: "unset",
          width: " 3rem",
          zIndex: "100"
        }}
      >
        <AtButton
          className="btn-mmc-top"
          title={`Rug Colors`}
          intent={null}
          tooltipProps={{ popoverClassName: "mmc-tooltip" }}
          tooltip={
            designDetailState?.designDetails?.DesignColors &&
            designDetailState.designDetails.DesignColors.length === 0
              ? "Please select a .CTFX or color separated .PNG to use this feature"
              : ""
          }
          disabled={
            designDetailState?.designDetails?.DesignColors &&
            designDetailState.designDetails.DesignColors.length === 0
          }
          onClick={() => setshowRugColors(true)}
        >
          <MMC />
        </AtButton>
      </div>
      {/* mmc */}
      <div
        className={(showRugColors ? "" : "hide") + " mmc-stickright-wrapper"}
        style={{ display: "none" }}
      >
        <H4>
          Rug Colors
          <AtIcon icon={"close"} title={"close"} onClick={e => setshowRugColors(false)} />
        </H4>
        <div className="design-color-wrapper" style={{ display: "flex" }}>
          {designDetailState?.designDetails?.DesignColors?.length &&
            designDetailState.designDetails.DesignColors.map((colorobj, index) => {
              return (
                <div
                  className="colorBox"
                  key={index}
                  style={{ backgroundColor: colorobj.Color }}
                  onClick={e => setshowMMC(index, colorobj)}
                ></div>
              );
            })}
        </div>
      </div>
      {blurData.active && (
        <Slider
          className={classNames("blur-slider", {
            idle: isIdle
          })}
          onRelease={val => setblurData({ ...blurData, radius: val })}
          value={blurData.radius}
          max={20}
          min={1}
          vertical
        />
      )}{" "}
      <CameraButton
        disabled={isRendering}
        nopointer={!disableCamera}
        onClick={() => {
          downloadIllustration();
        }}
        size="small"
        clipped={true}
      />
      <Tutorial show={showTutorial} onClose={() => setshowTutorial(false)}></Tutorial>
      <OptionsSidePanel
        className={(false && showFilterPanel ? "" : "options-panel-hidden") + " offsetRight"}
        show={false && showFilterPanel}
        options={filters}
        activeIndex={activeCameraFilter}
        onClick={(e, index) => {
          e.stopPropagation();
          setactiveCameraFilter(index);
        }}
      >
        <div className="options-button-row">
          <AtButton
            className={"btn-shot"}
            title={"Take Shot"}
            onClick={e => downloadIllustration()}
            disabled={isRendering}
          >
            {isRendering ? (
              <AtSpinner size="sm"></AtSpinner>
            ) : (
              <img alt="take-shot" src={`${window.assetspath}/v3assets/vps/checked.svg`}></img>
            )}
            <span>Take shot</span>
          </AtButton>
          <AtButton
            icon={"close"}
            title={"close"}
            onClick={e => setshowFilterPanel(false)}
          ></AtButton>
        </div>
      </OptionsSidePanel>
      <CameraOptions
        show={showFilterPanel}
        isRendering={isRendering}
        filterOptions={filters}
        activeFilterIndex={activeCameraFilter}
        onFilterSelect={(e, index) => {
          e.stopPropagation();
          setactiveCameraFilter(index);
        }}
        blurOptions={blurs}
        activeBlurIndex={activeCameraBlur}
        onBlurSelect={(e, index) => {
          e.stopPropagation();
          setactiveCameraBlur(index);
        }}
        onShot={e => downloadIllustration()}
        handleClose={() => {
          setshowFilterPanel(false);
          setactiveCameraFilter(0);
          setactiveCameraBlur(0);
        }}
      />
      <div
        className={classNames("photoscene-main", {
          loading: designDetailState.loading,
          hideActive: hideActive,
          inactive: showFilterPanel,
          isFullscreen: isFullScreen,
          "at-idle": isIdle
        })}
        id="photoscene-main"
        ref={mainRef}
      >
        {isFullScreen && (
          <>
            <AtButton
              className="btn-fullscreen"
              title={`${isFullScreen ? "Exit" : "Enter"} Fullscreen`}
              intent={null}
              icon={`${isFullScreen ? "exit" : "enter"}-fullscreen`}
              onClick={() => togglefullscreen()}
            />
            {prevIllustration && (
              <AtButton
                className="fullscreen-btns left-arrow"
                title="Previous illustration"
                intent={null}
                icon="chevron-left"
                onClick={prevIllustration}
              />
            )}
            {nextIllustration && (
              <AtButton
                className="fullscreen-btns right-arrow"
                title="Next illustration"
                intent={null}
                icon="chevron-right"
                onClick={nextIllustration}
              />
            )}
            {nextDesigns?.prev && (
              <AtButton
                className="fullscreen-btns up-arrow"
                title="Previous design"
                intent={null}
                icon="chevron-up"
                onClick={prevDesign}
              />
            )}
            {nextDesigns?.next && (
              <AtButton
                className="fullscreen-btns down-arrow"
                title="Next design"
                intent={null}
                icon="chevron-down"
                onClick={nextDesign}
              />
            )}
            <CameraButton
              disabled={isRendering}
              nopointer={!disableCamera}
              onClick={() => {
                downloadIllustration();
              }}
              className="fullWidth"
              size="small"
              clipped={true}
            />
          </>
        )}
        <div
          className={classNames("renderingContainer", {
            "renderingContainer--active": currentRenderingView.active === viewTypes.closeupview,
            "renderingContainer--previous": currentRenderingView.prev === viewTypes.closeupview
          })}
        >
          {closeviewRoomData && (
            <CloseupView
              roomData={closeviewRoomData}
              isIdle={isIdle}
              filterData={filters[activeCameraFilter].filterName}
              handleRendering={handleRendering}
              floor={floorOptions && floorOptions.activeFloor}
              resetTrigger={resetTrigger}
              setDefaultFloor={setActiveFloor}
            />
          )}
        </div>
        <div
          className={classNames("renderingContainer", {
            "renderingContainer--active": currentRenderingView.active === viewTypes.illustration,
            "renderingContainer--previous": currentRenderingView.prev === viewTypes.illustration
          })}
        >
          {illviewRoomData && (
            <IllustrationView
              cameraactive={showFilterPanel}
              active={currentRenderingView.active === viewTypes.illustration}
              roomData={illviewRoomData}
              handleRendering={handleRendering}
              filterData={filters[activeCameraFilter].filterName}
              // blurData={blurData}
              supported={!showunsupportedDesignAlert}
              tiltShiftFilter={tiltShiftFilter}
              isIdle={isIdle}
              floor={floorOptions && floorOptions.activeFloor}
              changeActiveFloor={changeActiveFloor}
              resetTrigger={resetTrigger}
            />
          )}
        </div>
        <div
          className={classNames("renderingContainer", {
            "renderingContainer--active": currentRenderingView.active === viewTypes.catalog,
            "renderingContainer--previous": currentRenderingView.prev === viewTypes.catalog
          })}
        >
          {catalogviewRoomData && (
            <CatalogView
              active={currentRenderingView.active === viewTypes.catalog}
              roomData={catalogviewRoomData}
              filterData={filters[activeCameraFilter].filterName}
            />
          )}
        </div>

        <AtSpinnerOverlay show={designDetailState.loading} />
      </div>
      <div
        className={classNames("photoscene-leftbar", {
          isIdle: isIdle,
          cameraactive: showFilterPanel
        })}
      >
        <div>
          <img src={window.logourl} alt="logo" />
          <AtButton
            title={"Shot Selection"}
            text={"Shot Selection"}
            className={"bottom-buttons " + (showIllustrationModal ? "active" : "")}
            onClick={() => handleCollapsePanel()}
          ></AtButton>
          <DesignList
            hidden={!showDesignPopOver}
            handleClose={handleDesignPopOverClose}
            setNextDesigns={handleSetNextDesigns}
            customUploadFolder={customUploadFolder}
            restrictJPEG={
              selectedView?.category &&
              selectedView.category.toLowerCase() === "carpet in the making"
            }
            restrictDesigns={selectedView && selectedView.category !== "Studio Rooms"}
            seteditDesign={seteditDesign}
          />
          <AtButton
            title={"Rug Selection"}
            text={"Rug Selection"}
            className={"bottom-buttons " + (showDesignPopOver ? "active" : "")}
            onClick={() => {
              setshowDesignPopOver(!showDesignPopOver);
            }}
          ></AtButton>
          <AtButton
            title={"Upload your design"}
            text={"Upload Your Design"}
            className={"bottom-buttons " + (showUploadRugDialog ? "active" : "")}
            onClick={() => {
              setshowUploadRugDialog(!showUploadRugDialog);
            }}
          ></AtButton>
          {/* {showUploadRugDialog && ( */}
          <UploadPlatform
            show={showUploadRugDialog}
            handleClose={handleUploadPlatformClose}
            editDesign={editDesign}
          />
          {/* )} */}
        </div>
        {(closeviewRoomData || illviewRoomData) &&
          roomdata.config.roomType !== "illustration" &&
          roomdata.category.toLowerCase() !== "carpet in the making" && (
            <div>
              <AtButton
                title={"Floor Selection"}
                text={"Floor Selection"}
                className={"bottom-buttons " + (floorOptions?.show ? "active" : "")}
                onClick={() => {
                  setfloorOptions({ ...floorOptions, show: true });
                }}
              ></AtButton>
              <FloorSelectionDialog
                floorOptions={floorOptions}
                handleClose={() => {
                  setfloorOptions({ ...floorOptions, show: false });
                }}
                handleReset={illviewRoomData && (() => changeActiveFloor(null))}
                handleThumbClick={changeActiveFloor}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default VpsStage;
