import React, { useState, Suspense } from "react";
import { useMount } from "react-use";
import VpsHome from "../VpsHome";
import AppProvider from "../../../api/appProvider";
import { UiStateProvider, useUiState, pageViews } from "../../../reducers/mainui.reducer";
import { DesignListStateProvider } from "../../../reducers/designstree.reducer";
import { VisualizationStateProvider } from "../../../reducers/visualizations.reducer";
import { ColorListStateProvider } from "../../../reducers/colors.reducer";
import { DesignDetailStateProvider } from "../../../reducers/designdetails.reducer";
import { MyroomStateProvider } from "../../../reducers/myroom.reducer";
import strings, { initializeStrings } from "../../../strings";
import deepmerge from "deepmerge";
import { defaultFlags } from "./defaultflags";
import { RoomConfigStateProvider } from "../../IllustrationView/roomconfig.reducer";
import { decodeColorsFromString, getFromSessionStorage, errorTypes } from "../../../utils/utils";
import ErrorPage from "../ErrorPage";


const PageTorender = props => {
  const uiState = useUiState();
  switch (uiState.pageView) {
    case pageViews.VPS:
      return <VpsHome {...props} />;
    default:
      break;
  }
};
const overwriteMerge = (destinationArray, sourceArray, options) => sourceArray;
const processRoomPath = path => {
  const initpatharr = path.split("/").filter(item => item && item !== "");
  return `${initpatharr[0] === "Rooms" ? "" : "Rooms/"}${path}`;
};
export const processDesignPath = path => {
  const initpatharr = path.split("/").filter(item => item && item !== "");
  return `${initpatharr[0] === "Designs" ? "" : "Designs/"}${path}`;
};
const EntryPage = () => {
  const [initialData, setInitialData] = useState({
    eCatalogDetails: null,
    initDesignColors: null,
    initDesignPath: null,
    initRoomPath: null,
    initdesignFolder: false,
    loading: true,
    error: false
  });
  useMount(() => {
    const initializeData = ({
      initDesignPath,
      initRoomPath,
      initDesignColors,
      eCatalogDetails
    }) => {
      let initData = { ...initialData, eCatalogDetails };

      if (initDesignPath) {
        initData = { ...initData, initDesignPath: processDesignPath(initDesignPath) };
      }
      if (initRoomPath) {
        initData = { ...initData, initRoomPath: processRoomPath(initRoomPath) };
        window.flags.visualizations.defaultView = "ROOM_VIEW";
      }
      if (initDesignColors) initData = { ...initData, initDesignColors };
      return initData;
    };

    const catalogId = getFromSessionStorage("catalogid");
    const mode = getFromSessionStorage("mode");
    const initDesignPath = getFromSessionStorage("initdesign");
    // const initDesignFolder = getFromSessionStorage("initdesignfolder");
    const initRoomPath = getFromSessionStorage("initview");
    const str = getFromSessionStorage("props");
    const initDesignColors = str ? decodeColorsFromString(str) : null;

    const init = async () => {
      try {
        await AppProvider.autoLogin({ keyfromsession: true });
        const { flags } = await AppProvider.fetchInterfaceElements();
        window.flags = deepmerge(defaultFlags, flags, { arrayMerge: overwriteMerge });

        //set up langauge
        const { language: obj } = window.flags;
        initializeStrings(obj.strings);

        if (obj.langOptions && obj.langOptions.length) {
          const defLang = obj.langOptions[0].val || null;
          if (defLang) strings.setLanguage(obj.langOptions[0].val);
          else {
            strings.setLanguage("en");
            window.flags.language.langOptions = [];
          }
        }
      } catch (error) {
        //*! could not set interface elements */
        console.error(error);
        setInitialData({ ...initialData, err: { ...errorTypes.INTERFACE_ERROR, error } });
        return;
      }

      const data = {
        initDesignPath,
        initRoomPath,
        initDesignColors,
        eCatalogDetails: null
      };
      let error = null;
      let initData;
      switch (mode) {
        case "stripedesign":
          try {
            const designPath = await AppProvider.getStripedDesign();
            data.initDesignPath = designPath;
            data.eCatalogDetails = {};
            initData = initializeData(data);
          } catch (err) {
            //*! could not get striped design */
            error = { type: errorTypes.INTERFACE_ERROR, err: err };
            console.error(err);
          }
          break;
        case "myroom":
          initData = initializeData(data);
          window.flags.visualizations.defaultView = "MY_ROOM";
          break;
        case "ecat":
          if (catalogId) {
            let eCatalogDetails;
            try {
              eCatalogDetails = await AppProvider.eCatalogDetails({ catalogId });
            } catch (err) {
              error = { type: errorTypes.INTERFACE_ERROR, err: err };
              console.error(error);
            }
            if (eCatalogDetails) {
              const { InitView, InitDesign, Colors } = eCatalogDetails;
              data.eCatalogDetails = eCatalogDetails;
              if (InitDesign && InitDesign !== "" && InitDesign !== "undefined") {
                data.initDesignPath = InitDesign;
              }
              if (InitView && InitView !== "" && InitView !== "undefined") {
                data.initRoomPath = InitView;
              }
              if (Colors) {
                data.initDesignColors = decodeColorsFromString(Colors);
              }
              initData = initializeData(data);
            }
          } else {
            data.eCatalogDetails = {};
            initData = initializeData(data);
            if (!initData.initDesignPath) error = { type: errorTypes.INVALID_LINK };
          }
          break;

        default:
          initData = initializeData(data);
          break;
      }
      window.initialData = initData;
      setInitialData({ ...initData, loading: false, error });
    };
    init();
  });
  return initialData.loading ? (
    <></>
  ) : initialData.error ? (
    <ErrorPage errorType={initialData.error.type} />
  ) : (
    <DesignListStateProvider>
      <DesignDetailStateProvider>
        <VisualizationStateProvider>
          <ColorListStateProvider>
            <MyroomStateProvider>
              <RoomConfigStateProvider>
                <PageTorender initialData={initialData} />
              </RoomConfigStateProvider>
            </MyroomStateProvider>
          </ColorListStateProvider>
        </VisualizationStateProvider>
      </DesignDetailStateProvider>
    </DesignListStateProvider>
  );
};

export default EntryPage;
