import React, { useState } from "react";
import { useRoomConfigState, useDispatchRoomConfig, roomConfigActions } from "./roomconfig.reducer";
import { MenuDivider, MenuItem, Menu } from "@blueprintjs/core";
import AtButton from "../atoms/AtButton";
import classNames from "classnames";
const IllustrationOptions = props => {
  const { roomData, isIdle } = props;
  const { config } = roomData;
  const { roomType, scene1 } = config;
  const dispatchRoomConfig = useDispatchRoomConfig();
  const roomConfigState = useRoomConfigState();
  const { floorOptions, carpetOptions, roomelements, activeshot, activedata } = roomConfigState;
  const [showMenu, setShowMenu] = useState(false);

  const isMultishot = roomelements.shots && Object.entries(roomelements.shots).length > 1;
  const hasIlluOptions =
    (roomType !== "illustration" && floorOptions.floors.length > 0) ||
    isMultishot ||
    (activedata && Object.keys(activedata).length > 0);
  const hasOptions = roomType !== "illustration" || hasIlluOptions;
  const tooltipProps = { position: "bottom" };

  window.dismissMenu = () => {
    setShowMenu(false);
  };

  const setCarpetOption = carpetOption =>
    dispatchRoomConfig({
      type: roomConfigActions.SET_CARPET_OPTION,
      payload: carpetOption
    });
  const handleRotation = angle => {
    const { rotation } = carpetOptions;
    if (!rotation) return;
    let newRotation = [rotation[0], rotation[1], rotation[2] + angle];
    const newCarpetOptions = { ...carpetOptions, rotation: newRotation };
    setCarpetOption(newCarpetOptions);
  };
  const handleResetRotation = () => {
    const newCarpetOptions = { ...carpetOptions, rotation: scene1.surface1.rotation };
    setCarpetOption(newCarpetOptions);
  };
  const handleShowFloor = () =>
    dispatchRoomConfig({
      type: roomConfigActions.SET_SHOW_FLOOR_OPTION,
      payload: true
    });
  const handleShowViewpoints = () => {
    dispatchRoomConfig({
      type: roomConfigActions.SET_SHOW_VP_DIALOG,
      payload: true
    });
  };
  const handleShowColorBox = object => {
    dispatchRoomConfig({
      type: roomConfigActions.SET_SHOW_COLOR_BOX,
      payload: { object, isOpen: true }
    });
  };
  const handleResetPosition = () => {
    const newCarpetOptions = { ...carpetOptions, position: scene1.surface1.position };
    setCarpetOption(newCarpetOptions);
  };
  const handleReset = () => {
    const newCarpetOptions = { ...carpetOptions, position: scene1.surface1.position, rotation: scene1.surface1.rotation };
    setCarpetOption(newCarpetOptions);
  }
  const renderFloorOption = path => (
    <div className="at-illu-option-item">
      {path ? (
        <img src={path} alt="" className="at-illu-option-item-image" />
      ) : (
        <div className="at-illu-option-item-image" />
      )}
      Change Floor
    </div>
  );
  const renderMultishotOption = shot => <div className="at-illu-option-item">Change Viewpoint</div>;
  const getObjectProps = key => roomelements.objects[key] || {};

  const renderActiveOption = objKey => (
    <div className="at-illu-option-item">Change {getObjectProps(objKey).label} Color</div>
  );

  const rotationOptions = (
    <div className="at-carpet-option-rotation">
      Rotate Carpet
      <div className="at-carpet-option-rotation-controls">
        <AtButton
          tooltip="Rotate Clockwise"
          tooltipProps={tooltipProps}
          icon="rotate-cw"
          onClick={() => handleRotation(-15)}
        />
        <AtButton
          tooltip="Rotate Counter-Clockwise"
          tooltipProps={tooltipProps}
          icon="rotate-ccw"
          onClick={() => handleRotation(15)}
        />
        {/* <AtButton
          tooltip="Reset Rotation"
          icon="reset"
          tooltipProps={tooltipProps}
          onClick={handleResetRotation}
        /> */}
      </div>
    </div>
  );
  return (
    <div className={classNames("at-illu-opt", { "at-idle-fadeout": isIdle })}>
      {showMenu && (
        <Menu className="at-illu-opt__menu">
          {hasIlluOptions && (
            <>
              <MenuDivider title="Illustration" />
              {floorOptions.floors.length > 0 && (
                <MenuItem
                  text={renderFloorOption(
                    floorOptions.activeFloor && floorOptions.activeFloor.thumb
                  )}
                  onClick={handleShowFloor}
                />
              )}
              {isMultishot && (
                <MenuItem text={renderMultishotOption(activeshot)} onClick={handleShowViewpoints} />
              )}
              {activedata &&
                Object.entries(activedata).map(([objKey, objProps]) => (
                  <MenuItem
                    key={objKey}
                    text={renderActiveOption(objKey)}
                    onClick={() => handleShowColorBox(objKey)}
                  />
                ))}
            </>
          )}
          {roomType !== "illustration" && (
            <>
              <MenuDivider title="Carpet" />
              <MenuItem className="at-carpet-option" text={rotationOptions} />
              <MenuItem
                className="at-carpet-option"
                text={
                  <AtButton
                    text="Reset"
                    onClick={() => {
                      handleReset();
                    }}
                    tooltipProps={tooltipProps}
                  />
                }
              />
            </>
          )}
        </Menu>
      )}
      {hasOptions && (
        <AtButton
          minimal
          className="at-illu-opt__plus_btn"
          icon="plus"
          onClick={() => setShowMenu(!showMenu)}
        />
      )}
    </div>
  );
};
export default IllustrationOptions;
