/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from "react";
import RoomViewHelper, { defaultRoomType, roomTypes } from "./roomviewhelper";
import { useMount } from "react-use";
import InputCanvas from "../atoms/InputCanvas";

import AppProvider, { getFloor } from "../../api/appProvider";
import Fade from "./../higher-order-components/Fade";

import CircularButton from "./../atoms/CircularButton";
import DayNightSlider from "./../DayNightSlider";
import { resizeKeepingAspect } from "../../utils/utils";

import { useWindowSize } from "./../../hooks";
import classNames from "classnames";

import {
  useRoomConfigState,
  useDispatchRoomConfig,
  roomConfigReducerHandlers,
  roomConfigActions
} from "./roomconfig.reducer";
import {
  useDesignDetailState,
  useDispatchDesignDetail,
  designDetailActions
} from "../../reducers/designdetails.reducer";
import { clearCanvas, createCanvas } from "../../utils/canvasutils";
import { createBlurImage } from "../../utils/blur";
import BlurCircle from "../molecules/BlurCircle";
import { applyPresetOnCanvas, presetsMapping } from "instagram-filters";
import IllustrationOptions from "./IllustrationOptions";
import FloorSelectionDialog from "./FloorSelectionDialog";
import ViewpointDialog from "./ViewpointDialog";
import ColorSelection from "./ColorSelection";

let roomViewHelper = new RoomViewHelper();
window.roomViewHelper = roomViewHelper;

let designRendered = false;
let designRendering = false;

function usePrevious(value) {
  const ref = useRef({});
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

export const getDominantColor = designDetails => {
  if (!designDetails || Object.keys(designDetails) < 1) return null;
  const smartKnot = designDetails.DesignColors?.map(c => c.Knots).sort((a, b) => b - a);
  const smartIndex = smartKnot.length > 2 ? (smartKnot.length === 3 ? 1 : 2) : smartKnot.length - 1;
  const domC = designDetails.DesignColors?.find(item => item.Knots === smartKnot[smartIndex]);
  if (domC) return domC.Color;
  else return null;
};

const IllustrationView = props => {
  const {
    cameraactive,
    active,
    roomData,
    isIdle,
    handleAvailableSizesChange,
    selectedSize,
    handleRendering,
    filterData = null,
    tiltShiftFilter,
    floor,
    changeActiveFloor,
    resetTrigger, supported
  } = props;
  const { Name: roomName, Files: files, config: config1, baseUrl } = roomData;
  const config = { ...config1 };
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const [inputoncarpet, setinputoncarpet] = useState(false);
  const [shotReady, setshotReady] = useState(false);
  const [transition, settransition] = useState(-1);
  const [dimensions, setdimensions] = useState({});
  const [showWand, setshowWand] = useState(false);
  const [inputmoved, setinputmoved] = useState(false);
  const [drawOnEffectsTrigger, setdrawOnEffectsTrigger] = useState(false);
  const [renderfromsavedstate, setrenderfromsavedstate] = useState(false);
  // const [tiltShiftFilter, settiltShiftFilter] = useState({
  //   active: false,
  //   options: { centerAt: { x: 0.5, y: 0.5 }, dontBlur: 20, maxRadius: 15 }
  // });
  const [colorSelectionPos, setcolorSelectionPos] = useState({ x: -1, y: -1 });
  const [showEffectCanvas, setshowEffectCanvas] = useState(false);

  const roomconfigstate = useRoomConfigState();
  // const { tiltShiftFilter } = roomconfigstate;
  const dispatchRoomConfig = useDispatchRoomConfig();

  const containerRef = useRef(null);
  const bgCanvasRef = useRef(null);
  const threeCanvasRef = useRef(null);
  const maskCanvasRef = useRef(null);
  const shadowCanvasRef = useRef(null);
  const transitionCanvasRef = useRef(null);
  const inputCanvasRef = useRef(null);
  const objCanvasContainerRef = useRef(null);
  const inputCanvasContainerRef = useRef(null);
  const effectCanvasRef = useRef();

  const {
    floorOptions,
    carpetOptions,
    roomelements,
    activeshot,
    showViewpointDialog,
    customDesignUrl
  } = roomconfigstate;
  const { activeFloor } = floorOptions;
  const { rotation: carpetRotation, position: carpetPosition } = carpetOptions;
  const {
    designDetails,
    fullpath: designPath,
    hash,
    updateDesignTiles,
    updateNormapTiles
  } = designDetailState;

  const prevRoomDetails = usePrevious(roomData);

  const windowsize = useWindowSize();

  useMount(() => {
    const canvasConfig = {
      bgCanvas: bgCanvasRef.current,
      threeCanvas: threeCanvasRef.current,
      maskCanvas: maskCanvasRef.current,
      shadowCanvas: shadowCanvasRef.current,
      transitionCanvas: transitionCanvasRef.current,
      effectCanvas: effectCanvasRef.current,
      container: containerRef.current,
      inputCanvas: inputCanvasRef.current,
      objCanvasContainer: objCanvasContainerRef.current
    };
    roomViewHelper.initCanvas(canvasConfig);
  });

  useEffect(() => {
    return () => {
      roomViewHelper.unmountClearUp();
      designRendered = false;
      designRendering = false;
    };
  }, []);

  useEffect(() => {
    sizeContainers({ windowsize: true });
  }, [windowsize]);

  useEffect(() => {
    if (!selectedSize) return;
    roomViewHelper.change3dObjectScale(selectedSize);
  }, [selectedSize]);
  useEffect(() => {
    handleShotReady();
  }, [shotReady]);
  useEffect(() => {
    if (!shotReady || designDetailState.loading || carpetRotation.length !== 3) return;
    roomViewHelper.updateCarpetRotation(carpetRotation);
  }, [carpetRotation]);

  useEffect(() => {
    const run = async () => {
      if (cameraactive) {
        await makeTransitionCanvas();
      } else {
        setshowEffectCanvas(false);
      }
    };

    run();
  }, [cameraactive]);

  useEffect(() => {
    if (!shotReady || designDetailState.loading || !carpetPosition || carpetPosition.length !== 3)
      return;

    roomViewHelper.updateCarpetPosition(carpetPosition);
  }, [carpetPosition]);
  useEffect(() => {
    if (!shotReady || designDetailState.loading) {
      return;
    }
    const updateFloor = async () => {
      if (!floor) {
        await roomViewHelper.renderFloor(floor);
        await roomViewHelper.updateShadow();
      } else {
        setshotReady(false);
        await roomViewHelper.renderFloor(floor);
        await roomViewHelper.updateShadow();
        setshotReady(true);
      }
    };
    updateFloor();
  }, [floor]);

  const handleShotReady = () => {
    if (shotReady) {
      settransition(0);
    }
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: !shotReady
    });
    if (handleRendering) handleRendering(shotReady);
  };
  const setRoomConfigState = async config => {
    const {
      name,
      dims,
      roomType,
      hasShadow,
      mouseControls,
      realTimeDynamicRendering,
      roomElements,
      scene1
    } = config;
    const carpetOptions = {
      rotation: scene1.surface1.rotation,
      position: scene1.surface1.position
    };
    const roomState = {
      name,
      dims,
      roomType,
      hasShadow,
      mouseControls,
      realTimeDynamicRendering,
      roomElements,
      carpetOptions
    };
    const roomConfigState = roomConfigReducerHandlers.setRoomElements({
      state: { ...roomconfigstate },
      payload: roomState
    });
    dispatchRoomConfig({
      type: "SET_ROOM_ELEMENTS",
      precalc: { ...roomConfigState }
    });
    return roomConfigState;
  };
  const selectSmartObject = smartObj => {
    //works only for one object currently
    if (smartObj) {
      roomViewHelper.selectColorCode(smartObj.colorCode);
    }
  };

  const getSmartObject = objects => {
    if (!objects) return;
    let objectEntries = Object.entries(objects);
    if (objectEntries && objectEntries.length > 0) {
      let object = Object.entries(objects)[0][1];
      if (objectEntries.length > 1) {
        let smartObjs = objectEntries.filter((entry, index) => entry[1].smart);
        if (smartObjs.length) object = smartObjs[0][1];
        return smartObjs[0];
      } else if (object && object.passive) {
        return object.hasOwnProperty("smart") && !object.smart ? null : Object.entries(objects)[0];
      }
    }
  };

  useEffect(() => {
    let la = true;
    const renderFloorInRoom = async (defaultFloor = false) => {
      if (!floor || !floor.path || defaultFloor) {
        changeActiveFloor(null);
        return roomViewHelper.renderFloor({ path: null });
      } else {
        return roomViewHelper.renderFloor(floor);
      }
    };

    const loadRoom = async () => {
      try {
        let temproomconfigstate = roomconfigstate;
        let newroom = false;
        if (prevRoomDetails !== roomData) {
          newroom = true;
          if (shotReady) await makeTransitionCanvas();
          settransition(1);
          if (!(!prevRoomDetails && roomViewHelper.roomData === roomData))
            roomViewHelper.clearAll();
          dispatchRoomConfig({
            type: "RESET"
          });
          temproomconfigstate = await setRoomConfigState(config);
          roomViewHelper.roomData = roomData;
          roomViewHelper.initConfig({ baseUrl, config, files, local: roomData.fromlocal });
          sizeContainers({ illustrationDims: config.dims });

          if (customDesignUrl?.url) {
            if (roomViewHelper.designDetails !== designDetails) roomViewHelper.resetOnDesignChange();
          } else {
            if (roomViewHelper.customDesignUrl?.url !== customDesignUrl?.url) roomViewHelper.resetOnDesignChange();
          }
          const renderScene = await initShot({
            shot: temproomconfigstate.activeshot,
            light: temproomconfigstate.activelight,
            view: temproomconfigstate.activeview,
            realTimeDynamicRendering: temproomconfigstate.realTimeDynamicRendering
          });
          if (!la || !renderScene) return;
          const smartObject = getSmartObject(temproomconfigstate.roomelements.objects);
          if (smartObject) {
            selectSmartObject(smartObject[1]);
            const dominantColorHex = getDominantColor(designDetailState?.designDetails);
            roomViewHelper.updateBackground({ dominantColorHex });
            if (dominantColorHex) {
              dispatchRoomConfig({
                type: "SET_ACTIVE_COLOR",
                payload: { object: smartObject[0], color: dominantColorHex }
              });
            }
          } else {
            roomViewHelper.updateBackground();
          }


          roomViewHelper.updateMask();

          if (
            (customDesignUrl?.url &&
              (roomViewHelper.customDesignUrl?.url === customDesignUrl.url))
            ||
            (!customDesignUrl?.url &&
              (roomViewHelper.designDetails === designDetails))
          ) {
            const availSizes = await roomViewHelper.updatethreeCanvas(
              temproomconfigstate.activeshot
            );
            if (!la) return;
            if (
              prevRoomDetails &&
              prevRoomDetails.config &&
              prevRoomDetails.config.designinroomformat !== roomData.config.designinroomformat
            ) {
              designRendered = false;
              designRendering = false;
            }
            if (!designRendering) {
              designRendering = true;
              if (customDesignUrl?.url) {
                await roomViewHelper.renderDesignFromCustomUrl({
                  customUrl: customDesignUrl.url, physicalWidth: customDesignUrl.physicalWidCms,
                  physicalHeight: customDesignUrl.physicalHgtCms
                });
              } else {
                await roomViewHelper.renderDesign({ designDetails, designPath, hash });
              }

              designRendered = true;
              if (availSizes && handleAvailableSizesChange) {
                handleAvailableSizesChange(availSizes);
              }
            }
            await renderFloorInRoom(newroom);
            roomViewHelper.updateShadow();
            roomViewHelper.updateGizmo();
          }
          updateAllObjectsInScene({ ...temproomconfigstate.objectstate });
          if (la && designRendered) {
            setshotReady(true);
            // clearCanvas(
            //   transitionCanvasRef.current,
            //   transitionCanvasRef.current.width,
            //   transitionCanvasRef.current.height
            // );
          }
        }
        if (
          (customDesignUrl?.url &&
            (roomViewHelper.customDesignUrl?.url !== customDesignUrl.url))
          ||
          (!customDesignUrl?.url &&
            (roomViewHelper.designDetails !== designDetails))
        ) {
          setshotReady(false);
          designRendered = false;
          designRendering = false;

          if (renderfromsavedstate) {
            await Promise.all(roomViewHelper.initShot());
          }

          const dominantColorHex = getDominantColor(designDetailState?.designDetails);
          if (renderfromsavedstate || roomViewHelper.currentAssets.backgroundpatch) {
            const smartObject = getSmartObject(temproomconfigstate.roomelements.objects);
            if (smartObject) {
              roomViewHelper.updateBackground({ dominantColorHex });
              dominantColorHex && dispatchRoomConfig({
                type: "SET_ACTIVE_COLOR",
                payload: { object: smartObject[0], color: dominantColorHex }
              });
              temproomconfigstate = {
                ...temproomconfigstate,
                objectstate: {
                  ...temproomconfigstate.objectstate,
                  [smartObject[0]]: {
                    ...temproomconfigstate.objectstate[smartObject[0]],
                    active: dominantColorHex
                  }
                }
              };
              handleBackgroundActives(temproomconfigstate);
            } else {
              roomViewHelper.updateBackground();
              handleBackgroundActives();
            }
            roomViewHelper.updateMask();
          }
          setrenderfromsavedstate(false);

          if (customDesignUrl?.url) {
            roomViewHelper.customDesignUrl = customDesignUrl;
            roomViewHelper.designDetails = null;
          }
          else {
            roomViewHelper.designDetails = designDetails;
            roomViewHelper.customDesignUrl = null;

          }


          if (
            customDesignUrl?.url || roomViewHelper.designPath !== designPath ||
            newroom
          ) {
            roomViewHelper.resetOnDesignChange();

            if (!customDesignUrl?.url) {
              const tileData = await AppProvider.fetchTileDetails({
                file: designPath
              });
              if (!la) return;
              dispatchDesignDetails({
                type: designDetailActions.SET_TILE_DETAILS,
                payload: tileData
              });
              roomViewHelper.setTileDetails(tileData);

            }


            const availSizes = await roomViewHelper.updatethreeCanvas(
              temproomconfigstate ? temproomconfigstate.activeshot : roomconfigstate.activeshot
            );
            if (!la) return;
            designRendering = true;
            roomViewHelper.threeView.changeFloorVisibility(false);
            if (customDesignUrl?.url) {
              await roomViewHelper.renderDesignFromCustomUrl({
                customUrl: customDesignUrl.url, physicalWidth: customDesignUrl.physicalWidCms,
                physicalHeight: customDesignUrl.physicalHgtCms
              });
            } else {
              await roomViewHelper.renderDesign({ designDetails, designPath, hash });
            }
            await renderFloorInRoom(newroom);

            if (availSizes && handleAvailableSizesChange) {
              handleAvailableSizesChange(availSizes);
            }
            await roomViewHelper.updateShadow();
            designRendered = true;
            if (la && designRendered) setshotReady(true);
            if (!la) return;
          } else {
            await roomViewHelper.updateTiles({
              designDetails,
              updateDesignTiles,
              updateNormapTiles,
              hash
            });
            await roomViewHelper.updateShadow();
            designRendered = true;
          }
        }
        setshotReady(true);
      } catch (error) {
        console.error(error);
        return;
      }
    };
    if (supported && roomData && (designDetails || customDesignUrl?.url) && active) loadRoom();

    return () => {
      la=false;
    }
  }, [roomData, active, designDetails, customDesignUrl, supported]);

  useEffect(() => {
    if (!active) return;
    applyEffects();
  }, [tiltShiftFilter, filterData]);

  // useEffect(() => {
  //   settiltShiftFilter({
  //     active: blurData.active,
  //     options: { ...tiltShiftFilter.options, maxRadius: blurData.radius }
  //   });
  // }, [blurData]);

  useEffect(() => {
    if (drawOnEffectsTrigger) {
      setdrawOnEffectsTrigger(false);
      drawOnEffects();
    }
  }, [drawOnEffectsTrigger]);

  const drawOnEffects = async () => {
    let canvas;

    if (filterData || tiltShiftFilter.active) {
      canvas = await roomViewHelper.initEffectCanvas();
    }
    if (filterData) {
      await applyPresetOnCanvas(canvas, presetsMapping[filterData]());
      roomViewHelper.blurMatrix = null;
      effectCanvasRef.current.getContext("2d").drawImage(canvas, 0, 0);
    }
    if (tiltShiftFilter.active) {
      canvas.toBlob(async function (blob) {
        var url = URL.createObjectURL(blob);
        await roomViewHelper.tiltShift(canvas, tiltShiftFilter);
        effectCanvasRef.current.getContext("2d").drawImage(canvas, 0, 0);
      });
    }

    setshowEffectCanvas(true);
  };

  const applyEffects = async () => {
    if (!effectCanvasRef || !effectCanvasRef.current) return;
    if (!(filterData || tiltShiftFilter.active)) return;
    setdrawOnEffectsTrigger(true);
  };

  // const handleBlurCenter = e => {
  //   if (!tiltShiftFilter.active) return;
  //   let isTouchEvent = e.nativeEvent.type === "touchstart" || e.nativeEvent.type === "touchmove";
  //   const { clientX, clientY } = isTouchEvent ? e.nativeEvent.targetTouches[0] : e;
  //   const { top, left, width, height } = effectCanvasRef.current.getBoundingClientRect();
  //   const xabs = (clientX - left) / width;
  //   const yabs = (clientY - top) / height;
  //   settiltShiftFilter({
  //     ...tiltShiftFilter,
  //     options: { ...tiltShiftFilter.options, centerAt: { x: xabs, y: yabs } }
  //   });
  // };

  const sizeContainers = ({ illustrationDims, windowsize = false }) => {
    const { width, height } = containerRef.current.getBoundingClientRect();
    roomViewHelper.resize({ width: width, height: height, windowsize: windowsize });
    const dimensionPixels = resizeKeepingAspect(
      illustrationDims ? illustrationDims : roomconfigstate.dims,
      { width: width, height: height },
      "fit_inside"
    );
    setdimensions({ width: `${dimensionPixels.width}px`, height: `${dimensionPixels.height}px` });
  };

  const initShot = async ({ shot, light, view, realTimeDynamicRendering }) => {
    const isinitialized = roomViewHelper.preinitShot({
      shot: shot,
      light: light,
      view: view
    });
    if (realTimeDynamicRendering === undefined || realTimeDynamicRendering === null)
      realTimeDynamicRendering = roomconfigstate.realTimeDynamicRendering;
    if (!realTimeDynamicRendering) {
      const isrenderedfromsavedstate = await roomViewHelper.renderSavedRoomState();
      if (isrenderedfromsavedstate) {
        setrenderfromsavedstate(true);

        if (!shotReady) {
          setshotReady(true);
        } else {
          handleShotReady();
        }
        return Promise.resolve(false);
      }
    }
    setrenderfromsavedstate(false);
    if (!isinitialized) {
      setshotReady(false);
      await Promise.all(roomViewHelper.initShot());
    } else {
      handleShotReady();
    }
    return Promise.resolve(true);
  };

  const handleBackgroundActives = (roomState = roomconfigstate) => {
    for (const objectname in roomState.objectstate) {
      if (
        roomState.objectstate[objectname].active &&
        roomState.objectstate[objectname].active !== "init"
      ) {
        const objectConfig = roomState.roomelements.objects[objectname];
        const objectPosition = roomState.activedata[objectname];
        const { passive, colorCode } = objectConfig;
        if (!passive) return;
        else {
          if (colorCode) {
            roomViewHelper.selectColorCode(colorCode);
          } else if (objectPosition) {
            const x = inputCanvasRef.current.width * objectPosition[0];
            const y = inputCanvasRef.current.height * objectPosition[1];
            roomViewHelper.selectColorAnnotation({ x, y });
          }
          if (colorCode || objectPosition) {
            roomViewHelper.updateBackground({
              dominantColorHex: roomState.objectstate[objectname].active,
              retainActiveColors: true
            });
            roomViewHelper.updateMask();
            roomViewHelper.updateShadow();
          }
        }
      }
    }
  };

  const changeShot = async newshot => {
    try {
      await makeTransitionCanvas();
      settransition(1);
      const temproomconfigstate = roomConfigReducerHandlers.changeShot({
        state: { ...roomconfigstate },
        payload: { activeshot: newshot }
      });
      dispatchRoomConfig({
        type: "CHANGE_SHOT",
        precalc: { ...temproomconfigstate }
      });
      await initShot({ shot: temproomconfigstate.activeshot });
      roomViewHelper.updateBackground();
      handleBackgroundActives();
      roomViewHelper.updateMask();

      await roomViewHelper.updateCameraShot(temproomconfigstate.activeshot);
      roomViewHelper.updateShadow();
      roomViewHelper.updateGizmo();
      updateAllObjectsInScene({ ...temproomconfigstate.objectstate });

      setshotReady(true);
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const changeLight = async (newlightname, lightIntensity) => {
    let intensity = lightIntensity ? Math.round(lightIntensity / 10) * 10 : null;
    if (
      roomViewHelper.currentState.light === newlightname &&
      (intensity ?
        intensity === roomViewHelper.lightIntensity : true)
    ) {
      setshotReady(true);
      return;
    }
    await makeTransitionCanvas();
    settransition(1);
    const temproomconfigstate = roomConfigReducerHandlers.changeLight({
      state: { ...roomconfigstate },
      payload: { activelight: newlightname }
    });
    dispatchRoomConfig({
      type: "CHANGE_LIGHT",
      precalc: { ...temproomconfigstate }
    });

    await initShot({ light: newlightname, view: temproomconfigstate.activeview });
    if (!intensity && roomViewHelper.currentAssets.lightIntensity && roomViewHelper.currentAssets.lightIntensity >= 0)
      dispatchRoomConfig({
        type: "CHANGE_LIGHT_INTENSITY",
        payload: roomViewHelper.currentAssets.lightIntensity
      });
    settransition(1);
    setTimeout(() => {
      settransition(0);
    }, 1000);

    roomViewHelper.updateBackground({ lightIntensity: intensity });
    handleBackgroundActives();
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
    updateAllObjectsInScene({ ...temproomconfigstate.objectstate });

    setshotReady(true);
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: false
    });
  };

  const changeView = async temproomconfigstate => {
    await makeTransitionCanvas();
    settransition(1);
    await initShot({ view: temproomconfigstate.activeview });
    setTimeout(() => {
      settransition(0);
    }, 1000);
    roomViewHelper.updateBackground();
    handleBackgroundActives();
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
    updateAllObjectsInScene({ ...temproomconfigstate.objectstate });

    setshotReady(true);
  };

  const makeTransitionCanvas = async (roomview = roomViewHelper) => {
    let sceneobjectstate = Object.fromEntries(
      Object.entries(roomconfigstate.objectstate).filter(object => {
        return (
          object[1].isobjectinscene &&
          (object[1].mystic ? (object[1].mystic === "show" ? true : false) : true)
        );
      })
    );
    const sortedobjectsarr = Object.entries(sceneobjectstate)
      .sort((a, b) => a[1].zindex - b[1].zindex)
      .map(el => {
        return { [el[0]]: el[1] };
      });
    const sortedobjects = sortedobjectsarr.reduce((obj, item) => {
      obj[Object.keys(item)[0]] = Object.values(item)[0];
      return obj;
    }, {});
    await roomview.makeTransitionCanvas({
      objects: sortedobjects
    });
  };

  const updateAllObjectsInScene = objectstate => {
    let sceneobjectstate = Object.fromEntries(
      Object.entries(objectstate).filter(object => object[1].isobjectinscene)
    );
    roomViewHelper.updateObjects({ ...sceneobjectstate });
  };

  const mouseObjectTracker = e => {
    if (
      !inputoncarpet &&
      roomconfigstate.roomelements.objects &&
      Object.keys(roomconfigstate.roomelements.objects).length > 0
    ) {
      const { left, top, width, height } = document
        .getElementById("inputcontainer")
        .getBoundingClientRect();
      const { x, y } = { x: e.clientX - left, y: e.clientY - top };
      const resolutionx = roomViewHelper.dimension.width / width;
      const resolutiony = roomViewHelper.dimension.height / height;
      let hoveredObjInd = null;
      try {
        Object.keys(roomconfigstate.objectstate).forEach((objectname, index) => {
          if (!roomconfigstate.objectstate[objectname].mystic) return;
          let objectindex = roomconfigstate.objectstate[objectname].order - 1;
          let c = objCanvasContainerRef.current.children[objectindex].getContext("2d");
          let p = c.getImageData(x * resolutionx, y * resolutiony, 100, 100).data;
          const alphaArr = p.filter((p1each, index) => (index + 1) % 4 === 0);
          const isObjectCheck = element => element > 250;
          const isobject = alphaArr.some(isObjectCheck);
          if (isobject) {
            if (!hoveredObjInd || hoveredObjInd < objectindex) hoveredObjInd = objectindex;
          } else {
            document
              .getElementById("objcontainer")
              .children[objectindex].classList.remove("objcontainer__objcanvas--trans");
          }
        });
        if (hoveredObjInd) {
          document
            .getElementById("objcontainer")
            .children[hoveredObjInd].classList.add("objcontainer__objcanvas--trans");
          setshowWand(true);
        } else setshowWand(false);
      } catch (err) { }
    }
  };

  const mouseObjectClick = e => {
    if (!inputmoved) {
      if (
        roomconfigstate.roomelements.objects &&
        Object.keys(roomconfigstate.roomelements.objects).length > 0
      ) {
        try {
          Object.keys(roomconfigstate.objectstate).forEach((objectname, index) => {
            let objectindex = roomconfigstate.objectstate[objectname].order - 1;
            const isobject =
              document
                .getElementById("objcontainer")
                .children[objectindex].className.indexOf("objcontainer__objcanvas--trans") > -1;
            if (isobject) {
              const temproomconfigstate = roomConfigReducerHandlers.toggleMystic({
                state: { ...roomconfigstate },
                payload: { object: objectname }
              });
              dispatchRoomConfig({
                type: "TOGGLE_MYSTIC",
                precalc: { ...temproomconfigstate }
              });
              if (temproomconfigstate.activeview !== roomconfigstate.activeview)
                changeView(temproomconfigstate);
            }
          });
        } catch (err) { }
      }
    }
    setinputmoved(false);
  };

  const handleInputStart = (e, pointer = true) => {
    if (!pointer) return;
    let inputoncarpettmp = roomViewHelper.mouseDownTouchStart(e);
    setinputoncarpet(inputoncarpettmp);
    if (inputoncarpettmp) {
      roomViewHelper.updateShadow({ clear: true });
    }
  };

  const handleinputMove = (e, pointer = true) => {
    if (!pointer) return;
    const { hoveredAtGizmo } = roomViewHelper.mouseDownTouchMove(e);
    if (hoveredAtGizmo) {
      inputCanvasRef.current.style.opacity = 1;
    } else {
      inputCanvasRef.current.style.opacity = 0.5;
    }
    if (inputoncarpet) {
      setinputmoved(true);
      setshowWand(false);
      removeTransState();
    }
  };

  const handleInputEnd = (e, pointer = true) => {
    if (pointer) {
      const opt = roomViewHelper.mouseDownTouchEnd(e);
      const { rotation, position } = opt;
      let option = carpetOptions;
      if (rotation) option = { ...option, rotation };
      if (position) option = { ...option, position };
      if (position || rotation)
        dispatchRoomConfig({
          type: roomConfigActions.SET_CARPET_OPTION,
          payload: option
        });
    }
    if (inputoncarpet && pointer) {
      setinputmoved(false);
      setinputoncarpet(false);
    } else {
      if (!roomViewHelper.moved && roomViewHelper.annotationCanvas) {
        const imgData = roomViewHelper.annotationCanvas
          .getContext("2d")
          .getImageData(e.x, e.y, 1, 1);
        if (!imgData.data.every(data => data === 255 || data === 0)) {
          roomViewHelper.selectedColorCode = imgData.data.slice(0, 3);
          setcolorSelectionPos({ ...e });
        }
      }
    }
  };

  const onRoomColorSwatchClick = color => {
    setcolorSelectionPos({ x: -1, y: -1 });
    roomViewHelper.updateBackground({ dominantColorHex: color.Color });
    roomViewHelper.updateMask();
    roomViewHelper.updateShadow();
  };

  const handleColorSwatchClick = (color, objectname) => {
    const temproomconfigstate = roomConfigReducerHandlers.setActiveColor({
      state: { ...roomconfigstate },
      payload: { object: objectname, color: color.Color }
    });
    dispatchRoomConfig({
      type: "SET_ACTIVE_COLOR",
      precalc: { ...temproomconfigstate }
    });
    const objectConfig = roomconfigstate.roomelements.objects[objectname];
    const objectPosition = roomconfigstate.activedata[objectname];
    const { passive, colorCode } = objectConfig;
    if (!passive)
      roomViewHelper.updateObjects({ [objectname]: temproomconfigstate.objectstate[objectname] });
    else {
      if (colorCode) {
        roomViewHelper.selectColorCode(colorCode);
      } else if (objectPosition) {
        const x = inputCanvasRef.current.width * objectPosition[0];
        const y = inputCanvasRef.current.height * objectPosition[1];
        roomViewHelper.selectColorAnnotation({ x, y });
      }
      if (colorCode || objectPosition) {
        roomViewHelper.updateBackground({
          dominantColorHex: color.Color,
          retainActiveColors: true
        });
        roomViewHelper.updateMask();
        roomViewHelper.updateShadow();
      }
    }
  };

  const removeTransState = (objectname = "all") => {
    if (objectname === "all") {
      var elems = document.getElementsByClassName("objcontainer__objcanvas--trans");

      elems.length > 0 &&
        Array.from(elems).forEach(element => {
          element.classList.remove("objcontainer__objcanvas--trans");
        });
      setshowWand(false);
      return;
    }
    let objectindex = roomconfigstate.objectstate[objectname].order - 1;
    document
      .getElementById("objcontainer")
      .children[objectindex].classList.remove("objcontainer__objcanvas--trans");
    setshowWand(false);
  };

  const handleLightSwitch = objectname => {
    const temproomconfigstate = roomConfigReducerHandlers.changeLightSwitch({
      state: { ...roomconfigstate },
      payload: { object: objectname }
    });
    dispatchRoomConfig({
      type: "TOGGLE_LIGHT_SWITCH",
      precalc: { ...temproomconfigstate }
    });
    if (temproomconfigstate.activeview !== roomconfigstate.activeview)
      changeView(temproomconfigstate);
  };
  window.downloadRendered3dIllinIllView = async () => {
    if (designDetailState.loading) return;
    const mime = "jpg";
    const downloadName = `${designDetailState.designName} in ${roomName}.${mime}`;
    const { dims, roomType = defaultRoomType } = config;
    const { width, height } = dims;
    const bgCanvas = createCanvas(width, height);
    const threeCanvas = createCanvas(width, height);
    const maskCanvas = createCanvas(width, height);
    const shadowCanvas = createCanvas(width, height);
    const container = { clientWidth: width, clientHeight: height };
    const inputCanvas = createCanvas(width, height);
    const transitionCanvas = createCanvas(width, height);
    const objCanvasContainer = document.createElement("div");
    roomconfigstate.roomelements.objects &&
      Object.keys(roomconfigstate.roomelements.objects).forEach((object, index) => {
        const objCanvas = createCanvas(width, height);
        objCanvasContainer.appendChild(objCanvas);
      });
    const canvasConfig = {
      bgCanvas,
      threeCanvas,
      maskCanvas,
      shadowCanvas,
      container,
      inputCanvas,
      transitionCanvas,
      objCanvasContainer
    };
    const rh = new RoomViewHelper();
    rh.initCanvas(canvasConfig);
    rh.initConfig({ baseUrl, config, files }, 1);

    const isinitialized = rh.preinitShot({
      shot: roomconfigstate.activeshot,
      light: roomconfigstate.activelight,
      view: roomconfigstate.activeview
    });
    if (!isinitialized) {
      await Promise.all(rh.initShot());
    }
    const dominantColorHex = getDominantColor(designDetailState.designDetails);
    rh.updateBackground();
    if (roomViewHelper.currentActiveColors) {
      roomViewHelper.currentActiveColors.forEach(col => {
        const { dominantColorHex, annotationColor } = col;
        rh.selectedColorCode = annotationColor;
        rh.updateBackground({ dominantColorHex, retainActiveColors: true });
      });
    }

    rh.updateMask();
    await rh.updatethreeCanvas(roomconfigstate.activeshot);
    rh.setTileDetails(designDetailState.tileDetails);
    if (customDesignUrl?.url) {
      await rh.renderDesignFromCustomUrl({
        customUrl: customDesignUrl.url, physicalWidth: customDesignUrl.physicalWidCms,
        physicalHeight: customDesignUrl.physicalHgtCms
      });
    } else {
      await rh.renderDesign({ designDetails, designPath, hash });
    }
    if (roomType === defaultRoomType) {
      const objectConfig = roomViewHelper.threeView.getObjectConfig();
      if (objectConfig) {
        rh.threeView.carpetMesh.position.copy(objectConfig.position);
        rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
        rh.threeView.render();
      }
    }
    await rh.renderFloor(floor);
    await rh.updateShadow();
    rh.updateObjects({ ...roomconfigstate.objectstate });
    // for (const objectname in roomconfigstate.objectstate) {
    //   const objectConfig = roomconfigstate.roomelements.objects[objectname];
    //   const objectPosition = roomconfigstate.activedata[objectname];
    //   const { passive, colorCode } = objectConfig;
    //   if (passive) {
    //     if (colorCode) {
    //       rh.selectColorCode(colorCode);
    //     } else if (objectPosition) {
    //       const x = inputCanvasRef.current.width * objectPosition[0];
    //       const y = inputCanvasRef.current.height * objectPosition[1];
    //       rh.selectColorAnnotation({ x, y });
    //     }
    //     if (colorCode || objectPosition) {
    //       await rh.updateBackground({
    //         dominantColorHex: roomconfigstate.objectstate[objectname].active
    //       });
    //       await rh.updateMask();
    //       await rh.updateShadow();
    //     }
    //   }
    // }
    await makeTransitionCanvas(rh);
    if (filterData) {
      await applyPresetOnCanvas(transitionCanvas, presetsMapping[filterData]());
    }
    if (tiltShiftFilter.active) {
      await roomViewHelper.tiltShift(transitionCanvas, tiltShiftFilter);
    }
    rh.downloadRendering(downloadName, mime);
    return Promise.resolve();
  };

  const getSortedKeys = object => {
    let unsortedobjects = object;
    const sortedobjectsarr = Object.entries(unsortedobjects)
      .sort((a, b) => a[1].order - b[1].order)
      .map(el => {
        return [el[0]];
      });
    return sortedobjectsarr;
  };

  const reset = () => {
    const newCarpetOptions = {
      ...carpetOptions,
      position: config.scene1.surface1.position,
      rotation: config.scene1.surface1.rotation
    };
    dispatchRoomConfig({
      type: roomConfigActions.SET_CARPET_OPTION,
      payload: newCarpetOptions
    });
  };

  useEffect(() => {
    if (resetTrigger > 0) {
      reset();
    }
  }, [resetTrigger]);

  return (
    <React.Fragment>
      {roomconfigstate.roomelements.lights &&
        Object.keys(roomconfigstate.roomelements.lights).length > 0 && (
          <DayNightSlider
            isIdle={isIdle}
            handledayState={(val, int) => changeLight(val)}
            lights={Object.keys(roomconfigstate.roomelements.lights)}
            handleNightOpacity={(val, int) => changeLight(val, int)}
            hasNightSlider={roomViewHelper.hasDimmer}
            defaultNightOpacity={roomconfigstate.lightIntensity}
          />
        )}
      <div
        className={(showWand ? "studiocontainer--wand " : "") + "studiocontainer"}
        ref={containerRef}
        onMouseOut={() => setshowWand(false)}
      >
        <canvas
          className={"studiocontainer__canvas studiocontainer__bgcanvas"}
          ref={bgCanvasRef}
          style={{
            zIndex: 1
          }}
        />
        <canvas
          className={"studiocontainer__canvas studiocontainer__threecanvas"}
          ref={threeCanvasRef}
          style={{
            zIndex: 2,
            pointerEvents: roomconfigstate.mouseControls ? "all" : "none",
            visibility: designDetailState.loading ? "hidden" : "inherit"
          }}
        />
        <canvas
          className={"studiocontainer__canvas studiocontainer__maskcanvas"}
          ref={maskCanvasRef}
          style={{
            zIndex: 3
          }}
        />
        <canvas
          className={"studiocontainer__canvas studiocontainer__shadowcanvas"}
          ref={shadowCanvasRef}
          style={{
            zIndex: 4,
            display: roomconfigstate.hasShadow ? "block" : "none"
          }}
        />
        <div
          className={"studiocontainer__objcontainer objcontainer"}
          id={"objcontainer"}
          ref={objCanvasContainerRef}
          style={{
            display:
              roomconfigstate.objectstate && Object.keys(roomconfigstate.objectstate).length > 0
                ? "block"
                : "none"
          }}
        >
          {roomconfigstate.objectstate &&
            getSortedKeys(roomconfigstate.objectstate).map((object, index) => {
              return (
                <canvas
                  key={index}
                  style={{
                    display: roomconfigstate.objectstate[object].isobjectinscene ? "block" : "none",
                    zIndex: roomconfigstate.objectstate[object].zindex,
                    opacity: roomconfigstate.objectstate[object].mystic
                      ? roomconfigstate.objectstate[object].mystic === "show"
                        ? 1
                        : 0
                      : 1
                  }}
                  className={"objcontainer__objcanvas"}
                />
              );
            })}
        </div>
        <div
          className={"studiocontainer__inputcontainer inputcontainer"}
          id={"inputcontainer"}
          ref={inputCanvasContainerRef}
          onMouseMove={mouseObjectTracker}
          onClick={mouseObjectClick}
          style={{
            width: dimensions.width,
            height: dimensions.height
          }}
        >
          <InputCanvas
            style={{ opacity: 0.5 }}
            className={classNames("inputcontainer__inputcanvas", {
              isidle: isIdle,
              cameraactive: cameraactive
            })}
            zIndex={50}
            pointerEvent
            ref={inputCanvasRef}
            onStart={e =>
              handleInputStart(
                e,
                roomData.category.toLowerCase() !== "carpet in the making" &&
                roomData.category.toLowerCase() !== "photographic views"
              )
            }
            onMove={e =>
              handleinputMove(
                e,
                roomData.category.toLowerCase() !== "carpet in the making" &&
                roomData.category.toLowerCase() !== "photographic views"
              )
            }
            onEnd={e =>
              handleInputEnd(
                e,
                roomData.category.toLowerCase() !== "carpet in the making" &&
                roomData.category.toLowerCase() !== "photographic views"
              )
            }
            trackMouseDown={false}
          />
        </div>
        <div
          className={classNames(
            {
              transitioncanvas__renderfromsavedstate: renderfromsavedstate,
              "studiocontainer__transitioncanvas--fadeCross": transition === 1
            },
            "transitioncanvas-container"
          )}
        >
          <canvas
            className={"studiocontainer__transitioncanvas"}
            ref={transitionCanvasRef}
            style={{
              zIndex: 10
            }}
          />
        </div>

        <canvas
          style={{
            zIndex: 15,
            display:
              !inputoncarpet && (tiltShiftFilter.active || filterData) && showEffectCanvas
                ? "block"
                : "none"
          }}
          className={"studiocontainer__effectCanvas"}
          ref={effectCanvasRef}
        // onClick={handleBlurCenter}
        />
        <Fade
          show={!cameraactive && (shotReady || transition === 1)}
          className={"studiocontainer__optionscontainer optionscontainer"}
        >
          <div
            className={"optionscontainer__pseudoWrapper "}
            id={"photoscene-optionscontainer"}
            style={{
              width: dimensions.width,
              height: dimensions.height,
              display: shotReady ? "block" : "none"
            }}
          >
            {/* teleportation points in a shot */}
            {roomconfigstate.teleportpoints &&
              Object.keys(roomconfigstate.teleportpoints).map((shotkey, index) => {
                return (
                  <div
                    key={index}
                    title={shotkey}
                    className={classNames("optionscontainer__shotteleporter", {
                      "at-idle-fadeout": isIdle
                    })}
                    onMouseOver={() => removeTransState()}
                    style={{
                      left: `${roomconfigstate.teleportpoints[shotkey][0]}%`,
                      top: `${roomconfigstate.teleportpoints[shotkey][1]}%`
                    }}
                  >
                    <CircularButton
                      icon={"viewing-height"}
                      onClick={() => changeShot(shotkey)}
                      className="circular-button"
                      size="small"
                    />
                  </div>
                );
              })}

            {/* Active objects trigger points in a shot */}
            <ColorSelection
              isIdle={isIdle}
              removeTransState={removeTransState}
              handleColorSwatchClick={handleColorSwatchClick}
            />

            {/* Light Sources Switch buttons in a Light Setting */}
            {Object.keys(roomconfigstate.lightswitchpoints).map((lightsourcekey, index) => {
              return (
                <div
                  key={index}
                  title={lightsourcekey}
                  className={classNames(
                    "optionscontainer__lightSwitch",
                    {
                      mysticsource: roomconfigstate.objectstate[lightsourcekey].mystic === "hide",
                      "at-idle-fadeout": isIdle
                    },
                    "optionscontainer__lightSwitch" +
                    (roomconfigstate.objectstate[lightsourcekey].lightSwitch === "on"
                      ? "__on"
                      : "__off ")
                  )}
                  style={{
                    left: `${roomconfigstate.lightswitchpoints[lightsourcekey][0]}%`,
                    top: `${roomconfigstate.lightswitchpoints[lightsourcekey][1]}%`
                  }}
                  onClick={() => handleLightSwitch(lightsourcekey)}
                  onMouseOver={() => removeTransState(lightsourcekey)}
                ></div>
              );
            })}

            {/* {tiltShiftFilter.active && (
              <BlurCircle
                left={`${tiltShiftFilter.options.centerAt.x * 100}%`}
                top={`${tiltShiftFilter.options.centerAt.y * 100}%`}
                handleDontBLurRad={val =>
                  settiltShiftFilter({
                    ...tiltShiftFilter,
                    options: { ...tiltShiftFilter.options, dontBlur: val }
                  })
                }
              />
            )} */}
          </div>
        </Fade>
        {/* <AtSpinnerOverlay show={!shotReady} /> */}
      </div>
      <ViewpointDialog
        baseUrl={baseUrl}
        shots={roomelements.shots}
        handleChangeShot={changeShot}
        activeshot={activeshot}
        showViewpointDialog={showViewpointDialog}
        handleClose={() => {
          dispatchRoomConfig({
            type: roomConfigActions.SET_SHOW_VP_DIALOG,
            payload: false
          });
        }}
        handleThumbClick={shot => {
          changeShot(shot);
        }}
      />
      {roomData && <IllustrationOptions isIdle={isIdle} roomData={roomData} />}
    </React.Fragment>
  );
};

export default IllustrationView;
