import React, { useEffect, useRef, useState, useCallback } from "react";
// import ReactDOM from "react-dom";
import { Overlay, H5, H2, H4, H6, H3, Tooltip } from "@blueprintjs/core";
import { PayPalScriptProvider, PayPalButtons, FUNDING } from "@paypal/react-paypal-js";
import AtIcon from "../../atoms/AtIcon";
import AtButton from "../../atoms/AtButton";
import { AtSpinner } from "../../atoms/AtSpinner";
import AtDialog from "../../molecules/AtDialog";
import loadImage from "blueimp-load-image";
import UploadPreview from "./../../../UploadPreview/App";
import {
  roomConfigActions,
  useDispatchRoomConfig
} from "../../IllustrationView/roomconfig.reducer";
import HttpClient from "../../../api/httpClient";
import AtDropdown from "../../atoms/AtDropdown";
import { addPhoto } from "./aws-utils";
import { addFolder, changeDesignDims, moveFile, uploadFile } from "../../../api/filemanagerapi";
import AppProvider from "../../../api/appProvider";
import { getExtension, getFilenameWithoutExt } from "../../../utils/utils";
import { createCanvas } from "../../../utils/canvasutils";
import AlertWarning from "../AlertWarning";
import { designDetailActions, useDispatchDesignDetail } from "../../../reducers/designdetails.reducer";
import { useDebounce } from "use-debounce/lib";
import { isRunner, unsupportedDesign } from "../VpsStage";
import { useDispatchDesignList, designListActions as actions, } from "../../../reducers/designstree.reducer";

const paymentAmount = 59;

const tryTimes = 1;

const paymentStatusEnum = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PROCESSING: "PROCESSING"
};

const units = ["inches", "cms"];
const unitFactor = { "inches": 1 / 2.54, "cms": 2.54 };
const unitAbbr = { "inches": "in", "cms": "cm" };
const defaultUnitIndex = 0;

const pileunits = ["mm", "cms"];
const defaultPileUnitIndex = 0;

const maxCarpetDims = {
  inches: 200,
  cms: 500
}

export const nameSeparator = "_~_";

let paymentWindow = null;

// let PayPalButton = window.paypal?.Buttons?.driver("react", { React, ReactDOM });
// window.opener.postMessage('success', "*");

const previewIframeBaseUrl = `${window.location.origin}/modules/uploadpreview`;
// const previewIframeBaseUrl = `http://localhost:3000/`;
// const previewIframeBaseUrl = `http://localhost:3000/`;

function hasTransparentPixel(data) {
  let isTransparent = false;

  for (let index = 0; index < data.length; index += 4) {

    if (data[index + 3] === 0) {
      isTransparent = true;
      break;
    }
  }
  return isTransparent;
}

function countPixels(data) {
  const colorCounts = {};
  let count = 0;
  let colorSeparated = true;
  for (let index = 0; index < data.length; index += 4) {
    const rgba = data[index] + "," + data[index + 1] + "," + data[index + 2] + "," + data[index + 3];
    if (!colorCounts[rgba]) {
      colorCounts[rgba] = 1;
      count++;
    }
    if (count >= 100) {
      colorSeparated = false;
      break;
    }
  }
  return colorSeparated;
}

const toFixed = (num) => {
  num = Number(num);
  let returnval = num;
  if (Math.trunc(num) === num) returnval = Number(num);
  else returnval = Number(num.toFixed(2));
  return returnval;
}

const estimateRugPhotoDims = (canvas) => {
  let maxW = 10 * 12;
  let maxH = 16 * 12;
  let physicalW = Math.min(canvas.width / 10, maxW);
  let physicalH = physicalW * canvas.height / canvas.width;
  if (physicalH > maxH) {
    physicalH = maxH;
    physicalW = physicalH * canvas.width / canvas.height;
  }
  return ({ width: physicalW, height: physicalH })
}

const UploadPlatform = props => {
  const { show, handleClose, editDesign } = props;
  const [eFile, seteFile] = useState(null);
  const [uploadedDesign, setuploadedDesign] = useState(null);
  const [showUnsupportedDisclaimer, setshowUnsupportedDisclaimer] = useState(false); //false , 1 for irregular , 2 for runner
  const [showPngToJpegDisclaimer, setshowPngToJpegDisclaimer] = useState(false);
  const [showJpegDisclaimer, setshowJpegDisclaimer] = useState(false);
  const [draggingFile, setdraggingFile] = useState(0);//  0 : none, 1: left input zone, 2: right preview drop zone
  const [alertMessage, setalertMessage] = useState(null);
  const [token, settoken] = useState('');
  const [useToken, setuseToken] = useState(false);
  const [tokenMsg, settokenMsg] = useState({ status: null, msg: null });
  const [progressPercent, setprogressPercent] = useState(-1);
  const [customDesignDetails, setcustomDesignDetails] = useState({
    w: 96,
    h: 120,
    kl: 1,
    unit: units[defaultUnitIndex],
    ph: 6,
    punit: pileunits[defaultPileUnitIndex]
  });
  const [debouncedDesignDetails] = useDebounce(customDesignDetails, 100);
  const [previewRendering, setpreviewRendering] = useState(false);
  const [designUploadSuccess, setdesignUploadSuccess] = useState(false);
  const [designFinalUploading, setdesignFinalUploading] = useState(null);
  const dispatchRoomConfig = useDispatchRoomConfig();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const dispatch = useDispatchDesignList();

  const inputRef = useRef(null);

  const validImageTypes = ['image/jpeg'];
  const validcarpetTypes = ['png', 'ctfx'];

  const reset = (resetdesign = true) => {
    if (resetdesign)
      setuploadedDesign(null);
    else if (uploadedDesign?.url) {
      setuploadedDesign({ file: uploadedDesign.file });
    }
    setdesignUploadSuccess(false);
    setdesignFinalUploading(null);
    setprogressPercent(-1);
    settokenMsg({ status: null, msg: null });
    setuseToken(false);
    settoken('');
    setalertMessage(null);
    setshowPngToJpegDisclaimer(false);
    setshowJpegDisclaimer(false);
    setshowUnsupportedDisclaimer(false);
  }

  useEffect(() => {
    if (!show) {
      seteFile(null);
      reset();
    }
  }, [show])

  useEffect(() => {
    if (!editDesign?.id) {
      reset();
      return;
    }
    let designDetails = editDesign;

    (async () => {
      reset();

      setprogressPercent(30);
      if (!designDetails) return;
      const keyType = designDetails.isJPEG ? AppProvider.keys.jpeg : AppProvider.keys.ctf;
      AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
      let canvas;
      if (designDetails.isJPEG) {
        canvas = await AppProvider.getRenderedDesign({ designDetails: designDetails.Props, fullpath: designDetails.fullpath, zoom: 1 });
      }
      else {
        canvas = await AppProvider.getRenderedDesign({ designDetails: designDetails.Props, fullpath: designDetails.fullpath, zoom: 2 });
      }

      if (!designDetails) return;
      setprogressPercent(70);
      const isTransparent = designDetails.Props.IsIrregular;
      if (isTransparent)
        setshowUnsupportedDisclaimer(unsupportedDesign.IRREGULAR);
      canvas.toBlob(function (blob) {
        if (!designDetails) return;
        const url = URL.createObjectURL(blob);
        setprogressPercent(100);
        setcustomDesignDetails({
          ...customDesignDetails,
          w: toFixed(designDetails.Props.PhysicalWidth),
          h: toFixed(designDetails.Props.PhysicalHeight),
          kl: designDetails.Props.KLRatio,
          unit: designDetails.Props.Unit === "in" ? units[0] : units[1]
        });
        setTimeout(() => {
          setuploadedDesign({ url: url, name: designDetails.label, file: new File([blob], designDetails.label), uploadedPath: designDetails.fullpath });
        }, 500);
        AppProvider.setActiveKey(null, null, true); //restoring previous key
      });
    })()


    return () => {
      designDetails = null;
    }

  }, [editDesign])

  useEffect(() => {
    if (!uploadedDesign?.url || !debouncedDesignDetails?.w || !debouncedDesignDetails?.h) {
      resetPreviewDesign();
      return;
    }
    let msg = {
      design: uploadedDesign.url,
      width: `${debouncedDesignDetails.w * (debouncedDesignDetails.unit === "cms" ? 1 : 2.54)}`,
      height: `${debouncedDesignDetails.h * (debouncedDesignDetails.unit === "cms" ? 1 : 2.54)}`
    }
    let iframeEl = document.getElementById("previewIframe");
    if (iframeEl) {
      iframeEl.contentWindow.postMessage(msg, "*");
    }
  }, [uploadedDesign, debouncedDesignDetails])

  const resetPreviewDesign = () => {
    let iframeEl = document.getElementById("previewIframe");
    if (!iframeEl) return;
    iframeEl.contentWindow.postMessage({ reset: true }, "*");
  }

  const triggerInput = () => {
    if (inputRef?.current)
      inputRef.current.click();
  };

  const checkTempJpegUpload = async (file) => {
    const keyType = AppProvider.keys.jpeg;
    AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
    const albumPhotosKey = encodeURIComponent(window.userEmail?.replace("@", "") || "default");
    await addFolder(
      {
        key: AppProvider.getApiKey(AppProvider.keyScopes.design),
        path: "/Designs/Internal/Uploads/Temp/" + albumPhotosKey,
      }
    )
    const res = await tryUpload(uploadFile,
      {
        key: AppProvider.getApiKey(AppProvider.keyScopes.design),
        file: file,
        path: "/Designs/Internal/Uploads/Temp/" + albumPhotosKey,
      }
      , tryTimes);

    AppProvider.setActiveKey(null, null, true); // set previous key
    if (!res) {
      setalertMessage("Could not upload the file. Please check your internet connection and that the name of the uploaded file only consists of english characters or numbers.");
      return false;
    }
    else {
      return true;
    }
  }

  useEffect(() => {
    if (!eFile) return;
    let uploadedImage = eFile;

    const fileType = uploadedImage['type'];
    const fileExt = getExtension(uploadedImage.name);

    if (!validImageTypes.includes(fileType) && !validcarpetTypes.includes(fileExt.toLowerCase())) {
      setalertMessage("Sorry, we only support .JPG, .PNG, or .CTFX files. Please refer to the upload guide on the left panel for more information.");
      return;
    }
    if (uploadedImage.size * 0.000001 > 15) {
      setalertMessage("Sorry, we only support files up to 15MB. Please try uploading another file.");
      return;
    }

    (async () => {
      reset(false);
      let fileNameIndex = 1;
      let uploadedImageNameLabel = getFilenameWithoutExt(uploadedImage.name);
      let uploadName = uploadedImageNameLabel;
      if (uploadName.length > 20) uploadName = uploadedImageNameLabel.substring(0, 20);

      const iter = (checkname, index) => {
        if (window.usedFileNames?.length && window.usedFileNames.includes(checkname)) {
          uploadName = `${uploadedImageNameLabel}_${index}`;
          iter(uploadName, index + 1);
        }
        else {
          let newuploadedImage = new File([uploadedImage], uploadName + `${nameSeparator}${Date.now()}` + "." + fileExt, { type: fileType });
          uploadedImage = newuploadedImage;
          window.usedFileNames.push(uploadName);
        }
      };
      if (!uploadedImage) return;
      iter(uploadName, fileNameIndex);

      if (validImageTypes.includes(fileType)) {
        setprogressPercent(30);
        if (!uploadedImage) return;
        let file = uploadedImage;
        let canUpload = await checkTempJpegUpload(file);
        if (!canUpload) return;
        loadImage(
          uploadedImage,
          async canvas1 => {
            let canvas = canvas1;
            if (!uploadedImage) return;
            if (canvas1.width > canvas1.height) {
              // let canvas = createCanvas(canvas1.height, canvas1.width);
              // canvas.drawImage
              await new Promise(resolve => {
                canvas = document.createElement("canvas");
                var ctx = canvas.getContext("2d");
                const image = canvas1;
                const degrees = 90;
                if (degrees == 90 || degrees == 270) {
                  canvas.width = image.height;
                  canvas.height = image.width;
                } else {
                  canvas.width = image.width;
                  canvas.height = image.height;
                }
                if (degrees == 90 || degrees == 270) {
                  ctx.translate(image.height / 2, image.width / 2);
                } else {
                  ctx.translate(image.width / 2, image.height / 2);
                }
                ctx.rotate(degrees * Math.PI / 180);
                ctx.drawImage(image, -image.width / 2, -image.height / 2);
                canvas.toBlob(blob => {
                  file = new File([blob], uploadedImage.name, {
                    type: uploadedImage.type,
                  });
                  resolve();
                }, uploadedImage.type)

                // loadImage(
                //   uploadedImage,
                //   async canvas2 => {
                //     canvas = canvas2;
                //     canvas.toBlob(blob => {
                //       file = new File([blob], uploadedImage.name);
                //       resolve();
                //     }, 'image/jpeg', 1)
                //   }, {
                //   canvas: true,
                //   orientation: 6
                // });
              })
            }
            setprogressPercent(70);
            canvas.toBlob(function (blob) {
              if (!uploadedImage) return;
              const { width: physicalW, height: physicalH } = estimateRugPhotoDims(canvas);
              setcustomDesignDetails({
                ...customDesignDetails,
                w: toFixed(physicalW),
                h: toFixed(physicalH),
                unit: units[0]
              });
              var url = URL.createObjectURL(blob);
              setprogressPercent(100);
              setTimeout(() => {
                setshowJpegDisclaimer(true);
                setuploadedDesign({ url: url, name: uploadedImage.name, file: file, isJpeg: true });
              }, 500);
            });
          },
          {
            canvas: true
          }
        );
      }
      else {
        if (validcarpetTypes.includes(fileExt.toLowerCase())) {
          setprogressPercent(10);

          const uploadToTemp = async (uploadcanvas) => {
            let file = null;
            if (uploadcanvas) {
              await uploadcanvas.toBlob(function (blob) {
                file = new File([blob], uploadedImage.name, {
                  type: uploadedImage.type,
                });
              })
            }
            else {
              file = uploadedImage;
            }
            const keyType = AppProvider.keys.ctf;
            if (!uploadedImage) return;
            AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
            const albumPhotosKey = encodeURIComponent(window.userEmail?.replace("@", "") || "default");
            await addFolder(
              {
                key: AppProvider.getApiKey(AppProvider.keyScopes.design),
                path: "/Designs/Internal/Uploads/Temp/" + albumPhotosKey,
              }
            )
            if (!uploadedImage) return;
            setprogressPercent(30);
            const res = await tryUpload(uploadFile,
              {
                key: AppProvider.getApiKey(AppProvider.keyScopes.design),
                file: file,
                path: "/Designs/Internal/Uploads/Temp/" + albumPhotosKey,
              }
              , tryTimes);
            if (!uploadedImage) return;
            setprogressPercent(50);
            if (!res) {
              setalertMessage("Could not upload the file. Please check your internet connection or if your file type is supported.");
              return;
            }

            //to update the key since new design is uploaded
            await AppProvider.autoLogin({ keyType: keyType });

            const fullpath = "Designs/Internal/Uploads/Temp/"
              + albumPhotosKey + "/"
              + uploadedImage.name.replace("." + fileExt, ".ctf");

            const designDetails = await AppProvider.getDesignThumbnails({
              designs: [
                {
                  fullpath: fullpath
                }]
            })
            if (!uploadedImage) return;
            setprogressPercent(50);
            const canvas = await AppProvider.getRenderedDesign({ designDetails: designDetails[0].Props, fullpath: fullpath, zoom: 2 });
            if (!uploadedImage) return;
            setprogressPercent(70);

            const isTransparent = designDetails[0].Props.IsIrregular;
            if (isTransparent)
              setshowUnsupportedDisclaimer(unsupportedDesign.IRREGULAR);

            canvas.toBlob(function (blob) {
              if (!uploadedImage) return;
              const url = URL.createObjectURL(blob);
              setprogressPercent(100);
              setcustomDesignDetails({
                ...customDesignDetails,
                w: toFixed(designDetails[0].Props.PhysicalWidth),
                h: toFixed(designDetails[0].Props.PhysicalHeight),
                kl: designDetails[0].Props.KLRatio,
                unit: designDetails[0].Props.Unit === "in" ? units[0] : units[1]
              });
              setTimeout(() => {
                setuploadedDesign({ url: url, name: uploadedImage.name, file: file, uploadedPath: fullpath });
              }, 500);
              AppProvider.setActiveKey(null, null, true); //restoring previous key
            });
          }

          if (fileType === 'image/png') {
            let file = uploadedImage;
            await loadImage(
              uploadedImage,
              async canvas1 => {
                let canvas = canvas1;
                if (!uploadedImage) return;
                let ctx = canvas.getContext("2d");
                let ctxdata = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
                let colorSeparated = countPixels(ctxdata);
                let isTransparent = hasTransparentPixel(ctxdata);
                if (isTransparent) {
                  setshowUnsupportedDisclaimer(unsupportedDesign.IRREGULAR);
                }
                if (isTransparent && !colorSeparated) {
                  setalertMessage("Irregular carpets are not supported in Virtual Rug Studio currently. Please try a different rug to continue.");
                  return;
                }
                await new Promise(resolve => {
                  if (canvas1.width > canvas1.height) {

                    canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    const image = canvas1;
                    const degrees = 90;
                    if (degrees == 90 || degrees == 270) {
                      canvas.width = image.height;
                      canvas.height = image.width;
                    } else {
                      canvas.width = image.width;
                      canvas.height = image.height;
                    }
                    if (degrees == 90 || degrees == 270) {
                      ctx.translate(image.height / 2, image.width / 2);
                    } else {
                      ctx.translate(image.width / 2, image.height / 2);
                    }
                    ctx.rotate(degrees * Math.PI / 180);
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);
                  }
                  resolve();

                })
                setprogressPercent(20);

                if ((fileType === 'image/png') && !colorSeparated) {
                  //treat as jpeg
                  await canvas.toBlob(async (blob) => {
                    let newuploadedImage = new File([uploadedImage], uploadedImage.name.replace(".png", "") + ".jpg", { type: "image/jpeg" });
                    uploadedImage = newuploadedImage;
                    file = new File([blob], uploadedImage.name, {
                      type: uploadedImage.type,
                    });

                    if (!uploadedImage) return;
                    let canUpload = await checkTempJpegUpload(file);
                    if (!canUpload) return;
                    setshowPngToJpegDisclaimer(true);
                    setshowJpegDisclaimer(true);
                    const { width: physicalW, height: physicalH } = estimateRugPhotoDims(canvas);
                    setcustomDesignDetails({
                      ...customDesignDetails,
                      w: toFixed(physicalW),
                      h: toFixed(physicalH),
                      unit: units[0]
                    });
                    const url = URL.createObjectURL(file);
                    setprogressPercent(100);
                    setTimeout(() => {
                      setuploadedDesign({ url: url, name: uploadedImage.name, file: file, isJpeg: true });
                    }, 500);
                  }, "image/jpeg");


                } else {
                  uploadToTemp(canvas);
                }
              },
              {
                canvas: true
              }
            )
          }
          else {
            uploadToTemp();
          }



        }

      }
    })()

    return () => {
      uploadedImage = null;
    }
  }, [eFile])

  useEffect(() => {
    if (progressPercent === 100) {
      setTimeout(() => {
        setprogressPercent(-1);
      }, 500);
    }

  }, [progressPercent])

  useEffect(() => {
    if (!customDesignDetails || showUnsupportedDisclaimer === unsupportedDesign.IRREGULAR) return;
    const isrunner = isRunner({ width: customDesignDetails.w, height: customDesignDetails.h });
    if (isrunner) setshowUnsupportedDisclaimer(unsupportedDesign.RUNNER);
    else if (showUnsupportedDisclaimer === unsupportedDesign.RUNNER) setshowUnsupportedDisclaimer(false);
  }, [customDesignDetails])

  const onInputChange = async (e) => {
    setprogressPercent(0);
    const files = e?.target?.files || e?.dataTransfer?.files;
    let uploadedImage = files[0];
    if (!uploadedImage) return;
    seteFile(uploadedImage);

  };


  const changeDesignDetails = (key, value) => {
    setcustomDesignDetails({ ...customDesignDetails, [key]: value });
  };

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: paymentAmount
          }
        }
      ],
      style: {
        layout: "horizontal"
      }
    });
  };

  // const onEditSucess = async () => {
  //   dispatchDesignDetails({
  //     type: designDetailActions.SET_LOADING,
  //     payload: true
  //   });
  //   const keyType = editDesign.isJPEG ? AppProvider.keys.jpeg : AppProvider.keys.ctf;
  //   AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
  //   await changeDesignDims(
  //     {
  //       key: AppProvider.getApiKey(AppProvider.keyScopes.design),
  //       file: uploadedDesign.uploadedPath,
  //       physicalWidth: `${customDesignDetails.w * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
  //       physicalHeight: `${customDesignDetails.h * customDesignDetails.kl * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
  //       unit: "cm"
  //     }
  //   );
  //   await AppProvider.clearCache({ mode: "designs", files: [uploadedDesign.uploadedPath] });
  //   const res = await moveFile(
  //     {
  //       key: AppProvider.getApiKey(AppProvider.keyScopes.design),
  //       oldfile: uploadedDesign.uploadedPath,
  //       newpath: "/Designs/Internal/Uploads/" + albumPhotosKey,
  //     }
  //   );
  //   await AppProvider.autoLogin({ keyType: keyType });

  //   window.getUploadedFile(uploadedDesign.file.name, uploadedDesign.file.type === "image/jpeg");
  //   setdesignUploadSuccess(true);
  // }

  const tryUpload = async (func, args, n, timeout = 0) => {
    const res = await func(args);
    if (!res) {
      if (--n > 0) {
        if (timeout > 0) {
          setTimeout(async () => {
            await tryUpload(func, args, n, timeout);
          }, timeout);
        }
        else {
          await tryUpload(func, args, n, timeout);
        }
      }
    }
    return res;
  }

  const onPaymentSuccess = async () => {
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: true
    });
    const keyType = uploadedDesign.isJpeg ? AppProvider.keys.jpeg : AppProvider.keys.ctf;
    AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
    const albumPhotosKey = encodeURIComponent(window.userEmail?.replace("@", "") || "default");
    await addFolder(
      {
        key: AppProvider.getApiKey(AppProvider.keyScopes.design),
        path: "/Designs/Internal/Uploads/" + albumPhotosKey,
      }
    )

    let file = uploadedDesign.file;
    let width = customDesignDetails.w * (customDesignDetails.unit === "cms" ? 1 : 2.54);
    let height = customDesignDetails.h * (customDesignDetails.unit === "cms" ? 1 : 2.54);
    const isCTFX = getExtension(file.name) === validcarpetTypes[1];
    let isFileChanged = false;

    if (!isCTFX && customDesignDetails.w > customDesignDetails.h) {
      await new Promise(resolve => {
        loadImage(
          uploadedDesign.file,
          async canvas1 => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            const image = canvas1;
            const degrees = 270;
            if (degrees == 90 || degrees == 270) {
              canvas.width = image.height;
              canvas.height = image.width;
            } else {
              canvas.width = image.width;
              canvas.height = image.height;
            }
            if (degrees == 90 || degrees == 270) {
              ctx.translate(image.height / 2, image.width / 2);
            } else {
              ctx.translate(image.width / 2, image.height / 2);
            }
            ctx.rotate(degrees * Math.PI / 180);
            ctx.drawImage(image, -image.width / 2, -image.height / 2);
            canvas.toBlob(blob => {
              isFileChanged = true;
              file = new File([blob], uploadedDesign.name, {
                type: uploadedDesign.file.type,
              });
              resolve();
            }, uploadedDesign.file.type)
          }, {
          canvas: true
        });
      })
    }

    if (isFileChanged) {
      let res;
      if (uploadedDesign.isJpeg) {
        res = await tryUpload(uploadFile,
          {
            key: AppProvider.getApiKey(AppProvider.keyScopes.design),
            file: file,
            path: "/Designs/Internal/Uploads/" + albumPhotosKey,
            // width: width,
            // length: height
          }
          , tryTimes);
      }
      else {
        res = await tryUpload(uploadFile,
          {
            key: AppProvider.getApiKey(AppProvider.keyScopes.design),
            file: file,
            path: "/Designs/Internal/Uploads/" + albumPhotosKey
          }
          , tryTimes);
      }

      if (!res) {
        setalertMessage("Could not upload the file.");
        settoken('');
        setdesignFinalUploading(null);
        settokenMsg({ status: null, msg: null });
        dispatchDesignDetails({
          type: designDetailActions.SET_LOADING,
          payload: false
        });
        return;
      }
      const fileExt = getExtension(uploadedDesign.name);
      const fullpath = "Designs/Internal/Uploads/"
        + albumPhotosKey + "/"
        + uploadedDesign.name.replace("." + fileExt, uploadedDesign.isJpeg ? ".jpg" : ".ctf");
      // if (!uploadedDesign.isJpeg) {
      await changeDesignDims(
        {
          key: AppProvider.getApiKey(AppProvider.keyScopes.design),
          file: fullpath,
          physicalWidth: width,
          physicalHeight: height,
          unit: "cm"
        }
      );
      // }
      await AppProvider.clearCache({ mode: "designs", files: [fullpath] });
    }
    else if (!uploadedDesign.uploadedPath) {
      const res = await tryUpload(uploadFile,
        {
          key: AppProvider.getApiKey(AppProvider.keyScopes.design),
          file: file,
          path: "/Designs/Internal/Uploads/" + albumPhotosKey,
          width: width,
          length: height
        }
        , tryTimes);
      if (!res) {
        setalertMessage("Could not upload the file.");
        settoken('');
        setdesignFinalUploading(null);
        settokenMsg({ status: null, msg: null });
        dispatchDesignDetails({
          type: designDetailActions.SET_LOADING,
          payload: false
        });
        return;
      }
      // await changeDesignDims(
      //   {
      //     key: AppProvider.getApiKey(AppProvider.keyScopes.design),
      //     file: uploadedDesign.uploadedPath,
      //     physicalWidth: `${customDesignDetails.w * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
      //     physicalHeight: `${customDesignDetails.h * customDesignDetails.kl * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
      //     unit: "cm"
      //   }
      // );
      // await AppProvider.clearCache({ mode: "designs", files: [uploadedDesign.uploadedPath] });
    }
    else {
      await changeDesignDims(
        {
          key: AppProvider.getApiKey(AppProvider.keyScopes.design),
          file: uploadedDesign.uploadedPath,
          physicalWidth: `${customDesignDetails.w * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
          physicalHeight: `${customDesignDetails.h * (uploadedDesign.isJpeg ? 1 : customDesignDetails.kl) * (customDesignDetails.unit === "cms" ? 1 : 2.54)}`,
          unit: "cm"
        }
      );
      await AppProvider.clearCache({ mode: "designs", files: [uploadedDesign.uploadedPath] });
      const res = await moveFile(
        {
          key: AppProvider.getApiKey(AppProvider.keyScopes.design),
          oldfile: uploadedDesign.uploadedPath,
          newpath: "/Designs/Internal/Uploads/" + albumPhotosKey,
        }
      );
    }

    //to update the key since new design is uploaded
    await AppProvider.autoLogin({ keyType: keyType });

    window.getUploadedFile(uploadedDesign.file.name, uploadedDesign.file.type === "image/jpeg");
    // const toCms = customDesignDetails.unit === "cms" ? 1 : 2.54;
    // dispatchRoomConfig({
    //   type: roomConfigActions.SET_CUSTOM_DESIGN_URL,
    //   payload: { url: uploadedDesign.url, physicalWidCms: customDesignDetails.w * toCms, physicalHGtCms: customDesignDetails.h * toCms }
    // });
    setdesignUploadSuccess(true);
    // handleClose();
  }

  const onApprove = (data, actions) => {
    return actions.order.capture().then(async function (orderData) {
      setdesignFinalUploading('paypal');
      onPaymentSuccess();
    });
  };

  const uploadToken = (e) => {
    e.preventDefault();
    if (tokenMsg.status === 2) return;
    if (!token || token === "") {
      settokenMsg({ status: 0, msg: "Please enter your coupon in the field above." });
      setTimeout(() => {
        settokenMsg(tokenMsg => tokenMsg.status === 0 ? ({ status: null, msg: null }) : tokenMsg)
      }, 3000);
      return;
    }
    settokenMsg({ status: 2, msg: null }); //status 2 is for processing
    HttpClient.get(
      `https://alternative.com.np/atcurrency.php?couponid=${token}&amount=${paymentAmount}`,
      {}
    ).then(response => {
      if (response.data === "success") {
        settokenMsg({ status: 1, msg: "Token applied successfully." });
        setdesignFinalUploading('token');
        onPaymentSuccess();
      }
      else {
        settokenMsg({ status: 0, msg: "Invalid Token." });
      }
    }).catch(error => {
      settokenMsg({ status: 0, msg: "Could not process token. Try again later." });
    });
  }

  const handlePreviewRendering = useCallback(value => {
    setpreviewRendering(value);
  }, []);

  const onDesignEdit = async () => {
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: true
    });
    setdesignFinalUploading('edit');
    const keyType = editDesign.isJPEG ? AppProvider.keys.jpeg : AppProvider.keys.ctf;
    AppProvider.setActiveKey(keyType, [AppProvider.keyScopes.design]);
    const newWid = customDesignDetails.w * (customDesignDetails.unit === "cms" ? 1 : 2.54);
    const newHgt = customDesignDetails.h * (editDesign.isJPEG ? 1 : customDesignDetails.kl) * (customDesignDetails.unit === "cms" ? 1 : 2.54);
    await changeDesignDims(
      {
        key: AppProvider.getApiKey(AppProvider.keyScopes.design),
        file: editDesign.fullpath,
        physicalWidth: Math.floor(newWid),
        physicalHeight: Math.floor(newHgt),
        unit: "cm"
      }
    );
    await AppProvider.clearCache({ mode: "designs", files: [editDesign.fullpath] });
    //to update the key since new design is uploaded
    await AppProvider.autoLogin({ keyType: keyType });

    const finish = async () => {
      const updatedDesign = await AppProvider.getDesignThumbnails({ designs: [editDesign] });
      dispatch({
        type: actions.UPDATE_SINGLE_FILE_PROP,
        payload: { ...editDesign, ...updatedDesign[0] }
      });
      dispatch({
        type: actions.SET_ACTIVE_NODE,
        payload: { ...editDesign, ...updatedDesign[0] }
      });
      setdesignUploadSuccess(true);
    }

    setTimeout(() => {
      finish();
    }, 1000);

  }

  return (
    <>

      <AtDialog
        isOpen={true}
        onClose={() => {
          handleClose();
        }}
        canOutsideClickClose={false}
        className=" illustration-dialog upload-design-dialog"
        overlayClassName={"upload-modal-overlay " + (show || editDesign?.id ? "show" : "hide")}
        size="lg"
      >
        <AtIcon icon="close" size={2.5} onClick={handleClose} />
        {/* <div className="illustration-dialog-header-row">
          <div style={{ display: "flex", alignItems: "center" }}>
            <H2>Please upload your design</H2>
          </div>
         
        </div> */}

        <AlertWarning
          message={alertMessage}
          show={alertMessage && alertMessage !== ""}
          handleClose={() => {
            setalertMessage(null);
            setprogressPercent(-1);
          }}
        />
        {!editDesign?.id && <div className={"show upload-wrapper-parent"}>
          {designUploadSuccess && <>
            <div className="design-detail-card">
              <H4>{uploadedDesign.name.split(nameSeparator)[0]}</H4>
              <div style={{ color: 'var(--text-success)' }}>Design uploaded successfully!</div>
              <div>Your design is now ready.</div>
              <div>You can view it in different settings available in the 'Shot Selection' menu.</div>
              <AtButton text="Continue" style={{ backgroundColor: "var(--brand-primary)", color: 'black', width: "fit-content" }}
                onClick={() => handleClose()}></AtButton>
            </div>
          </>
          }
          {!designUploadSuccess && (uploadedDesign?.file ? (
            <div className={(previewRendering ? "payment-disabled" : "") + " design-detail-card"}>
              <H4>{uploadedDesign.file.name.split(nameSeparator)[0]}</H4>
              {showUnsupportedDisclaimer && <div className={"disclaimer-text" +
                // (showUnsupportedDisclaimer === unsupportedDesign.RUNNER ? " danger" : "")
                (showUnsupportedDisclaimer ? " danger" : "")
              }>
                {showUnsupportedDisclaimer === unsupportedDesign.IRREGULAR ?
                  "Irregular carpets are currently not supported in Virtual Rug Studio. Please try a different rug or change the dimensions to continue."
                  : "Runner carpets are currently not supported in Virtual Rug Studio. Please try a different rug or change the dimensions to continue."
                }
              </div>}
              {showPngToJpegDisclaimer && <div className="disclaimer-text">
                The .PNG file you uploaded has more than 100 colors and will be treated as a rug photo. <span style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => setshowPngToJpegDisclaimer(false)}>Okay</span>
              </div>}
              {showJpegDisclaimer && <div className="disclaimer-text">
                Please note that some illustrations do not support rug photos. <span style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => setshowJpegDisclaimer(false)}>Okay</span>
              </div>}
              {progressPercent > -1 ?
                <div className="upload-wrapper">
                  {eFile && <div className="upload-design-name-row">
                    <H4>{eFile.name}</H4>
                    <AtIcon icon="close" size={2} onClick={() => {
                      seteFile(null);
                      setprogressPercent(-1);
                    }} />
                  </div>}
                  <div className="progress-bar">
                    <div
                      className={
                        // (toastProps.intent === "danger" ? "stop " : "") + 
                        (progressPercent === 100 ? "fadeOut " : "") +
                        "" +
                        "progress-meter"}
                      style={{ width: progressPercent + "%" }}
                    ></div>
                  </div>
                </div>
                :
                <label htmlFor="designUploadAnother">
                  Upload another design
                  <input
                    id="designUploadAnother"
                    type="file"
                    accept=".png,.jpg, .ctfx"
                    onChange={onInputChange}
                    style={{ display: "none" }}
                  />
                </label>
              }


              <div>Please specify the desired size of the rug</div>
              <div className={(((tokenMsg.status === 2) || designFinalUploading === 'token') ? "disabled" : "") + " input-row"}>
                <div>
                  <span>Width</span>
                  <Tooltip
                    isOpen={customDesignDetails.w > maxCarpetDims[customDesignDetails.unit]}
                    position="bottom"
                    content={`Maximum ${maxCarpetDims[customDesignDetails.unit]} ${customDesignDetails.unit} allowed`}
                  >
                    <input
                      disabled={(tokenMsg.status === 2) || designFinalUploading === 'token'}
                      type="number"
                      value={customDesignDetails.w}
                      onChange={e => changeDesignDetails("w", toFixed(e.target.value))}
                    ></input>
                  </Tooltip>
                </div>
                <div>
                  <span>Length</span>
                  <Tooltip
                    isOpen={customDesignDetails.h > maxCarpetDims[customDesignDetails.unit]}
                    position="bottom"
                    content={`Maximum ${maxCarpetDims[customDesignDetails.unit]} ${customDesignDetails.unit} allowed`}
                  >
                    <input
                      disabled={(tokenMsg.status === 2) || designFinalUploading === 'token'}
                      type="number"
                      value={customDesignDetails.h}
                      onChange={e => changeDesignDetails("h", toFixed(e.target.value))}
                    ></input>
                  </Tooltip>
                </div>
                <div>
                  <span>Unit</span>
                  <AtDropdown
                    dropdownItems={units}
                    defaultItemIndex={units.indexOf(customDesignDetails.unit)}
                    onChange={val => {
                      setcustomDesignDetails({
                        ...customDesignDetails,
                        "unit": units[val],
                        "w": toFixed(customDesignDetails.w * unitFactor[units[val]]),
                        "h": toFixed(customDesignDetails.h * unitFactor[units[val]]),
                      });
                    }}
                  />
                </div>
              </div>

              {
                // (!showUnsupportedDisclaimer ||
                //   (showUnsupportedDisclaimer !== unsupportedDesign.RUNNER)) &&
                (!showUnsupportedDisclaimer) &&
                (customDesignDetails.w <= maxCarpetDims[customDesignDetails.unit] &&
                  customDesignDetails.h <= maxCarpetDims[customDesignDetails.unit])
                &&
                <>
                  <div className="pay-disclaimer">
                    Please make sure that the uploaded design looks appropriate in this view.
                  </div>
                  <span>
                    You can then view and save this rug in the offered Virtual Studio settings, all for US ${paymentAmount} only.
                  </span>

                  <div className="action-row">
                    {designFinalUploading === 'paypal' ?
                      <AtButton tertiary onClick={() => { }}
                        style={{ width: '150px !important', height: '25px', backgroundColor: "var(--brand-primary)", color: 'white' }}>
                        {((tokenMsg.status === 2) || designFinalUploading === 'token') && <AtSpinner
                          size="xs"
                        />}
                        <H6 className="at-button-text">Verifying</H6>
                      </AtButton>
                      :
                      <PayPalScriptProvider options={{
                        "client-id": "Ac4Y_6FlUgsg9HsOHta_LgnviTbdIC_wclts1Eg72ROpiEyjeIwraDahc30yEPsliH4yzhlyjX1BFLVO",
                        "currency": "USD"
                        // ,
                        // "disable-funding": "credit,card"
                      }}>
                        <PayPalButtons fundingSource={FUNDING.PAYPAL}
                          style={{ label: "pay" }}
                          createOrder={(data, actions) => createOrder(data, actions)}
                          onApprove={(data, actions) => onApprove(data, actions)} />
                      </PayPalScriptProvider>

                    }



                    {/* {PayPalButton && <PayPalButton
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />} */}
                    <AtButton text="Cancel" tertiary onClick={() => setuploadedDesign(null)}></AtButton>
                  </div>
                  <div>
                    <span className="token-link" onClick={() => setuseToken(true)}>I have a Coupon Code</span>
                  </div>
                </>
              }
              {useToken &&
                <>
                  <div className="input-row action-row">
                    <form onSubmit={uploadToken}>
                      <input
                        type="text"
                        value={token}
                        placeholder="Your Coupon Code"
                        className={(tokenMsg.status === 2) || designFinalUploading === 'token' ? "short" : ""}
                        onChange={e => settoken(e.target.value)}
                      ></input>
                      <AtButton tertiary style={{ width: 'auto', flexDirection: "row" }} type="submit">
                        {((tokenMsg.status === 2) || designFinalUploading === 'token') && <AtSpinner
                          size="xs"
                        />}
                        <H6 className="at-button-text">Use Coupon</H6>
                      </AtButton>
                    </form>

                  </div>
                  {tokenMsg?.msg &&
                    <div style={{ color: tokenMsg.status === 1 ? 'var(--text-success)' : 'var(--text-danger)' }}>{tokenMsg.msg}</div>}

                </>
              }
            </div>
          ) :
            <>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <H3>{progressPercent > -1 ? "Uploading your design..." : "Please upload your design"}</H3>
              </div>
              <div className="upload-wrapper"
                onDragEnter={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setdraggingFile(1);
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setdraggingFile(1);
                }}
                onDrop={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onInputChange(e);
                  setdraggingFile(0);
                }}
                onDragExit={(e) => {
                  setdraggingFile(0);
                }}
                onDragLeave={(e) => {
                  setdraggingFile(0);
                }}
                onMouseLeave={(e) => {
                  setdraggingFile(0);
                }}
                onClick={triggerInput}
              >
                {progressPercent < 0 && <>
                  <AtButton
                    className={draggingFile === 1 ? "dropping" : ""}
                    minimal
                    title="Upload Rug"
                    intent={null}
                    icon="upload"
                    onClick={(e) => {
                      e.stopPropagation();
                      triggerInput(e)
                    }}
                  ></AtButton>
                  <div>
                    <span>Drop your design here</span>
                    {/* <label htmlFor="designUpload"> */}
                    <label> or browse</label>
                    <input
                      id="designUpload"
                      ref={inputRef}
                      type="file"
                      // accept=".png,.jpg, .ctf, .ctfx"
                      // accept=".png,.jpg"
                      accept=".png,.jpg, .ctfx"
                      onChange={onInputChange}
                      style={{ display: "none" }}
                    />
                    {/* </label> */}
                  </div>
                </>}
                {progressPercent > -1 &&
                  <>
                    {eFile && <div className="upload-design-name-row">
                      <H4>{eFile.name}</H4>
                      <AtIcon icon="close" size={2} onClick={() => {
                        seteFile(null);
                        setprogressPercent(-1);
                      }} />
                    </div>}
                    <div className="progress-bar">
                      <div
                        className={
                          // (toastProps.intent === "danger" ? "stop " : "") + 
                          (progressPercent === 100 ? "fadeOut " : "") +
                          "" +
                          "progress-meter"}
                        style={{ width: progressPercent + "%" }}
                      ></div>
                    </div>
                  </>
                }
              </div>
              <div>
                Supported files:
                <ul>
                  <li>Maximum 15 MB</li>
                  <li>JPG for rug photos
                    <br />
                    High quality JPGs with atleast 2500 px width or height look better in closeup shots
                  </li>
                  <li>PNG for color separated designs</li>
                  <li>CTFX for Galaincha designs</li>
                  <li>Recommended minimum size :
                    <br />
                    5′ × 9′ or 150 × 275 cms</li>
                  <li>Recommended maximum size :
                    <br />
                    12′ × 16′ or 370 × 480 cms</li>
                </ul>
              </div>
              <div>
                Your uploaded designs are private and can only be accessed by you.
              </div>

            </>
          )}

        </div>}

        {editDesign?.id && uploadedDesign?.file && <div className="design-detail-card edit-design">
          {designUploadSuccess && <>
            <div className="design-detail-card">
              <H4>{uploadedDesign.name.split(nameSeparator)[0]}</H4>
              <div style={{ color: 'var(--text-success)' }}>Design edited successfully!</div>
              <div>Your design is now ready.</div>
              <div>You can view it in different settings available in the 'Shot Selection' menu.</div>
              <AtButton text="Continue" style={{ backgroundColor: "var(--brand-primary)", color: 'black', width: "fit-content" }}
                onClick={() => handleClose()}></AtButton>
            </div>
          </>
          }
          {!designUploadSuccess &&
            <>
              <H4>{uploadedDesign.name.split(nameSeparator)[0]}</H4>
              {showUnsupportedDisclaimer && <div className={"disclaimer-text" +
                (showUnsupportedDisclaimer ? " danger" : "")
                // (showUnsupportedDisclaimer === unsupportedDesign.RUNNER ? " danger" : "")
              }>
                {showUnsupportedDisclaimer === unsupportedDesign.IRREGULAR ?
                  "Disclaimer: The design you uploaded is irregular. Irregular designs are supported in limited views only."
                  : "Runner carpets are currently not supported in Virtual Rug Studio. Please try a different rug or change the dimensions to continue."
                }
              </div>}
              <div>Change Size</div>
              <div className={(designFinalUploading === 'edit' ? "disabled" : "") + " input-row"}>
                <div>
                  <span>Width</span>
                  <Tooltip
                    isOpen={customDesignDetails.w > maxCarpetDims[customDesignDetails.unit]}
                    position="bottom"
                    content={`Maximum ${maxCarpetDims[customDesignDetails.unit]} ${customDesignDetails.unit} allowed`}
                  >
                    <input
                      disabled={designFinalUploading === 'edit'}
                      type="number"
                      value={customDesignDetails.w}
                      onChange={e => changeDesignDetails("w", toFixed(e.target.value))}
                    ></input>
                  </Tooltip>

                </div>
                <div>
                  <span>Length</span>
                  <Tooltip
                    isOpen={customDesignDetails.h > maxCarpetDims[customDesignDetails.unit]}
                    position="bottom"
                    content={`Maximum ${maxCarpetDims[customDesignDetails.unit]} ${customDesignDetails.unit} allowed`}
                  >
                    <input
                      disabled={designFinalUploading === 'edit'}
                      type="number"
                      value={customDesignDetails.h}
                      onChange={e => changeDesignDetails("h", toFixed(e.target.value))}
                    ></input>
                  </Tooltip>
                </div>
                <div>
                  <span>Unit</span>
                  <AtDropdown
                    dropdownItems={units}
                    defaultItemIndex={units.indexOf(customDesignDetails.unit)}
                    onChange={val => {
                      setcustomDesignDetails({
                        ...customDesignDetails,
                        "unit": units[val],
                        "w": toFixed(customDesignDetails.w * unitFactor[units[val]]),
                        "h": toFixed(customDesignDetails.h * unitFactor[units[val]]),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="action-row">
                <AtButton tertiary style={{ width: 'auto', flexDirection: "row" }} onClick={onDesignEdit}
                  disabled={(designFinalUploading === 'edit') ||
                    // (showUnsupportedDisclaimer && (showUnsupportedDisclaimer === unsupportedDesign.RUNNER)) ||
                    showUnsupportedDisclaimer ||
                    !(customDesignDetails.w <= maxCarpetDims[customDesignDetails.unit] &&
                      customDesignDetails.h <= maxCarpetDims[customDesignDetails.unit])
                  }
                >
                  {designFinalUploading === 'edit' && <AtSpinner
                    size="xs"
                  />}
                  <H6 className="at-button-text">Update Design</H6>
                </AtButton>
              </div>
            </>
          }

        </div>
        }

        <div className="design-preview-row"
          onDragEnter={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setdraggingFile(2);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setdraggingFile(2);
          }}
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // if (!uploadedDesign?.url)
            onInputChange(e);
            setdraggingFile(0);
          }}
          onDragExit={(e) => {
            setdraggingFile(0);
          }}
          onDragLeave={(e) => {
            setdraggingFile(0);
          }}
          onMouseLeave={(e) => {
            setdraggingFile(0);
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!uploadedDesign?.url)
              triggerInput(e)
          }}
        >
          {/* <iframe
            id="previewIframe"
            className="showIframe"
            src=""
            src={`${previewIframeBaseUrl}`}
          ></iframe> */}
          <UploadPreview
            design={uploadedDesign?.url || null}
            showLoaderonInit={editDesign?.fullpath}
            width={`${debouncedDesignDetails.w * (debouncedDesignDetails.unit === "cms" ? 1 : 2.54)}`}
            height={`${debouncedDesignDetails.h * (debouncedDesignDetails.unit === "cms" ? 1 : 2.54)}`}
            onRendering={handlePreviewRendering}
          />
          {!uploadedDesign?.file && <div
            className={(draggingFile === 2 ? "dropping" : "") + " div-carpet-overlay"}></div>}
        </div>
      </AtDialog>
    </>
  );
};

export default UploadPlatform;
