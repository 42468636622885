// import { saveImageToDisk } from "./../../api/nodeApi";
import { readImage, resizeKeepingAspect, convertArrintoDeg, convertUnit } from "../../utils/utils";
import { clearCanvas, createCanvas, downloadImageData } from "../../utils/canvasutils";
import ThreeViewHelper from "./threeviewhelper";
import TileCanvas from "../../tilecanvasnew";
import PhotographicView from "./photographicviewhelper";
import { createBlurImage, createBlurMatrix } from "../../utils/blur";
import { hexToRGB } from "../../utils/colorutils";

const selectActiveObjectByMouseClick = false;

const tileCanvas = new TileCanvas();
export const roomTypes = {
  ILLUSTRATION: "illustration",
  ROOMVIEW: "roomview",
  PHOTOGRAPHIC: "photographic",
  PERSPECTIVE: "perspective"
};
export const defaultRoomType = roomTypes.ROOMVIEW;

function makeUrl() {
  let res = "";
  Array.from(arguments).forEach((argument, index) => {
    if (argument) {
      res = `${res}${index === 0 ? "" : "/"}${argument}`;
    }
  });
  return res;
}
const rgbFromHex = hex => {
  let rgb = [0, 0, 0];
  rgb[0] = parseInt(hex.substring(1, 3), 16);
  rgb[1] = parseInt(hex.substring(3, 5), 16);
  rgb[2] = parseInt(hex.substring(5, 7), 16);
  return rgb;
};

const patchRgb = [45, 24, 18];

export default class RoomViewHelper {
  constructor() {
    this.config = {};
    this.roomAssets = {};
    this.currentState = { shot: null, light: null, view: null };
    this.currentAssets = {};
    this.baseUrl = null;
    this.dimension = { width: null, height: null };
    this.dimensionPixels = { width: null, height: null };
    this.bgImage = null;
    this.maskImage = null;
    this.bgPatchImage = null;
    this.bgPatchShadowImage = null;
    this.shadowImage = null;
    this.highlightImage = null;
    this.canvasArray = Array(4);

    this.carpetURL = "";
    this.fbxLoaded = false;
    this.threeView = new ThreeViewHelper();
    this.phview = new PhotographicView();


    this.realTimeDynamicRendering = true;
    this.savedRoomState = {};
    this.currentActiveColors = [];

    this.zoom = 2;
  }

  initCanvas(options) {
    this.bgCanvas = options.bgCanvas;
    this.threeCanvas = options.threeCanvas;
    this.maskCanvas = options.maskCanvas;
    this.shadowCanvas = options.shadowCanvas;
    this.transitionCanvas = options.transitionCanvas;
    this.effectCanvas = options.effectCanvas;
    this.container = options.container;
    this.inputCanvas = options.inputCanvas;
    this.objCanvasContainer = options.objCanvasContainer;
  }
  clearAll() {
    this.clearAllCanvases();
    this.config = {};
    this.roomAssets = {};
    this.currentState = { shot: null, light: null, view: null };
    this.currentAssets = {};
    this.baseUrl = null;
    this.dimension = { width: null, height: null };
    this.dimensionPixels = { width: null, height: null };
    this.bgImage = null;
    this.maskImage = null;
    this.bgPatchImage = null;
    this.bgPatchShadowImage = null;
    this.shadowImage = null;
    this.highlightImage = null;
    this.canvasArray = Array(4);
    this.blurMatrix = null;

    // this.carpetURL = "";
    // this.fbxLoaded = false;
  }
  clearAllCanvases() {
    const { width, height } = this.dimension;
    clearCanvas(this.bgCanvas, width, height);
    clearCanvas(this.maskCanvas, width, height);
    clearCanvas(this.shadowCanvas, width, height);
    clearCanvas(this.inputCanvas, width, height);
    this.blurMatrix = null;
    // clearCanvas(this.transitionCanvas, width, height);
  }
  unmountClearUp() {
    const { roomType } = this.config;
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        this.phview.reset();
        this.phview.clearScene();
        break;
      default:
        break;
    }
  }
  resetOnDesignChange() {
    const { roomType } = this.config;
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        this.phview.reset();
        if (this.savedRoomState && this.savedRoomState[this.config.name]) {
          delete this.savedRoomState[this.config.name]
        }
        break;
      default:
        break;
    }
    this.savedRoomState = {};
  }

  buildRoomAssets({ config }) {
    this.roomAssets = { ...config.roomAssets };
  }

  initConfig(roomData, resolution = null) {
    const { baseUrl, config, files, local } = roomData;
    this.roomData = roomData;
    this.realTimeDynamicRendering =
      config.realTimeDynamicRendering !== undefined || config.realTimeDynamicRendering !== null
        ? config.realTimeDynamicRendering
        : true;
    this.buildRoomAssets({ config });
    this.baseUrl = baseUrl;
    this.config = config;
    this.files = normalizeDirNames(files);
    this.local = local;
    const illustrationDims = this.config.dims;
    const containerDims = {
      width: this.container.clientWidth,
      height: this.container.clientHeight
    };
    this.currentActiveColors = [];
    this.resolution = resolution || window.devicePixelRatio;
    this.dimensionPixels = resizeKeepingAspect(illustrationDims, containerDims, "fit_inside");
    this.dimension = {
      width: Math.trunc(illustrationDims.width * this.resolution),
      height: Math.trunc(illustrationDims.height * this.resolution)
    };
    // this.dimension = { ...this.dimensionPixels };
    this.resolution = this.dimension.width / this.dimensionPixels.width;
    this.inputCanvas.width = this.dimensionPixels.width;
    this.inputCanvas.height = this.dimensionPixels.height;
  }

  getShotAssets({ shot, light, view }) {
    let commons = {};
    if (this.roomAssets[shot].CommonAssets) {
      commons = { ...commons, ...this.roomAssets[shot].CommonAssets }
    }
    if (light && view) {
      if (this.roomAssets[shot][light].CommonAssets) {
        commons = { ...commons, ...this.roomAssets[shot][light].CommonAssets }
      }
      if (view) {
        return { ...commons, ...this.roomAssets[shot][light][view] };
      }
      else {
        return { ...commons, ...this.roomAssets[shot][light][this.config.defaultview] };
      }
    } else {
      return { ...commons, ...this.roomAssets[shot] };
    }
  }

  updateRoomAssets({ shot, light, view, assets }) {
    if (light && view) {
      if (view) {
        this.roomAssets[shot][light][view] = {};
        this.roomAssets[shot][light][view] = { ...assets };
        if (assets && assets !== {}) this.roomAssets[shot][light][view]["init"] = true;
      } else {
        this.roomAssets[shot][light][this.config.defaultview] = {};
        this.roomAssets[shot][light][this.config.defaultview] = { ...assets };
        if (assets && assets !== {})
          this.roomAssets[shot][light][this.config.defaultview]["init"] = true;
      }
    } else if (shot) {
      this.roomAssets[shot] = {};
      this.roomAssets[shot] = { ...assets };
      if (assets && assets !== {}) this.roomAssets[shot]["init"] = true;
    }
  }

  preinitShot({ shot, light, view }) {
    this.updateRoomAssets({
      ...this.currentState,
      assets: { ...this.currentAssets }
    });

    let x = {
      shot: shot ? shot : this.currentState.shot,
      light: light ? light : this.currentState.light
    };
    if (x.light)
      x.view = !view
        ? this.currentState.view
          ? this.config.roomElements.lights[x.light].views.includes(this.currentState.view)
            ? this.currentState.view
            : this.config.defaultview
          : this.config.defaultview
        : view;

    this.currentState = { ...x };
    this.currentAssets = { ...this.getShotAssets({ ...x }) };

    return this.currentAssets.init;
  }

  initShot() {
    const promises = [];
    const bgUrl = this.currentAssets.background && this.currentAssets.background.url;
    const bgDimUrl = this.currentAssets.backgrounddim && this.currentAssets.backgrounddim.url;
    const bgPatchUrl = this.currentAssets.backgroundpatch && this.currentAssets.backgroundpatch.url;
    const bgPatchGreyUrl = this.currentAssets.backgroundpatchgrey && this.currentAssets.backgroundpatchgrey.url;
    const bgPatchGreyDimUrl = this.currentAssets.backgroundpatchgreydim && this.currentAssets.backgroundpatchgreydim.url;
    const bgPatchShadowUrl =
      this.currentAssets.backgroundpatchshadow && this.currentAssets.backgroundpatchshadow.url;
    const bgPatchShadowDimUrl =
      this.currentAssets.backgroundpatchshadowdim && this.currentAssets.backgroundpatchshadowdim.url;
    const maskUrl = this.currentAssets.mask && this.currentAssets.mask.url;
    const shadowUrl = this.currentAssets.shadow && this.currentAssets.shadow.url;
    const shadowDimUrl = this.currentAssets.shadowdim && this.currentAssets.shadowdim.url;
    const highlightUrl = this.currentAssets.highlight && this.currentAssets.highlight.url;
    const glowUrl = this.currentAssets.glow && this.currentAssets.glow.url;
    const blurmapUrl = this.currentAssets.blurmap && this.currentAssets.blurmap.url;
    const depthImageUrl = this.currentAssets.depthimage && this.currentAssets.depthimage.url;

    let objects = { ...this.currentAssets.objects };

    if (bgUrl && (this.files.includes(bgUrl) || this.local))
      promises.push(
        readImageFromPCorWeb(makeUrl(this.baseUrl, bgUrl), this.config.saveLocal ? `${this.config.name}/${bgUrl}` : null).then(
          img => (this.currentAssets.background.image = img)
        )
      );
    if (bgDimUrl && (this.files.includes(bgDimUrl) || this.local)) {
      promises.push(
        readImageFromPCorWeb(makeUrl(this.baseUrl, bgDimUrl), this.config.saveLocal ? `${this.config.name}/${bgDimUrl}` : null).then(
          img => (this.currentAssets.backgrounddim.image = img)
        )
      );
    }
    if (bgPatchUrl && (this.files.includes(bgPatchUrl) || this.local))
      promises.push(
        this.currentAssets.backgroundpatch.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, bgPatchUrl), this.config.saveLocal ? `${this.config.name}/${bgPatchUrl}` : null).then(img => (this.currentAssets.backgroundpatch.image = img))
      );
    if (bgPatchGreyUrl && (this.files.includes(bgPatchGreyUrl) || this.local))
      promises.push(
        this.currentAssets.backgroundpatchgrey.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, bgPatchGreyUrl), this.config.saveLocal ? `${this.config.name}/${bgPatchGreyUrl}` : null).then(
            img => (this.currentAssets.backgroundpatchgrey.image = img)
          )
      );
    if (bgPatchGreyDimUrl && (this.files.includes(bgPatchGreyDimUrl) || this.local))
      promises.push(
        this.currentAssets.backgroundpatchgreydim.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, bgPatchGreyDimUrl), this.config.saveLocal ? `${this.config.name}/${bgPatchGreyDimUrl}` : null).then(
            img => (this.currentAssets.backgroundpatchgreydim.image = img)
          )
      );
    if (bgPatchShadowUrl && (this.files.includes(bgPatchShadowUrl) || this.local))
      promises.push(
        this.currentAssets.backgroundpatchshadow.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, bgPatchShadowUrl), this.config.saveLocal ? `${this.config.name}/${bgPatchShadowUrl}` : null).then(
            img => (this.currentAssets.backgroundpatchshadow.image = img)
          )
      );
    if (bgPatchShadowDimUrl && (this.files.includes(bgPatchShadowDimUrl) || this.local))
      promises.push(
        this.currentAssets.backgroundpatchshadowdim.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, bgPatchShadowDimUrl), this.config.saveLocal ? `${this.config.name}/${bgPatchShadowDimUrl}` : null).then(
            img => (this.currentAssets.backgroundpatchshadowdim.image = img)
          )
      );
    if (maskUrl && (this.files.includes(maskUrl) || this.local || (maskUrl.includes("blob"))))
      promises.push(
        this.currentAssets.mask.image
          ? Promise.resolve()
          : readImageFromPCorWeb(maskUrl.includes("blob") ? maskUrl : makeUrl(this.baseUrl, maskUrl), this.config.saveLocal ? `${this.config.name}/${maskUrl}` : null).then(
            img => (this.currentAssets.mask.image = img)
          )
      );
    if (shadowUrl && (this.files.includes(shadowUrl) || this.local))
      promises.push(
        this.currentAssets.shadow.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, shadowUrl), this.config.saveLocal ? `${this.config.name}/${shadowUrl}` : null).then(
            img => (this.currentAssets.shadow.image = img)
          )
      );
    if (shadowDimUrl && (this.files.includes(shadowDimUrl) || this.local))
      promises.push(
        this.currentAssets.shadowdim.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, shadowDimUrl), this.config.saveLocal ? `${this.config.name}/${shadowDimUrl}` : null).then(
            img => (this.currentAssets.shadowdim.image = img)
          )
      );
    if (highlightUrl && (this.files.includes(highlightUrl) || this.local))
      promises.push(
        this.currentAssets.highlight.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, highlightUrl), this.config.saveLocal ? `${this.config.name}/${highlightUrl}` : null).then(
            img => (this.currentAssets.highlight.image = img)
          )
      );
    if (glowUrl && (this.files.includes(glowUrl) || this.local))
      promises.push(
        this.currentAssets.glow.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, glowUrl), this.config.saveLocal ? `${this.config.name}/${glowUrl}` : null).then(
            img => (this.currentAssets.glow.image = img)
          )
      );
    if (blurmapUrl && (this.files.includes(blurmapUrl) || this.local))
      promises.push(
        this.currentAssets.blurmap.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, blurmapUrl), this.config.saveLocal ? `${this.config.name}/${blurmapUrl}` : null).then(
            img => (this.currentAssets.blurmap.image = img)
          )
      );
    if (depthImageUrl && (this.files.includes(depthImageUrl) || this.local))
      promises.push(
        this.currentAssets.depthimage.image
          ? Promise.resolve()
          : readImageFromPCorWeb(makeUrl(this.baseUrl, depthImageUrl), this.config.saveLocal ? `${this.config.name}/${depthImageUrl}` : null).then(
            img => (this.currentAssets.depthimage.image = img)
          )
      );

    Object.keys(objects).forEach(object => {
      if (
        objects[object].object &&
        objects[object].object.url &&
        (this.files.includes(objects[object].object.url) || this.local)
      ) {
        promises.push(
          this.currentAssets.objects[object].object.image
            ? Promise.resolve()
            : readImageFromPCorWeb(makeUrl(this.baseUrl, objects[object].object.url), this.config.saveLocal ? `${this.config.name}/${objects[object].object.url}` : null).then(
              img => (this.currentAssets.objects[object].object.image = img)
            )
        );
      }
      if (
        objects[object].objectmask &&
        objects[object].objectmask.url &&
        (this.files.includes(objects[object].objectmask.url) || this.local)
      ) {
        promises.push(
          this.currentAssets.objects[object].objectmask.image
            ? Promise.resolve()
            : readImageFromPCorWeb(makeUrl(this.baseUrl, objects[object].objectmask.url), this.config.saveLocal ? `${this.config.name}/${objects[object].objectmask.url}` : null).then(
              img => (this.currentAssets.objects[object].objectmask.image = img)
            )
        );
      }

      if (
        objects[object].objectshadow &&
        objects[object].objectshadow.url &&
        (this.files.includes(objects[object].objectshadow.url) || this.local)
      ) {
        promises.push(
          this.currentAssets.objects[object].objectshadow.image
            ? Promise.resolve()
            : readImageFromPCorWeb(makeUrl(this.baseUrl, objects[object].objectshadow.url), this.config.saveLocal ? `${this.config.name}/${objects[object].objectshadow.url}` : null).then(
              img => (this.currentAssets.objects[object].objectshadow.image = img)
            )
        );
      }

      if (
        objects[object].patchgrey &&
        objects[object].patchgrey.url &&
        (this.files.includes(objects[object].patchgrey.url) || this.local)
      ) {
        promises.push(
          this.currentAssets.objects[object].patchgrey.image
            ? Promise.resolve()
            : readImageFromPCorWeb(makeUrl(this.baseUrl, objects[object].patchgrey.url), this.config.saveLocal ? `${this.config.name}/${objects[object].patchgrey.url}` : null).then(
              img => (this.currentAssets.objects[object].patchgrey.image = img)
            )
        );
      }

      if (
        objects[object].patchshadow &&
        objects[object].patchshadow.url &&
        (this.files.includes(objects[object].patchshadow.url) || this.local)
      ) {
        promises.push(
          this.currentAssets.objects[object].patchshadow.image
            ? Promise.resolve()
            : readImageFromPCorWeb(makeUrl(this.baseUrl, objects[object].patchshadow.url), this.config.saveLocal ? `${this.config.name}/${objects[object].patchshadow.url}` : null).then(
              img => (this.currentAssets.objects[object].patchshadow.image = img)
            )
        );
      }
    });

    return promises;
  }



  updateBackground(options = {}) {
    const { clear = false, dominantColorHex = null, canvas = this.bgCanvas, retainActiveColors = false } = options;
    const { width, height } = this.dimension;
    if (options.hasOwnProperty('lightIntensity') && options.lightIntensity !== null) {
      this.currentAssets.lightIntensity = options.lightIntensity;
    }


    const bgCtx = canvas.getContext("2d");

    const tempBgCanvas = createCanvas(width, height);
    tempBgCanvas.getContext("2d").drawImage(this.bgCanvas, 0, 0, width, height);

    clearCanvas(canvas, width, height);
    setCanvasDimensions(canvas, this.dimension, this.dimensionPixels);
    if (clear) {
      return "clear";
    }
    if (!this.currentAssets.background) return;

    if (this.currentAssets.backgrounddim?.image) {
      this.hasDimmer = true;
      const bgCtxDrawCanvas = combineDimImage({ image: this.currentAssets.background.image, imagedim: this.currentAssets.backgrounddim.image, width, height, lightIntensity: this.currentAssets.lightIntensity });
      bgCtx.drawImage(bgCtxDrawCanvas, 0, 0, width, height);
    }
    else {
      this.hasDimmer = false;
      bgCtx.drawImage(this.currentAssets.background.image, 0, 0, width, height);
    }



    retainActiveColors && bgCtx.drawImage(tempBgCanvas, 0, 0, width, height);
    if (
      this.currentAssets.backgroundpatch &&
      this.currentAssets.backgroundpatch.image &&
      dominantColorHex
    ) {
      this.annotationCanvas = createCanvas(this.dimensionPixels.width, this.dimensionPixels.height);
      this.annotationCanvas
        .getContext("2d")
        .drawImage(this.currentAssets.backgroundpatch.image, 0, 0, this.dimensionPixels.width, this.dimensionPixels.height);
      const activeColor = rgbFromHex(dominantColorHex);
      const patchCanvas = createCanvas(width, height);
      const patchCtx = patchCanvas.getContext("2d");
      patchCtx.drawImage(this.currentAssets.backgroundpatch.image, 0, 0, width, height);
      let patchData = patchCtx.getImageData(0, 0, width, height);

      if (!this.selectedColorCode) this.selectedColorCode = patchRgb;
      for (let i = 0; i < patchData.data.length; i += 4) {
        if (
          ((patchData.data[i] >= (this.selectedColorCode[0] - 5)) && (patchData.data[i] <= (this.selectedColorCode[0] + 5))) &&
          ((patchData.data[i + 1] >= (this.selectedColorCode[1] - 5)) && (patchData.data[i + 1] <= (this.selectedColorCode[1] + 5))) &&
          ((patchData.data[i + 2] >= (this.selectedColorCode[2] - 5)) && (patchData.data[i + 2] <= (this.selectedColorCode[2] + 5)))
        ) {
          patchData.data[i] = activeColor[0];
          patchData.data[i + 1] = activeColor[1];
          patchData.data[i + 2] = activeColor[2];
        }
        else {
          patchData.data[i] = 128;
          patchData.data[i + 1] = 128;
          patchData.data[i + 2] = 128;
          patchData.data[i + 3] = 0;
        }
      }

      const sel = this.currentActiveColors.findIndex(
        item => item.annotationColor === this.selectedColorCode
      );
      if (sel === -1) {
        this.currentActiveColors.push({
          annotationColor: this.selectedColorCode,
          dominantColorHex
        });
      } else
        this.currentActiveColors[sel] = {
          annotationColor: this.selectedColorCode,
          dominantColorHex
        };

      patchCtx.putImageData(patchData, 0, 0);
      const patchGreyCanvas = createCanvas(width, height);
      const patchGreyCtx = patchGreyCanvas.getContext("2d");
      if (this.currentAssets.backgroundpatchgrey && this.currentAssets.backgroundpatchgrey.image) {
        if (this.currentAssets.backgroundpatchgreydim?.image) {
          const patchGreyCtxCanvas = combineDimImage({ image: this.currentAssets.backgroundpatchgrey.image, imagedim: this.currentAssets.backgroundpatchgreydim.image, width, height, lightIntensity: this.currentAssets.lightIntensity });
          patchGreyCtx.drawImage(patchGreyCtxCanvas, 0, 0, width, height);
        }
        else {
          patchGreyCtx.drawImage(this.currentAssets.backgroundpatchgrey.image, 0, 0, width, height);
        }

      }
      else {
        bgCtx.globalCompositeOperation = "overlay";
      }
      patchGreyCtx.globalCompositeOperation = "overlay";
      patchGreyCtx.drawImage(patchCanvas, 0, 0, width, height);
      if (
        this.currentAssets.backgroundpatchshadow &&
        this.currentAssets.backgroundpatchshadow.image
      ) {
        patchGreyCtx.globalCompositeOperation = "multiply";
        if (this.currentAssets.backgroundpatchshadowdim?.image) {
          const patchGreyShadowCanvas = combineDimImage({ image: this.currentAssets.backgroundpatchshadow.image, imagedim: this.currentAssets.backgroundpatchshadowdim.image, width, height, lightIntensity: this.currentAssets.lightIntensity });
          patchGreyCtx.drawImage(patchGreyShadowCanvas, 0, 0, width, height);
        } else {
          patchGreyCtx.drawImage(this.currentAssets.backgroundpatchshadow.image, 0, 0, width, height);
        }

      }
      patchGreyCtx.globalCompositeOperation = "destination-in";
      patchGreyCtx.drawImage(patchCanvas, 0, 0, width, height);
      bgCtx.drawImage(patchGreyCanvas, 0, 0, width, height);

    }
    else {
      this.annotationCanvas = null;
    }
    return "successfully updated bg";
  }
  async updateCameraShot(shot) {
    // console.log(this.config);
    // console.log(this.config.scene1);
    // console.log(this.config.scene1[this.config.shots[shot]]);
    await this.threeView.changeShot({ ...this.config.scene1[shot] });
    // await this.threeView.setupCarpet({ fbxUrl: "rug.fbx" })
    return;
  }
  async updatethreeCanvas(shot) {
    if (!this.config.scenes) return;
    const scene = this.config.scenes[0];
    const { roomType = defaultRoomType } = this.config;
    const sceneConfig = this.config[scene];

    if (this.roomType && (this.roomType !== roomType)) {
      switch (this.roomType) {
        case roomTypes.ROOMVIEW:
          await this.threeView.clearScene();
          this.phview.reset();
          break;
        case roomTypes.ILLUSTRATION:
          await this.phview.clearScene();
          break;
        default:
          break;
      }
    }

    // this.threeView.init({
    //   canvas: this.threeCanvas,
    //   config: sceneConfig,
    //   shot: shot,
    //   dims: this.dimensionPixels,
    //   resolution: this.resolution,
    //   roomType,
    //   baseUrl: this.baseUrl
    // });
    // this.threeView.clearScene();
    // this.phview.clearScene();
    switch (roomType) {
      // case roomTypes.ILLUSTRATION:
      //   this.threeView.init({
      //     canvas: this.threeCanvas,
      //     config: sceneConfig,
      //     shot: shot,
      //     dims: this.dimensionPixels,
      //     resolution: this.resolution,
      //     roomType,
      //     baseUrl: this.baseUrl
      //   });
      //   return this.threeView.setup3dObject({
      //     fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl)
      //   });
      case roomTypes.ROOMVIEW:
        this.threeView.init({
          canvas: this.threeCanvas,
          config: sceneConfig,
          shot: shot,
          dims: this.dimensionPixels,
          resolution: this.resolution,
          roomType,
          baseUrl: this.baseUrl
        });
        this.roomType = roomTypes.ROOMVIEW;
        return this.threeView.setupSceneObjects();
      case roomTypes.ILLUSTRATION:
        await this.phview.init({
          canvas: this.threeCanvas,
          config: sceneConfig,
          shot: shot,
          dims: this.dimensionPixels,
          resolution: this.resolution,
          roomType,
          baseUrl: this.baseUrl
        });
        this.roomType = roomTypes.ILLUSTRATION;
        return this.phview.setup3dObject({
          fbxUrl: makeUrl(this.baseUrl, sceneConfig.modelUrl)
        });

      default:
        console.warn("ROOMVIEW: no roomtype specified");
        break;
    }
  }
  async renderFloor(options = {}) {
    if (!this.threeView || (this.roomType !== roomTypes.ROOMVIEW)) {
      return;
    }
    if (!options || !options.path) {
      this.threeView.changeFloorVisibility(false)
      return;
    }
    try {
      const floorImage = await readImage(options.path)
      if (!this.floorCanvas) this.floorCanvas = createCanvas(floorImage.width, floorImage.height);
      else {
        this.floorCanvas.width = floorImage.width;
        this.floorCanvas.height = floorImage.height;
      }
      this.floorCanvas.getContext("2d").drawImage(floorImage, 0, 0);
      this.threeView.setFloorTexture({ ...options, floorCanvas: this.floorCanvas });
    } catch (error) {
      this.threeView.setFloorTexture();
    }

  }
  renderDesignFromCustomUrl({ customUrl, physicalWidth, physicalHeight }) {
    return new Promise((resolve, reject) => {
      readImage(customUrl).then(image => {
        const { width, height } = image;
        const designCanvas = createCanvas(width, height);
        const ctx = designCanvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const normapCanvas = createCanvas(width, height);
        const ctxNorm = normapCanvas.getContext("2d")
        ctxNorm.fillStyle = "rgb(127,127,255)";
        ctxNorm.fillRect(0, 0, width, height);
        let PhysicalWidth, PhysicalHeight
        if (!physicalWidth || !physicalHeight) {
          const maxDims = { width: 1200, height: 1500 }
          const { width: newWidth, height: newHeight } = resizeKeepingAspect({ width, height }, maxDims, "fit_inside")
          PhysicalWidth = convertUnit("in", "ft", newWidth / 10)
          PhysicalHeight = convertUnit("in", "ft", newHeight / 10)
        } else {
          PhysicalWidth = convertUnit("cm", "ft", physicalWidth)
          PhysicalHeight = convertUnit("cm", "ft", physicalHeight)
        }
        const designDetails = { Width: width, Height: height, PhysicalWidth, PhysicalHeight, Unit: "ft" }
        const { roomType = roomTypes.ROOMVIEW } = this.config;
        switch (roomType) {
          case roomTypes.ROOMVIEW:
            let textureDrawn = this.threeView.setCarpetTexture({ designDetails, designCanvas, normapCanvas })
            if (!textureDrawn) reject("Could not draw texture");
            this.updateGizmo();
            break;
          case roomTypes.ILLUSTRATION:
            this.phview.setObjectTexture({
              designDetails,
              designCanvas: designCanvas
            });
            break;
        }
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
  renderDesign({ designDetails, designPath, hash }) {
    return new Promise(async (resolve, reject) => {
      this.designDetails = designDetails;
      const { IsIrregular } = designDetails;
      this.designPath = designPath;
      const { roomType = roomTypes.ROOMVIEW } = this.config;
      const { designScale, canvasSize, renderBounds, offset } = { ...this.config[this.config.scenes[0]], designScale: window.globalCarpetScale };
      if (designScale) this.zoom = designScale;
      if (window.InterfaceElements.IsJpeg) this.zoom = 1
      tileCanvas.init({
        tileSize: 256,
        zoom: this.zoom,
        designDetails,
        canvasSize,
        renderBounds,
        offset
      });

      const tileTransparency = this.tileDetails && this.tileDetails[`tileTransparency${this.zoom}`] ? this.tileDetails[`tileTransparency${this.zoom}`] : (IsIrregular ? [] : [1]);
      let commonTileProps = {
        designPath,
        zoom: this.zoom,
        designDetails,
        hash,
        tileTransparency
      };
      switch (roomType) {
        case roomTypes.ROOMVIEW:
          this.threeView.setCarpetTexture({
            designDetails,
            designCanvas: tileCanvas.canvas,
            normapCanvas: tileCanvas.canvasNorm
          });
          this.threeView.setCarpetVisibility(false);
          const { DesignColors } = designDetails;
          tileCanvas.drawCanvasTiles(
            {
              ...commonTileProps,
              drawNormap:
                !DesignColors.every(color => color.PileHeight === DesignColors[0].PileHeight) ||
                tileTransparency.length
            },
            undefined,
            () => {
              this.threeView.updateMap();
              this.threeView.setCarpetVisibility(true);
              this.updateGizmo();

              resolve();
            }
          );
          break;
        case roomTypes.ILLUSTRATION:
          let backrendered, frontrendered;
          const resolvethis = () => {
            if (frontrendered && backrendered) {
              resolve();
            }
          };
          if (this.config[this.config.scenes[0]].surfaces.back) {
            let renderBounds =
              this.config[this.config.scenes[0]].back &&
              this.config[this.config.scenes[0]].back.renderBounds;
            if (!renderBounds && this.config[this.config.scenes[0]].surfaces.front)
              renderBounds = {
                p1: { x: 0, y: 0 },
                p2: { x: 1024, y: 1024 }
              };
            this.phview.setObjectTexture({
              designDetails,
              designCanvas: this.config[this.config.scenes[0]].surfaces.front ? tileCanvas.canvas : null,
              backDesignCanvas: tileCanvas.canvasBack
            });
            tileCanvas.drawCanvasBackTiles(
              {
                ...commonTileProps,
                renderBounds: renderBounds
              },
              undefined,
              () => {
                this.phview.updateMap();
                backrendered = true;
                resolvethis();
              }
            );
          } else {
            backrendered = true;
          }
          if (this.config[this.config.scenes[0]].surfaces.front) {
            const { DesignColors } = designDetails;
            tileCanvas.drawCanvasTiles(
              {
                ...commonTileProps,
                drawNormap:
                  !DesignColors.every(color => color.PileHeight === DesignColors[0].PileHeight) ||
                  tileTransparency.length
              },
              undefined,
              async () => {
                await this.phview.setObjectTexture({
                  designDetails,
                  designCanvas: tileCanvas.canvas,
                  normapCanvas: tileCanvas.canvasNorm
                });
                this.phview.updateMap();
                frontrendered = true;
                resolvethis();
              }
            );
          }
          else {
            frontrendered = true;
            resolvethis();
          }




          break;
        default:
          console.warn("ROOMVIEW: no roomtype specified");
          break;
      }
    });
  }

  setTileDetails(tileDetails) {
    this.tileDetails = tileDetails;
  }
  updateTiles({ designDetails, updateDesignTiles, updateNormapTiles, hash }) {
    if (!this.tileDetails) {
      return Promise.resolve();
    }
    const { roomType = roomTypes.ROOMVIEW } = this.config;
    return new Promise((resolve, reject) => {
      let colorIndex;
      if (updateDesignTiles) colorIndex = updateDesignTiles.colorIndex;
      if (updateNormapTiles) colorIndex = updateNormapTiles.colorIndex;

      this.designDetails = designDetails;

      let colorTileData = null;
      if (colorIndex && colorIndex !== -1) {
        const tileData = this.tileDetails[`colorTileData${this.zoom}`];
        colorTileData = tileData[colorIndex].tiles;
      } else {
        //all tiles
      }
      const tileTransparency = this.tileDetails[`tileTransparency${this.zoom}`];

      const props = {
        tiles: colorTileData,
        zoom: this.zoom,
        designDetails,
        designPath: this.designPath,
        hash,
        tileTransparency
      };

      if (!updateNormapTiles) {
        tileCanvas.designTilesUpdated = true;
        if (roomType === roomTypes.ROOMVIEW) {
          tileCanvas.updateDesignTiles(
            props,
            () => {
              this.threeView.updateMap()
            },
            () => {
              this.threeView.setCarpetTexture({
                designDetails,
                designCanvas: tileCanvas.canvas,
                normapCanvas: tileCanvas.canvasNorm
              });
              this.threeView.updateMap()
              setTimeout(() => {
                resolve();
              }, 500);
            }
          );
        }
        else if (roomType === roomTypes.ILLUSTRATION) {
          if (this.savedRoomState && this.savedRoomState[this.config.name]) {
            delete this.savedRoomState[this.config.name]
          }
          tileCanvas.updateVisTiles(
            props,
            () => {
              this.phview.setObjectTexture({
                designDetails,
                designCanvas: this.config[this.config.scenes[0]].surfaces.front ? tileCanvas.canvas : null,
                backDesignCanvas: tileCanvas.canvasBack
              });
              this.phview.updateMap();
              setTimeout(() => {
                resolve();
              }, 500);

            }
          );
        }

      } else {
        tileCanvas.normapTilesUpdated = true;
        tileCanvas.updateNormapTiles(
          props,
          () => {
            this.threeView.updateMap()
          },
          () => {
            if (roomType === roomTypes.ILLUSTRATION) {
              this.phview.setObjectTexture({
                designDetails,
                designCanvas: this.config[this.config.scenes[0]].surfaces.front ? tileCanvas.canvas : null,
                backDesignCanvas: tileCanvas.canvasBack
              });
              this.phview.updateMap();
              setTimeout(() => {
                resolve();
              }, 500);
            }
            else {
              this.threeView.setCarpetTexture({
                designDetails,
                designCanvas: tileCanvas.canvas,
                normapCanvas: tileCanvas.canvasNorm
              });
              this.threeView.updateMap()
              setTimeout(() => {
                resolve();
              }, 500);
            }
          }
        );
        // this.tileCanvas1x.updateNo(props, () => this.render())
      }
    });
  }
  updateShadowAssetFromSize(size) {
    if (!size || !this.currentAssets.shadow) return Promise.resolve();
    const { key } = size;
    const shad = this.currentAssets.shadow[key];
    if (!shad) return Promise.resolve();

    const setAssets = img => {
      this.currentAssets.shadow.image = img;
      this.currentAssets.shadow.url = img;
    };
    if (shad.url && !shad.image) {
      return readImage(makeUrl(this.baseUrl, shad.url)).then(img => {
        this.currentAssets.shadow[key].image = img;
        setAssets(img);
      });
    } else {
      setAssets(shad.image);
      return Promise.resolve();
    }
  }
  change3dObjectScale(selectedSize) {
    const { roomType = roomTypes.ROOMVIEW } = this.config;
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        this.threeView.change3dObjectScalePhysical(selectedSize);
        this.updateShadowAssetFromSize(selectedSize).then(() => {
          this.updateShadow();
        });
        break;

      default:
        break;
    }
  }
  updateMask(options = {}) {
    const { clear = false } = options;
    const { width, height } = this.dimension;
    setCanvasDimensions(this.maskCanvas, this.dimension, this.dimensionPixels);
    clearCanvas(this.maskCanvas, width, height);
    if (clear) return "clear";

    // if (this.currentAssets.maskCanvasData) {
    //   this.maskCanvas
    //     .getContext("2d")
    //     .drawImage(this.currentAssets.maskCanvasData, 0, 0, width, height);
    //   return;
    // }

    if (!(this.currentAssets.mask && this.currentAssets.mask.image)) return;

    const tmpCanvas = createCanvas(width, height);
    tmpCanvas.getContext("2d").drawImage(this.bgCanvas, 0, 0, width, height);
    // makeMask(tmpCanvas, width, height, this.currentAssets.mask.image);
    tmpCanvas.getContext("2d").globalCompositeOperation = "destination-in";
    tmpCanvas.getContext("2d").drawImage(this.currentAssets.mask.image, 0, 0, width, height);
    // this.currentAssets.maskCanvasData = tmpCanvas;
    this.maskCanvas.getContext("2d").drawImage(tmpCanvas, 0, 0, width, height);
  }

  makeTransitionCanvas({ objects }) {
    let transitionCanvas = this.transitionCanvas;
    clearCanvas(transitionCanvas, transitionCanvas.width, transitionCanvas.height);
    this.blurMatrix = null;
    setCanvasDimensions(transitionCanvas, this.dimension, this.dimensionPixels);
    const transitionctx = transitionCanvas.getContext("2d");
    if (!this.realTimeDynamicRendering && this.savedRoomState[this.config.name]) {
      transitionctx.drawImage(
        this.savedRoomState[this.config.name],
        0,
        0,
        transitionCanvas.width,
        transitionCanvas.height
      );
      return Promise.resolve();
    }
    transitionctx.drawImage(this.bgCanvas, 0, 0, transitionCanvas.width, transitionCanvas.height);
    transitionctx.drawImage(this.threeCanvas, 0, 0, transitionCanvas.width, transitionCanvas.height);
    transitionctx.drawImage(this.maskCanvas, 0, 0, transitionCanvas.width, transitionCanvas.height);
    transitionctx.drawImage(this.shadowCanvas, 0, 0, transitionCanvas.width, transitionCanvas.height);
    Object.keys(objects).forEach(object => {
      const index = objects[object].order - 1;
      let objectcanvas = this.objCanvasContainer.children[index];
      transitionctx.drawImage(objectcanvas, 0, 0, transitionCanvas.width, transitionCanvas.height);
    });
    if (!this.realTimeDynamicRendering && !this.savedRoomState[this.config.name]) {
      const tempCanvas = createCanvas(this.dimension.width, this.dimension.height);
      const tCtx = tempCanvas.getContext("2d");
      setCanvasDimensions(tempCanvas, this.dimension, this.dimensionPixels);
      tCtx.drawImage(transitionCanvas, 0, 0, this.dimension.width, this.dimension.height);
      this.savedRoomState[this.config.name] = tempCanvas;
    }
    return Promise.resolve();
  }
  async initEffectCanvas() {
    return new Promise((resolve) => {
      let canvas = document.createElement('canvas');
      setCanvasDimensions(canvas, this.dimension, this.dimensionPixels);
      setCanvasDimensions(this.effectCanvas, this.dimension, this.dimensionPixels);
      // clearCanvas(this.effectCanvas, this.effectCanvas.width, this.effectCanvas.height);
      canvas
        .getContext("2d")
        .drawImage(
          this.transitionCanvas,
          0,
          0,
          this.transitionCanvas.width,
          this.transitionCanvas.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      resolve(canvas);
    })

  }
  async tiltShift(canvas, tiltShiftFilter) {

    if (!this.currentAssets.depthimage || !this.currentAssets.depthimage.image) return;
    let canvasDims = { width: canvas.width, height: canvas.height };
    const dims = { width: this.currentAssets.depthimage.image.width, height: this.currentAssets.depthimage.image.height };
    if (canvas.width !== this.currentAssets.depthimage.image.width) {
      canvasDims = resizeKeepingAspect(canvasDims, dims, "fit_inside");
    }
    const srcCanvas = createCanvas(canvasDims.width, canvasDims.height);
    const srcCtx = srcCanvas.getContext('2d');
    srcCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, canvasDims.width, canvasDims.height);
    let centerAt = 100;
    if (tiltShiftFilter.options.centerAt) {
      const depthCanvas = createCanvas(dims.width, dims.height);
      const depthCtx = depthCanvas.getContext('2d');
      depthCtx.drawImage(this.currentAssets.depthimage.image, 0, 0, dims.width, dims.height);
      const centerCoord = { x: tiltShiftFilter.options.centerAt.x * dims.width, y: tiltShiftFilter.options.centerAt.y * dims.height };
      centerAt = (depthCtx.getImageData(centerCoord.x, centerCoord.y, 1, 1)).data[0];
    }
    if (!this.blurMatrix) {
      this.blurMatrix = await createBlurMatrix(srcCanvas);
    }
    let blurImgData = await createBlurImage(
      srcCanvas,
      this.currentAssets.depthimage.image,
      centerAt,
      tiltShiftFilter.options.dontBlur,
      tiltShiftFilter.options.maxRadius,
      this.blurMatrix
    );
    blurImgData.toBlob(function (blob) {
      var url = URL.createObjectURL(blob);
      console.log(url);
    });
    clearCanvas(canvas, canvas.width, canvas.height);
    canvas.getContext('2d').drawImage(blurImgData, 0, 0, blurImgData.width, blurImgData.height, 0, 0, canvas.width, canvas.height)
  }
  renderSavedRoomState() {
    if (!this.savedRoomState || !this.savedRoomState[this.config.name]) return false;
    setCanvasDimensions(this.transitionCanvas, this.dimension, this.dimensionPixels);
    const transitionctx = this.transitionCanvas.getContext("2d");
    transitionctx.drawImage(
      this.savedRoomState[this.config.name],
      0,
      0,
      this.dimension.width,
      this.dimension.height
    );

    return true;
  }
  updateCarpetPosition(position) {
    this.threeView.setCarpetPositon(position);
    this.updateGizmo();
    this.updateShadow()
  }
  updateCarpetRotation(rotation) {
    this.threeView.setCarpetRotation(rotation);
    this.updateShadow()
  }
  async updateShadow(options = {}) {
    const { clear = false } = options;
    clearCanvas(this.shadowCanvas, this.shadowCanvas.width, this.shadowCanvas.height);
    if (clear) return "clear";
    const { width, height } = this.dimension;
    if (!width || !height) return;
    const tempCanvas = createCanvas(width, height);
    const tCtx = tempCanvas.getContext("2d");
    setCanvasDimensions(this.shadowCanvas, this.dimension, this.dimensionPixels);
    const { roomType = defaultRoomType } = this.config;
    tCtx.drawImage(this.bgCanvas, 0, 0, width, height);
    tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
    tCtx.drawImage(this.maskCanvas, 0, 0, width, height);
    // switch (roomType) {
    //   case roomTypes.ILLUSTRATION:
    //     this.threeView.render();
    //     tCtx.drawImage(this.bgCanvas, 0, 0, width, height);
    //     let strMime = "image/png";
    //     let imgData = this.threeView.renderer.domElement.toDataURL(strMime);
    //     threeLayer = await readImage(imgData);
    //     break;
    //   default:
    //     threeLayer = this.threeCanvas;
    //     // console.warn("ROOMVIEW: no roomtype specified");
    //     break;
    // }

    if (this.currentAssets.shadow && this.currentAssets.shadow.image) {
      tCtx.globalCompositeOperation = "multiply";
      if (this.currentAssets.shadowdim?.image) {
        const shadowCanvas = combineDimImage({ image: this.currentAssets.shadow.image, imagedim: this.currentAssets.shadowdim.image, width, height, lightIntensity: this.currentAssets.lightIntensity });
        tCtx.drawImage(shadowCanvas, 0, 0, width, height);
      }
      else {
        tCtx.drawImage(this.currentAssets.shadow.image, 0, 0, width, height);
      }

    }
    if (this.currentAssets.highlight && this.currentAssets.highlight.image) {
      tCtx.globalCompositeOperation = "screen";
      tCtx.drawImage(this.currentAssets.highlight.image, 0, 0, width, height);
    }
    if (this.currentAssets.glow && this.currentAssets.glow.image) {
      tCtx.globalCompositeOperation = "overlay";
      tCtx.drawImage(this.currentAssets.glow.image, 0, 0, width, height);
    }
    if (this.currentAssets.blurmap && this.currentAssets.blurmap.image) {
      tCtx.globalCompositeOperation = "source-over";
      makeBlur(tempCanvas, width, height, this.currentAssets.blurmap.image);
    }
    tCtx.globalCompositeOperation = "destination-in";
    switch (roomType) {
      case roomTypes.ROOMVIEW:
        tCtx.drawImage(this.threeCanvas, 0, 0, width, height);
        break;
      default:

        break;
    }
    this.shadowCanvas.getContext("2d").drawImage(tempCanvas, 0, 0, width, height);
  }

  updateObjects(options = {}) {
    const { width, height } = this.dimension;
    this.objCanvasContainer.style.width = `${this.dimensionPixels.width}px`;
    this.objCanvasContainer.style.height = `${this.dimensionPixels.height}px`;

    Object.keys(options).forEach(object => {
      const indexindiv = options[object].order - 1;
      const ref = this.objCanvasContainer.children[indexindiv];
      const refCtx = ref.getContext("2d");
      setCanvasDimensions(ref, this.dimension, this.dimensionPixels);
      if (!options[object] || !options[object].isobjectinscene) return;
      if (
        options[object] &&
        (options[object].active || options[object].mystic) &&
        this.currentAssets.objects[object].object.image
      ) {
        refCtx.drawImage(this.currentAssets.objects[object].object.image, 0, 0, width, height);
      }

      if (options[object] && options[object].active && options[object].active !== "init") {
        refCtx.drawImage(this.currentAssets.objects[object].patchgrey.image, 0, 0, width, height);

        const patchCanvas = createCanvas(width, height);
        const patchCtx = patchCanvas.getContext("2d");
        patchCtx.fillStyle = options[object].active;
        patchCtx.fillRect(0, 0, width, height);

        patchCtx.globalCompositeOperation = "destination-in";
        patchCtx.drawImage(this.currentAssets.objects[object].patchgrey.image, 0, 0, width, height);

        refCtx.globalCompositeOperation = "overlay";
        refCtx.drawImage(patchCanvas, 0, 0, width, height);

        const shadowCanvas = createCanvas(width, height);
        const shadowCtx = shadowCanvas.getContext("2d");
        shadowCtx.drawImage(
          this.currentAssets.objects[object].patchshadow.image,
          0,
          0,
          width,
          height
        );
        shadowCtx.globalCompositeOperation = "destination-in";
        shadowCtx.drawImage(patchCanvas, 0, 0, width, height);

        refCtx.globalCompositeOperation = "multiply";
        refCtx.drawImage(shadowCanvas, 0, 0, width, height);
      }
    });
  }

  mouseDownTouchStart(e) {
    this.updateGizmo();
    this.intersectsGizmo = this.findGizmoIntersection(e);
    this.prev = { ...e };
    this.moved = false;
    this.mousedown = true
    if (!this.intersectsGizmo) {
      const intersectsCarpet = this.threeView.mouseDownTouchStart(e);
      this.shadowCleared = false;
      if (intersectsCarpet) {
        this.updateShadow({ clear: true });
        this.shadowCleared = true;
        return true;
      }
    } else {
      this.prev = { ...e };
      this.updateShadow({ clear: true });
      this.shadowCleared = true;
      return true;
    }
  }
  mouseDownTouchMove(e) {
    if (!this.mousedown) {
      const hoveredAtGizmo = this.findGizmoIntersection(e);
      return { hoveredAtGizmo }
    }
    const difference = e.x - this.prev.x;
    this.moved = Math.abs(difference) > 10;
    if (!this.intersectsGizmo) {
      this.threeView.mouseTouchMove(e);
      this.updateGizmo();
    } else {
      this.threeView.rotateCarpet(difference, "z");
      this.prev = { ...e };
    }
    return { hoveredAtGizmo: this.intersectsGizmo }
  }
  mouseDownTouchEnd(e) {
    this.mousedown = false;
    if (this.shadowCleared) this.updateShadow();
    let showColorSelectionBox = null;
    if (!this.moved && selectActiveObjectByMouseClick) {
      showColorSelectionBox = this.selectColorAnnotation(e)
    }
    let rotation = null;
    let position = null;
    const object = this.threeView.getObjectConfig()
    if (object) {
      if (this.intersectsGizmo) {
        rotation = convertArrintoDeg(object.rotation.toArray().slice(0, 3))
      } else {
        position = object.position
      }
    }

    return { showColorSelectionBox, rotation, position }
  }
  selectColorAnnotation(e) {
    let showColorSelectionBox = null;
    if (!this.annotationCanvas) return null;

    const imgData = this.annotationCanvas.getContext("2d").getImageData(e.x, e.y, 1, 1);
    if (!imgData.data.every(data => data === 255 || data === 0))
      showColorSelectionBox = this.selectedColorCode = imgData.data.slice(0, 3);
    return showColorSelectionBox
  }
  selectColorCode(colorCode) {
    const { r, g, b } = hexToRGB(colorCode)
    const color = [r, g, b]
    if (color.every(value => value === 0) || color.every(value => value === 255)) return
    this.selectedColorCode = color
  }
  findGizmoIntersection(e) {
    const { x, y } = e;
    if (!this.inputCanvas) return;
    var imgData = this.inputCanvas.getContext("2d").getImageData(x - 10, y - 10, 20, 20);
    var ingizmo = false;
    for (var i = 0; i < imgData.data.length; i += 4) {
      if (imgData.data[i + 3] !== 0) {
        ingizmo = true;
        break;
      }
    }
    return ingizmo;
  }
  updateGizmo() {
    const { roomType = defaultRoomType } = this.config;
    if (roomType !== roomTypes.ROOMVIEW) return;
    const diamondHeight = 10;
    const context = this.inputCanvas.getContext("2d");
    const { width, height } = this.inputCanvas;
    if (roomType !== roomTypes.ROOMVIEW) {
      context.clearRect(0, 0, width, height);
      return;
    }
    const gizmoCoordinates = this.threeView.getGizmoCordinates();
    if (!gizmoCoordinates) return;
    let { radX, radY, canvasCenter } = gizmoCoordinates;
    const rgb = {
      r: 250,
      g: 250,
      b: 250,
      a: 0.8
    };
    const colorStr = "rgba(" + rgb.r + ", " + rgb.g + ", " + rgb.b + ", " + rgb.a + ")";
    var radiusX;
    var radiusY;
    if (radX > radY) {
      radiusX = radX;
      radiusY = radY;
    } else {
      radiusX = radY;
      radiusY = radX;
    }
    // Draw the ellipse
    context.strokeStyle = colorStr;
    context.fillStyle = colorStr;
    context.lineWidth = 1;
    context.shadowOffsetX = 0;
    context.shadowColor = "black";
    context.shadowOffsetY = 1;
    context.clearRect(0, 0, width, height);
    context.beginPath();
    context.ellipse(canvasCenter.x, canvasCenter.y, radiusX, radiusY, 0, 0, 2 * Math.PI);
    context.stroke();
    context.beginPath();
    context.moveTo(canvasCenter.x, canvasCenter.y + radiusY - 5);
    context.lineTo(canvasCenter.x + diamondHeight, canvasCenter.y + radiusY);
    context.lineTo(canvasCenter.x, canvasCenter.y + radiusY + 5);
    context.lineTo(canvasCenter.x - diamondHeight, canvasCenter.y + radiusY);
    context.fill();
  }
  resize(newDims) {
    if (!this.config.dims) return
    this.dimensionPixels = resizeKeepingAspect(this.config.dims, newDims, "fit_inside");
    if (this.transitionCanvas && newDims.windowsize) setCanvasDimensionsStyle(this.transitionCanvas, this.dimensionPixels);
    if (this.effectCanvas) {
      setCanvasDimensionsStyle(this.effectCanvas, this.dimensionPixels);
      this.blurMatrix = null;
    }
    const { roomType } = this.config;
    switch (roomType) {
      case roomTypes.ILLUSTRATION:
        if (!this.phview.renderer) return
        this.phview.resizeRenderer(this.dimensionPixels);
        break;

      default:
        if (!this.threeView.renderer) return
        this.threeView.resizeRenderer(this.dimensionPixels);
        break;
    }
    setCanvasDimensionsStyle(this.bgCanvas, this.dimensionPixels);
    setCanvasDimensionsStyle(this.maskCanvas, this.dimensionPixels);
    setCanvasDimensionsStyle(this.shadowCanvas, this.dimensionPixels);
    this.inputCanvas.width = this.dimensionPixels.width;
    this.inputCanvas.height = this.dimensionPixels.height;
    this.objCanvasContainer.style.width = `${this.dimensionPixels.width}px`;
    this.objCanvasContainer.style.height = `${this.dimensionPixels.height}px`;
    let objectlength = this.objCanvasContainer.children.length;
    for (let i = 0; i < objectlength; i++) {
      setCanvasDimensionsStyle(this.objCanvasContainer.children[i], this.dimensionPixels);
    }
    this.updateGizmo();
  }

  renderinCanvas() {
    const { width: w, height: h } = this.dimensionPixels;
    const renderCanvas = createCanvas(w, h);
    const renderCtx = renderCanvas.getContext("2d");
    renderCtx.drawImage(this.bgCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.threeCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.maskCanvas, 0, 0, w, h);
    renderCtx.drawImage(this.shadowCanvas, 0, 0, w, h);
    Array.from(this.objCanvasContainer.children).forEach(item => {
      console.log(item.nodeName);
      if (item.nodeName === "CANVAS") {
        renderCtx.drawImage(item, 0, 0, w, h);
      }
    });
    return renderCanvas;
  }
  downloadRendering(name, mime) {
    downloadImageData(this.transitionCanvas, name, "image/" + mime);
  }
  addToLocalForage() {
    // localforage.setItem('somekey', 'some value').then(function (value) {
    //   // Do other things once the value has been saved.
    //   console.log(value);
    // }).catch(function (err) {
    //   // This code runs if there were any errors
    //   console.log(err);
    // });
  }
}

function combineDimImage({ image, imagedim, width, height, lightIntensity }) {
  let canvas = createCanvas(width, height);
  const ctx = canvas.getContext('2d');
  if (lightIntensity !== 0 && lightIntensity && lightIntensity === 100) {
    ctx.drawImage(image, 0, 0, width, height);
  }
  else if (lightIntensity === 0) { ctx.drawImage(imagedim, 0, 0, width, height); }
  else {
    ctx.drawImage(imagedim, 0, 0, width, height);
    ctx.globalAlpha = lightIntensity / 100;
    // ctx.globalCompositeOperation = 'copy';
    ctx.drawImage(image, 0, 0, width, height);
    ctx.globalAlpha = 1.00;
    ctx.globalCompositeOperation = 'source-over';
  }
  return canvas;
}

function makeBlur(canvas, w, h, blurMap) {
  const blurCanvas = createCanvas(w, h);
  const blurredCanvas = createCanvas(w, h);
  const ctx = canvas.getContext("2d");
  const blurCtx = blurCanvas.getContext("2d");
  const blurredCtx = blurredCanvas.getContext("2d");
  blurredCtx.filter = "blur(6px)";
  blurredCtx.drawImage(canvas, 0, 0, w, h);
  blurCtx.drawImage(blurMap, 0, 0, w, h);
  let imageData = ctx.getImageData(0, 0, w, h);
  let blurData = blurCtx.getImageData(0, 0, w, h);
  let blurredData = blurredCtx.getImageData(0, 0, w, h);
  for (let i = 0; i < blurData.data.length; i += 4) {
    imageData.data[i + 3] = 255 - blurData.data[i];
    blurredData.data[i + 3] = blurData.data[i];
  }
  ctx.putImageData(imageData, 0, 0);
  blurredCtx.putImageData(blurredData, 0, 0);
  ctx.drawImage(blurredCanvas, 0, 0, w, h);
}
const setCanvasDimensionsStyle = (canvas, dimensionPixels) => {
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
};
const setCanvasDimensions = (canvas, dimension, dimensionPixels) => {
  const { width, height } = dimension;
  const { width: widthPix, height: heightPix } = dimensionPixels;

  canvas.width = width;
  canvas.height = height;
  canvas.style.width = `${widthPix}px`;
  canvas.style.height = `${heightPix}px`;
};
const normalizeDirNames = files =>
  files.map(item => (item.charAt(0) === "/" ? item.substring(1) : item));


const readImageFromPCorWeb = async (url, relativeSavePath) => {
  // let po = performance.now();
  let newUrl = url;
  return new Promise(async (resolve, reject) => {
    // if (relativeSavePath) newUrl = await saveImageToDisk(url, relativeSavePath);
    readImage(newUrl).then(
      async (img) => {
        // let p1 = performance.now();
        resolve(img);
      }
    )
      .catch(() => {
        reject();
      })
  })
}
