import React, { useEffect, useState } from "react";
import { useMount } from "react-use";
import AppProvider from "../../../api/appProvider";
import { arrangeIntoTree, getPathOffile } from "../../../utils/arrayUtils";
import { useDebouncedCallback } from "use-debounce/lib";
import {
  designListActions as actions,
  useDesignListState,
  useDispatchDesignList
} from "../../../reducers/designstree.reducer";
import { H2, H6 } from "@blueprintjs/core";
import AtDialog from "../../molecules/AtDialog";
import ThumbList from "../../molecules/ThumbList";
import AtIcon from "../../atoms/AtIcon";
import { getExtension, getFilenameWithoutExt } from "../../../utils/utils";
import deepmerge from "deepmerge";
import { isRunner, unsupportedDesign } from "../VpsStage";
import { nameSeparator } from "../UploadPlatform";

const thumbnailType = "portrait";
export const jpegExtensions = ["jpg", "jpeg"];
const DesignList = props => {
  const { handleThumbnailClick, hidden = true, handleClose, setNextDesigns, customUploadFolder, restrictJPEG, restrictDesigns, seteditDesign } = props;

  const [customTree, setcustomTree] = useState({});
  const [stateTree, setstateTree] = useState([]);
  const [uploadedFile, setuploadedFile] = useState({ name: null, isJPEG: false });

  const { initDesignPath } = window.initialData;

  const state = useDesignListState();
  const dispatch = useDispatchDesignList();

  useEffect(() => {
    if (!state.tree) return;
    if (JSON.stringify(state.tree) !== JSON.stringify(stateTree)) {
      setstateTree(state.tree);
    }
  }, [state.tree]);

  window.getUploadedFile = (filename, isJPEG) => {
    setuploadedFile({ name: filename, isJPEG: isJPEG });
  }

  const getFolderTree = ({ folderPath, defaultFilePath, defaultFileKeyType, scope }) => {
    if (!folderPath) return;
    folderPath = folderPath.toLowerCase();
    let passPath = folderPath;
    if (scope === "internal") {
      passPath = folderPath.split("designs/")[1];
    }
    return new Promise((resolve) => {
      AppProvider.fetchList("design", passPath).then(initialList => {
        const files = initialList.filter(item => (item.Type === "file" && item.FullPath.toLowerCase().includes(folderPath)));
        const folders = initialList.filter(item => (item.Type === "folder" && item.FullPath.toLowerCase() === folderPath));
        let defaultItemPath;


        if (!defaultFilePath) {
          defaultItemPath = files[0].FullPath;
        } else {
          // if (defaultFileKeyType) {
          //   files.map(item => {
          //     if (getFilenameWithoutExt(item.FullPath) === defaultFilePath) {
          //       defaultItemPath = item.FullPath;
          //       return { ...item, KeyType: defaultFileKeyType }
          //     }
          //     else return item;
          //   });
          // }
          // else
          defaultItemPath = (files.find(item => getFilenameWithoutExt(item.FullPath) === defaultFilePath)).FullPath;
        }
        const params = { files, folders, defaultItemPath };
        const { tree, defaultItem, openNode, flatfileList } = arrangeIntoTree(params);
        if (!openNode) resolve(null);
        AppProvider.getDesignThumbnails({ designs: [...flatfileList] }).then(items => {
          let updatedTree = [...tree];

          const findinItems = (filesInNode) => {
            items.forEach(item => {
              const index = filesInNode.findIndex(
                fileInNode => item.fullpath === fileInNode.fullpath
              );
              if (index !== -1) filesInNode[index] = item;
            });
            return filesInNode;
          }
          let folderTree = null;
          updatedTree.forEach(function iter(node, index, object) {
            let { filesInNode } = node;
            node.filesInNode = findinItems(filesInNode);
            if (!folderTree && (node.fullpath.toLowerCase() === "/" + folderPath)) {
              folderTree = node;
              Array.isArray(folderTree.childNodes) && folderTree.childNodes.forEach(iter);
            }
            else {
              Array.isArray(node.childNodes) && node.childNodes.forEach(iter);
            }

          });

          const defItemWithThumb = items.find(item => item.fullpath === defaultItem.fullpath);

          resolve({ tree: folderTree, flatfileList, openNode, defItemWithThumb });

        });
      })
    });

  }

  useEffect(() => {
    if (!state.tree || !uploadedFile?.name) return;

    (async () => {
      const folderPath = window.InterfaceElements.StartupFolder;
      const defaultFilePath = window.InterfaceElements.StartupFolder + '/' + getFilenameWithoutExt(uploadedFile.name);
      const returned = await getFolderTree({
        folderPath,
        defaultFilePath,
        defaultFileKeyType: AppProvider.activeKey,
        scope: "internal"
      });
      if (!returned) return;
      const { tree: folderTree, flatfileList, openNode, defItemWithThumb } = returned;

      defItemWithThumb.isJPEG = uploadedFile.isJPEG;
      defItemWithThumb.label = defItemWithThumb.label.split(nameSeparator)[0];

      for (let i = 0; i < folderTree.length; i++) {
        const iter = async (node) => {
          if (node.type === 'folder' && node.filesInNode?.length) {
            for (let j = 0; j < node.filesInNode.length; j++) {
              await iter(node.filesInNode[j]);
            }
          }
          else if (node.type === 'file' && node.fullpath === defaultFilePath) {
            node.isJPEG = uploadedFile.isJPEG;
            node.label = node.label.split(nameSeparator)[0];
            return;
          }

          if (Array.isArray(node.childNodes)) {
            for (let j = 0; j < node.childNodes.length; j++) {
              await iter(node.childNodes[j]);
            }
          }
        }
        await iter(folderTree[i]);
      }

      let updatedTree = [...state.tree];
      if (updatedTree[0].label === "Your Designs") {
        updatedTree[0].filesInNode.push(defItemWithThumb);
      }
      else {
        updatedTree = [{ ...folderTree, label: "Your Designs", level: 0 }, ...updatedTree];
      }
      dispatch({
        type: actions.SET_TREE,
        payload: { tree: updatedTree, flatfileList, openNode }
      });

      if (openNode.filesInNode && openNode.filesInNode.length > 0)
        onThumbnailClick(defItemWithThumb);
    })();
  }, [uploadedFile])

  useEffect(() => {
    (async () => {
      if (!customUploadFolder) return;

      const currentKey = AppProvider.activeKey;
      window.InterfaceElements.StartupFolder = `Designs/Internal/Uploads/${customUploadFolder}`;

      const findinItems = (items, filesInNode) => {
        items.forEach(item => {
          const index = filesInNode.findIndex(
            fileInNode => item.fullpath === fileInNode.fullpath
          );
          item.label = item.label.split(nameSeparator)[0];
          if (index !== -1) {
            if (jpegExtensions.includes(getExtension(item.fullpath))) {
              filesInNode[index] = { ...item, infoThumbs: [<AtIcon icon={"image"} />], infoTitles: ["This design is a rug photo"], isJPEG: true };
            }
            else
              filesInNode[index] = { ...item };
          }
        });
        return filesInNode;
      }

      //functions for deepmerger designs folder

      const mapNodeFullPath = (arr) => {
        return arr.map((el) => el.fullpath);
      }

      const mergeNodes = async (a, b) => {
        if ((!a || a.length === 0) && (!b || b.length === 0)) return []
        if (!a || !a.length) return [...b]
        if (!b || !b.length) return [...a]

        let result = [];
        let aMapped = mapNodeFullPath(a);
        let bMapped = mapNodeFullPath(b);


        for (let index = 0; index < aMapped.length; index++) {
          var bIndex = bMapped.indexOf(aMapped[index]);
          if (bIndex !== -1) {
            let merged = await deepmerge.all([a[index], b[bIndex]], options);
            result.push(merged);
            bMapped.splice(bIndex, 1);
            b.splice(bIndex, 1);
          }
          else {
            result.push(a[index])
          }
        }
        return result.concat(b);
      }

      const options = {
        customMerge: (key) => {
          if (key === "childNodes") {
            return mergeNodes
          }
        }
      }

      let treeArray = [];
      let flatfileListMerged = [];
      let openNodeAfterMerge = null;
      let defItemWithThumb;
      const keyEntries = Object.entries(AppProvider.keys);
      window.usedFileNames = [];

      for (let index = 0; index < keyEntries.length; index++) {
        let startupDefault = false;

        AppProvider.setActiveKey(keyEntries[index][1], [AppProvider.keyScopes.design]);

        const internalCustomFolderPath = `Internal/Uploads/${customUploadFolder}`;

        const initialList = await AppProvider.fetchList("design", internalCustomFolderPath);
        const files = initialList.filter(item => item.Type === "file");
        const folders = initialList.filter(item => item.Type === "folder");

        let defaultItemPath;

        if (!initDesignPath) {
          let folder = internalCustomFolderPath;
          const folderarr = folder.split("/").find(item => item === "Designs");
          if (!folderarr) folder = `Designs/${folder}`;
          if (folder[0] !== "/") folder = `/${folder}`;
          const found = files.find(
            item => getPathOffile(item.FullPath).toLowerCase() === folder.toLowerCase()
          );
          if (found) {
            defaultItemPath = found.FullPath;
            startupDefault = true;
          }
        } else {
          defaultItemPath = initDesignPath;
        }

        const params = {
          files,
          folders,
          defaultItemPath
        };

        const { tree, defaultItem, openNode, flatfileList } = arrangeIntoTree(params);
        if (tree.length === 0) {
          treeArray.push([]);
        }
        else {
          // if (!openNode) return;
          flatfileListMerged = [...flatfileListMerged, ...flatfileList];

          const items = await AppProvider.getDesignThumbnails({ designs: [...flatfileList] });
          let updatedTree = [...tree];
          let uploadedNodeTemp = null;

          updatedTree.forEach(function iter(node, index, object) {
            let { filesInNode } = node;

            if (node.fullpath === "/Designs/Internal/Uploads") {
              uploadedNodeTemp = node.childNodes.find(node => (node.fullpath === ("/Designs/" + internalCustomFolderPath)));
              if (uploadedNodeTemp?.filesInNode?.length) {
                uploadedNodeTemp.filesInNode = findinItems(items, uploadedNodeTemp.filesInNode);
                uploadedNodeTemp = { ...uploadedNodeTemp, label: "Your Designs", level: 0 };
                let fileNames = uploadedNodeTemp.filesInNode.map(item => item.label);
                window.usedFileNames = [...window.usedFileNames, ...fileNames];
              }
              object.splice(index, 1);
              return;
            }

            node.filesInNode = findinItems(items, filesInNode);

            if (!defItemWithThumb || startupDefault) {
              defItemWithThumb = items.find(item => item.fullpath === defaultItem.fullpath);
              openNodeAfterMerge = openNode;
            }

            Array.isArray(node.childNodes) && node.childNodes.forEach(iter);
          });

          if (uploadedNodeTemp) {
            updatedTree = [uploadedNodeTemp, ...updatedTree]
          }
          treeArray.push(updatedTree);
        }
      }

      let mergedTree = await mergeNodes(treeArray[0], treeArray[1]);

      //pushing Your Designs folder to the front of array
      let yourDesignsNode = null;
      mergedTree.forEach(function iter(node, index, object) {
        if (node.label === "Your Designs") {
          yourDesignsNode = object[index];
          object.splice(index, 1);
          return;
        }
      });
      if (yourDesignsNode) {
        setcustomTree({ tree: [yourDesignsNode], flatfileList: flatfileListMerged });
      }

      AppProvider.setActiveKey(currentKey.key, [...currentKey.scope]);
      // if (defItemWithThumb)
      //   onThumbnailClick(defItemWithThumb);
    })()
  }, [customUploadFolder])

  useEffect(() => {
    if (customTree?.tree?.length) {
      dispatch({
        type: actions.SET_TREE,
        payload: {
          tree: [...customTree.tree, ...state.tree],
          flatfileList: [...customTree.flatfileList, ...(state.flatfileList || [])]
        }
      });
    }
  }, [customTree])

  useMount(async () => {
    if (state.tree) return;
    if (!window.InterfaceElements.StartupFolder) window.InterfaceElements.StartupFolder = ""
    const findinItems = (items, filesInNode) => {
      items.forEach(item => {
        const index = filesInNode.findIndex(
          fileInNode => item.fullpath === fileInNode.fullpath
        );
        if (index !== -1) {
          if (jpegExtensions.includes(getExtension(item.fullpath))) {
            filesInNode[index] = { ...item, infoThumbs: [<AtIcon icon={"image"} />], infoTitles: ["This design is a rug photo"], isJPEG: true };
          }
          else
            filesInNode[index] = item;
        }
      });
      return filesInNode;
    }

    let treeArray = [];
    let flatfileListMerged = [];
    let openNodeAfterMerge = null;
    let defItemWithThumb;

    const keyEntries = Object.entries(AppProvider.keys);
    window.usedFileNames = [];

    for (let index = 0; index < keyEntries.length; index++) {
      let startupDefault = false;

      AppProvider.setActiveKey(keyEntries[index][1], [AppProvider.keyScopes.design]);

      const initialList = await AppProvider.fetchList("design");
      const files = initialList.filter(item => item.Type === "file");
      const folders = initialList.filter(item => item.Type === "folder");

      let defaultItemPath;

      if (!initDesignPath) {
        let folder = window.InterfaceElements.StartupFolder;
        const folderarr = folder.split("/").find(item => item === "Designs");
        if (!folderarr) folder = `Designs/${folder}`;
        if (folder[0] !== "/") folder = `/${folder}`;
        const found = files.find(
          item => getPathOffile(item.FullPath).toLowerCase() === folder.toLowerCase()
        );
        if (found) {
          defaultItemPath = found.FullPath;
          startupDefault = true;
        }
      } else {
        defaultItemPath = initDesignPath;
      }

      const params = {
        files,
        folders,
        defaultItemPath
      };

      const { tree, defaultItem, openNode, flatfileList } = arrangeIntoTree(params);
      if (!openNode) return;
      flatfileListMerged = [...flatfileListMerged, ...flatfileList];

      const items = await AppProvider.getDesignThumbnails({ designs: [...flatfileList] });
      let updatedTree = [...tree];
      let uploadedNodeTemp = null;

      updatedTree.forEach(function iter(node, index, object) {
        let { filesInNode } = node;

        if (node.fullpath === "/Designs/Internal/Uploads") {
          uploadedNodeTemp = node.childNodes.find(node => (node.fullpath === "/" + window.InterfaceElements.StartupFolder));
          if (uploadedNodeTemp?.filesInNode?.length) {
            uploadedNodeTemp.filesInNode = findinItems(items, uploadedNodeTemp.filesInNode);
            uploadedNodeTemp = { ...uploadedNodeTemp, label: "Your Designs", level: 0 };
            let fileNames = uploadedNodeTemp.filesInNode.map(item => item.label);
            window.usedFileNames = [...window.usedFileNames, ...fileNames];
          }
          object.splice(index, 1);
          return;
        }

        node.filesInNode = findinItems(items, filesInNode);

        if (!defItemWithThumb || startupDefault) {
          defItemWithThumb = items.find(item => item.fullpath === defaultItem.fullpath);
          openNodeAfterMerge = openNode;
        }

        Array.isArray(node.childNodes) && node.childNodes.forEach(iter);
      });

      if (uploadedNodeTemp) {
        updatedTree = [uploadedNodeTemp, ...updatedTree]
      }
      treeArray.push(updatedTree);
    }

    //deepmerger designs folder

    const mapNodeFullPath = (arr) => {
      return arr.map((el) => el.fullpath);
    }

    const mergeNodes = async (a, b) => {
      if ((!a || a.length === 0) && (!b || b.length === 0)) return []
      if (!a || !a.length) return [...b]
      if (!b || !b.length) return [...a]

      let result = [];
      let aMapped = mapNodeFullPath(a);
      let bMapped = mapNodeFullPath(b);


      for (let index = 0; index < aMapped.length; index++) {
        var bIndex = bMapped.indexOf(aMapped[index]);
        if (bIndex !== -1) {
          let merged = await deepmerge.all([a[index], b[bIndex]], options);
          result.push(merged);
          bMapped.splice(bIndex, 1);
          b.splice(bIndex, 1);
        }
        else {
          result.push(a[index])
        }
      }
      return result.concat(b);
    }

    const options = {
      customMerge: (key) => {
        if (key === "childNodes") {
          return mergeNodes
        }
      }
    }

    let mergedTree = await mergeNodes(treeArray[0], treeArray[1]);

    //pushing Your Designs folder to the front of array
    let yourDesignsNode = null;
    mergedTree.forEach(function iter(node, index, object) {
      if (node.label === "Your Designs") {
        yourDesignsNode = object[index];
        object.splice(index, 1);
        return;
      }
    });
    if (yourDesignsNode) mergedTree = [yourDesignsNode, ...mergedTree];
    //
    // if (defItemWithThumb?.fullpath) {
    //   if (jpegExtensions.includes(getExtension(defItemWithThumb.fullpath))) {
    //     AppProvider.setActiveKey(AppProvider.keys.jpeg, [AppProvider.keyScopes.design]);
    //   } else {
    //     AppProvider.setActiveKey(AppProvider.keys.ctf);
    //   }
    // }
    dispatch({
      type: actions.SET_TREE,
      payload: { tree: mergedTree, flatfileList: flatfileListMerged, openNode: openNodeAfterMerge }
    });

    if (defItemWithThumb?.fullpath) {
      onThumbnailClick(defItemWithThumb);
    }

  });

  const [debouncedThumbnailClick] = useDebouncedCallback((node, e) => {
    if (!!handleThumbnailClick) handleThumbnailClick(node, e);
  }, 800);
  const onThumbnailClick = (node, e, prev = null, next = null) => {
    if (!node.Props) return;
    if (state.activeNode)
      if (node.id === state.activeNode.id) {
        handleClose && handleClose();
        return;
      }
    dispatch({
      type: actions.SET_ACTIVE_NODE,
      payload: node
    });
    debouncedThumbnailClick(node, e);
    handleClose && handleClose();
  };

  useEffect(() => {
    let prev = null;
    let next = null;
    let current = null;

    if (!state?.activeNode?.fullpath) return;

    if (jpegExtensions.includes(getExtension(state.activeNode.fullpath))) {
      AppProvider.setActiveKey(AppProvider.keys.jpeg, [AppProvider.keyScopes.design]);
    } else {
      AppProvider.setActiveKey(AppProvider.keys.ctf, [AppProvider.keyScopes.design]);
    }

    const findActive = async nodes => {
      if (!nodes || !nodes.length) return;
      const filteredNodes = stateTree.filter(
        node => node.childNodes.length || node.filesInNode.length
      );
      for (let i = 0; i < filteredNodes.length && !current; i++) {
        if (filteredNodes[i].filesInNode?.length) {
          for (let j = 0; j < filteredNodes[i].filesInNode.length && !current; j++) {
            prev = filteredNodes[i].filesInNode[j - 1];
            next = filteredNodes[i].filesInNode[j + 1];
            if (filteredNodes[i].filesInNode[j].id === state.activeNode.id) {
              current = await filteredNodes[i].filesInNode[j];
              if (restrictJPEG) {
                const iter = (nodeIndex, fileIndex, increment) => {
                  if (increment > 0) {
                    if (fileIndex >= filteredNodes[nodeIndex].filesInNode.length) {
                      nodeIndex += 1;
                      fileIndex = 0;
                    }
                  }
                  else if (increment < 0) {
                    if (fileIndex < 0) {
                      nodeIndex -= 1;
                      fileIndex = filteredNodes[nodeIndex] ? filteredNodes[nodeIndex].filesInNode.length - 1 : null;
                    }
                  }

                  if (fileIndex === null)
                    return false

                  let des = filteredNodes[nodeIndex]?.filesInNode[fileIndex];
                  if (des?.isJPEG) {
                    return iter(nodeIndex, fileIndex + increment, increment);
                  }
                  else {
                    return des;
                  }
                }
                if (next?.isJPEG) {
                  next = iter(i, j + 2, 1)
                }
                if (prev?.isJPEG) {
                  prev = iter(i, j - 2, -1)
                }
              }
              if (setNextDesigns) {
                setNextDesigns({ next: next, prev: prev });
              }
            }
          }
        }
        if (!current) {
          findActive(filteredNodes.childNodes);
        }
      }
    };
    findActive(stateTree);
  }, [state.activeNode.id]);

  const renderNodes = (treeNodes, restrictJPEG, restrictDesigns) => {
    if (!treeNodes || !treeNodes.length) {
      return null;
    }
    const filteredNodes = treeNodes.filter(
      node => (node.childNodes.length || node.filesInNode.length)
    );
    const nodeItems = filteredNodes.map((node, index) => {
      return (
        <DesignCategory
          key={index}
          restrictJPEG={restrictJPEG}
          restrictDesigns={restrictDesigns}
          node={node}
          thumbnailType={thumbnailType}
          thumbList={node.filesInNode}
          activeId={state.activeNode.id}
          onThumbnailClick={(node, e, prev, next) => {
            onThumbnailClick(node, e, prev, next);
          }}
          lastNode={index === filteredNodes.length - 1}
        ></DesignCategory>
      );
    });
    return <>{nodeItems}</>;
  };

  const DesignCategory = props => {
    const { node, restrictJPEG, restrictDesigns, thumbList, ...otherProps } = props;
    const [thumblistArr, setthumblistArr] = useState([]);

    useEffect(() => {
      if (!restrictDesigns || !restrictJPEG) {
        setthumblistArr([...thumbList]);
        return;
      }
      const checkRestriction =
        (item) => {
          const isirregular = item.Props.IsIrregular;
          const isrunner = isRunner({ width: item.Props.PhysicalWidth, height: item.Props.PhysicalHeight });
          if (isirregular)
            return `${unsupportedDesign.IRREGULAR} designs`

          if (isrunner)
            return `${unsupportedDesign.RUNNER} designs`
          if (restrictJPEG && item.isJPEG)
            return 'Rug photos'
          return false
        }
      const thumbListTemp = thumbList.map(x => {
        const restriction = checkRestriction(x)
        if (restriction) {
          return {
            ...x,
            className: "disabled",
            tooltip: `${restriction} are not supported in the current shot`
          };
        }
        else
          return x
      });
      setthumblistArr([...thumbListTemp]);

    }, [thumbList])

    return (
      <>
        <div className={`category-wrapper level-${node.level}`}>
          <div className={"thumb-item category-heading"}>
            <div className={"thumb-image-container landscape "}>
              <span className="thumb-heading">{node.label}</span>
              <span className="thumb-subheading"></span>
            </div>
          </div>
          <div>
            <ThumbList {...otherProps} node={node} thumbList={thumblistArr}
              hasEdit={node.label.toLowerCase() === "your designs"}
              onEditClick={(e) => {

                seteditDesign(e)
                handleClose && handleClose();
              }}
            ></ThumbList>
          </div>

          {!props.lastNode && node.childNodes && node.childNodes.length <= 0 && (
            <div className="category-divider"></div>
          )}
        </div>
        {renderNodes(node.childNodes, restrictJPEG, restrictDesigns)}
      </>
    );
  };

  return (
    <>
      {stateTree && (
        <AtDialog
          isOpen={true}
          onClose={() => {
            handleClose && handleClose();
          }}
          className={"illustration-dialog design-view-dialog"}
          overlayClassName={hidden ? "hidden" : ""}
          size="lg"
        >
          <div className="illustration-dialog-header-row">
            <div style={{ display: "flex", alignItems: "center" }}>
              <H2>Please choose your design</H2>
            </div>
            <AtIcon
              icon="close"
              size={2.5}
              onClick={() => {
                handleClose && handleClose();
              }}
            />
          </div>
          <div className={"category-container"}>
            {renderNodes(stateTree, restrictJPEG, restrictDesigns)}
          </div>
        </AtDialog>
      )}
    </>
  );
};

export default React.memo(DesignList);
