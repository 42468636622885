import React from "react";

const getSquareSVG = props => {
  const { yarns, handleClick, shape, strokeColor="#808080", ...otherprops } = props;
  
  return (
    <svg {...otherprops} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        onClick={e => handleClick(e, 0)}
        x="0.5"
        y="0.5"
        width="71"
        height="71"
        fill={yarns[0]}
        stroke={strokeColor}
      />
      <rect
        onClick={e => handleClick(e, 1)}
        x="0.5"
        y="0.5"
        width="35"
        height="71"
        fill={yarns[1]}
        stroke={strokeColor}
      />
    </svg>
  );
};

const getCircleSVG = props => {
  const { yarns, handleClick, shape, strokeColor="#808080", ...otherprops } = props;
  return (
    <svg {...otherprops} viewBox="0 0 74 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        onClick={e => handleClick(e, 0)}
        d="M4.20226 22.4147L4.1974 22.4265L4.19194 22.438C2.12953 26.7787 1.39774 32.0208 1.48683 36.5L37 36.5L72.514 36.5C72.6072 31.9172 71.6687 26.5523 69.8041 22.4294L69.8008 22.4221L69.7977 22.4147C68.0137 18.1077 65.3988 14.1942 62.1023 10.8977C58.8058 7.60123 54.8923 4.98632 50.5853 3.20228C46.2782 1.41823 41.6619 0.499997 37 0.499997C32.3381 0.499996 27.7218 1.41823 23.4147 3.20227C19.1077 4.98631 15.1942 7.60122 11.8977 10.8977C8.60123 14.1942 5.9863 18.1077 4.20226 22.4147Z"
        fill={yarns[0]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 1)}
        d="M4.20228 49.5853L4.19676 49.5719L4.19048 49.559C2.2139 45.4712 1.32362 40.1005 1.47944 35.5L37 35.5L72.5133 35.5C72.6041 40.2099 71.6667 45.5661 69.8063 49.5657L69.8018 49.5754L69.7977 49.5853C68.0137 53.8923 65.3988 57.8058 62.1023 61.1023C58.8058 64.3988 54.8923 67.0137 50.5853 68.7977C46.2782 70.5818 41.6619 71.5 37 71.5C32.3381 71.5 27.7218 70.5818 23.4147 68.7977C19.1077 67.0137 15.1942 64.3988 11.8977 61.1023C8.60124 57.8058 5.98632 53.8923 4.20228 49.5853Z"
        fill={yarns[1]}
        stroke={strokeColor}
      />
    </svg>
  );
};
const getSVGofShape = props => {
  const { yarns, handleClick, shape, ...otherprops } = props;
  switch (shape) {
    case "CIRCLE":
      return getCircleSVG(props);
      break;
    case "SQUARE":
      return getSquareSVG(props);
      break;
    default:
      return null;
  }
};

const Mixply2 = props => {
  return getSVGofShape(props);
};

Mixply2.propTypes = {};

export default Mixply2;
