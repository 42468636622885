import { jsPDF } from "jspdf";
export const makePDf = (id, width, height, name, callback) => {
  const element = document.getElementById(id);
  // var doc = new jsPDF('p', 'pt', 'a4');
  let o = width > height ? "l" : "p";
  var doc = new jsPDF({
    orientation: o,
    unit: "px",
    format: [width, height]
    // putOnlyUsedFonts: true,
    // floatPrecision: 16, // or "smart", default is 16,
    // hotfixes: ["px_scaling"]
  });

  doc.html(element, {
    callback: function(doc) {
      let count = doc.internal.getNumberOfPages();
      if (count > 1) {
        doc.deletePage(count);
      }
      doc.save(`${name}.pdf`);
      if (callback) callback();
    },
    x: 0,
    y: 0,
    margin: [0, 0, 0, 0]
  });
};

// var doc = new jsPDF({
//   orientation: "p",
//   unit: "px",
//   format: [width, height],
//   putOnlyUsedFonts: true,
//   floatPrecision: 16, // or "smart", default is 16,
//   hotfixes: ["px_scaling"]
// });
