import axios from "axios";
import convert from "convert-units";
import pako from "pako";

const fileApiUrl = 'https://v3.explorug.com/managefiles.aspx';

function bytesToBase64(bytes) {
  var binary = "";
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
const comperessJSON = (colors) => bytesToBase64(pako.deflate(JSON.stringify(colors), { to: "string" }));



export const renameFolder = async ({ key, oldpath, newfoldername }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "renamefolder");
  data.append("newfoldername", newfoldername);
  data.append("oldpath", oldpath);

  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const renameFile = async ({ key, oldfile, newfilename }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "renamefile");
  data.append("newfilename", newfilename);
  data.append("oldfile", oldfile);

  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const addFolder = async ({ key, path }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "addfolder");
  data.append("path", path);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};

export const deletefile = async ({ key, file }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "deletefile");
  data.append("file", file);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const deleteFolder = async ({ key, path }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "deletefolder");
  data.append("path", path);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};

export const uploadFile = async ({ key, file, path, width, length, forceUnit = "" }) => {
  let newWid = width;
  let newLgt = length;
  let data = new FormData();
  data.append("key", key);
  data.append("action", "uploadfile");
  data.append("file", file);
  data.append("path", path);
  if (forceUnit !== "" && forceUnit === "in") {
    newWid = newWid * 2.54;
    newLgt = newLgt * 2.54;
  }
  if (width && length) {
    data.append("width", newWid);
    data.append("length", newLgt);
  }
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};

export const moveFile = async ({ key, oldfile, newpath }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "movefile");
  data.append("oldfile", oldfile);
  data.append("newpath", newpath);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
//TODO: check success
export const copyFileList = async ({ key, fileMap }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "copyfiles");
  data.append("filemap", JSON.stringify(fileMap));
  try {
    const res = await axios.post(fileApiUrl, data);
    return true;
  } catch (err) {
    return false;
  }
};
export const moveFileList = async ({ key, fileMap }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "movefiles");
  data.append("filemap", JSON.stringify(fileMap));
  try {
    const res = await axios.post(fileApiUrl, data);
    return true;
  } catch (err) {
    return false;
  }
};
export const copyFile = async ({ key, oldfile, newpath }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "copyfile");
  data.append("oldfile", oldfile);
  data.append("newpath", newpath);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const setFileOrder = async ({ key, fileOrder, path }) => {
  // return true;
  let data = new FormData();
  data.append("key", key);
  data.append("action", "addfileorder");
  data.append("order", JSON.stringify(fileOrder));
  data.append("path", path);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const changeDesignDims = async ({ key, file, physicalWidth, physicalHeight, unit }) => {
  const width = convert(physicalWidth)
    .from(unit)
    .to("cm");
  const height = convert(physicalHeight)
    .from(unit)
    .to("cm");
  let data = new FormData();
  data.append("key", key);
  data.append("action", "resize");
  data.append("width", width);
  data.append("length", height);
  data.append("file", file);
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};
export const addFolderInfo = async ({ key, path, info }) => {
  let data = new FormData();
  data.append("key", key);
  data.append("action", "addfolderinfo");
  data.append("path", path);
  data.append("info", comperessJSON(info));
  try {
    const res = await axios.post(fileApiUrl, data);
    return res.data === "Success";
  } catch (err) {
    return false;
  }
};