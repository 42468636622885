import React from "react";
import { roomTypes, defaultRoomType } from "./roomviewhelper";
const roomConfigState = {
  activeshot: "",
  activelight: "",
  activeview: "",
  activedata: {},
  teleportpoints: {},
  lightswitchpoints: {},
  objectstate: {},
  roomelements: {},
  roomname: "",
  roomtype: "",
  hasShadow: null,
  mouseControls: false,
  realTimeDynamicRendering: true,
  lightIntensity: 50,
  dims: {},
  floorOptions: {
    show: false,
    floors: [],
    activeFloor: {}
  },
  carpetOptions: {
    rotation: [],
    position: []
  },
  showViewpointDialog: false,
  customDesignUrl: { url: null, physicalWidCms: null, physicalHGtCms: null }
};
const SET_FLOOR_OPTIONS = "SET_FLOOR_OPTIONS";
const SET_ACTIVE_FLOOR_OPTION = "SET_ACTIVE_FLOOR_OPTION";
const SET_SHOW_FLOOR_OPTION = "SET_SHOW_FLOOR_OPTION";
const SET_CARPET_OPTION = "SET_CARPET_OPTION";
const SET_ROOM_OPTIONS = "SET_ROOM_OPTIONS";
const SET_SHOW_VP_DIALOG = "SET_SHOW_VP_DIALOG";
const SET_SHOW_COLOR_BOX = "SET_SHOW_COLOR_BOX";
const SET_CUSTOM_DESIGN_URL = "SET_SET_CUSTOM_DESIGN_URL";
const roomConfigActions = {
  SET_ACTIVE_FLOOR_OPTION,
  SET_SHOW_FLOOR_OPTION,
  SET_FLOOR_OPTIONS,
  SET_CARPET_OPTION,
  SET_ROOM_OPTIONS,
  SET_SHOW_VP_DIALOG,
  SET_SHOW_COLOR_BOX,
  SET_CUSTOM_DESIGN_URL
};
const roomConfigReducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case "RESET":
      return { ...roomConfigState };
    case "SET_ROOM_ELEMENTS":
      return !action.precalc
        ? setRoomElements({ state, payload: action.payload })
        : { ...action.precalc };
    case "INIT_ROOM_STATE":
      return !action.precalc
        ? initRoomState({ state, payload: action.payload })
        : { ...action.precalc };
    case "CHANGE_SHOT":
      return !action.precalc
        ? changeShot({ state, payload: action.payload })
        : { ...action.precalc };
    case "CHANGE_LIGHT":
      return !action.precalc
        ? changeLight({ state, payload: action.payload })
        : { ...action.precalc };
    case "CHANGE_LIGHT_INTENSITY":
      return !action.precalc
        ? changeLightIntensity({ state, payload: action.payload })
        : { ...action.precalc };
    case "CLEAR_ACTIVE_COLOR":
      return !action.precalc
        ? clearActiveColor({ state, payload: action.payload })
        : { ...action.precalc };
    case "SET_ACTIVE_COLOR":
      return !action.precalc
        ? setActiveColor({ state, payload: action.payload })
        : { ...action.precalc };
    case "TOGGLE_MYSTIC":
      return !action.precalc
        ? toggleMystic({ state, payload: action.payload })
        : { ...action.precalc };
    case "TOGGLE_LIGHT_SWITCH":
      return !action.precalc
        ? changeLightSwitch({ state, payload: action.payload })
        : { ...action.precalc };
    case "APPLY_TILT_SHIFT":
      return { ...state, tiltShiftFilter: action.payload }
    case SET_FLOOR_OPTIONS:
      return setFloorOptions(state, payload);
    case SET_ACTIVE_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, activeFloor: payload } };
    case SET_SHOW_FLOOR_OPTION:
      return { ...state, floorOptions: { ...state.floorOptions, show: !!payload } };
    case SET_CARPET_OPTION:
      return { ...state, carpetOptions: payload };
    case SET_ROOM_OPTIONS:
      return { ...state, ...payload }
    case SET_SHOW_VP_DIALOG:
      return { ...state, showViewpointDialog: payload }
    case SET_SHOW_COLOR_BOX:
      return setShowColorBox({ state, payload });
    case SET_CUSTOM_DESIGN_URL:
      return { ...state, customDesignUrl: !payload || payload === "" ? roomConfigState.customDesignUrl : payload }
    default:
      return state;
  }
};

const setFloorOptions = (state, payload) => {
  const { floorOptions } = state;
  const {
    floors = floorOptions.floors,
    activeFloor = floorOptions.activeFloor,
    show = floorOptions.show
  } = payload;
  return { ...state, floorOptions: { ...state.floorOptions, floors, activeFloor, show } };
};

// reducer case handlers

const setRoomElements = ({ state, payload }) => {
  const {
    floorOptions = {
      show: false,
      floors: [],
      activeFloor: {}
    },
    carpetOptions = {
      position: [],
      rotation: []
    }
  } = payload
  let unsortedobjects = payload.roomElements.objects;
  const sortedobjectsarr = Object.entries(unsortedobjects)
    .sort((a, b) => a[1].order - b[1].order)
    .map(el => {
      return { [el[0]]: el[1] };
    });
  const sortedobjects = sortedobjectsarr.reduce((obj, item) => {
    obj[Object.keys(item)[0]] = Object.values(item)[0];
    return obj;
  }, {});

  const roomtype = payload.roomType && payload.roomType !== "" ? payload.roomType : defaultRoomType;
  const hasShadow = payload.hasShadow ? payload.hasShadow : false;
  const mouseControls = payload.mouseControls ? payload.mouseControls : false;
  const realTimeDynamicRendering =
    payload.realTimeDynamicRendering !== undefined || payload.realTimeDynamicRendering !== null
      ? payload.realTimeDynamicRendering
      : true;
  const roomelements = { ...payload.roomElements, objects: { ...sortedobjects } }
  let newstate = {
    ...state,
    roomname: payload.name,
    pointerinputcanvas: roomtype === roomTypes.ROOMVIEW,
    roomtype: roomtype,
    hasShadow: hasShadow,
    mouseControls: mouseControls,
    realTimeDynamicRendering: realTimeDynamicRendering,
    dims: { ...payload.dims },
    roomelements,
    floorOptions,
    carpetOptions
  };
  return initRoomState({ state: newstate, payload });
};

const initRoomState = ({ state, payload }) => {
  const activeshot = payload.activeshot
    ? payload.activeshot
    : Object.keys(state.roomelements.shots)[0];

  const hasLights =
    Object.keys(state.roomelements.lights) && Object.keys(state.roomelements.lights).length > 0;
  const activelight = payload.activelight
    ? payload.activelight
    : hasLights
      ? Object.keys(state.roomelements.lights)[0]
      : "";

  const activeview = payload.activeview
    ? payload.activeview
    : hasLights
      ? state.roomelements.lights[activelight].views[0]
      : "";

  const activedata = state.roomelements.shots[activeshot].activeData;

  const teleportpoints = state.roomelements.shots[activeshot].teleportPoints;

  const lightsources = hasLights
    ? state.roomelements.lights[activelight].lightSources
    : state.roomelements.shots[activeshot].lightSources;

  let alllightswitchpoints = { ...state.roomelements.shots[activeshot].lightSwitchPoints };
  let lightswitchpoints = {};
  lightsources &&
    lightsources.forEach(lightobject => {
      if (alllightswitchpoints[lightobject])
        lightswitchpoints[lightobject] = alllightswitchpoints[lightobject];
    });

  let objectstate = {};
  Object.keys(state.roomelements.objects).forEach((object, index) => {
    let objectval = state.roomelements.objects[object];
    let formattedObj = {};
    formattedObj.order = objectval.order;
    const isobjectinscene =
      state.roomelements.shots[activeshot].objects &&
      Object.keys(state.roomelements.shots[activeshot].objects).length > 0 &&
      Object.keys(state.roomelements.shots[activeshot].objects).indexOf(object) > -1;

    if (isobjectinscene) {
      formattedObj.zindex = state.roomelements.shots[activeshot].objects[object].zindex;
      formattedObj.isobjectinscene = true;
    } else {
      formattedObj.zindex = -1;
      formattedObj.isobjectinscene = false;
    }
    if (objectval.active) formattedObj.active = "init";
    if (objectval.mystic) formattedObj.mystic = "show";
    if (lightsources && lightsources.includes(object)) formattedObj.lightSwitch = "on";
    objectstate[object] = formattedObj;
  });

  return {
    ...state,
    activeshot: activeshot,
    activelight: activelight,
    activeview: activeview,
    activedata: { ...activedata },
    teleportpoints: { ...teleportpoints },
    lightswitchpoints: { ...lightswitchpoints },
    objectstate: { ...objectstate }
  };
};

const changeShot = ({ state, payload }) => {
  const { label, thumbUrl } = payload;

  const activeshot = payload.activeshot;
  const activedata = state.roomelements.shots[activeshot].activeData;
  const teleportpoints = state.roomelements.shots[activeshot].teleportPoints;

  const lightsources = (state.roomelements.lights && state.activelight) ? state.roomelements.lights[state.activelight].lightSources : null;
  let alllightswitchpoints = { ...state.roomelements.shots[activeshot].lightSwitchPoints };
  let lightswitchpoints = {};
  lightsources &&
    lightsources.forEach(lightobject => {
      if (alllightswitchpoints[lightobject])
        lightswitchpoints[lightobject] = alllightswitchpoints[lightobject];
    });

  let objectstate = {};
  Object.keys(state.objectstate).forEach((object, index) => {
    let formattedObj = { ...state.objectstate[object] };
    const isobjectinscene =
      state.roomelements.shots[activeshot].objects &&
      Object.keys(state.roomelements.shots[activeshot].objects).length > 0 &&
      Object.keys(state.roomelements.shots[activeshot].objects).indexOf(object) > -1;

    if (isobjectinscene) {
      formattedObj.zindex = state.roomelements.shots[activeshot].objects[object].zindex;
      formattedObj.isobjectinscene = true;
    } else {
      formattedObj.zindex = -1;
      formattedObj.isobjectinscene = false;
    }
    objectstate[object] = formattedObj;
  });

  return {
    ...state,
    activeshot: activeshot,
    activedata: { ...activedata },
    teleportpoints: { ...teleportpoints },
    lightswitchpoints: { ...lightswitchpoints },
    objectstate: { ...objectstate }, label, thumbUrl
  };
};

const changeLightIntensity = ({ state, payload }) => {
  return {
    ...state,
    lightIntensity: payload
  };
};

const changeLight = ({ state, payload }) => {
  const activelight = payload.activelight;

  const lightsources = state.roomelements.lights[activelight].lightSources;
  let alllightswitchpoints = { ...state.roomelements.shots[state.activeshot].lightSwitchPoints };
  let lightswitchpoints = {};
  lightsources &&
    lightsources.forEach(lightobject => {
      if (alllightswitchpoints[lightobject])
        lightswitchpoints[lightobject] = alllightswitchpoints[lightobject];
    });

  let objectstate = {};
  Object.keys(state.objectstate).forEach((object, index) => {
    let formattedObj = { ...state.objectstate[object] };

    if (lightsources && lightsources.includes(object)) formattedObj.lightSwitch = "on";
    objectstate[object] = formattedObj;
  });

  const activeview = calculateView({ state, activelight, objectstate });

  return {
    ...state,
    activelight: activelight,
    activeview: activeview,
    lightswitchpoints: { ...lightswitchpoints },
    objectstate: { ...objectstate }
  };
};

const clearActiveColor = ({ state, payload }) => {
  const object = payload.object;
  return {
    ...state,
    objectstate: {
      ...state.objectstate,
      [object]: { ...state.objectstate[object], active: "init" }
    }
  };
};

const setActiveColor = ({ state, payload }) => {
  const object = payload.object;
  const color = payload.color;
  return {
    ...state,
    objectstate: { ...state.objectstate, [object]: { ...state.objectstate[object], active: color } }
  };
};
const setShowColorBox = ({ state, payload }) => {
  const { object, isOpen } = payload;
  return {
    ...state,
    objectstate: { ...state.objectstate, [object]: { ...state.objectstate[object], isOpen } }
  };
}

const toggleMystic = ({ state, payload }) => {
  const object = payload.object;
  const newmysticval = state.objectstate[object].mystic === "show" ? "hide" : "show";
  const newobjectstate = {
    ...state.objectstate,
    [object]: { ...state.objectstate[object], mystic: newmysticval }
  };
  const activeview =
    state.roomelements.lights[state.activelight].lightSources &&
      state.roomelements.lights[state.activelight].lightSources.includes(object)
      ? calculateView({ state, objectstate: newobjectstate })
      : state.activeview;
  return {
    ...state,
    activeview: activeview,
    objectstate: { ...newobjectstate }
  };
};

const changeLightSwitch = ({ state, payload }) => {
  const object = payload.object;
  const newlightswitchval = state.objectstate[object].lightSwitch === "on" ? "off" : "on";
  const newobjectstate = {
    ...state.objectstate,
    [object]: { ...state.objectstate[object], lightSwitch: newlightswitchval }
  };
  const activeview = calculateView({ state, objectstate: newobjectstate });
  return {
    ...state,
    activeview: activeview,
    objectstate: { ...newobjectstate }
  };
};

// reducer case handler helpers

const calculateView = ({ state, activelight, objectstate }) => {
  let light = activelight ? activelight : state.activelight;
  let thisobjectstate = objectstate ? objectstate : state.objectstate;

  let newview = state.activeview ? state.activeview : state.roomelements.lights[light].views[0];

  let lightobjectstatus = {};
  if (state.roomelements.lights[light].lightSources) {
    state.roomelements.lights[light].lightSources.forEach(lightsource => {
      lightobjectstatus[lightsource] =
        (thisobjectstate[lightsource].mystic
          ? thisobjectstate[lightsource].mystic === "show"
          : true) && thisobjectstate[lightsource].lightSwitch === "on"
          ? true
          : false;
    });

    const objectviewdataarr = state.roomelements.lights[light].objectViews.filter(
      objectviewconfig => {
        let thisistheview = true;
        Object.keys(lightobjectstatus).forEach(lightsourceobj => {
          let isobjectinthisobjectview = !objectviewconfig[lightsourceobj]
            ? false
            : objectviewconfig[lightsourceobj];
          if (isobjectinthisobjectview !== lightobjectstatus[lightsourceobj]) thisistheview = false;
        });
        return thisistheview;
      }
    );
    newview = objectviewdataarr.length > 0 ? objectviewdataarr[0].view : newview;
  }
  newview = state.roomelements.lights[light].views.includes(newview)
    ? newview
    : state.roomelements.lights[light].views[0];
  return newview;
};

export const roomConfigReducerHandlers = {
  setRoomElements,
  initRoomState,
  changeShot,
  changeLight,
  clearActiveColor,
  setActiveColor,
  toggleMystic,
  changeLightSwitch,
  calculateView
};

// dispatch reducer as context

const StateContext = React.createContext();
const DispatchContext = React.createContext();

function RoomConfigStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(roomConfigReducer, roomConfigState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useRoomConfigState() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error("useRoomConfigState must be used within a RoomConfigStateProvider");
  }
  return context;
}
function useDispatchRoomConfig() {
  const context = React.useContext(DispatchContext);
  if (context === undefined) {
    throw new Error("useDispatchRoomConfig must be used within a RoomConfigStateProvider");
  }
  return context;
}
export { RoomConfigStateProvider, useRoomConfigState, useDispatchRoomConfig, roomConfigActions };
