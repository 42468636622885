import {
  mergeArraysWithoutDuplicate,
  convertNameToTilePoint,
  convertTilePointToName,
  readImage
} from "./utils/utils";
import AppProvider from "./api/appProvider";
import {generateHash} from "./reducers/designdetails.reducer"
let requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame;

let cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

const tileStandardSize = 256;

const calculateDistance = (pointA, pointB) => {
  const { x: ia, y: ja } = pointA;
  const { x: ib, y: jb } = pointB;
  return Math.sqrt(Math.pow(ia - ib, 2) + Math.pow(ja - jb, 2));
};
export default class TileCanvas {
  constructor(options = {}) {
    const { canvas } = options;
    if (canvas) {
      this.setRenderingCanvas(canvas);
    }
    // this.tileArray = { sources: null, images: null }
    // this.imageLoader = new ImageLoader()
    this.tilePoints = [];
    this.width = null;
    this.height = null;
    this.tilesToUpdate = [];
    this.designTilesToUpdate = [];
    this.normapTilesToUpdate = [];
    this.roughTilesToUpdate = [];
    this.offsetX = 0;
    this.offsetY = 0;
    this.canvas = document.createElement("canvas");
    this.canvasBack = document.createElement("canvas");
    this.canvasNorm = document.createElement("canvas");
    this.canvasRough = document.createElement("canvas");
    this.canvasVis = document.createElement("canvas");
    // this.canvasNorm.classList.add("test-canvas");
    // document.body.appendChild(this.canvas);
    // document.body.appendChild(this.canvasNorm);
  }

  init(options) {
    const {
      tileSize = tileStandardSize,
      designDetails,
      maxArea = 15000*15000,
      zoom,
      offset = [0, 0],
      renderBounds,
      canvasSize = { width: null, height: null }
    } = options;
    this.desTilesUpdated = false;
    this.normapTilesUpdated = false;
    this.zoom = zoom;
    this.tileSize = tileSize;
    this.designDetails = designDetails;
    const { Width, Height } = designDetails;
    const ratio = Width / Height;
    let designWidth = Width * zoom;
    let designHeight = designWidth / ratio;

    if (maxArea && (designWidth * designHeight > maxArea)) {
      let newdesignWidth = Math.trunc(Math.sqrt(maxArea * ratio));
      let newdesignHeight = Math.trunc(Math.sqrt(maxArea / ratio));
      const resizeRatio = newdesignWidth / designWidth;
      designWidth = newdesignWidth;
      designHeight = newdesignHeight;
      this.tileSize = Math.trunc(this.tileSize * resizeRatio);
    }

    let w = designWidth;
    let h = designHeight;

    const { width: canvasWidth, height: canvasHeight } = canvasSize;
    if (canvasWidth) w = canvasWidth;
    if (canvasHeight) h = canvasHeight;
    this.width = w;
    this.height = h;
    const dw = w - designWidth;
    const dh = h - designHeight;

    this.offsetX = dw * offset[0];
    this.offsetY = dh * offset[1];

    this.canvas.width = w;
    this.canvas.height = h;
    this.canvasBack.width = w;
    this.canvasBack.height = h;
    this.canvasNorm.height = h;
    this.canvasNorm.width = w;
    this.canvasRough.height = h;
    this.canvasRough.width = w;
    this.canvasVis.height = h;
    this.canvasVis.width = w;

    // const ctx = this.canvas.getContext("2d");
    // ctx.fillStyle = "rgb(0,0,0)";
    // ctx.fillRect(0, 0, canvasWidth, canvasHeight)
    let xOff = 0,
      yOff = 0;
    let tilesWidth = designWidth,
      tilesHeight = designHeight;
    //*important: the points should be multiple of 256 and should not be greater than width of canvas itself
    let p1 = { x: 0, y: 0 };
    let p2 = { x: designWidth, y: designHeight };

    if (renderBounds) {
      const { p1: point1, p2: point2 } = renderBounds;
      if (point1) p1 = point1;
      if (point2) p2 = point2;
    }
    /*
     * * if drawing canvas is smaller, tiles should not start from 0 and not end on design width depending on offset value
     * * if drawing canvas is larger, tiles should start from 0 but drawn on offsetpoint
     */
    if (this.offsetX < 0) {
      // * start drawing tiles from offset point, add bound start point to it
      xOff = this.offsetX - p1.x;
      // * draw tiles till the end of canvas or specified bound
      tilesWidth = p2.x - p1.x;
    } else {
      // * first tile is start point of bound
      xOff = p1.x;
      // * draw till last point of bound
      tilesWidth = p2.x - xOff;
    }
    if (this.offsetY < 0) {
      yOff = this.offsetY - p1.y;
      tilesHeight = p2.y - p1.y;
    } else {
      yOff = p1.y - this.offsetY;
      tilesHeight = p2.y - yOff;
      if (yOff < 0) yOff = 0;
    }
    if (tilesWidth > designWidth) tilesWidth = designWidth - Math.abs(xOff);
    if (tilesHeight > designHeight) tilesHeight = designHeight - Math.abs(yOff);
    const xOffPoint = Math.floor(Math.abs(xOff) / this.tileSize);
    const yOffPoint = Math.floor(Math.abs(yOff) / this.tileSize);

    this.xTotal = Math.floor((tilesWidth - 1) / this.tileSize) + 1 + xOffPoint;
    this.yTotal = Math.floor((tilesHeight - 1) / this.tileSize) + 1 + yOffPoint;
    this.tilePoints = [];

    for (let x = xOffPoint; x < this.xTotal; x++) {
      for (let y = yOffPoint; y < this.yTotal; y++) {
        this.tilePoints.push({ x, y, name: convertTilePointToName(x, y) });
      }
    }
    this.tilesToUpdate = this.tilePoints;
    this.designTilesToUpdate = this.tilePoints;
    this.normapTilesToUpdate = JSON.parse(JSON.stringify(this.tilePoints));
    this.roughTilesToUpdate = JSON.parse(JSON.stringify(this.tilePoints));
    this.visTilesToUpdate = JSON.parse(JSON.stringify(this.tilePoints));
    this.fetchNonce = null;
    this.fetchNonce1 = null;
    this.fetchNonce2 = null;
    this.fetchNonce3 = null;
    this.fetchNonce4 = null;
    this.fetchNonce5 = null;
    this.latesttiles = null;
  }

  drawCanvasBackTiles(options = {}, onUpdate, onComplete) {
    const { zoom } = this;
    const { x = 0, y = 0, designPath, renderBounds } = options;
    const designDetails = {...options.designDetails};
    let hash = options.hash;
    let tilesWidth, tilesHeight, xOff, yOff, xTotal, yTotal, tilePoints;
    const { Width, Height } = designDetails;
    const ratio = Width / Height;
    const designWidth = Width * zoom;
    const designHeight = designWidth / ratio;
    let p1 = { x: 0, y: 0 };
    let p2 = { x: designWidth, y: designHeight };

    if (renderBounds) {
      const { p1: point1, p2: point2 } = renderBounds;
      if (point1) p1 = point1;
      if (point2) p2 = point2;
    }
    if (this.offsetX < 0) {
      xOff = this.offsetX - p1.x;
      tilesWidth = p2.x - p1.x;
    } else {
      // * first tile is start point of bound
      xOff = p1.x;
      // * draw till last point of bound
      tilesWidth = p2.x - xOff;
    }
    if (this.offsetY < 0) {
      yOff = this.offsetY - p1.y;
      tilesHeight = p2.y - p1.y;
    } else {
      yOff = p1.y - this.offsetY;
      tilesHeight = p2.y - yOff;
      if (yOff < 0) yOff = 0;
    }
    if (tilesWidth > designWidth) tilesWidth = designWidth - Math.abs(xOff);
    if (tilesHeight > designHeight) tilesHeight = designHeight - Math.abs(yOff);
    const xOffPoint = Math.floor(Math.abs(xOff) / this.tileSize);
    const yOffPoint = Math.floor(Math.abs(yOff) / this.tileSize);

    xTotal = Math.floor((tilesWidth - 1) / this.tileSize) + 1 + xOffPoint;
    yTotal = Math.floor((tilesHeight - 1) / this.tileSize) + 1 + yOffPoint;
    tilePoints = [];

    for (let x = xOffPoint; x < xTotal; x++) {
      for (let y = yOffPoint; y < yTotal; y++) {
        tilePoints.push({ x, y, name: convertTilePointToName(x, y) });
      }
    }

    const ctx = this.canvasBack.getContext("2d");
    const backTextureMaterialIndex = 4;
    let newDesignColors = [];
    designDetails.DesignColors.forEach((designColor, index) => {
      let newYarnDetails = [];
      designColor.YarnDetails.forEach((yarnDetails, yarnIndex) => {
        newYarnDetails.push({ ...yarnDetails, Material: backTextureMaterialIndex });
      });
      newDesignColors.push({
        ...designColor,
        Material: backTextureMaterialIndex,
        YarnDetails: [...newYarnDetails]
      });
    });
    hash = generateHash(designDetails,designPath  );

    const pivot = {
      x: Math.round((xOffPoint + xTotal) / 2),
      y: Math.round((yOffPoint + yTotal) / 2)
    };

    if (tilePoints.length) {
      //calculate distance from pivot point of each point
      const tileVec = tilePoints.map(tile => ({
        ...tile,
        dist: calculateDistance(tile, pivot)
      }));
      tileVec.sort((a, b) => (a.dist > b.dist ? 1 : -1));

      const designTileNamesinVp = tileVec.map(tile => tile.name);
      const designApiProps = {
        file: designPath,
        zoom,
        tiles: designTileNamesinVp,
        props: { ...designDetails, DesignColors: [...newDesignColors] },
        hash
      };

      AppProvider.fetchDesignTiles(designApiProps).then(baseDesignPath => {
        drawTilesIterateFunc({ tileVec, baseDesignPath, hash, ctx, onComplete, mime: window.InterfaceElements.IsJpeg ? "rendered.jpg" : "jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })
      });
    }
  }
  drawCanvasTiles(options = {}, onUpdate, onComplete) {
    const { xTotal, yTotal, zoom } = this;
    const {
      x = 0,
      y = 0,
      endX = xTotal,
      endY = yTotal,
      designPath,
      designDetails,
      drawNormap = true,
      drawRoughMap = false,
      hash,
      tileTransparency = []
    } = options;
    const localNonce = (this.fetchNonce = {});
    const ctx = this.canvas.getContext("2d");
    const ctxnorm = this.canvasNorm.getContext("2d");
    const ctxrough = this.canvasRough.getContext("2d");
    const { startX, startY, endpointX, endpointY } = this.getStartEndPoints(x, y, endX, endY);

    const designTilesinVp = this.designTilesToUpdate.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const pivot = {
      x: Math.round((startX + endpointX) / 2),
      y: Math.round((startY + endpointY) / 2)
    };
    //tiles not in vp
    const designTilesNotinVp = this.designTilesToUpdate.filter(
      tile => !designTilesinVp.includes(tile)
    );
    this.designTilesToUpdate = designTilesNotinVp;

    const normapTilesinVp = this.normapTilesToUpdate.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const normapTilesNotinVp = this.normapTilesToUpdate.filter(
      tile => !normapTilesinVp.includes(tile)
    );
    this.normapTilesToUpdate = normapTilesNotinVp;

    const roughmapTilesinVp = this.roughTilesToUpdate.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const roughmapTilesNotinVp = this.roughTilesToUpdate.filter(
      tile => !roughmapTilesinVp.includes(tile)
    );
    this.roughTilesToUpdate = roughmapTilesNotinVp;

    // let designsLoaded = false, normapsloaded = false

    if (designTilesinVp.length) {
      //calculate distance from pivot point of each point
      const tileVec = designTilesinVp.map(tile => ({
        ...tile,
        dist: calculateDistance(tile, pivot)
      }));
      tileVec.sort((a, b) => (a.dist > b.dist ? 1 : -1));

      const designTileNamesinVp = tileVec.map(tile => tile.name);
      const designApiProps = {
        file: designPath,
        zoom,
        tiles: designTileNamesinVp,
        props: designDetails,
        hash
      };
      let designtilescomplete = false;
      let normaptilescomplete = !drawNormap;
      let roughmaptilescomplete = !drawRoughMap;

      const checkComplete = () => {
        if (designtilescomplete && normaptilescomplete && roughmaptilescomplete) {
          if (tileTransparency.length) {
            ctx.globalCompositeOperation = "destination-in";
            ctx.drawImage(this.canvasNorm, 0, 0);
          } else {
            ctx.globalCompositeOperation = "source-over";
          }
          onComplete()
        }
      };
      const drawNormalMaps = () => {
        if (normapTilesinVp.length && drawNormap) {
          const designTileNamesinVp = designTilesinVp.map(tile => tile.name);
          const designApiProps = {
            file: designPath,
            zoom,
            tiles: designTileNamesinVp,
            props: designDetails,
            hash
          };
          AppProvider.fetchPileTiles(designApiProps).then((baseNormapPath, index) => {
            const drawFinishNormap = () => {
              normaptilescomplete = true;
              checkComplete();
            };
            drawTilesIterateFunc({ tileVec: normapTilesinVp, baseDesignPath: baseNormapPath, hash, ctx: ctxnorm, onComplete: drawFinishNormap, mime: "png", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } });

          });
        }
      };

      const drawRoughMaps = () => {
        if (roughmapTilesinVp.length && drawRoughMap) {
          const designTileNamesinVp = designTilesinVp.map(tile => tile.name);
          const designApiProps = {
            file: designPath,
            zoom,
            tiles: designTileNamesinVp,
            props: designDetails,
            hash
          };
          AppProvider.fetchSilkSheenTiles(designApiProps).then((baseRoughmapPath, index) => {
            const drawFinishRoughmap = () => {
              roughmaptilescomplete = true;
              checkComplete();
            };
            drawTilesIterateFunc({ tileVec: roughmapTilesinVp, baseDesignPath: baseRoughmapPath, hash, ctx: ctxrough, onComplete: drawFinishRoughmap, mime: "silk.jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } });

          });
        }
      };

      AppProvider.fetchDesignTiles(designApiProps).then(baseDesignPath => {
        const drawFinishDesignTiles = () => {
          if (!drawNormap) {
            ctxnorm.fillStyle = "rgb(127,127,255)";
            ctxnorm.fillRect(0, 0, this.canvasNorm.width, this.canvasNorm.height);
            designtilescomplete = true;
            checkComplete();
            return;
          }
          designtilescomplete = true;
          checkComplete();
        };
        drawTilesIterateFunc({ tileVec: tileVec, baseDesignPath: baseDesignPath, hash, ctx: ctx, onComplete: drawFinishDesignTiles, mime: window.InterfaceElements.IsJpeg ? "rendered.jpg" : "jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })

      });
      if (localNonce !== this.fetchNonce) return;
      if (drawNormap) {
        drawNormalMaps();
      }
      if (drawRoughMap) {
        drawRoughMaps();
      }
    }
  }
  drawVisTiles(options, onComplete) {
    const { xTotal, yTotal, zoom } = this;
    const { x = 0, y = 0, endX = xTotal, endY = yTotal, designPath, designDetails, hash } = options;
    let localNonce = (this.fetchNonce4 = {});
    const ctxVis = this.canvasVis.getContext("2d");
    const { startX, startY, endpointX, endpointY } = this.getStartEndPoints(x, y, endX, endY);
    const visTilesinVP = this.designTilesToUpdate.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const pivot = {
      x: Math.round((startX + endpointX) / 2),
      y: Math.round((startY + endpointY) / 2)
    };
    if (visTilesinVP.length) {
      const tileVec = visTilesinVP.map(tile => ({
        ...tile,
        dist: calculateDistance(tile, pivot)
      }));
      tileVec.sort((a, b) => (a.dist > b.dist ? 1 : -1));

      const apiProps = {
        file: designPath,
        zoom,
        tiles: tileVec.map(tile => tile.name),
        props: designDetails,
        hash
      };
      AppProvider.fetchVisualizationTiles(apiProps).then(basePath => {
        const drawInDesignCanvasFinish = () => {
          if (onComplete) {
            onComplete();
            return;
          }

        };
        drawTilesIterateFunc({ tileVec: tileVec, baseDesignPath: basePath, hash, ctx: ctxVis, onComplete: drawInDesignCanvasFinish, mime: "rendered.jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })

      });
    }
    //tiles not in vp
    const designTilesNotinVp = this.designTilesToUpdate.filter(
      tile => !visTilesinVP.includes(tile)
    );
    this.designTilesToUpdate = designTilesNotinVp;
  }
  updateVisTiles(options, onComplete) {
    const { xTotal, yTotal } = this;
    const {
      x,
      y,
      endX = xTotal,
      endY = yTotal,
      tiles,
      zoom,
      designPath,
      designDetails,
      hash
    } = options;
    let tilePoints;
    let localNonce = (this.fetchNonce3 = {});
    if (tiles) tilePoints = tiles.map(name => ({ ...convertNameToTilePoint(name), name }));
    else tilePoints = this.tilePoints;
    const { startX, startY, endpointX, endpointY } = this.getStartEndPoints(x, y, endX, endY);
    const tilesinVp = tilePoints.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const tileNamesVp = tilesinVp.map(tile => convertTilePointToName(tile.x, tile.y));
    const tilesNotInVp = tilePoints.filter(n => !tilesinVp.includes(n));
    this.visTilesToUpdate = mergeArraysWithoutDuplicate(this.visTilesToUpdate, tilesNotInVp);
    const ctxVis = this.canvasVis.getContext("2d");

    AppProvider.fetchVisualizationTiles({
      file: designPath,
      zoom,
      tiles: tileNamesVp,
      props: designDetails,
      hash
    }).then(baseDesignPath => {
      drawTilesIterateFunc({ tileVec: tilesinVp, baseDesignPath: baseDesignPath, hash, ctx: ctxVis, onComplete: onComplete, mime: "rendered.jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })
    });
  }
  updateDesignTiles(options, onUpdate, onComplete) {
    const { xTotal, yTotal } = this;
    const {
      x,
      y,
      endX = xTotal,
      endY = yTotal,
      tiles,
      zoom,
      designPath,
      designDetails,
      hash,
      tileTransparency = []
    } = options;
    const localNonce = (this.fetchNonce1 = {});
    let tilePoints;
    if (tiles) tilePoints = tiles.map(name => ({ ...convertNameToTilePoint(name), name }));
    else tilePoints = this.tilePoints;
    const { startX, startY, endpointX, endpointY } = this.getStartEndPoints(x, y, endX, endY);
    const tilesinVp = tilePoints.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    const tileNamesVp = tilesinVp.map(tile => convertTilePointToName(tile.x, tile.y));
    const tilesNotInVp = tilePoints.filter(n => !tilesinVp.includes(n));
    this.designTilesToUpdate = mergeArraysWithoutDuplicate(this.designTilesToUpdate, tilesNotInVp);
    const ctx = this.canvas.getContext("2d");
    this.latesttiles = [...tileNamesVp];
    AppProvider.fetchDesignTiles({
      file: designPath,
      zoom,
      tiles: tileNamesVp,
      props: designDetails,
      hash
    }).then(baseDesignPath => {
      const issamecolornumber = JSON.stringify(this.latesttiles) === JSON.stringify(tileNamesVp);
      if (issamecolornumber && localNonce !== this.fetchNonce1) return;
      let loadcount = 0;
      const latesttiles = this.latesttiles;
      const uniquetilesinvp = issamecolornumber
        ? tilesinVp
        : tilesinVp.filter(function (tile) {
          return !latesttiles.includes(tile.name);
        });
      const drawInDesignCanvasFinish = (index) => {
        if (tileTransparency.length) {
          ctx.globalCompositeOperation = "destination-in";
          ctx.drawImage(this.canvasNorm, 0, 0);
        }
        onComplete()
      };
      drawTilesIterateFunc({ tileVec: uniquetilesinvp, baseDesignPath: baseDesignPath, hash, ctx: ctx, onComplete: drawInDesignCanvasFinish, mime: window.InterfaceElements.IsJpeg ? "rendered.jpg" : "jpg", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })

    });
  }
  updateNormapTiles(options, onUpdate, onComplete) {
    const { xTotal, yTotal } = this;
    const {
      x,
      y,
      endX = xTotal,
      endY = yTotal,
      tiles,
      zoom,
      designPath,
      designDetails,
      hash = ""
    } = options;
    const localNonce = (this.fetchNonce2 = {});
    const ctxNorm = this.canvasNorm.getContext("2d");
    let tilePoints;
    if (tiles) tilePoints = tiles.map(name => convertNameToTilePoint(name));
    else tilePoints = this.tilePoints;
    const { startX, startY, endpointX, endpointY } = this.getStartEndPoints(x, y, endX, endY);
    const tilesinVp = tilePoints.filter(point => {
      const { x, y } = point;
      return x >= startX && y >= startY && x <= endpointX && y <= endpointY;
    });
    this.normapTilesUpdated = true;
    const tileNamesVp = tilesinVp.map(tile => convertTilePointToName(tile.x, tile.y));

    const tilesNotInVp = tilePoints.filter(n => !tilesinVp.includes(n));
    this.normapTilesToUpdate = mergeArraysWithoutDuplicate(this.normapTilesToUpdate, tilesNotInVp);

    AppProvider.fetchPileTiles({
      file: designPath,
      zoom,
      tiles: tileNamesVp,
      props: designDetails,
      hash
    }).then(baseNormalPath => {
      drawTilesIterateFunc({ tileVec: tilesinVp, baseDesignPath: baseNormalPath, hash, ctx: ctxNorm, onComplete: onComplete, mime: "png", tileSize: this.tileSize, offset: { X: this.offsetX, Y: this.offsetY } })
    });
  }

  getStartEndPoints(x, y, endX, endY) {
    const { xTotal, yTotal } = this;

    let startX = x >= 0 ? x : 0;
    let startY = y >= 0 ? y : 0;

    return {
      startX,
      startY,
      endpointX: endX > xTotal ? xTotal : endX,
      endpointY: endY > yTotal ? yTotal : endY
    };
  }
}

const drawTilesIterateFunc = ({ tileVec, baseDesignPath, hash, ctx, onComplete, mime, tileSize, offset }) => {
  let tileImagesLoaded = 0;
  let errorArr = [];
  tileVec.forEach((tilePoint, index) => {
    const { name: tileName } = tilePoint;
    let filename = `${baseDesignPath}/${tileName}.${mime}?t=${hash}`;
    const img = document.createElement("img");
    img.setAttribute("crossOrigin", "Anonymous");
    img.src = filename;
    tilePoint.image = img;
    img.onerror = () => {
      tileImagesLoaded++;
      errorArr.push(tilePoint);
      if (tileImagesLoaded === tileVec.length) {
        drawFinish();
      }
    }
    img.onload = () => {
      drawSingleTileInDesignCanvas(index);
      tileImagesLoaded++;
      if (tileImagesLoaded === tileVec.length) {
        drawFinish();
      }
    };
  });

  const drawSingleTileInDesignCanvas = index => {
    const tilepoint = tileVec[index];
    const startX = tilepoint.x * tileSize + offset.X;
    const startY = tilepoint.y * tileSize + offset.Y;
    const destTileSizeX = tileSize * tilepoint.image.width / tileStandardSize;
    const destTileSizeY = tileSize * tilepoint.image.height / tileStandardSize;
    ctx.drawImage(tilepoint.image, startX, startY, destTileSizeX, destTileSizeY);
  };

  const drawFinish = () => {
    let errorCount = errorArr.length;
    if (errorCount > 0) {
      let errorLoadCount = 0;
      errorArr.forEach(tilePoint => {
        const img = document.createElement("img");
        img.setAttribute("crossOrigin", "Anonymous");
        img.onerror = () => {
          errorLoadCount++;
          if (errorLoadCount === errorArr.length) {
            finishError();
          }
        }
        img.onload = () => {
          tilePoint.image = img;
          const startX = tilePoint.x * tileSize + offset.X;
          const startY = tilePoint.y * tileSize + offset.Y;
          const destTileSizeX = tileSize * tilePoint.image.width / tileStandardSize;
          const destTileSizeY = tileSize * tilePoint.image.height / tileStandardSize;
          ctx.drawImage(tilePoint.image, startX, startY, destTileSizeX, destTileSizeY);
          errorCount--;
          errorLoadCount++;
          if (errorLoadCount === errorArr.length) {
            finishError();
          }
        };
        img.src = tilePoint.image.src;
      });
      const finishError = () => {
        if (errorCount !== 0) {
          console.error("Some Tiles could not load");
        }
        onComplete();
      }
    }
    else {
      onComplete();
    }
  }
}