import React from "react";
const StateContext = React.createContext();
const DispatchContext = React.createContext();


const SET_COLORS = "SET_COLORS";
const SELECT_COLOR = "SELECT_COLOR";
const SELECT_COLLECTION = "SELECT_COLLECTION";
const SET_ACTIVE_COLOR = "SET_ACTIVE_COLOR";
const SEARCH_COLOR = "SEARCH_COLOR";
const SET_COLOR_TEXTURES = "SET_COLOR_TEXTURES";
const colorListActions = {
  SET_COLORS,
  SELECT_COLOR,
  SELECT_COLLECTION,
  SET_ACTIVE_COLOR,
  SEARCH_COLOR,
  SET_COLOR_TEXTURES
};
const defColIndex = 0;
const colorListState = {
  loading: true,
  collections: null,
  filteredCollection: null,
  collectionIndex: defColIndex,
  showColorSelectionBox: false,
  activeColor: null,
  isSearching: false,
  colorTextures: null
};

const colorListReducer = (state, action) => {
  switch (action.type) {
    case SET_COLORS:
      return { ...state, collections: action.payload, filteredCollection: action.payload[defColIndex], loading: false }
    case SELECT_COLLECTION:
      return { ...state, collectionIndex: action.payload, filteredCollection: state.collections[action.payload] };
    case SELECT_COLOR:
      if (!state.collections) return state;
      return findColor(state, action.payload)
    case SET_ACTIVE_COLOR:
      return { ...state, activeColor: action.payload }
    case SEARCH_COLOR:
      return searchColor(state, action.payload)
    case SET_COLOR_TEXTURES:
      return { ...state, colorTextures: action.payload }
    default:
      return state;
  }
};
const findColor = (state, payload) => {
  let collectionIndex = 0;
  let activeColor = null;
  const array = [...state.collections]
  const obj = payload;
  array.forEach((coll, index) => {
    const ind = coll.ColorRows.findIndex(colorObj => {

      return obj.Color === colorObj.Color && obj.ColorName === colorObj.ColorName;
    })
    if (ind !== -1) {
      collectionIndex = index;
      activeColor = coll.ColorRows[ind]
    }
  })
  return { ...state, filteredCollection: state.collections[collectionIndex], collectionIndex, showColorSelectionBox: true, activeColor }
}
const searchColor = (state, payload) => {
  const { collections, collectionIndex } = state
  const { ColorRows } = collections[collectionIndex]
  const keyword = payload;
  const filteredRows = ColorRows.filter(colorRow =>
    colorRow.ColorName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
  )
  return {
    ...state, filteredCollection: {
      ...state.filteredCollection, ColorRows: filteredRows
    }, isSearching: true
  }
}
function ColorListStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(colorListReducer, colorListState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}
function useColorListState() {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a CountProvider')
  }
  return context
}
function useDispatchColorList() {
  const context = React.useContext(DispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a CountProvider')
  }
  return context
}
export { ColorListStateProvider, useColorListState, useDispatchColorList, colorListActions }
