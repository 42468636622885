import React, { useEffect, useState } from "react";
import "./daynightslider.scss";
import { ReactComponent as Day } from "./Day.svg";
import { ReactComponent as Night } from "./Night.svg";
import { ReactComponent as Cloudy } from "./Cloudy.svg";
import AtButton from "../atoms/AtButton";
import classNames from "classnames";
// import CircularSlider from "circular-slider";
import CircularSlider from "react-circular-slider-responsive";
import { useWindowSize } from "../../hooks";
import AtIcon from "../atoms/AtIcon";
// const Day = () => {
//   return (
//     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
//       <circle cx="16" cy="15.3879" r="8.97561" stroke="#EEF0F1" stroke-width="2" />
//       <line x1="16.6094" y1="1" x2="16.6094" y2="3.68293" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="16.6094" y1="27.5366" x2="16.6094" y2="30.2195" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="26.7256" y1="4.53616" x2="24.8285" y2="6.43328" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="1" y1="-1" x2="3.68293" y2="-1" transform="matrix(0.707107 0.707107 0.707107 -0.707107 5.27441 3.12195)" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="31" y1="15.0488" x2="28.3171" y2="15.0488" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="1" y1="-1" x2="3.68293" y2="-1" transform="matrix(1 -4.37114e-08 -4.37114e-08 -1 0 14.0488)" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="26.9676" y1="25.165" x2="25.0705" y2="23.2679" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//       <line x1="1" y1="-1" x2="3.68293" y2="-1" transform="matrix(0.707107 -0.707107 -0.707107 -0.707107 3.61816 25.165)" stroke="#EEF0F1" stroke-width="2" stroke-linecap="round" />
//     </svg>

//   )
// }
// let eventadded = false;
// const sliderSize = 40;
// const slideroffset = 2;
const sliderData = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
const DayNightSlider = props => {
  const { handledayState, isIdle, lights, hasNightSlider = false, handleNightOpacity, defaultNightOpacity } = props;
  const [currentlightindex, setcurrentlightindex] = useState(0);
  const [dir, setdir] = useState(1);
  const [sliderVal, setsliderVal] = useState(50);
  const [lightArr, setlightArr] = useState(null);
  const [sliderSize, setsliderSize] = useState({ width: 40, knob: 20 })

  const oscillate = false;

  const windowsize = useWindowSize();

  useEffect(() => {
    const rem = 3;
    const reminpx = rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    setsliderSize({ width: reminpx, knob: 20 })
  }, [windowsize])

  useEffect(() => {
    if (lights && lights.length > 0) {
      setlightArr([...lights]);
    }
  }, [lights]);

  const mouseup = () => {
    handleNightOpacity(lightArr[currentlightindex], sliderVal);
  };
  const trackClick = () => {
    handleNightOpacity(lightArr[currentlightindex], sliderVal);
  };

  // useEffect(() => {
  //   let el = document.getElementById("circular-slider");
  //   let el1 = document.getElementById("track");
  //   if (!el) return;
  //   // if (el.offsetLeft > sliderSize) {
  //   //   el.style.left = `${sliderSize}px`;
  //   // }
  //   // if (el.offsetTop > sliderSize) {
  //   //   el.style.top = `${sliderSize}px`;
  //   // }

  //   if (eventadded) return;
  //   el.addEventListener("mouseup", mouseup, false);
  //   // el1.addEventListener("click", trackClick, false);
  //   eventadded = true;
  //   return () => {
  //     el.removeEventListener("mouseup", mouseup, false);
  //     // el1.addEveremoveEventListenerntListener("click", trackClick, false);

  //     eventadded = false;
  //   };
  // }, [sliderVal]);

  // const handlebuttonClick = () => {
  //   let newindex;
  //   if (oscillate) {
  //     newindex = dir > 0 ? currentlightindex + 1 : currentlightindex - 1;

  //     if (newindex < 0) newindex = 0;
  //     if (newindex > lightArr.length - 1) newindex = lightArr.length - 1;

  //     if (newindex === 0) setdir(1);
  //     if (newindex === lightArr.length - 1) setdir(-1);
  //   } else {
  //     newindex = currentlightindex + 1;
  //     if (newindex > lightArr.length - 1) newindex = 0;
  //   }

  //   handledayState(lightArr[newindex]);

  //   console.log(newindex);
  //   setcurrentlightindex(newindex);
  // };

  const handleChangeLight = index => {
    handledayState(lightArr[index]);
    setcurrentlightindex(index);
  };

  const sliderOnChange = val => {
    if (hasNightSlider && currentlightindex === lightArr.indexOf("Night")) {
      setsliderVal(val);
      handleNightOpacity(lightArr[currentlightindex], val);
    }
  };
  const dncClassname = active =>
    classNames("day-state-btn__image", { "day-state-btn__image--active": active });
  return (
    <React.Fragment>
      {lightArr && lightArr.length > 0 && (
        <div
          className={classNames("day-state-btn", {
            "at-idle-fadeout": isIdle,
            "show-night-slider": currentlightindex === lightArr.indexOf("Night")
          })}
        // onClick={handlebuttonClick}
        >
          {lightArr.includes("Day") && (
            <AtButton
              title="Day"
              minimal active={currentlightindex === lightArr.indexOf("Day")}
              icon="day"
              size="large"
              onClick={() => handleChangeLight(lightArr.indexOf("Day"))}
            ></AtButton>
          )}
          {lightArr.includes("Cloudy") && (
            <AtButton
              title="Cloudy"
              minimal active={currentlightindex === lightArr.indexOf("Cloudy")}
              icon="cloudy"
              size="large"

              onClick={() => handleChangeLight(lightArr.indexOf("Cloudy"))}
            ></AtButton>
          )}

          {lightArr.includes("Night") && (
            <>
              <AtButton
                title="Night"
                minimal active={currentlightindex === lightArr.indexOf("Night")}
                icon="night"
                size="large"

                onClick={() => handleChangeLight(lightArr.indexOf("Night"))}
              >
                <div id="circular-slider" style={{ display: hasNightSlider ? "block" : "none" }}>
                  <CircularSlider
                    hideLabelValue={true}
                    // labelColor="#005a58"
                    width={sliderSize.width}
                    knobColor="#ffb627"
                    progressColorFrom="#ffb627"
                    progressColorTo="#ffb627"
                    progressSize={3}
                    trackColor="#4E4E56"
                    trackSize={3}
                    data={sliderData} //...
                    dataIndex={defaultNightOpacity ? sliderData.indexOf(defaultNightOpacity) : 5}
                    onChange={value => sliderOnChange(value)}
                    knobSize={sliderSize.knob}
                  >
                    <div style={{ background: "#4E4E56", width: 20, height: 20 }}></div>
                  </CircularSlider>
                </div>

              </AtButton>
              {/* 
              <AtButton
                title="Night"
                minimal active={currentlightindex === lightArr.indexOf("Night")}
                icon="night"
                onClick={() => handleChangeLight(lightArr.indexOf("Night"))}
              >
                <div id="circular-slider" style={{ display: hasNightSlider ? "block" : "none" }}>
                  <CircularSlider
                    hideLabelValue={true}
                    // labelColor="#005a58"
                    width={sliderSize.width}
                    knobColor="#ffb627"
                    progressColorFrom="#ffb627"
                    progressColorTo="#ffb627"
                    progressSize={3}
                    trackColor="#4E4E56"
                    trackSize={3}
                    data={sliderData} //...
                    dataIndex={defaultNightOpacity ? sliderData.indexOf(defaultNightOpacity) : 5}
                    onChange={value => sliderOnChange(value)}
                    knobSize={sliderSize.knob}
                  >
                    <div style={{ background: "#4E4E56", width: 20, height: 20 }}></div>
                  </CircularSlider>
                </div>

              </AtButton> */}
              {/* <AtButton minimal active={currentlightindex === lightArr.indexOf("Night")} onClick={() => handleChangeLight(lightArr.indexOf("Night"))}>
                <div id="circular-slider" style={{ display: hasNightSlider ? "block" : "none" }}>
                  <CircularSlider
                    hideLabelValue={true}
                    // labelColor="#005a58"
                    width={sliderSize.width}
                    knobColor="#ffb627"
                    progressColorFrom="#ffb627"
                    progressColorTo="#ffb627"
                    progressSize={3}
                    trackColor="#4E4E56"
                    trackSize={3}
                    data={sliderData} //...
                    dataIndex={defaultNightOpacity ? sliderData.indexOf(defaultNightOpacity) : 5}
                    onChange={value => sliderOnChange(value)}
                    knobSize={sliderSize.knob}
                  >
                    <div style={{ background: "#4E4E56", width: 20, height: 20 }}></div>
                  </CircularSlider>
                </div>
                <AtIcon icon="night" />
                {/* <Night className={dncClassname(currentlightindex === lightArr.indexOf("Night"))} /> */}
              {/* </AtButton> * /} */}
            </>
          )}
        </div>
      )
      }
    </React.Fragment >
  );
};
export default DayNightSlider;
