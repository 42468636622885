import React from "react";
import PropTypes from "prop-types";

const getSquareSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;

  return (
    <svg {...otherprops} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        onClick={e => handleClick(e, 0)}
        x="0.5"
        y="0.5"
        width="71"
        height="71"
        fill={yarns[0]}
        stroke={strokeColor}
      />
    </svg>
  );
};
const getCircleSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;
  return (
    <svg {...otherprops} viewBox="0 0 72 72" fill={yarns[0]}>
      <circle cx="36" cy="36" r="35.5" stroke={strokeColor} onClick={e => handleClick(e, 0)} />
    </svg>
  );
};
const getSVGofShape = props => {
  const { shape } = props;
  switch (shape) {
    case "CIRCLE":
      return getCircleSVG(props);
      break;
    case "SQUARE":
      return getSquareSVG(props);
      break;
    default:
      return null;
  }
};
const Mixply1 = props => {
  const { yarns, handleClick, ...otherprops } = props;
  return getSVGofShape(props);
};

Mixply1.propTypes = {};

export default Mixply1;
