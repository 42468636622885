import React from "react";
import PropTypes from "prop-types";
import { H6, Tooltip } from "@blueprintjs/core";
import AtIcon from "../AtIcon";
import classNames from "classnames";

const AtButton = props => {
  const {
    intent,
    icon,
    children,
    className,
    onClick,
    tertiary,
    disabled,
    minimal,
    text,
    active,
    small,
    tooltip,
    tooltipProps,
    ...otherProps
  } = props;
  const btnClassNames = () =>
    classNames(
      "at-button",
      {
        [`at-intent-${intent}`]: intent,
        "at-minimal": minimal,
        "at-disabled": disabled,
        icon: icon,
        "at-tertiary": tertiary,
        "at-active": active,
        "at-small": small
      },
      className
    );

  const renderButton = (
    <button
      {...otherProps}
      disabled={disabled}
      className={btnClassNames()}
      onClick={onClick}
      onDoubleClick={e => {
        e.stopPropagation();
      }}
    >
      {icon && <AtIcon icon={icon} />}
      {text && <H6 className="at-button-text">{text}</H6>}
      {children}
    </button>
  );
  return tooltip ? (
    <Tooltip {...tooltipProps} content={tooltip}>
      {renderButton}
    </Tooltip>
  ) : (
      renderButton
    );
};

AtButton.propTypes = {
  intent: PropTypes.oneOf(["primary", "secondary", "tertiary", "ghost", "danger"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  className: PropTypes.string,
  tertiary: PropTypes.bool,
  active: PropTypes.bool,
  text: PropTypes.string
};
AtButton.defaultProps = {
  size: "medium",
  className: "",
  tertiary: false,
  active: false
};
export default AtButton;
