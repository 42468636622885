import React, { useEffect } from "react";
import SidePanel from "./../../templates/SidePanel";
import Thumbnail from "./../../molecules/Thumbnail";
import AtIcon from "./../../atoms/AtIcon";
import { H5 } from "@blueprintjs/core";

const OptionsSidePanel = props => {
  const {
    side = "right",
    show = true,
    header,
    options,
    activeIndex = -1,
    onClick,
    onClose,
    className,
    children
  } = props;

  return (
    <React.Fragment>
      <SidePanel id={"options-sidepanel"} side={side} show={show} className={className}>
        {onClose && <AtIcon icon={"close"} onClick={onClose}></AtIcon>}
        {header && <H5>{header}</H5>}
        <div className={"options-container"}>
          {options.map((option, index) => {
            return (
              <Thumbnail
                key={index}
                className="optionsThumb"
                thumb={option}
                onThumbnailClick={e => onClick(e, index)}
                aspect="landscape"
                active={activeIndex === index}
              />
            );
          })}
        </div>
        {children}
      </SidePanel>
    </React.Fragment>
  );
};

export default OptionsSidePanel;
