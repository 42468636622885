import React from "react";
import AtIcon from "../AtIcon";

const AtDropdown = ({ dropdownItems, defaultItemIndex = 0, onChange, minimal }) => {
  const [value, setValue] = React.useState(defaultItemIndex);
  React.useEffect(() => {
    setValue(defaultItemIndex);
  }, [defaultItemIndex]);
  return (
    <div className={`bp3-select at-select ${minimal ? "minimal" : ""}`}>
      <select
        value={value}
        onChange={e => {
          onChange(e.target.value);
          setValue(e.target.value);
        }}
      >
        {dropdownItems &&
          dropdownItems.map((cat, index) => (
            <option className="at-option" value={index} label={cat} key={index}>
              {cat}
            </option>
          ))}
      </select>
      <AtIcon icon="triangle-down" />
    </div>
  );
};

export default AtDropdown;
