/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import RoomViewHelper from "./roomviewhelper";
import { useMount, useWindowSize } from "react-use";
import InputCanvas from "../InputCanvas";
import { clearCanvas, createCanvas, downloadImageData } from "./../../utils/canvasutils";
import { readJSON } from "./../../utils/utils";
let roomViewHelper = new RoomViewHelper();
const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    &.show{
        display: block;
    }
    & > canvas {
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /* width: inherit !important;
    height: auto !important; */
    }
`;
const Canvas = styled.canvas`
    z-index: ${(props) => props.zIndex};
    pointer-events: ${(props) => (props.pointerEvent ? "all" : "none")};
`;
function usePrevious(value) {
    const ref = useRef({});
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
const RoomViewNew = (props) => {
    const { show, roomData, designImageProps, onRendered, onRoomLoaded, onInteracted } = props;
    const { Name: roomName, Dir: dir, Files: files, baseUrl, config } = roomData;
    const { designImagePath, designName, physicalWidthCms, physicalHeightCms } = designImageProps;
    const containerRef = useRef(null);
    const bgCanvasRef = useRef(null);
    const threeCanvasRef = useRef(null);
    const maskCanvasRef = useRef(null);
    const shadowCanvasRef = useRef(null);
    const inputCanvasRef = useRef(null);

    const prevRoomDetails = usePrevious(roomData);
    const prevDesignImagePath = usePrevious(designImagePath);
    const windowSize = useWindowSize();
    const [isDownloading, setIsDownloading] = useState(false);

    useEffect(() => {
        roomViewHelper.resize(windowSize);
    }, [windowSize]);
    useMount(() => {
        const canvasConfig = {
            bgCanvas: bgCanvasRef.current,
            threeCanvas: threeCanvasRef.current,
            maskCanvas: maskCanvasRef.current,
            shadowCanvas: shadowCanvasRef.current,
            container: containerRef.current,
            inputCanvas: inputCanvasRef.current,
        };
        roomViewHelper.initCanvas(canvasConfig);
    });
    useEffect(() => {
        if (!roomViewHelper?.designDetails || !physicalWidthCms || !physicalHeightCms) return;
        roomViewHelper.changeDimensions({ physicalWidthCms, physicalHeightCms });
        roomViewHelper.updateShadow();
        onRendered();
    }, [physicalWidthCms, physicalHeightCms])
    useEffect(() => {
        let la = true;
        const loadRoom = async () => {
            try {
                //if room has been changed
                if (prevRoomDetails !== roomData) {
                    if (!la) return;
                    await Promise.all(roomViewHelper.initConfig({ baseUrl, config, files }));
                    if (!la) return;
                    roomViewHelper.updateBackground();
                    roomViewHelper.updateMask();
                    onRoomLoaded();
                    await roomViewHelper.updatethreeCanvas();
                    // if (prevDesignImagePath === designImagePath) {
                    //     await roomViewHelper.updatethreeCanvas();
                    //     if (!la) return;
                    //     roomViewHelper.updateShadow();
                    // }
                } else {
                    onRoomLoaded();
                }
                if (prevDesignImagePath !== designImagePath) {
                    // if (!la) return;
                    // await roomViewHelper.updatethreeCanvas();
                    if (!la) return;
                    await roomViewHelper.renderFromJpg({ designImagePath: designImagePath, physicalWidthCms, physicalHeightCms });
                    if (!la) return;
                    roomViewHelper.updateShadow();
                }
                onRendered();
            } catch (error) {
                console.error(error);
                return;
            }
        };
        loadRoom();
        return () => {
            la = false;
        }
    }, [roomData, designImagePath]);

    const handleInputStart = (e) => {
        roomViewHelper.mouseDownTouchStart(e);
    };
    const handleinputMove = (e) => {
        roomViewHelper.mouseDownTouchMove(e);
    };
    const handleInputEnd = (e) => {
        roomViewHelper.mouseDownTouchEnd(e);
        onInteracted && onInteracted();
    };
    window.downloadRendered3dIllNQ = async () => {
        const mime = "jpeg";
        const downloadName = `${designName} in ${roomName}.${mime}`;
        roomViewHelper.downloadRendering(downloadName, mime);
    };
    window.downloadRendered3dIllHQ = async () => {
        if (isDownloading) return;
        setIsDownloading(true);
        const mime = "jpeg";
        const downloadName = `${designName} in ${roomName}.${mime}`;
        const { dims } = config;
        const { width, height } = config;
        const bgCanvas = createCanvas(width, height);
        const threeCanvas = createCanvas(width, height);
        const maskCanvas = createCanvas(width, height);
        const shadowCanvas = createCanvas(width, height);
        const container = { clientWidth: width, clientHeight: height };
        const inputCanvas = createCanvas(width, height);
        const canvasConfig = {
            bgCanvas,
            threeCanvas,
            maskCanvas,
            shadowCanvas,
            container,
            inputCanvas,
        };
        const rh = new RoomViewHelper();
        rh.initCanvas(canvasConfig);
        await Promise.all(rh.initConfig({ baseUrl, config, files }));
        rh.updateBackground();

        rh.updateMask();
        await rh.updatethreeCanvas();
        await rh.renderFromJpg({ designImagePath: designImagePath });
        const objectConfig = roomViewHelper.threeView.getObjectConfig();
        if (objectConfig) {
            rh.threeView.carpetMesh.position.copy(objectConfig.position);
            rh.threeView.carpetMesh.rotation.copy(objectConfig.rotation);
            rh.threeView.render();
        }
        await rh.updateShadow();
        const renderedCanvas = rh.renderInCanvas();
        setIsDownloading(false);
        return Promise.resolve(renderedCanvas);
    };
    return (
        <React.Fragment>
            <Container ref={containerRef} className={show ? "show" : ""}>
                <Canvas ref={bgCanvasRef} zIndex={1} />
                <Canvas ref={threeCanvasRef} zIndex={2} pointerEvent />
                <Canvas ref={maskCanvasRef} zIndex={3} />
                <Canvas ref={shadowCanvasRef} zIndex={4} />
                <InputCanvas
                    zIndex={50}
                    pointerEvent
                    ref={inputCanvasRef}
                    onStart={handleInputStart}
                    onMove={handleinputMove}
                    onEnd={handleInputEnd}
                />
            </Container>
        </React.Fragment>
    );
};

export default RoomViewNew;
