import uuid from "uuid";
import HttpClient from "./httpClient";
import {
  readJSON,
  convertTilePointToName,
  readImage,
  makeid,
  getJsonFromUrl
} from "../utils/utils";
import { createCanvas } from "../utils/canvasutils";
import { MD5 } from "../utils/md5";
// import { decode } from "base64-arraybuffer"
// import pako from "pako";

// export const domain = "http://localhost:61960";
export const build = "v3";
export const domain = `https://${build}.explorug.com`;
export const assetsDomain =
  build === "v3" ? "https://assets.explorug.com/" : `${domain}/Assets`;
// `${domain}/Assets`;
const myroomServerUrl = "https://www.myownrug.com/segment_image";

let floorsFromServer = null;

let provider = "appproviderv3.aspx";
const API_KEY = "apikey";
const JPEG_API_KEY = "jpegapikey";

const keyScopes = {
  master: "master",
  design: "design",
  room: "room"
}
const multiKey = {
  ctf: API_KEY,
  jpeg: JPEG_API_KEY
};
const defaultKey = multiKey.ctf
let activeKey = { key: defaultKey, scope: Object.values(keyScopes) };
let previousKey = null;

const setActiveKey = (val, scope, previous = false) => {
  if (previous) {
    val = previousKey.key;
    scope = previousKey.scope;
  }
  if (Object.values(multiKey).includes(val)) {
    previousKey = activeKey;
    activeKey = { key: val, scope: scope || Object.values(keyScopes) };
    if (activeKey.key === multiKey.jpeg) {
      window.InterfaceElements.IsJpeg = true;
    }
    else {
      window.InterfaceElements.IsJpeg = false;
    }
  }
}


let cacheLocation = "";
const getCacheLocationFromUrl = url => url.split("/")[2];
const processPath = path => {
  const s = path.split("/").map(encodeURIComponent);
  if (s[1] === "Assets") {
    const ss = s.slice(2);
    return `${assetsDomain}/${ss.join("/")}`;
  } else {
    return `${domain}${path}`;
  }
};

const postHttpClient = (data, config) =>
  HttpClient.post(`${domain}/${provider}`, data, config).then(response => response.data);
const getPageName = () => {
  let page;
  let relogin = true;
  page = getJsonFromUrl().page;
  if (!page) {
    page = sessionStorage.getItem("page");
    relogin = true;
  }
  return { page: "photostudio", jpegpage: "photostudiojpg", relogin };
};
// const getPageName = () => {
//   let page;
//   let relogin = true;
//   page = getJsonFromUrl().page;
//   if (!page) {
//     page = sessionStorage.getItem("page");
//     relogin = sessionStorage.getItem("relogin");
//   }
//   return { page, relogin };
// };

const postWithRetry = data => {
  return new Promise((resolve, reject) => {
    let numtries = 0;
    const fetchData = () => {
      postHttpClient(data)
        .then(resolve)
        .catch(err => {
          numtries++;
          if (numtries <= 5) fetchData();
          else reject(err);
        });
    };
    fetchData();
  });
};
const getApiKey = (scope) => sessionStorage.getItem(
  scope ?
    (activeKey.scope.includes(scope) ? activeKey.key : defaultKey)
    : activeKey.key
);

const fetchApiKey = ({ username, password }) => {
  let data = new FormData();
  data.append("action", "login");
  data.append("username", username);
  data.append("password", password);
  return new Promise((resolve, reject) => {
    postWithRetry(data)
      .then(res => {
        const key = res.Key;
        if (!key) reject("INVALUD CREDENTIALS");
        else {
          sessionStorage.setItem(API_KEY, key);
          sessionStorage.setItem("relogin", false);
          resolve(key);
        }
      })
      .catch(reject);
  });
};
const autoLogin = params => {
  const { page, jpegpage, relogin } = getPageName();
  const { forcelogin } = getJsonFromUrl();
  return new Promise(async (resolve, reject) => {
    if (forcelogin) {
      reject("NO_LOGIN_PAGE");
      return;
    }
    if (JSON.parse(relogin) && page && page !== "undefined") {
      let resolvedata = null;
      if (!params?.keyType || (params.keyType === multiKey.ctf)) {
        const sessionApiKey = sessionStorage.getItem(API_KEY);
        if (params.keyfromsession && sessionApiKey) {
          resolvedata = { Key: sessionApiKey };
        }
        else {
          await HttpClient.post(`${domain}/login/app${page}.aspx`).then(response => {
            if (!response.data) {
              reject("INVALID_CREDENTIALS");
            return;
            }
            sessionStorage.setItem("relogin", false);
            sessionStorage.setItem(API_KEY, response.data.Key);
            resolvedata = response.data;
            return;
          }).catch(reject)
        }
      }
      if (!params?.keyType || (params.keyType === multiKey.jpeg)) {
        if (jpegpage) {
          const sessionApiKey = sessionStorage.getItem(JPEG_API_KEY);
          if (params.keyfromsession && sessionApiKey) {
            resolvedata = { Key: sessionApiKey };
          }
          else {
            await HttpClient.post(`${domain}/login/app${jpegpage}.aspx`).then(response => {
              if (!response.data) {
                return;
              }
              sessionStorage.setItem("relogin", false);
              sessionStorage.setItem(JPEG_API_KEY, response.data.Key);
              return;
            }).catch(reject)
          }
        }
      }

      resolve(resolvedata);
    } else {
      const key = sessionStorage.getItem(API_KEY);
      if (key) resolve(key);
      else reject("NO_LOGIN_PAGE");
      return;
    }
  });
};
const fetchInterfaceElements = () => {
  let data = new FormData();
  data.append("action", "interfaceelements");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  return postHttpClient(data).then(interfaceElements => {
    const { InterfaceElements, TextureOptions } = interfaceElements;

    window.InterfaceElements = InterfaceElements;
    window.TextureOptions = TextureOptions;

    const { LogoUrl } = InterfaceElements;
    cacheLocation = getCacheLocationFromUrl(LogoUrl);
    if (InterfaceElements.CustomizeCssUrl && InterfaceElements.CustomizeCssUrl !== "") {
      var link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("type", "text/css");
      link.setAttribute("href", `${domain}${InterfaceElements.CustomizeCssUrl}`);
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    if (!InterfaceElements.CustomizeJsUrl || InterfaceElements.CustomizeJsUrl === "") {
      return { ...interfaceElements, flags: {} };
    }
    return HttpClient.get(`${domain}${InterfaceElements.CustomizeJsUrl}?t=${makeid(8)}`)
      .then(flags => {
        return { ...interfaceElements, flags: flags.data };
      })
      .catch(err => {
        return { ...interfaceElements, flags: {} };
      });
  });
  // return postHttpClient(data)
};
const isAuthenticated = () => {
  if (sessionStorage.getItem(API_KEY)) return true;
  else return false;
};
const fetchDesignList = params => {
  let data = new FormData();
  data.append("action", "designlist");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  if (params.folder) {
    data.append("folder", params.folder);
  }
  return new Promise((resolve, reject) => {
    let numtries = 0;
    post();
    function post() {
      postHttpClient(data)
        .then(resolve)
        .catch(err => {
          if (err.code === "ECONNABORTED" && numtries < 5) {
            numtries++;
            post();
          } else {
            reject(err);
          }
        });
    }
  });
};
const fetchColorList = params => {
  let data = new FormData();
  data.append("action", "colorlist");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  return postHttpClient(data);
};
const fetchRoomList = params => {
  let data = new FormData();
  data.append("action", "roomlist");
  data.append("key", getApiKey(AppProvider.keyScopes.room));
  return postHttpClient(data);
};
const fetchDesignTiles = ({ file, zoom = 2, tiles, props, hash }) => {
  let data = new FormData();
  data.append("action", "designtiles");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));

  return postHttpClient(data).then(processPath);
};

const fetchPileTiles = ({ file, zoom = 2, tiles, props, hash }) => {
  let data = new FormData();
  data.append("action", "piletiles");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(processPath);
};

const fetchSilkSheenTiles = ({ file, zoom = 2, tiles, props, hash }) => {
  let data = new FormData();
  data.append("action", "silksheentiles");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(processPath);
};
const fetchVisualizationTiles = ({ file, zoom, tiles, props }) => {
  let data = new FormData();
  data.append("action", "visualizationtiles");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("zoom", zoom);
  data.append("tiles", JSON.stringify(tiles));
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(processPath);
};
const fetchTileDetails = ({ file, backColor = "" }) => {
  let data = new FormData();
  data.append("action", "tiledetails");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("backcolor", backColor);
  if (window.InterfaceElements.IsJpeg) {
    return Promise.resolve({
      tileTransparency1: [],
      tileTransparency2: [],
      tileTransparency4: [],
      colorTileData1: [],
      colorTileData2: [],
      colorTileData4: []
    });
  }
  return postHttpClient(data).then(tilePaths => {
    const {
      ColorTiles1X,
      ColorTiles2X,
      ColorTiles4X,
      TileTransparency1X,
      TileTransparency2X,
      TileTransparency4X
    } = tilePaths;
    // const arr = decode("H4sIAAAAAAAEAO3RsXEFIQxFUUpXaSpFJRASMNiBx/67QuiyQ+h96Ung6uvr3bt37969+3cb4A3cwAW85NzBK7iCS+6j5N7ADVzBS+4dvIEbuIKX3Dt4AzdwBS+5D/AGbuAKLrn/PG/tHbyCG7iCl9wHeAOv4AYu4CX3Ad7AK7iBK3jJfYB38Apu4AouuX+eH3sHb+AV3MAFvOQ+wDt4A6/gBi7gJfcB3sEbeAU3cAUX8JJ7B2/gFdzAFVzAS+4DvIM38Apu4Aou4CX3Ad7BG3gFN3A9dAEvufs83n0e7z6Pd5/Hu8/j3X/fu/++d88PfcrjfMrjfMrjfMrj3MAVXA594rvPee4+57n7nOfuc55nbuAKLuAzP/Ig382DfDcP8j3yIN/NDVzB5dADvnqU7+pRvice5bt6lO/qdugKLoce8cXDvBcP8z3wMO/Fw7wP3MAVXA495H2P8388zrvvcf6Px3n33cD10OXQY/7zRf5tX+T/80X+bV/k33YD10OXQ1/wrq/O8+ur8+z66jy7vjrPrhu4Hroc+oo3fXm+8g1Ue2ogACAAAA==")
    // const arr1 = new Uint8Array(arr)
    // console.log(arr1)
    // console.log(arr)
    // var output = pako.inflate(arr1);
    // console.log(output)
    // const op = toBits(output)
    // console.log(op)
    let promises = [
      readJSON(`${domain}${ColorTiles1X}`),
      readJSON(`${domain}${ColorTiles2X}`),
      readJSON(`${domain}${ColorTiles4X}`)
    ];
    const isIrregular = TileTransparency1X && TileTransparency1X !== "";
    if (isIrregular) {
      promises.push(
        readJSON(`${domain}${TileTransparency1X}`),
        readJSON(`${domain}${TileTransparency2X}`),
        readJSON(`${domain}${TileTransparency4X}`)
      );
    }
    return Promise.all(promises).then(tileDataArray => {
      let transparencyData = {
        tileTransparency1: [],
        tileTransparency2: [],
        tileTransparency4: []
      };
      if (isIrregular) {
        transparencyData = {
          tileTransparency1: tileDataArray[3],
          tileTransparency2: tileDataArray[4],
          tileTransparency4: tileDataArray[5]
        };
      }
      return {
        colorTileData1: tileDataArray[0],
        colorTileData2: tileDataArray[1],
        colorTileData4: tileDataArray[2],
        ...transparencyData
      };
    });
  });
};
const fetchRoomDetails = ({ file }) => {
  let data = new FormData();
  data.append("action", "3droomdetails");
  data.append("key", getApiKey(AppProvider.keyScopes.room));
  data.append("file", file);
  return postHttpClient(data);
};
const fetchRenderedDesignOrRoom = ({ backColor = "#00000000", file, props, view = "", at }) => {
  let data = new FormData();
  data.append("action", "rendering");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("view", view);
  data.append("backcolor", backColor);
  data.append("props", JSON.stringify(props));
  if (at) data.append("at", at);
  return postWithRetry(data).then(data => {
    return { ...data, Design: `${domain}${data.Design}`, View: `${domain}${data.View}` };
  });
};

const changeColorCombination = ({ numColors, colorTab }) => {
  let data = new FormData();
  data.append("action", "colorcombination");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("numcolors", numColors);
  data.append("tab", colorTab);
  return postHttpClient(data);
};
const publishDesign = ({ file, props, view = "", title, desc }) => {
  var data = new FormData();
  data.append("action", "publishdesign");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("view", view);
  if (hasText(title)) data.append("title", title);
  if (hasText(desc)) data.append("desc", desc);
  if (props) data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(data => {
    return { ...data, CatalogCache: `${domain}/${data.CatalogCache}` };
  });
};
const eCatalogDetails = ({ catalogId }) => {
  var data = new FormData();
  data.append("action", "ecatalogdetails");
  data.append("catalogid", catalogId);
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  return postHttpClient(data).then(data => {
    const d = { ...data };
    let InitDesign;
    const { InitDesign: designPath } = d;
    const extArr = designPath.split(".");
    const ext = extArr[extArr.length - 1];
    if (ext !== "ctf") InitDesign = designPath + ".ctf";
    else InitDesign = designPath;

    return { ...data, InitDesign: "Designs/" + InitDesign };
  });
};
const calculatePriceOfRug = ({ props, file }) => {
  var data = new FormData();
  data.append("action", "price");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};
const fetchCustomFieldOrdersheet = () => {
  var data = new FormData();
  data.append("action", "customfields");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  return postHttpClient(data);
};
const fetchOrderSheet = ({ file, props, customFields, materialNames = "", watermark, mode }) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("view", "");
  data.append("customfields", customFieldsToString(customFields));
  data.append("materialnames", JSON.stringify(materialNames));
  data.append("materialsilk", "");
  if (watermark) data.append("watermark", watermark);
  if (mode) data.append("mode", mode);
  return postHttpClient(data).then(data => mode !== "postback" ? data : ({ ...data, propsid: decodeURIComponent(data.propsid) }));
};

const downloadCatalog = ({ file }) => {
  const url = `${domain}/${provider}?action=downloadfile&key=${getApiKey(AppProvider.keyScopes.master)}&file=${file}`;
  const link = document.createElement("a");
  link.href = url;
  link.click();
};
const saveFileLocally = ({ file, props }) => {
  const data = new FormData();
  data.append("action", "savefilelocally");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  if (props) data.append("props", JSON.stringify(props));
  HttpClient.post(`${domain}/${provider}`, data, {
    responseType: "blob"
  }).then(response => {
    let url = URL.createObjectURL(response.data);
    const link = document.createElement("a");

    link.href = url;
    const farr = file.split("/").filter(item => item && item !== "");
    link.download = farr[farr.length - 1];
    link.click();
  });
};
const sendOrdersheetEmail = ({
  file,
  props,
  name,
  email,
  phone,
  note,
  customFields = [],
  view = "",
  setCustomEmail,
  customerEmailBody,
  customerEmailSubject,
  customerEmailFromAddress,
  companyEmailBody,
  companyEmailSubject,
  companyEmailFromAddress
}) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("view", view);
  data.append("customfields", customFieldsToString(customFields));

  data.append("mode", "email"); // mode=postback for posting back to iframe
  data.append("name", name);
  data.append("email", email);
  data.append("phone", phone);
  data.append("note", note);
  //add template
  if (setCustomEmail) {
    if (hasText(companyEmailBody)) data.append("EmailBodyTemplate", companyEmailBody);
    if (hasText(companyEmailFromAddress)) data.append("EmailFromAddress", companyEmailFromAddress);
    if (hasText(companyEmailSubject)) data.append("EmailSubject", companyEmailSubject);
    if (hasText(customerEmailBody)) data.append("CustomerEmailBodyTemplate", customerEmailBody);
    if (hasText(customerEmailFromAddress))
      data.append("CustomerEmailFromAddress", customerEmailFromAddress);
    if (hasText(customerEmailSubject)) data.append("customerEmailSubject", customerEmailSubject);
  }
  return postHttpClient(data);
};
const hasText = variable => {
  if (variable && variable !== "") {
    return true;
  } else {
    return false;
  }
};
const getColorAt = ({ file, x, y, Width, Height }) => {
  const data = new FormData();
  data.append("action", "colorat");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("file", file);
  data.append("x", x);
  data.append("y", y);
  data.append("width", Width);
  data.append("height", Height);
  return postHttpClient(data);
};
const getCatalogThumb = id => {
  return `${domain}/templates/catalogtemplates/${id}.thumb.jpg`;
};
const getColorTexturePreviewImage = ({ color, colorIndex = 0, collectionName = "" }) => {
  // return value in css background format
  if (colorIndex > 3) colorIndex = 0;
  const colorCode = color.substring(1);
  const url = `${domain}/Assets/${cacheLocation}/colors/${colorCode}-${collectionName}-${colorIndex}.jpg`;
  if (cacheLocation !== "") return `url(${url})`;
  else return color;
};
const fetchCatalog = ({ file, props, catalogName, view = "" }) => {
  const data = new FormData();
  data.append("action", "ordersheet");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("props", JSON.stringify(props));
  data.append("catalogname", catalogName);
  data.append("view", view);
  return postHttpClient(data).then(data => data.Value);
};
const DESIGN = "design";
const ROOM = "room";
const fetchList = (listType = DESIGN, folder) => {
  if (listType === DESIGN) return AppProvider.fetchDesignList({ folder });
  else if (listType === ROOM) return AppProvider.fetchRoomList({});
};
const getCustomConstructions = () => {
  const data = new FormData();
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("action", "customconstructions");
  return postHttpClient(data).then(data => {
    if (data) return data.map(item => ({ ...item, Num: parseInt(item.Num) }));
    else return [];
  });

  // data ? data.map(item => ({ ...item, Num: parseInt(item.Num) })) : [])
};

const uploadMyRoom = roomImage => {
  const data = new FormData();
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("action", "uploadmyroom");
  data.append("myroom", roomImage);
  return postHttpClient(data);
};
const uploadMyRoomMask = ({ maskUrl, roomId }) => {
  const data = new FormData();
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("action", "uploadmyroommask");
  data.append("myroommask", maskUrl);
  data.append("roomid", roomId);
  return postHttpClient(data);
};
const getRoomMask = image => {
  const data = new FormData();
  data.append("image", image);
  return new Promise((resolve, reject) => {
    return HttpClient.post(myroomServerUrl, data)
      .then(response => {
        if (response.status === 201) resolve(response.data);
        else reject(response.data);
      })
      .catch(reject);
  });
};

const getProcessedRoomMask = ({
  roomId,
  file,
  props,
  floorpoints,
  notfloorpoints,
  carpetpoints
}) => {
  const data = new FormData();
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("action", "processmyroom");
  data.append("mode", "mask");
  data.append("roomid", roomId);
  data.append("file", file);
  data.append("props", JSON.stringify(props));

  data.append("floorpoints", JSON.stringify(floorpoints));
  data.append("notfloorpoints", JSON.stringify(notfloorpoints));
  data.append("carpetpoints", JSON.stringify(carpetpoints));

  return postHttpClient(data, { responseType: "blob" });
};

const saveAsRoom = ({ mode, roomId, file, props, floorpoints, notfloorpoints, carpetpoints }) => {
  const data = new FormData();
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("action", "processmyroom");
  data.append("mode", mode);
  data.append("roomid", roomId);
  data.append("file", file);
  data.append("props", JSON.stringify(props));

  data.append("floorpoints", JSON.stringify(floorpoints));
  data.append("notfloorpoints", JSON.stringify(notfloorpoints));
  data.append("carpetpoints", JSON.stringify(carpetpoints));

  return postHttpClient(data);
};

const getDesignThumbnails = ({ designs }) => {
  const fullpaths = designs.map(item => item.fullpath);

  let data = new FormData();
  data.append("action", "designthumbs");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("files", JSON.stringify(fullpaths));
  return postWithRetry(data).then(thumbList => {
    return designs.map(childFile => {
      const item = thumbList.find(item => item.Name === childFile.fullpath);
      let add = {};
      if (item) {
        const hash = MD5(JSON.stringify(item.Props));
        const path = processPath(item.Thumb);
        add = { Thumb: `${path}?t=${hash}`, Props: item.Props };
        cacheLocation = getCacheLocationFromUrl(item.Thumb);
      }
      return { ...childFile, ...add };
    });
  });
};
const getRoomThumbnails = ({ rooms }) => {
  const fullpaths = rooms.map(item => item.FullPath);
  let data = new FormData();
  data.append("action", "roomthumbs");
  data.append("key", getApiKey(AppProvider.keyScopes.room));
  data.append("files", JSON.stringify(fullpaths));
  return postWithRetry(data).then(thumbList => {
    return rooms.map(room => {
      //console.log(thumbList.map(item => item.Name), childFile.FullPath)
      const item = thumbList.find(item => item.Name === room.FullPath);
      let add = { label: room.Name };
      if (item) add = { ...add, Thumb: `${AppProvider.domain}${item.Thumb}`, id: uuid.v4() };
      return { ...room, ...add };
    });
  });
};
const getRenderedDesign = async ({ designDetails, fullpath, hash, zoom = 1, logoUrl }) => {
  const tileSize = 256;
  return new Promise((resolve, reject) => {
    const { Width, Height } = designDetails;
    const canvasWidth = Width * zoom;
    const canvasHeight = canvasWidth / (Width / Height);
    const canvas = createCanvas(canvasWidth, canvasHeight);

    let xTotal = Math.floor((canvasWidth - 1) / 256) + 1;
    let yTotal = Math.floor((canvasHeight - 1) / 256) + 1;
    let tilepoints = [];
    for (let x = 0; x < xTotal; x++) {
      for (let y = 0; y < yTotal; y++) {
        tilepoints.push({ x, y, z: zoom, name: convertTilePointToName(x, y) });
      }
    }
    const context = canvas.getContext("2d");

    const fetchDesignCallback = (basePath) => {
      let tileImagesLoaded = 0;
      tilepoints.forEach((tilePoint, index) => {
        const img = document.createElement("img");
        img.setAttribute("crossOrigin", "Anonymous");
        const { name } = tilePoint;
        let filename = `${basePath}/${name}.rendered.jpg`;
        if (hash && hash !== "") {
          filename = `${filename}?t=${hash}`;
        }
        img.src = filename;
        tilePoint.image = img;
        img.onload = () => {
          drawSingleTileInCanvas(index);

          if (tileImagesLoaded + 1 === tilepoints.length) {

            drawWaterMarkIfNeeded();
          }
          tileImagesLoaded++;
        };
      });
    }

    if (window.InterfaceElements.IsJpeg) {
      fetchDesignTiles({
        file: fullpath,
        zoom: 1,
        props: designDetails,
        tiles: tilepoints.map(item => item.name)
      }).then(fetchDesignCallback);
    }
    else {
      fetchVisualizationTiles({
        file: fullpath,
        zoom,
        props: designDetails,
        tiles: tilepoints.map(item => item.name)
      }).then(fetchDesignCallback);
    }

    const drawSingleTileInCanvas = (index) => {
      const tilepoint = tilepoints[index];
      context.drawImage(tilepoint.image, tilepoint.x * tileSize, tilepoint.y * tileSize);
    }
    function drawWaterMarkIfNeeded() {
      if (!logoUrl) {
        resolve(canvas);
        return;
      }
      readImage(logoUrl).then(logoImage => {
        const width = 100 * zoom;
        const height = (logoImage.height * width) / logoImage.width;

        const startx = canvasWidth - (width + 15);
        const starty = canvasHeight - (height + 15);
        context.globalAlpha = 0.5;
        context.drawImage(logoImage, startx, starty, width, height);
        resolve(canvas);
      });
    }
  });
};
const readTextureImages = textures => {
  // const url = `${domain}/Assets/${cacheLocation}/colors/${colorCode}-${collectionName}-${colorIndex}.jpg`;
  const textureImages = textures.map(
    item => `${domain}/Assets/${cacheLocation}/textures/${item}.jpg`
  );
  let promises = [];
  textureImages.forEach((textureUrl, index) => {
    promises.push(readImage(textureUrl).then(image => ({ image, index: textures[index] })));
  });
  return Promise.all(promises);
};
const getStripedDesign = () => {
  let data = new FormData();
  data.append("action", "getstripedesign");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  return postHttpClient(data).then(data => `Designs/${data}`);
};
const saveOnServer = ({ file, name, props }) => {
  let data = new FormData();
  data.append("action", "saveonserver");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  if (name) {
    data.append("name", name);
    data.append("copy", true);
  }
  data.append("props", JSON.stringify(props));
  return postHttpClient(data).then(data => `Designs/${data}`);
};
const clearCache = ({ mode, files }) => {
  if (!mode) console.warn("CLEAR CACHE MODE NOT SPECIFIED");
  let data = new FormData();
  data.append("action", "clearcache");
  data.append("key", getApiKey(AppProvider.keyScopes.design));
  data.append("mode", mode);
  if (files) data.append("files", JSON.stringify(files));
  return postHttpClient(data);
};
const isStripedDesignMode = sessionStorage.getItem("mode") === "stripedesign";
const isEcatMode = sessionStorage.getItem("mode") === "ecat";

const getInstanceUrl = ({ file, props, view = "" }) => {
  var data = new FormData();
  data.append("action", "instanceurl");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("file", file);
  data.append("view", view);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};
const openFileManager = () => {
  window.localStorage.setItem("key", getApiKey(AppProvider.keyScopes.master));
  const x = window.location.pathname.split("/").filter(item => item !== "");
  x.pop();
  const urlArr = [window.location.origin, ...x, "filemanager/index.html"];
  const urlStr = urlArr.join("/");
  window.open(urlStr);
};
const mapColors = ({ props, colorTab }) => {
  var data = new FormData();
  data.append("action", "mapcolors");
  data.append("key", getApiKey(AppProvider.keyScopes.master));
  data.append("tab", colorTab);
  data.append("props", JSON.stringify(props));
  return postHttpClient(data);
};

const downloadPdf = ({ url, catalogProps, rendereddesign, renderedroom }) => {
  var data = new FormData();
  data.append("action", "renderedcatalog");
  data.append("template", 'vps-catalog1');
  data.append("catalogprops", JSON.stringify(catalogProps));
  data.append("rendereddesign", rendereddesign);
  data.append("renderedroom", renderedroom);
  return HttpClient.post(url, data).then(response => response.data);
}

const AppProvider = {
  keys: multiKey,
  keyScopes,
  activeKey,
  setActiveKey,
  getApiKey,
  fetchApiKey,
  fetchDesignList,
  fetchColorList,
  fetchRoomList,
  fetchRoomDetails,
  fetchRenderedDesignOrRoom,
  publishDesign,
  isAuthenticated,
  fetchInterfaceElements,
  domain,
  assetsDomain,
  autoLogin,
  changeColorCombination,
  calculatePriceOfRug,
  fetchCustomFieldOrdersheet,
  fetchOrderSheet,
  downloadCatalog,
  sendOrdersheetEmail,
  getColorAt,
  eCatalogDetails,
  getCatalogThumb,
  fetchCatalog,
  getColorTexturePreviewImage,
  fetchList,
  getCustomConstructions,
  uploadMyRoom,
  uploadMyRoomMask,
  getRoomMask,
  getProcessedRoomMask,
  saveAsRoom,
  fetchTileDetails,
  fetchDesignTiles,
  fetchPileTiles,
  fetchSilkSheenTiles,
  getDesignThumbnails,
  getRoomThumbnails,
  fetchVisualizationTiles,
  getStripedDesign,
  getRenderedDesign,
  saveOnServer,
  isStripedDesignMode,
  readTextureImages,
  isEcatMode,
  clearCache,
  getInstanceUrl,
  openFileManager,
  mapColors,
  saveFileLocally,
  downloadPdf
};
export default AppProvider;
const customFieldsToString = fields => {
  let customFieldsString = "";
  fields.forEach(field => {
    customFieldsString = customFieldsString.concat(`${field.fieldName}|${field.fieldValue}|`);
  });
  return customFieldsString;
};

export const getFloor = () => {
  const floorDomain = "https://v3.explorug.com/modules/vpsassets/apps/getjpeglist/default.aspx?folder=onlinevps/floors";
  const floorAssetsDomain = "https://cdn.virtualrugstudio.com/";
  const floorRelPath = "OnlineVPS/Floors/";
  return new Promise((resolve, reject) => {
    if (!floorsFromServer) {
      HttpClient.post(floorDomain).then(response => {
        let floorArr = [];
        floorArr = response.data.map((eachFloor, index) => {
          let config = JSON.parse(eachFloor.config);
          let obj = { ...config, path: `${floorAssetsDomain}${floorRelPath}${eachFloor.diffuse}`, thumb: `${floorAssetsDomain}${floorRelPath}${eachFloor.diffuse.replace('.jpg', '.thumb.jpg')}` };
          return obj;
        })
        const returnObj = {
          show: false,
          floors: floorArr,
          activeFloor: floorArr[0]
        }
        floorsFromServer = returnObj;
        resolve(returnObj)
      });
    }
    else {
      resolve(floorsFromServer)
    }

  })
}

export const sendSignIn = ({ type, email, name }) => {
  window.userEmail = email;
  const url = `https://ramrodesigns.com/virtual-exibition/Social_Media_SignIn.php?sm=${type}&ema=${email}&usr=${name}`
  HttpClient.post(url, {})
    .then(response => {

    });
}

// const partial = function (len, x, _end) {
//   if (len === 32) { return x; }
//   return (_end ? x | 0 : x << (32 - len)) + len * 0x10000000000;
// }
// const toBits = function (bytes) {
//   var out = [], i, tmp = 0;
//   for (i = 0; i < bytes.length; i++) {
//     tmp = tmp << 8 | bytes[i];
//     if ((i & 3) === 3) {
//       out.push(tmp);
//       tmp = 0;
//     }
//   }
//   if (i & 3) {
//     out.push(partial(8 * (i & 3), tmp));
//   }
//   return out;
// }

//colorhex-tabName-materialIndex
//tabname==null?""
