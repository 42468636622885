import { Classes, Overlay, Popover } from "@blueprintjs/core";
import { applyPresetOnCanvas, presetsMapping } from "instagram-filters";
import React, { useEffect, useState } from "react";
import { useMount } from "react-use";
import AppProvider from "../../api/appProvider";
import { useWindowSize } from "../../hooks";
import {
  designDetailActions,
  useDesignDetailState,
  useDispatchDesignDetail
} from "../../reducers/designdetails.reducer";
import { createCanvas, downloadImageData } from "../../utils/canvasutils";
import { readImage } from "../../utils/utils";
import { getDominantColor } from "../IllustrationView";
import { useRoomConfigState } from "../IllustrationView/roomconfig.reducer";
import RoomViewHelper from "../IllustrationView/roomviewhelper";
import ColorSelectionBox from "../organisms/ColorSelectionBox";
import { makePDf } from "./pdfcreator";

let w, h;

const mockHtml = `
    <div id="at_catalog_layout" style="    
    clip-path: polygon(0 0%, 100% 0%, 100% 95%, 0 95%);
    max-width: calc(100% + 2rem);
    top: 2.5%;
    ">
        <div id="ors_room_view" > </div>
        <div id="ors_design_area" style="
        position: absolute;
        width: 20.73%;
        top: 18.96%;
        right: 4.75%;
        height: 37.04%;
        display: flex;
        justify-content: center;
        align-items: center;
        "></div>
        <div id="ors_design_name" style="
        position: absolute;
        color: #FFFFFF !important;
        right: 4.75%;
        bottom: 3.36%;
        font-size: 28px;
        font-family: sans-serif;
        height: 6.72%;
        display: flex;
        align-items: center;
        justify-content: center;
        "></div>
        <div id="ors_logo_area" style="
        position: absolute;
        top: 6.72%;
        right: 4.75%;
        max-width: 20%;
        max-height: 5%;
        "></div>
    </div>
`;
const mockHtml1 = `
<div id="at_catalog_layout" >
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="
        position: absolute;
        width: 20%;
        height: 37.8%;
        left: 10%;
        top: 10.8%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        "></div>
        <div id="ors_design_name" style="
        position: absolute;
        top: 36%;
        color: #FFFFFF !important;
        left: 33.94%;
        font-size: 28px;
        "></div>
        <div id="ors_logo_area" style="
        position: absolute;
        top: 27.5%;
        left: 33.44%;
        max-width: 20%;
        max-height: 5%;
        overflow: hidden;
        "></div>
    </div>
`;
const mockHtml_FoldedView = `
<div id="at_catalog_layout" >
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="
        position: absolute;
        border: 2px solid transparent;
        border-radius: 2px;
        width: 30%;
        height: 60%;
        left: 60.85%;
        top: 14.6%;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        "></div>
        <div id="ors_design_name" style="
        display:none
        "></div>
        <div id="ors_logo_area" style="
        display:none
        "></div>
    </div>
`;
const mockHtml_PerspectiveView = `
<div id="at_catalog_layout" >
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="
          position: absolute;
          border: 7.5px solid transparent;
          width: 22.2%;
          height: 51.6%;
          left: 68.05%;
          top: 8.2%;
          box-sizing: border-box;
          display: inline-flex;
          justify-content: center;
          align-items: flex-end;
        "></div>
        <div id="ors_design_name" style="
        display:none
        "></div>
        <div id="ors_logo_area" style="
        display:none
        "></div>
    </div>
`;
const mockHtml_BedroomModern = `
<div id="at_catalog_layout" >
<div id="ors_room_view"
      style="
      width: 70%;
    left: 30%;
    height: 100%;
    display: flex;
    align-items: center;"
      > </div>
        <div id="ors_design_area" style="position: absolute;
        width: 30%;
        left: 0;
        background: white;
        top: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform-origin: top right;
        transform: translateY(-50%) rotate(351deg);
        padding: 7px;
        box-shadow: 0px 0px 5px 1px #978484;
        "></div>
        <div id="ors_design_name" style="
        display:none
        "></div>
        <div id="ors_logo_area" style="
        display:none
        "></div>
    </div>
`;
const mockHtml_FoldedBack = `
<div id="at_catalog_layout" >
<div id="ors_room_view"
      > </div>
        <div id="ors_design_area" style="position: absolute;
    height: 100%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: white;
    border-right: 2px solid white;
    max-width: 50%;
        "></div>
        <div id="ors_design_name" style="
        display:none
        "></div>
        <div id="ors_logo_area" style="
        display:none
        "></div>
    </div>
`;
const mockHtml_ActBody = `
    <div id="at_catalog_layout" >
        <div id="ors_room_view" >
        </div>
        <div id="ors_design_area"
            style="
            position: absolute;
            width: 20%;
            height: 37.8%;
            right: 4%;
            top: 4%;
            display: inline-flex;
            justify-content: center;
            align-items: flex-end;
            ">
        </div>
        <div id="ors_design_name"
            style="
            position: absolute;
            width: auto;
            text-align: center;
            left: 50%;
            bottom: 5.04%;
            transform: translateX(-50%);
            font-family: 'IBM PLEX SANS';
            color: #616161;
            font-size: 28px;
            line-height: 43px;">
        </div>
        <div id="ors_logo_area"
            style="
            position: absolute;
            top: 8.4%;
            left: 50%;
            transform: translateX(-50%);
            height: 3.36%;
            width: auto;">
        </div>
    </div>
`;
const mockHtml_artgallery = `
  <div id="at_catalog_layout" >
  <div id="ors_room_view" > </div>
  <div id="ors_design_area"
      style="
        position: absolute;
        width: 20%;
        height: 37.8%;
        left: 10%;
        display: none;
        top: 10.8%;">
  </div>
  <div id="ors_design_name"
        style="
        position: absolute;
        font-family: sans-serif;
        color: #353535;
        font-size: 18px;
        bottom: 2.87%;
        height: 5.43%;
        display: flex;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);">
  </div>


  <div id="ors_design_size" style="
  position: absolute;
  font-family: sans-serif;
  bottom: 2.87%;
  height: 5.43%;
  display: flex;
  align-items: center;
  top: 44%;
  color: #FFFFFF;
  left: 33.94%;
  opacity: 0.5;

        "><div>10' x 10'</div>
  </div>


        <div id="ors_logo_area" style="
        position: absolute;
        max-width: 25%;
        width: auto;
        left: 3.37%;
        bottom: 2.87%;
        height: 5.43%;
        display: flex;
        align-items: center;
        "></div>
    </div>
`;
const mockHtml_BabyFeet = `
<div id="at_catalog_layout" >
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="
        position: absolute;
        bottom: 2.38%;
        left: 3.37%;
        width: 34.2%;
        height: 28.24%;"></div>
        <div id="ors_design_name" style="
        position: absolute;
        font-family: sans-serif;
        color: #FFFFFF;
        font-size: 26px;
        top: 3.025%;
        left: 50%;
        transform: translateX(-50%);">
        </div>

        <div id="ors_design_size" style="
        position: absolute;
        font-family: sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        top: 7.025%;
        left: 50%;
        transform: translateX(-50%);
        "><div>10' x 10'</div></div>

        <div id="ors_logo_area" style="
        position: absolute;
        bottom: 2.8%;
        right: 3.16%;
        max-height: 5%;
        overflow: hidden;
        max-width: 30%;
        "></div>
    </div>
`;
const mockHtml_BookWorm = `
<div id="at_catalog_layout" style="height: calc(100% - 2rem);">
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="position: absolute;
        width: 20%;
        height: 37.8%;
        left: 10%;
        display:none;
        top: 10.8%;"></div>
        <div id="ors_design_name" style="position: absolute;
        width: 90.5%;
    height: 32px;
    left: 40px;
    top: 92%;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */
        text-align: right;
        width: fit-content;
    left: 33%;
    top: 28%;
    font-size: 40px;
    display:none;
        /* utility / white */
        color: #FFFFFF;"></div>
        <div id="ors_logo_area" style="
        position: absolute;
        left: 50%;
        bottom: 6.625%;
        transform: translateX(-50%);
        max-height: 5%;
        overflow: hidden;
        max-width: 30%;
        "></div>
    </div>
`;
const mockHtml_Bodypaint = `
<div id="at_catalog_layout" >
<div id="ors_room_view" > </div>
        <div id="ors_design_area" style="position: absolute;
        width: 34%;
        height: 37.8%;
        left: 3%;
        bottom: 1rem;"></div>
        <div id="ors_design_name" style="position: absolute;
        width: 90.5%;
    height: 32px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */
        text-align: right;
        width: fit-content;
    right: 5%;
    top: 4.1%;
    font-size: 40px;
        /* utility / white */
        color: #FFFFFF !important;"></div>
        <div id="ors_logo_area" style="position: absolute;
        width: 20%;
        height: 3%;
        right: 3%;
        bottom: 3%;
        "></div>
    </div>
`;

let catalogProps = [
  {
    id: "ors_design_name",
    type: "DESIGN_NAME"
  },
  {
    id: "ors_design_area",
    type: "DESIGN_AREA"
  },
  {
    id: "ors_room_view",
    type: "ROOM_VIEW",
    path: "Rooms/Catalogs/Just Married.crf3d"
  },
  {
    id: "ors_logo_area",
    type: "LOGO_AREA"
  }
];

const areaComponentref = {
  DESIGN_NAME: "DESIGN_NAME",
  DESIGN_AREA: "DESIGN_AREA",
  ROOM_VIEW: "ROOM_VIEW",
  LOGO_AREA: "LOGO_AREA"
};

const CatalogUrl = "";
let catalogRoomView = new RoomViewHelper();

const CatalogView = props => {
  const { active, roomData, filterData = null } = props;
  const designDetailState = useDesignDetailState();
  const dispatchDesignDetails = useDispatchDesignDetail();
  const [catalogPropsState, setcatalogPropsState] = useState([...catalogProps]);
  const [renderedDesign, setrenderedDesign] = useState(null);
  const [renderedRoom, setrenderedRoom] = useState(null);
  const [colorSelectionPos, setcolorSelectionPos] = useState({ x: -1, y: -1 });
  const [selectedHtml, setselectedHtml] = useState(null);
  const [showTransition, setshowTransition] = useState(false);

  const roomconfigstate = useRoomConfigState();

  const { customDesignUrl } = roomconfigstate;
  const { designDetails } = designDetailState;
  const windowsize = useWindowSize();

  useMount(() => {
    const canvasConfig = {
      bgCanvas: document.createElement("canvas"),
      threeCanvas: document.createElement("canvas"),
      maskCanvas: document.createElement("canvas"),
      shadowCanvas: document.createElement("canvas"),
      transitionCanvas: document.createElement("canvas"),
      container: {
        clientWidth: document.getElementById("catalogview").offsetWidth,
        clientHeight: document.getElementById("catalogview").offsetHeight
      },
      inputCanvas: document.createElement("canvas"),
      objCanvasContainer: document.createElement("div")
    };
    catalogRoomView.initCanvas(canvasConfig);
  });

  useEffect(() => {
    sizeContainers({});
  }, [windowsize]);

  const sizeContainers = () => {
    catalogRoomView.container = {
      clientWidth: document.getElementById("catalogview").offsetWidth,
      clientHeight: document.getElementById("catalogview").offsetHeight
    };
    catalogRoomView.resize({
      width: catalogRoomView.container.clientWidth,
      height: catalogRoomView.container.clientHeight
    });
    // const at_catalog_layout = document.getElementById("at_catalog_layout");
    // const catalog_roomcanvas = document.getElementById("catalog-roomcanvas");
    // if (at_catalog_layout && catalog_roomcanvas) {
    //   at_catalog_layout.style.width =
    //     catalog_roomcanvas.offsetWidth + "px";
    //   at_catalog_layout.style.height =
    //     catalog_roomcanvas.offsetHeight + "px";
    // }
  };

  useEffect(() => {
    if (!active) return;
    if (!filterData) {
      let effectcanvas = document.getElementById("catalog-effectcanvas");
      if (effectcanvas && effectcanvas.width) {
        effectcanvas.style.display = "none";
        effectcanvas.getContext("2d").clearRect(0, 0, effectcanvas.width, effectcanvas.height);
      }
      let designcanvas = document.getElementById("catalog-designcanvas");
      if (designcanvas) {
        designcanvas.style.opacity = 1;
      }
    } else {
      applyEffects();
    }
  }, [filterData]);

  const applyEffects = async () => {
    let roomcanvas = document.getElementById("catalog-roomcanvas");
    let designcanvas = document.getElementById("catalog-designcanvas");
    if (!roomcanvas) return;
    const canvas = document.createElement("canvas");
    canvas.width = roomcanvas.width;
    canvas.height = roomcanvas.height;
    canvas.getContext("2d").drawImage(roomcanvas, 0, 0);

    if (designcanvas && designcanvas.width > 0) {
      let dx =
        ((designcanvas.getBoundingClientRect().left - roomcanvas.getBoundingClientRect().left) /
          roomcanvas.offsetWidth) *
        roomcanvas.width;
      let dy =
        ((designcanvas.getBoundingClientRect().top - roomcanvas.getBoundingClientRect().top) /
          roomcanvas.offsetHeight) *
        roomcanvas.height;
      canvas
        .getContext("2d")
        .drawImage(
          designcanvas,
          dx,
          dy,
          (designcanvas.offsetWidth / roomcanvas.offsetWidth) * roomcanvas.width,
          (designcanvas.offsetHeight / roomcanvas.offsetHeight) * roomcanvas.height
        );
    }
    await applyPresetOnCanvas(canvas, presetsMapping[filterData]());
    let effectcanvas = document.getElementById("catalog-effectcanvas");
    effectcanvas.getContext("2d").drawImage(canvas, 0, 0);
    effectcanvas.style.display = "block";
    if (designcanvas && designcanvas.width > 0) designcanvas.style.opacity = 0;
  };

  useEffect(() => {
    if (roomData && designDetails && active) {
      if (
        !(
          catalogRoomView.designDetails === designDetails &&
          catalogRoomView.roomData &&
          catalogRoomView.roomData.config === roomData.config
        )
      ) {
        setshowTransition(true);
        if (catalogRoomView.roomData && catalogRoomView.roomData.config !== roomData.config) {
          catalogRoomView.clearAll();
        }
        renderHtml();
      } else if (customDesignUrl?.url) {
        renderHtml();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomData, designDetails, customDesignUrl, active]);

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type
    });
  }
  const renderHtml = async () => {
    dispatchDesignDetails({
      type: designDetailActions.SET_LOADING,
      payload: true
    });
    document.getElementById("catalogview").innerHTML = "";
    if (roomData.config.name === "Just Married") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml);
      setselectedHtml(mockHtml);
    } else if (roomData.config.name === "Art Gallery") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_artgallery);
      setselectedHtml(mockHtml_artgallery);
    } else if (roomData.config.name === "Baby Feet") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_BabyFeet);
      setselectedHtml(mockHtml_BabyFeet);
    } else if (roomData.config.name === "Book Worm") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_BookWorm);
      setselectedHtml(mockHtml_BookWorm);
    } else if (roomData.config.name === "Bodypaint") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_Bodypaint);
      setselectedHtml(mockHtml_Bodypaint);
    } else if (roomData.config.name === "Act Body") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_ActBody);
      setselectedHtml(mockHtml_ActBody);
    } else if (roomData.config.name === "Folded View") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_FoldedView);
      setselectedHtml(mockHtml_FoldedView);
    } else if (roomData.config.name === "Perspective View") {
      document
        .getElementById("catalogview")
        .insertAdjacentHTML("afterbegin", mockHtml_PerspectiveView);
      setselectedHtml(mockHtml_PerspectiveView);
    } else if (roomData.config.name === "Bedroom Modern") {
      document
        .getElementById("catalogview")
        .insertAdjacentHTML("afterbegin", mockHtml_BedroomModern);
      setselectedHtml(mockHtml_BedroomModern);
    } else if (roomData.config.name === "Folded Back") {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml_FoldedBack);
      setselectedHtml(mockHtml_FoldedBack);
    } else {
      document.getElementById("catalogview").insertAdjacentHTML("afterbegin", mockHtml1);
      setselectedHtml(mockHtml1);
    }
    const catalogPropsTemp = [...catalogProps];
    let count = catalogPropsState.length;
    const resolveThis = () => {
      count--;
      if (count === 0) {
        setcatalogPropsState([...catalogPropsTemp]);
        // document.getElementById("at_catalog_layout").style.width =
        //   document.getElementById("catalog-roomcanvas").offsetWidth + "px";
        setTimeout(() => {
          dispatchDesignDetails({
            type: designDetailActions.SET_LOADING,
            payload: false
          });
          setshowTransition(false);
        }, 400);
      }
    };

    catalogPropsState.forEach(async (component, index) => {
      if (component.type === areaComponentref.DESIGN_NAME) {
        document
          .getElementById(component.id)
          .insertAdjacentHTML("afterbegin", `<div>${designDetailState.designName}</div>`);
        catalogPropsTemp[index].value = designDetailState.designName;
        resolveThis();
      } else if (component.type === areaComponentref.DESIGN_AREA) {
        const designfile = await renderDesign(component);
        catalogPropsTemp[index].value = `${CatalogUrl}/Data/${designfile.name}`;
        setrenderedDesign(designfile);
        resolveThis();
      } else if (component.type === areaComponentref.ROOM_VIEW) {
        const roomfile = await renderRoomArea(component);
        catalogPropsTemp[index].value = `${CatalogUrl}/Data/${roomfile.name}`;
        setrenderedRoom(roomfile);
        resolveThis();
      } else if (component.type === areaComponentref.LOGO_AREA) {
        catalogPropsTemp[index].value = await renderLogo(component);
        resolveThis();
      }
    });
  };

  const renderRoomArea = component => {
    return new Promise(async resolve => {
      const roomImgBlob = await getIllustration();

      let roomimg = new Image();
      roomimg.onload = () => {
        w = roomimg.width;
        h = roomimg.height;
        let roomcanvas = createCanvas(roomimg.width, roomimg.height);
        roomcanvas.setAttribute("id", "catalog-roomcanvas");
        let effectcanvas = createCanvas(roomimg.width, roomimg.height);
        effectcanvas.setAttribute("id", "catalog-effectcanvas");
        let offsetX = 0;
        if (roomData.config.name === "Folded Back") {
          offsetX = 0.3 * w;
        }
        roomcanvas.getContext("2d").drawImage(roomimg, offsetX, 0);
        const transitionCanvas = document.getElementById("catalog-transition-canvas");
        transitionCanvas.width = roomimg.width;
        transitionCanvas.height = roomimg.height;
        transitionCanvas.getContext("2d").drawImage(roomimg, 0, 0);

        // if (roomimg.width < roomimg.height) {
        //   roomcanvas.style.cssText = `position: absolute;max-width: 100%;top: -9999px;right: -9999px;left:-9999px;bottom: -9999px;margin: auto;`;
        // } else {
        //   roomcanvas.style.cssText = `position: absolute;max-height: 100%;top: -9999px;right: -9999px;left:-9999px;bottom: -9999px;margin: auto;`;
        // }
        roomcanvas.addEventListener("click", e =>
          onCanvasClick(e, roomcanvas.getBoundingClientRect())
        );
        document.getElementById(component.id).innerHTML = "";
        document.getElementById(component.id).appendChild(roomcanvas);
        document.getElementById(component.id).appendChild(effectcanvas);
        const roomname = `${designDetailState.designName.replace(/ /g, "")}_room-view.png`;
        const file = blobToFile(roomImgBlob, roomname);
        resolve(file);
      };
      roomimg.src = URL.createObjectURL(roomImgBlob);
    });
  };

  const renderLogo = async component => {
    let logo = new Image();

    logo.onload = () => {
      logo.style.cssText = `max-width: 100%;max-height: 100%;`;
      document.getElementById(component.id).appendChild(logo);
    };
    const logosrc = `${AppProvider.domain}${window.InterfaceElements.LogoUrl}`;
    logo.src = logosrc;

    return logosrc;
  };

  const renderDesign = async component => {
    let designcanvas;
    if (customDesignUrl?.url) {
      let image = await readImage(customDesignUrl.url);
      designcanvas = createCanvas(image.width, image.height);
      designcanvas.getContext("2d").drawImage(image, 0, 0);
    } else {
      designcanvas = await AppProvider.getRenderedDesign({
        designDetails: designDetailState.designDetails,
        fullpath: designDetailState.fullpath,
        hash: designDetailState.hash
      });
    }
    // designcanvas.height = designcanvas.height * designDetailState.designDetails.KLRatio;
    designcanvas.style.cssText = `max-width: 100%;max-height: 100%;transform:translateY(${((designDetailState
      .designDetails.KLRatio -
      1) /
      2) *
      100}%) scaleY(${designDetailState.designDetails.KLRatio})`;
    designcanvas.setAttribute("id", "catalog-designcanvas");
    document.getElementById(component.id).appendChild(designcanvas);
    return new Promise(resolve => {
      designcanvas.toBlob(function (blob) {
        const designname = `${designDetailState.designName.replace(/ /g, "")}_visualization.png`;
        const file = blobToFile(blob, designname);
        resolve(file);
      });
    });
  };

  window.downloadRenderedCatalog_old = async () => {
    let roomimg = new Image();

    const drawImg = async (canvas, id, blob) => {
      const width = canvas.width;
      const height = canvas.height;
      return new Promise(resolve => {
        let designimg = new Image();
        designimg.crossOrigin = "anonymous";
        designimg.onload = () => {
          const elementW = document.getElementById(id).style.width;
          const elementH = document.getElementById(id).style.height;
          const elementL = document.getElementById(id).style.left;
          const elementT = document.getElementById(id).style.top;
          let w = elementW.includes("%")
            ? (Number(elementW.replace("%", "")) * width) / 100
            : Number(elementW.replace("px", ""));
          let h = elementH.includes("%")
            ? (Number(elementH.replace("%", "")) * height) / 100
            : Number(elementH.replace("px", ""));
          let left = elementL.includes("%")
            ? (Number(elementL.replace("%", "")) * width) / 100
            : Number(elementL.replace("px", ""));
          let top = elementT.includes("%")
            ? (Number(elementT.replace("%", "")) * height) / 100
            : Number(elementT.replace("px", ""));
          if (designimg.height > designimg.width) {
            canvas
              .getContext("2d")
              .drawImage(
                designimg,
                0,
                0,
                designimg.width,
                designimg.height,
                left,
                top,
                (designimg.width * h) / designimg.height,
                h
              );
          } else {
            canvas
              .getContext("2d")
              .drawImage(
                designimg,
                0,
                0,
                designimg.width,
                designimg.height,
                left,
                top,
                w,
                (w * designimg.height) / designimg.width
              );
          }
          resolve();
        };
        designimg.src = typeof blob === "string" ? blob : URL.createObjectURL(blob);
      });
    };

    roomimg.onload = async () => {
      const height = roomimg.height;
      const width =
        (Number(document.getElementById("at_catalog_layout").style.width.replace("vh", "")) / 100) *
        roomimg.height;
      const canvas = createCanvas(width, height);
      canvas
        .getContext("2d")
        .drawImage(roomimg, (roomimg.width - width) / 2, 0, width, height, 0, 0, width, height);
      await drawImg(canvas, "ors_design_area", renderedDesign);
      await drawImg(
        canvas,
        "ors_logo_area",
        `${AppProvider.domain}${window.InterfaceElements.LogoUrl}`
      );
      canvas.getContext("2d").font =
        (document.getElementById("ors_design_name").offsetHeight * height) /
        document.getElementById("at_catalog_layout").offsetHeight +
        "px " +
        document.getElementById("ors_design_name").style.fontFamily;
      canvas.getContext("2d").fillStyle = document.getElementById("ors_design_name").style.color;
      canvas.getContext("2d").textAlign = "right";
      canvas
        .getContext("2d")
        .fillText(
          designDetailState.designName,
          (Number(document.getElementById("ors_design_name").style.width.replace("%", "")) *
            width) /
          100,
          (Number(document.getElementById("ors_design_name").style.top.replace("%", "")) * height) /
          100
        );
      const mime = "jpg";
      const downloadName = `${designDetailState.designName} in ${roomData.Name}.${mime}`;
      downloadImageData(canvas, downloadName, "image/" + mime);
    };
    roomimg.src = URL.createObjectURL(renderedRoom);
  };

  // const downlodPDF = () => {
  //   AppProvider.downloadPdf({
  //     url: CatalogUrl,
  //     catalogProps: catalogPropsState,
  //     rendereddesign: renderedDesign,
  //     renderedroom: renderedRoom
  //   });
  // };

  const getIllustration = async () => {
    // if (designDetailState.loading) return;
    const { dims } = roomData.config;
    // const { dims } = {...roomData.config, roomType:"roomview", designinroomformat:"roomview"};
    const { width, height } = dims;
    catalogRoomView.bgCanvas.width = width;
    catalogRoomView.bgCanvas.height = height;
    catalogRoomView.threeCanvas.width = width;
    catalogRoomView.threeCanvas.height = height;
    catalogRoomView.maskCanvas.width = width;
    catalogRoomView.maskCanvas.height = height;
    catalogRoomView.shadowCanvas.width = width;
    catalogRoomView.shadowCanvas.height = height;
    catalogRoomView.inputCanvas.width = width;
    catalogRoomView.inputCanvas.height = height;
    catalogRoomView.transitionCanvas.width = width;
    catalogRoomView.transitionCanvas.height = height;
    catalogRoomView.initConfig({
      baseUrl: roomData.baseUrl,
      config: roomData.config,
      // config: {...roomData.config, roomType:"roomview", designinroomformat:"roomview"},
      files: roomData.Files,
      local: roomData.fromlocal
    });
    const activeshot = Object.keys(roomData.config.roomElements.shots)[0];
    const isinitialized = catalogRoomView.preinitShot({
      shot: activeshot,
      light: null,
      view: null
    });
    if (!isinitialized) {
      await Promise.all(catalogRoomView.initShot());
    }
    catalogRoomView.resetOnDesignChange();
    const dominantColorHex = getDominantColor(designDetailState.designDetails);
    catalogRoomView.realTimeDynamicRendering = true;
    catalogRoomView.updateBackground({ dominantColorHex });
    catalogRoomView.updateMask();
    // if (
    //   catalogRoomView.designPath !== designDetailState.designPath
    // ) {
    //   const tileData = await AppProvider.fetchTileDetails({
    //     file: designDetailState.designPath
    //   });
    //   dispatchDesignDetails({
    //     type: designDetailActions.SET_TILE_DETAILS,
    //     payload: tileData
    //   });
    //   catalogRoomView.setTileDetails(tileData);
    // }
    await catalogRoomView.updatethreeCanvas(activeshot);
    catalogRoomView.setTileDetails(designDetailState.tileDetails);
    if (customDesignUrl?.url) {
      await catalogRoomView.renderDesignFromCustomUrl({ customUrl: customDesignUrl.url });
    } else {
      await catalogRoomView.renderDesign({
        designDetails: designDetailState.designDetails,
        designPath: designDetailState.fullpath,
        hash: designDetailState.hash
      });
    }

    await catalogRoomView.updateShadow();
    await catalogRoomView.makeTransitionCanvas({
      objects: {},
      canvas: null,
      resetDimensions: false
    });

    return new Promise(resolve => {
      catalogRoomView.transitionCanvas.toBlob(blob => {
        resolve(blob);
      });
    });
  };

  const onCanvasClick = (event, rect) => {
    const { clientX, clientY } = event;
    const { top, left, width, height } = rect;
    const x = clientX - left;
    const y = clientY - top;
    if (!catalogRoomView.moved && catalogRoomView.annotationCanvas) {
      const imgData = catalogRoomView.annotationCanvas.getContext("2d").getImageData(x, y, 1, 1);
      if (!imgData.data.every(data => data === 255 || data === 0)) {
        catalogRoomView.selectedColorCode = imgData.data.slice(0, 3);
        setcolorSelectionPos({ x: x, y: y });
      }
    }
  };

  const onRoomColorSwatchClick = async color => {
    setcolorSelectionPos({ x: -1, y: -1 });
    catalogRoomView.updateBackground({ dominantColorHex: color.Color });
    catalogRoomView.updateMask();
    await catalogRoomView.updateShadow();
    await catalogRoomView.makeTransitionCanvas({ objects: {}, canvas: null });
    catalogRoomView.transitionCanvas.toBlob(roomImgBlob => {
      let roomimg = new Image();
      roomimg.onload = () => {
        let roomcanvas = createCanvas(roomimg.width, roomimg.height);
        roomcanvas.getContext("2d").drawImage(roomimg, 0, 0);

        // if (roomimg.width < roomimg.height) {
        //   roomcanvas.style.cssText = `position: absolute;max-width: 100%;top: -9999px;right: -9999px;left:-9999px;bottom: -9999px;margin: auto;`;
        // } else {
        //   roomcanvas.style.cssText = `position: absolute;max-height: 100%;top: -9999px;right: -9999px;left:-9999px;bottom: -9999px;margin: auto;`;
        // }
        roomcanvas.addEventListener("click", e =>
          onCanvasClick(e, roomcanvas.getBoundingClientRect())
        );
        let index = -1;
        catalogPropsState.forEach((element, i) => {
          if (element.type === areaComponentref.ROOM_VIEW) {
            index = i;
          }
        });
        document.getElementById(catalogPropsState[index].id).innerHTML = "";
        document.getElementById(catalogPropsState[index].id).appendChild(roomcanvas);
        const roomname = `${designDetailState.designName.replace(/ /g, "")}_room-view.png`;
        const file = blobToFile(roomImgBlob, roomname);
        let catalogPropsTemp = [...catalogPropsState];
        catalogPropsTemp[index].value = `${CatalogUrl}/Data/${file.name}`;
        setcatalogPropsState([...catalogPropsTemp]);
        setrenderedRoom(file);
      };
      roomimg.src = URL.createObjectURL(roomImgBlob);
    });
  };

  window.downloadRenderedCatalog = async () => {
    let wd = document.getElementById("at_catalog_layout").getBoundingClientRect().width;
    let ht = document.getElementById("at_catalog_layout").getBoundingClientRect().height;
    let roomcanvas = document.getElementById("catalog-roomcanvas");
    let effectcanvas = document.getElementById("catalog-effectcanvas");
    if (filterData) {
      roomcanvas.style.display = "none";
      document.getElementById("catalogview").appendChild(roomcanvas);
    } else {
      effectcanvas.style.display = "none";
      document.getElementById("catalogview").appendChild(effectcanvas);
    }
    effectcanvas.style.transform = "none";
    const downloadName = `${designDetailState.designName} in ${roomData.config.name}`;
    return new Promise(resolve => {
      makePDf("at_catalog_layout", wd, ht, downloadName, () => {
        if (filterData) {
          effectcanvas.before(roomcanvas);
        } else {
          roomcanvas.after(effectcanvas);
        }
        effectcanvas.style.transform = "translateY(calc(-100% - 4px))";
        if (filterData) {
          effectcanvas.style.display = "block";
        }
        roomcanvas.style.display = "block";
        resolve();
      });
    });
  };

  return (
    <React.Fragment>

      <div id="catalogview">

      </div>
      <Overlay
        onClose={() => setcolorSelectionPos({ x: -1, y: -1 })}
        isOpen={colorSelectionPos.x > -1}
        className={Classes.OVERLAY_SCROLL_CONTAINER}
        hasBackdrop={false}
        lazy={false}
      // usePortal={true}
      // portalContainer={document.getElementById("catalogview")}
      >
        <ColorSelectionBox
          style={{
            left: `${colorSelectionPos.x}px`,
            top: `${colorSelectionPos.y}px`,
            display: "block",
            // display: `${colorSelectionPos.x > -1 ? "block" : "none"}`,
            position: "absolute",
            transform: "translate(0%, -50%)",
            pointerEvents: "all"
          }}
          onColorSwatchClick={(color, e) => onRoomColorSwatchClick(color)}
          onClose={() => setcolorSelectionPos({ x: -1, y: -1 })}
        />
      </Overlay>
      <canvas
        id="catalog-transition-canvas"
        className="catalog-transition-canvas"
        style={{ opacity: showTransition ? 1 : 0, pointerEvents: "none" }}
      ></canvas>
    </React.Fragment>
  );
};

export default CatalogView;
