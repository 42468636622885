import {
  Box3, Clock, Color,
  DirectionalLight, PointLight,
  PerspectiveCamera,
  Raycaster,
  Scene, Vector3, WebGLRenderer, AmbientLight,
  HemisphereLight,
} from "three";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { convertArrIntoRad, convertUnit, createVector, makeUrl, readImage, resizeKeepingAspect } from "../../utils/utils";
import CameraControls from "../IllustrationView/cameracontrols/camera-controls";
import TileCanvas from "../../tilecanvasnew";
import { createCanvas, downloadImageData } from "../../utils/canvasutils";

CameraControls.install({ THREE: THREE });
const tileCanvas = new TileCanvas();

class ResourceTrackerCs {
  constructor() {
    this.resources = new Set();
    this.resourceslocal = new Set();
  }
  track(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.track(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resources.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.track(resource.geometry);
      this.track(resource.material);
      this.track(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.track(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.track(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  tracklocal(resource) {
    if (!resource) {
      return resource;
    }
    // handle children and when material is an array of materials or
    // uniform is array of textures
    if (Array.isArray(resource)) {
      resource.forEach(resource => this.tracklocal(resource));
      return resource;
    }

    if (resource.dispose || resource instanceof THREE.Object3D) {
      this.resourceslocal.add(resource);
    }
    if (resource instanceof THREE.Object3D) {
      this.tracklocal(resource.geometry);
      this.tracklocal(resource.material);
      this.tracklocal(resource.children);
    } else if (resource instanceof THREE.Material) {
      // We have to check if there are any textures on the material
      for (const value of Object.values(resource)) {
        if (value instanceof THREE.Texture) {
          this.tracklocal(value);
        }
      }
      // We also have to check if any uniforms reference textures or arrays of textures
      if (resource.uniforms) {
        for (const value of Object.values(resource.uniforms)) {
          if (value) {
            const uniformValue = value.value;
            if (uniformValue instanceof THREE.Texture ||
              Array.isArray(uniformValue)) {
              this.tracklocal(uniformValue);
            }
          }
        }
      }
    }
    return resource;
  }
  untrack(resource) {
    this.resources.delete(resource);
  }
  untracklocal(resource) {
    this.resourceslocal.delete(resource);
  }
  dispose() {
    const allresources = new Set([...this.resources, ...this.resourceslocal])
    for (const resource of allresources) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resources.clear();
    this.resourceslocal.clear();
  }
  disposelocal() {
    for (const resource of this.resourceslocal) {
      if (resource instanceof THREE.Object3D) {
        if (resource.parent) {
          resource.parent.remove(resource);
        }
      }
      if (resource.dispose) {
        resource.dispose();
      }
    }
    this.resourceslocal.clear();
  }
}

export default class CloseupViewHelper {
  constructor() {
    this.scene = new Scene()
    this.fbxLoader = new FBXLoader();
    this.raycaster = new Raycaster();
    this.lightsInScene = [];
    this.resTracker = new ResourceTrackerCs();
    this.track = this.resTracker.track.bind(this.resTracker);
    this.tracklocal = this.resTracker.tracklocal.bind(this.resTracker);
  }
  cleanup() {
    tileCanvas.designDetails = null;
    if (this.resTracker) {
      this.resTracker.dispose();
    }
  }
  init({ canvas }) {
    if (this.resTracker) {
      this.resTracker.disposelocal();
      this.scene = null;
      this.scene = new Scene();
    }
    this.renderer = this.tracklocal(new WebGLRenderer({
      canvas,
      preserveDrawingBuffer: true,
      antialias: false
    }));
    this.renderer.shadowMap.enabled = true
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    // this.renderer.toneMapping = THREE.LinearToneMapping;
    // this.renderer.toneMappingExposure = 0.53;
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.w = width;
    this.h = height;
    this.renderer.setPixelRatio(1);
    this.renderer.setSize(this.w, this.h);
    this.lastKnownIntersectionPoint = null;
    window.scene = this.scene;

  }
  initConfig({ config, baseUrl }) {
    this.sceneConfig = { ...config[config.scenes[0]], designScale: window.globalCarpetScale };
    this.flipY = config.hasOwnProperty('flipY') ? config.flipY : true;
    this.baseUrl = baseUrl

    const { Shot_1: shot1 } = this.sceneConfig;
    this.updateCamera(shot1);
    this.shot = "Shot_1";
  }
  updateCamera() {
    const { Shot_1: shot1, } = this.sceneConfig;
    this.camera = this.tracklocal(perspectiveCamera({ ...shot1, width: this.w, height: this.h }));
    this.orbit = addCameraControl(this.renderer, this.scene, this.camera, shot1);
    this.orbit.enabled = true;
  }
  async setupFloor(floorOption) {
    // if (this.floor) return;
    //**unit is feet */
    const geometry = this.tracklocal(new THREE.PlaneGeometry(10, 10, 100, 100));
    const material = this.tracklocal(new THREE.MeshStandardMaterial({ side: THREE.FrontSide, metalness: 0, roughness: 0.1 }));
    // const material = new THREE.MeshStandardMaterial({ side: THREE.FrontSide, metalness: 0.4, roughness: 1 });
    this.floor = this.tracklocal(new THREE.Mesh(geometry, material));
    this.floor.rotation.x = -Math.PI / 2
    this.floor.name = "floor"
    this.floor.castShadow = false;
    this.floor.receiveShadow = true;
    //**50 feet */
    this.floor.scale.set(5, 5, 1)
    // this.floor.scale.set(10, 10, 1)
    this.updateFloorTexture(floorOption)
    this.scene.add(this.floor)
  }
  async updateFloor(floorOption) {
    if (!this.floor) this.setupFloor(floorOption)
    await this.updateFloorTexture(floorOption)
    this.render()
  }
  async updateFloorTexture(floorOption) {
    const { path, width1 = 640, height1 = 640 } = floorOption;
    let width = 640, height = 640;
    if (floorOption.metalness) {
      this.floor.material.metalness = floorOption.metalness;
    }
    if (floorOption.roughness) {
      this.floor.material.roughness = floorOption.roughness;
    }
    if (!this.floorCanvas) {
      this.floorCanvas = document.createElement("canvas")
    }
    try {
      const floorImage = await readImage(path);
      this.floorCanvas.width = floorImage.width;
      this.floorCanvas.height = floorImage.height;
      this.floorCanvas.getContext("2d").drawImage(floorImage, 0, 0);
      width = floorImage.width / 305;
      height = floorImage.height / 305;
    } catch (error) {
      return;
      this.floorCanvas.width = 1024;
      this.floorCanvas.height = 1024;
      const ctx = this.floorCanvas.getContext("2d")
      ctx.fillStyle = "#f0f0f0";
      ctx.fillRect(0, 0, 1024, 1024);
    }

    const floorTexture = this.tracklocal(new THREE.CanvasTexture(this.floorCanvas));
    floorTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    floorTexture.wrapS = floorTexture.wrapT = THREE.RepeatWrapping;
    // floorTexture.repeat.set(50 / width, 50 / height);
    window.floor = this.floor;
    floorTexture.repeat.set(50 / width, 50 / height);
    this.floor.material.map = floorTexture;
    this.floor.material.needsUpdate = true;
    this.floor.material.map.needsUpdate = true;
  }

  setupObjects() {
    return new Promise((resolve, reject) => {
      const { modelUrl, objects3d, surfaces, carpetMeshName } = this.sceneConfig;
      let carpetObjectName = carpetMeshName;
      if (!carpetObjectName) {
        carpetObjectName = "carpet"
      }
      const setup = (object) => {
        if (this.object3d) {
          this.scene.remove(this.object3d)
        }
        if (this.object3dBack) {
          this.scene.remove(this.object3dBack)
        }
        if (this.objectGroup) {
          this.scene.remove(this.objectGroup)
        }
        const carpetObject = this.tracklocal(object.getObjectByName(carpetObjectName));
        const objectConfig = this.sceneConfig[carpetObjectName]
        const {
          position = [0, 0, 0],
          rotation = [90, 0, 0],
          scale = [1, 1, 1],
          shadow = {}
        } = objectConfig;
        carpetObject.position.fromArray(position);
        carpetObject.scale.fromArray(scale);
        carpetObject.rotation.fromArray(convertArrIntoRad(rotation));
        carpetObject.castShadow = shadow.cast;
        carpetObject.receiveShadow = shadow.receive;
        this.scene.add(carpetObject);

        if (surfaces) {
          this.objectGroup = carpetObject;
          const { back, front } = surfaces;
          this.object3d = carpetObject.getObjectByName(front);
          const frontConf = this.sceneConfig[front] || {}
          if (back) {
            this.object3dBack = carpetObject.getObjectByName(back);
            const backConf = this.sceneConfig[back] || {}
            const { shadow = {} } = backConf
            this.object3dBack.castShadow = shadow.cast
            this.object3dBack.receiveShadow = shadow.receive
          }
          else this.object3dBack = null
          const { shadow = {} } = frontConf
          this.object3d.castShadow = shadow.cast
          this.object3d.receiveShadow = shadow.receive
        } else {
          this.objectGroup = null;
          this.object3dBack = null
          this.object3d = carpetObject;
        }
        if (this.material) {
          this.object3d.material = this.material;
        }
        if (this.materialBack && this.object3dBack) {
          this.object3dBack.material = this.materialBack;
        }
        let shouldrenderDesignBack = !this.materialBack && this.object3dBack
        resolve(shouldrenderDesignBack)
      }
      this.fbxLoader.load(makeUrl(this.baseUrl, modelUrl), (object) => {
        setup(object)
      }, undefined, reject)
    })
  }
  setupLights() {
    this.lightsInScene.forEach(light => {
      this.scene.remove(light);
    })
    this.lightsInScene = [];
    const { lights: lightsConfig } = this.sceneConfig;
    const { shadowCamSize = 5, sources } = lightsConfig;

    sources.forEach((lightConf => {
      const {
        type,
        intensity = 1,
        position = [0, 0, 0],
        shadowRadius = 1,
        name = "light",
        color = "#ffffff"
      } = lightConf;
      const lightColor = new Color(color)
      const secondColor = "#808080"
      let light;
      // let light = new AmbientLight(lightColor, intensity);
      switch (type) {
        case "DIRECTIONAL":
          light = new DirectionalLight(lightColor, intensity);
          light.shadow.radius = shadowRadius;
          //** thorai vaye shadow half render hunxa dherai vaye carpet mathi shadow auxa*/
          const d = shadowCamSize;

          light.shadow.camera.left = - d;
          light.shadow.camera.right = d;
          light.shadow.camera.top = d;
          light.shadow.camera.bottom = - d;

          light.shadow.camera.far = 500;
          light.shadow.camera.near = 0.5;
          light.castShadow = true;
          if (process.env.NODE_ENV === "development") {
            const helper = this.tracklocal(new THREE.DirectionalLightHelper(light));
            this.scene.add(helper);
          }

          break;
        case "POINTLIGHT":
          light = new PointLight(lightColor, intensity);
          light.shadow.radius = shadowRadius;

          const e = shadowCamSize;
          light.shadow.camera.left = - e;
          light.shadow.camera.right = e;
          light.shadow.camera.top = e;
          light.shadow.camera.bottom = - e;

          light.shadow.camera.far = 500;
          light.shadow.camera.near = 0.5;
          light.castShadow = true;
          if (process.env.NODE_ENV === "development") {
            const helper = this.tracklocal(new THREE.PointLightHelper(light));
            this.scene.add(helper);
          }
          break;
        case "HEMISPHERELIGHT":
          light = new HemisphereLight(lightColor, secondColor, intensity);
          // light.shadow.radius = shadowRadius;

          // const f = shadowCamSize;
          // light.shadow.camera.left = - f;
          // light.shadow.camera.right = f;
          // light.shadow.camera.top = f;
          // light.shadow.camera.bottom = - f;

          // light.shadow.camera.far = 500;
          // light.shadow.camera.near = 0.5;
          // light.castShadow = true;
          break;
        default:
          light = new AmbientLight(lightColor, intensity);
          break;
      }
      light.name = name;
      light.position.fromArray(position);
      this.lightsInScene.push(light)
      this.scene.add(this.tracklocal(light))
    }))
  }
  renderDesignFromCustomUrl({ customUrl, physicalWidth, physicalHeight }) {
    return new Promise((resolve, reject) => {
      readImage(customUrl).then(image => {
        const { width, height } = image;
        const designCanvas = createCanvas(width, height);
        const ctx = designCanvas.getContext('2d')
        ctx.drawImage(image, 0, 0)
        const backDesignCanvas = createCanvas(width, height);
        const bctx = backDesignCanvas.getContext('2d')
        bctx.drawImage(image, 0, 0)
        const normapCanvas = createCanvas(width, height);
        const ctxNorm = normapCanvas.getContext("2d")
        ctxNorm.fillStyle = "rgb(127,127,255)";
        ctxNorm.fillRect(0, 0, width, height);
        let PhysicalWidth, PhysicalHeight
        if (!physicalWidth || !physicalHeight) {
          const maxDims = { width: 1200, height: 1500 }
          const { width: newWidth, height: newHeight } = resizeKeepingAspect({ width, height }, maxDims, "fit_inside")
          PhysicalWidth = convertUnit("in", "ft", newWidth / 10)
          PhysicalHeight = convertUnit("in", "ft", newHeight / 10)
        } else {
          PhysicalWidth = convertUnit("cm", "ft", physicalWidth)
          PhysicalHeight = convertUnit("cm", "ft", physicalHeight)
        }
        const designDetails = { Width: width, Height: height, PhysicalWidth, PhysicalHeight, Unit: "ft" }
        this.setCarpetTexture({
          designDetails,
          designCanvas,
          normapCanvas,
          backDesignCanvas
        });
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
  renderDesign({ designDetails, designPath, hash }) {
    const { IsIrregular } = designDetails;
    this.designPath = designPath;
    this.designDetails = designDetails;
    this.zoom = this.sceneConfig.designScale || 4;
    return new Promise((resolve, reject) => {
      if (tileCanvas.designDetails !== designDetails) {
        tileCanvas.fronttilesDrawn = false;
        tileCanvas.backtilesDrawn = false;
        if (window.InterfaceElements.IsJpeg) this.zoom = 1
        tileCanvas.init({
          designDetails,
          tileSize: 256,
          zoom: this.zoom
        });
      }
      const tileTransparency = this.tileDetails && this.tileDetails[`tileTransparency${this.zoom}`] ? this.tileDetails[`tileTransparency${this.zoom}`] : (IsIrregular ? [] : [1]);
      let commonTileProps = {
        designPath,
        zoom: this.zoom,
        designDetails,
        hash,
        tileTransparency
      };
      const { DesignColors } = designDetails;
      const options = {
        ...commonTileProps,
        drawNormap:
          DesignColors?.length &&
          !DesignColors.every(color => color.PileHeight === DesignColors[0].PileHeight) ||
          tileTransparency.length
      }
      let renderBounds = {
        p1: { x: 0, y: 0 },
        p2: { x: 1024, y: 1024 }
      };
      let fronttilesDrawn;
      let backtilesDrawn;

      const drawBack = () => {
        if (this.object3dBack) {
          if (!tileCanvas.backtilesDrawn) {
            tileCanvas.drawCanvasBackTiles({ ...options, renderBounds }, undefined, () => {
              backtilesDrawn = true;
              tileCanvas.backtilesDrawn = true;
              setTexture(fronttilesDrawn, backtilesDrawn);
            })
          }
          else {
            backtilesDrawn = true;
            setTexture(fronttilesDrawn, backtilesDrawn);
          }
        }
      }

      const setTexture = () => {
        if (!fronttilesDrawn) return
        if (!backtilesDrawn) {
          drawBack();
          return;
        }
        this.setCarpetTexture({
          designDetails,
          designCanvas: tileCanvas.canvas,
          normapCanvas: tileCanvas.canvasNorm,
          // roughmapCanvas: tileCanvas.canvasRough,
          backDesignCanvas: tileCanvas.canvasBack
        });
        resolve();
      }
      if (!tileCanvas.fronttilesDrawn) {
        tileCanvas.drawCanvasTiles(options, undefined, () => {
          fronttilesDrawn = true;
          tileCanvas.fronttilesDrawn = true;
          if (!this.object3dBack) backtilesDrawn = true;
          setTexture(fronttilesDrawn, backtilesDrawn);
        });
      }
      else {
        fronttilesDrawn = true;
        setTexture(fronttilesDrawn, backtilesDrawn);
      }
      this.setCarpetScale(designDetails);
    });
  }
  setCarpetScale(designDetails) {
    const carpetObjectName = this.sceneConfig.carpetMeshName ? this.sceneConfig.carpetMeshName : "carpet";
    const carpetConfig = this.sceneConfig[carpetObjectName]

    if (carpetConfig.dynamicScaling) {
      const { Unit, PhysicalHeight, PhysicalWidth, KLRatio } = designDetails;
      const wid = convertUnit(Unit, "ft", PhysicalWidth);
      const hgt = convertUnit(Unit, "ft", PhysicalHeight);
      this.object3d.scale.set(wid * KLRatio, hgt, 1);
    }
  }
  setCarpetTexture({ designCanvas, normapCanvas, backDesignCanvas, roughmapCanvas }) {
    const designTexture = this.tracklocal(new THREE.CanvasTexture(
      designCanvas, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearFilter
    ));
    const normalTexture = this.tracklocal(new THREE.CanvasTexture(
      normapCanvas, THREE.UVMapping, THREE.ClampToEdgeWrapping, THREE.ClampToEdgeWrapping, THREE.LinearFilter, THREE.LinearFilter
    ));
    // const roughTexture = new THREE.CanvasTexture(roughmapCanvas);
    designTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    normalTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    // normalTexture.wrapS = normalTexture.wrapT = THREE.RepeatWrapping;
    // designTexture.wrapS = designTexture.wrapT = THREE.RepeatWrapping;
    designTexture.flipY = this.flipY;
    normalTexture.flipY = this.flipY;

    designTexture.minFilter = THREE.LinearFilter;
    designTexture.needsUpdate = true;
    normalTexture.minFilter = THREE.LinearFilter;
    normalTexture.needsUpdate = true;
    // roughTexture.flipY = this.flipY;


    const carpetObjectName = this.sceneConfig.carpetMeshName ? this.sceneConfig.carpetMeshName : "carpet";
    const carpetConfig = this.sceneConfig[carpetObjectName]
    this.material = this.tracklocal(new THREE.MeshStandardMaterial({
      map: designTexture,
      normalMap: normalTexture,
      // roughnessMap: roughTexture,
      color: 0xffffff,
      roughness: carpetConfig.roughness ? carpetConfig.roughness : 1,
      metalness: carpetConfig.metalness ? carpetConfig.metalness : 0,
      needsUpdate: true,
      // transparent: true,
      side: THREE.FrontSide
    }));
    if (!this.object3d) {
      console.error("Error seting carpet texure. Could not find the 3d object");
      return;
    }
    this.object3d.material = this.material;
    this.object3d.material.needsUpdate = true;
    if (this.object3dBack && backDesignCanvas) {
      const designTextureBack = this.tracklocal(new THREE.CanvasTexture(backDesignCanvas));
      designTextureBack.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      designTextureBack.wrapS = designTextureBack.wrapT = THREE.RepeatWrapping;
      this.materialBack = this.tracklocal(new THREE.MeshStandardMaterial({
        map: designTextureBack,
        color: 0xffffff,
        transparent: true,
        side: THREE.FrontSide,
        needsUpdate: true,
        roughness: 1,
        metalness: 0,
      }));
      this.object3dBack.material = this.materialBack;
      this.object3dBack.material.needsUpdate = true;
    }
    // this.setCarpetScale(designDetails);
    this.render();
  }
  updateMap() {
    if (this.object3d && this.object3d.material && this.object3d.material.map) {
      this.object3d.material.needsUpdate = true
      this.object3d.material.map.needsUpdate = true
    }
    if (this.object3dBack && this.object3dBack.material && this.object3dBack.material.map) {
      this.object3dBack.material.needsUpdate = true
      this.object3dBack.material.map.needsUpdate = true
    }
    this.render()
  }
  render() {
    this.renderer.render(this.scene, this.camera);
  }
  resizeRenderer({ width, height }) {
    if (!this.renderer || !this.camera) return
    this.w = width;
    this.h = height;
    if (this.camera) {
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }
    this.renderer.setSize(width, height);
    this.render();
  }
  // interseectsFloor(e) {
  //   const { x, y } = e;
  //   let { mouseX, mouseY } = this.convMouseCord(x, y);
  //   var mouse = new THREE.Vector3(mouseX, mouseY, 0.99);
  //   this.raycaster.setFromCamera(mouse, this.camera);
  //   var intersects = this.raycaster.intersectObjects(this.scene.children);
  //   if (!intersects.length) return false
  //   if (intersects[0].object.name === "floor") {
  //     this.lastKnownIntersectionPoint = intersects[0].point.clone()
  //     return intersects[0];
  //   }
  //   else return false
  // }
  projectPoint(point) {
    if (!this.lastKnownIntersectionPoint)
      return;
    const size = new THREE.Vector2()
    // this.projected = true;
    this.renderer.getSize(size)
    const pnt = point ? point : this.lastKnownIntersectionPoint
    return createVector(
      pnt,
      this.camera,
      size.x, size.y
    );
  }
  convMouseCord(x, y) {
    const vec = new THREE.Vector2();
    const { width, height } = this.renderer.getSize(vec);
    var mouseX = (x / width) * 2 - 1;
    var mouseY = -(y / height) * 2 + 1;
    return { mouseX, mouseY };
  }
  downloadRendering(name, mime) {
    if (!this.dwRenderer) {
      const canvas = document.createElement("canvas");
      this.dwRenderer = new WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
        canvas
      })
    }
    this.dwRenderer.shadowMap.enabled = true
    this.dwRenderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.dwRenderer.setPixelRatio(1)
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const renderWidth = 3840;
    const renderHeight = 2160;
    let width, height;
    if (renderWidth / windowWidth < renderHeight / windowHeight) {
      width = renderWidth
      height = windowHeight * renderWidth / windowWidth;
    } else {
      height = renderHeight;
      width = windowWidth * renderHeight / windowHeight;
    };
    this.dwRenderer.setSize(width, height);
    this.dwRenderer.render(this.scene, this.camera);
    downloadImageData(this.dwRenderer.domElement, name, mime);
  }
  getInstance() {
    return new Promise((resolve) => {
      if (!this.dwRenderer) {
        const canvas = document.createElement("canvas");
        this.dwRenderer = new WebGLRenderer({
          antialias: true,
          preserveDrawingBuffer: true,
          canvas
        })
      }

      this.dwRenderer.shadowMap.enabled = true
      this.dwRenderer.shadowMap.type = THREE.PCFSoftShadowMap;

      this.dwRenderer.setPixelRatio(2)
      //**not sure if this should be set to 1920x1080 explicitly */
      this.dwRenderer.setSize(window.innerWidth, window.innerHeight);
      this.dwRenderer.render(this.scene, this.camera);
      resolve(this.dwRenderer.domElement)
    })
  }
  changeShot(shotId) {
    //TODO: animate this
    if (!shotId) return
    const shotConfig = this.sceneConfig[shotId]
    const { position, target, fov, enableRotate, boundingBox, minDistance, maxDistance } = shotConfig;
    if (fov) {
      this.camera.fov = fov;
      this.camera.updateProjectionMatrix();
    }
    if (position && target && position.length === 3 && target.length === 3) {
      this.orbit.setLookAt(position[0], position[1], position[2], target[0], target[1], target[2], true)
    }
    // this.orbit.setLookAt(-5.6, 2.7, -0.81, -0.79, -2.34, -2.81, true)
    this.orbit.update();
    this.render();
    this.shot = shotId;
    //test block of code
    setTimeout(() => {
      this.render();
    }, 400);

  }
  resetView() {

  }

}



const addCameraControl = function (renderer, scene, camera, config = {}) {
  let {
    target = [0, 0, 0],
    boundingBox,
    minDistance,
    maxDistance,
    enableRotate = false,
    sphericalInit,
    position = [0, 200, 500],
  } = config;
  const clock = new Clock();
  const cameraControls = new CameraControls(camera, renderer.domElement);
  cameraControls.setTarget(target[0], target[1], target[2], false);
  cameraControls.verticalDragToForward = true;
  cameraControls.polarRotateSpeed = enableRotate ? 1 : 0;
  cameraControls.azimuthRotateSpeed = enableRotate ? 1 : 0;
  cameraControls.minPolarAngle = 0
  cameraControls.maxPolarAngle = 60 * Math.PI / 180

  if (sphericalInit) {
    cameraControls.rotateTo(sphericalInit.theta * Math.PI / 180, sphericalInit.phi * Math.PI / 180, true);
    sphericalInit.radius && cameraControls.dollyTo(sphericalInit.radius, false);
    camera.position.set(...position);
  }

  if (boundingBox) {
    const bb = new Box3(
      new Vector3(...boundingBox.start),
      new Vector3(...boundingBox.end)
    );
    cameraControls.setBoundary(bb);
    cameraControls.boundaryFriction = 0.2;
  }
  cameraControls.minDistance = minDistance;
  cameraControls.maxDistance = maxDistance;
  window.cameraControls = cameraControls;

  renderer.render(scene, camera);
  let counter = 0;
  (function anim() {
    const delta = clock.getDelta();
    // const elapsed = clock.getElapsedTime();
    const updated = cameraControls.update(delta);
    // if (updated) counter = 10;
    requestAnimationFrame(anim);
    if (updated || (counter-- > 0)) {
      renderer.render(scene, camera);
    }
  })();
  return cameraControls;
};
const perspectiveCamera = (config = {}) => {
  const { innerWidth, innerHeight } = window;
  let {
    fov = 40,
    near = 0.1,
    far = 100000,
    height = innerHeight,
    width = innerWidth,
    position = [0, 200, 500],
    target = [0, 0, 0],
    rotation = [0, 0, 0]
  } = config;
  const aspect = width / height;
  const camera = new PerspectiveCamera(fov, aspect, near, far);
  camera.lookAt(new Vector3(...target)); // This seems to be disabled by OrbitControls
  camera.position.set(...position);
  camera.rotation.set(...convertArrIntoRad(rotation));
  return camera;
};

