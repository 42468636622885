const { createCanvas } = require("./canvasutils")

const toXY = (i, width) => {
  return { x: (i / 4) % width, y: Math.floor((i / 4) / width) }
}
const fromXY = (x, y, width) => {
  return (x + y * width) * 4
}

export const createBlurMatrix = (srcCanvas) => {
  const srcCtx = srcCanvas.getContext("2d");
  const src_w = srcCanvas.width;
  const src_h = srcCanvas.height;
  const srcData = srcCtx.getImageData(0, 0, src_w, src_h);
  let blurMatrix = Array(srcData.data.length)

  return new Promise((resolve) => {
    for (let i = 0; i < srcData.data.length; i += 4) {
      let totR = srcData.data[i + 0];
      let totG = srcData.data[i + 1];
      let totB = srcData.data[i + 2];
      let totA = srcData.data[i + 3];
      const { x, y } = toXY(i, src_w);
      if (x > 0) {
        const ix = fromXY(x - 1, y, src_w, src_h)
        totR += blurMatrix[ix];
        totG += blurMatrix[ix + 1];
        totB += blurMatrix[ix + 2];
        totA += blurMatrix[ix + 3];
      }
      if (y > 0) {
        const iy = fromXY(x, y - 1, src_w, src_h)
        totR += blurMatrix[iy];
        totG += blurMatrix[iy + 1];
        totB += blurMatrix[iy + 2];
        totA += blurMatrix[iy + 3];
      }
      if (x > 0 && y > 0) {
        const ix = fromXY(x - 1, y - 1, src_w, src_h)
        totR -= blurMatrix[ix];
        totG -= blurMatrix[ix + 1];
        totB -= blurMatrix[ix + 2];
        totA -= blurMatrix[ix + 3];
      }
      blurMatrix[i + 0] = totR;
      blurMatrix[i + 1] = totG;
      blurMatrix[i + 2] = totB;
      blurMatrix[i + 3] = totA;
      if (i >= (srcData.data.length - 4)) {
        resolve(blurMatrix);
      }
    }
  })


}

export const createBlurImage = async (src, depthImage, centerAt, dontBlur, maxRadius, blurMatrix = null) => {
  return new Promise(async (resolve) => {
    const width = src.width;
    const height = src.height;
    let srcCanvas, srcCtx;
    if (src.nodeName === "IMAGE") {
      srcCanvas = createCanvas(width, height);
      srcCtx = srcCanvas.getContext("2d")
      srcCtx.drawImage(src, 0, 0, width, height)
    }
    else if (src.nodeName === "CANVAS") {
      srcCanvas = src;
      srcCtx = srcCanvas.getContext("2d");
    }
    const depthCanvas = createCanvas(width, height);
    const depthCtx = depthCanvas.getContext("2d")
    depthCtx.drawImage(depthImage, 0, 0, width, height)
    const dstCanvas = createCanvas(width, height);
    const dstCtx = dstCanvas.getContext("2d");
    const t0 = performance.now()
    if (!blurMatrix) {
      blurMatrix = await createBlurMatrix(srcCanvas)
    }
    const t1 = performance.now()

    const min1 = centerAt - dontBlur;
    const min2 = 0;
    const max1 = centerAt + dontBlur;
    const max2 = 255;

    const dstData = dstCtx.getImageData(0, 0, width, height)
    const srcData = srcCtx.getImageData(0, 0, width, height)
    const depthData = depthCtx.getImageData(0, 0, width, height)

    for (let i = 0; i < srcData.data.length; i += 4) {
      const gs = depthData.data[i];
      let delta = 0;
      if (gs < min1)
        delta = (min1 - gs) * 255.0 / (min1 - min2);
      if (gs > max1)
        delta = (gs - max1) * 255.0 / (max2 - max1);
      const factor = delta / 255.0;
      const radius = Math.round(factor * maxRadius);
      if (radius === 0) {
        dstData.data[i] = srcData.data[i];
        dstData.data[i + 1] = srcData.data[i + 1]
        dstData.data[i + 2] = srcData.data[i + 2]
        dstData.data[i + 3] = srcData.data[i + 3]
      }
      else {
        let boxw = radius;
        let boxh = radius;
        let mul = 1.0 / ((boxw * 2) * (boxh * 2));
        const { x, y } = toXY(i, width);
        const ria = fromXY(x + boxw, y + boxh, width, height)
        const rib = fromXY(x - boxw, y - boxh, width, height)
        const ric = fromXY(x - boxw, y + boxh, width, height)
        const rid = fromXY(x + boxw, y - boxh, width, height)

        const getBlurData = (ind) => {
          let returnVal = (ind >= 0 ? blurMatrix[ind] : 0);
          return returnVal;
        }
        dstData.data[i + 0] += Math.round((getBlurData(ria + 0) + getBlurData(rib + 0) - getBlurData(ric + 0) - getBlurData(rid + 0)) * mul);
        dstData.data[i + 1] += Math.round((getBlurData(ria + 1) + getBlurData(rib + 1) - getBlurData(ric + 1) - getBlurData(rid + 1)) * mul);
        dstData.data[i + 2] += Math.round((getBlurData(ria + 2) + getBlurData(rib + 2) - getBlurData(ric + 2) - getBlurData(rid + 2)) * mul);
        dstData.data[i + 3] += Math.round((getBlurData(ria + 3) + getBlurData(rib + 3) - getBlurData(ric + 3) - getBlurData(rid + 3)) * mul);
      }
      if (i >= (srcData.data.length - 4)) {
        dstCtx.putImageData(dstData, 0, 0)
        const t2 = performance.now()
        console.log(t2 - t1, t1 - t0)
        resolve(dstCanvas);
      }
    }
  });
}




/*

const { createCanvas } = require("./canvasutils")

const toXY = (i, width) => {
  return { x: (i / 4) % width, y: Math.floor((i / 4) / width) }
}
const fromXY = (x, y, width) => {
  return (x + y * width) * 4
}

const createBlurMatrix = (srcCanvas) => {
  const srcCtx = srcCanvas.getContext("2d");
  const src_w = srcCanvas.width;
  const src_h = srcCanvas.height;
  const srcData = srcCtx.getImageData(0, 0, src_w, src_h);
  let blurMatrix = Array(srcData.data.length)

  return new Promise((resolve) => {
    for (let i = 0; i < srcData.data.length; i += 4) {
      let totR = srcData.data[i + 0];
      let totG = srcData.data[i + 1];
      let totB = srcData.data[i + 2];
      let totA = srcData.data[i + 3];
      const { x, y } = toXY(i, src_w);
      if (x > 0) {
        const ix = fromXY(x - 1, y, src_w, src_h)
        totR += blurMatrix[ix];
        totG += blurMatrix[ix + 1];
        totB += blurMatrix[ix + 2];
        totA += blurMatrix[ix + 3];
      }
      if (y > 0) {
        const iy = fromXY(x, y - 1, src_w, src_h)
        totR += blurMatrix[iy];
        totG += blurMatrix[iy + 1];
        totB += blurMatrix[iy + 2];
        totA += blurMatrix[iy + 3];
      }
      if (x > 0 && y > 0) {
        const ix = fromXY(x - 1, y - 1, src_w, src_h)
        totR -= blurMatrix[ix];
        totG -= blurMatrix[ix + 1];
        totB -= blurMatrix[ix + 2];
        totA -= blurMatrix[ix + 3];
      }
      blurMatrix[i + 0] = totR;
      blurMatrix[i + 1] = totG;
      blurMatrix[i + 2] = totB;
      blurMatrix[i + 3] = totA;
      // console.log(i);
      if (i >= (srcData.data.length - 1)) {
        resolve(blurMatrix);
      }
    }
  })


}

export const createBlurImage = async (src, depthImage, centerAt, dontBlur, maxRadius) => {
  const width = depthImage.width;
  const height = depthImage.height;
  let srcCanvas, srcCtx;
  srcCanvas = createCanvas(width, height);
  srcCtx = srcCanvas.getContext("2d")
  srcCtx.drawImage(src, 0, 0, width, height)
  const depthCanvas = createCanvas(width, height);
  const depthCtx = depthCanvas.getContext("2d")
  depthCtx.drawImage(depthImage, 0, 0, width, height)
  const dstCanvas = createCanvas(width, height);
  const dstCtx = dstCanvas.getContext("2d");
  const t0 = performance.now()
  const blurMatrix = await createBlurMatrix(srcCanvas)
  const t1 = performance.now()

  const min1 = centerAt - dontBlur;
  const min2 = 0;
  const max1 = centerAt + dontBlur;
  const max2 = 255;

  const dstData = dstCtx.getImageData(0, 0, width, height)
  const srcData = srcCtx.getImageData(0, 0, width, height)
  const depthData = depthCtx.getImageData(0, 0, width, height)

  return new Promise(resolve => {
    for (let i = 0; i < srcData.data.length; i += 4) {
      const gs = depthData.data[i];
      let delta = 0;
      if (gs < min1)
        delta = (min1 - gs) * 255.0 / (min1 - min2);
      if (gs > max1)
        delta = (gs - max1) * 255.0 / (max2 - max1);
      const factor = delta / 255.0;
      const radius = Math.round(factor * maxRadius);
      if (radius === 0) {
        dstData.data[i] = srcData.data[i];
        dstData.data[i + 1] = srcData.data[i + 1]
        dstData.data[i + 2] = srcData.data[i + 2]
        dstData.data[i + 3] = srcData.data[i + 3]
      } else {
        let boxw = radius;
        let boxh = radius;
        let mul = 1.0 / ((boxw * 2) * (boxh * 2));
        const { x, y } = toXY(i, width);
        const ria = fromXY(x + boxw, y + boxh, width, height)
        const rib = fromXY(x - boxw, y - boxh, width, height)
        const ric = fromXY(x - boxw, y + boxh, width, height)
        const rid = fromXY(x + boxw, y - boxh, width, height)
        dstData.data[i + 0] += (blurMatrix[ria + 0] + blurMatrix[rib + 0] - blurMatrix[ric + 0] - blurMatrix[rid + 0]) * mul;
        dstData.data[i + 1] += (blurMatrix[ria + 1] + blurMatrix[rib + 1] - blurMatrix[ric + 1] - blurMatrix[rid + 1]) * mul;
        dstData.data[i + 2] += (blurMatrix[ria + 2] + blurMatrix[rib + 2] - blurMatrix[ric + 2] - blurMatrix[rid + 2]) * mul;
        dstData.data[i + 3] += (blurMatrix[ria + 3] + blurMatrix[rib + 3] - blurMatrix[ric + 3] - blurMatrix[rid + 3]) * mul;
      }
    }
    dstCtx.putImageData(dstData, 0, 0)
    resolve(dstCanvas);
  })
}

*/