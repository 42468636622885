import React from "react";

const getSquareSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;

  return (
    <svg {...otherprops} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect onClick={e => handleClick(e, 0)} x="0.5" y="0.5" width="71" height="71" fill={yarns[0]} stroke={strokeColor} />
      <rect onClick={e => handleClick(e, 1)} x="0.5" y="0.5" width="53" height="71" fill={yarns[1]} stroke={strokeColor} />
      <rect onClick={e => handleClick(e, 2)} x="0.5" y="0.5" width="35" height="71" fill={yarns[0]} stroke={strokeColor} />
      <rect onClick={e => handleClick(e, 3)} x="0.5" y="0.5" width="17" height="71" fill={yarns[1]} stroke={strokeColor} />
    </svg>

  );
};
const getCircleSVG = props => {
  const { yarns, handleClick, shape,strokeColor="#808080", ...otherprops } = props;
  return (
       <svg {...otherprops} viewBox="0 0 74 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        onClick={e => handleClick(e, 0)}
        d="M23.4147 3.20228C27.5585 1.48588 32.9288 0.566421 37.5 0.503467V35.5L1.50352 35.5C1.56679 31.0083 2.48204 26.5678 4.20228 22.4147C5.98632 18.1077 8.60123 14.1942 11.8977 10.8977C15.1942 7.60123 19.1077 4.98632 23.4147 3.20228Z"
        fill={yarns[0]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 1)}
        d="M62.1023 10.8977C68.6419 17.4373 72.3666 26.2656 72.4965 35.5H37.5L37.5 0.503517C46.7344 0.633431 55.5627 4.35811 62.1023 10.8977Z"
        fill={yarns[1]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 2)}
        d="M62.1113 61.0935L62.1112 61.0934L62.1023 61.1023C55.5627 67.6419 46.7344 71.3666 37.5 71.4965L37.5 35.5L72.5004 35.5C72.5888 44.921 68.8406 54.6942 62.1113 61.0935Z"
        fill={yarns[2]}
        stroke={strokeColor}
      />
      <path
        onClick={e => handleClick(e, 3)}
        d="M11.9065 61.1016L11.8971 61.0917L11.8872 61.0824C5.09906 54.6802 1.35184 44.9081 1.50038 35.4903L37.5 35.4903L37.5 71.4985C28.0793 71.5867 18.306 67.8311 11.9065 61.1016Z"
        fill={yarns[3]}
        stroke={strokeColor}
      />
    </svg>
  );
};
const getSVGofShape = props => {
  const { yarns, handleClick, shape, ...otherprops } = props;
  switch (shape) {
    case "CIRCLE":
      return getCircleSVG(props);
      break;
    case "SQUARE":
      return getSquareSVG(props);
      break;
    default:
      return null
  }
};

const Mixply4 = props => {
  const { yarns, handleClick, ...otherprops } = props;
  return (
    getSVGofShape(props)
  );
};

Mixply4.propTypes = {};

export default Mixply4;
