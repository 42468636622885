import React, { useEffect, useState } from "react";
import AtButton from "../../atoms/AtButton";
import AtIcon from "../../atoms/AtIcon";
import { ReactComponent as Tutorial0 } from "./tutorial0.svg";
import { ReactComponent as Tutorial1 } from "./tutorial1.svg";
import { ReactComponent as Tutorial2 } from "./tutorial2.svg";
import { ReactComponent as Tutorial3 } from "./tutorial3.svg";

const tutorialTexts = [
  { text: "Tap and drag to view a different part of the carpet", icon: <Tutorial0 /> },
  { text: "Drag with two fingers or right click to adjust the viewing angle", icon: <Tutorial1 /> },
  { text: "Pinch in or out or scroll to zoom in or out the shot", icon: <Tutorial2 /> },
  { text: "Tap or click the shutter button to capture the shot", icon: <Tutorial3 /> }
];
const Tutorial = props => {
  const [activeIndex, setactiveIndex] = useState(0);
  const { show, onClose } = props;
  const [isViewing, setisViewing] = useState(false);
  const [timeout, settimeout] = useState(false);

  useEffect(() => {
    if (show) {
      settimeout(false);
      startTimeout();
    }
  }, [show]);

  // useEffect(() => {
  //   if (!timeout) return;
  //   if (!isViewing || activeIndex === 0) {
  //     onClose();
  //   } else {
  //     settimeout(false);
  //     startTimeout();
  //   }
  // }, [timeout]);

  const startTimeout = () => {
    setTimeout(() => {
      settimeout(true);
    }, 12000);
  };

  const handleNext = () => {
    if (!(activeIndex < tutorialTexts.length - 1)) {
      handleClose();
      return;
    }
    setactiveIndex(activeIndex + 1);
  };

  const handlePrev = () => {
    if (!(activeIndex > 0)) return;
    setactiveIndex(activeIndex - 1);
  };

  const handleClose = () => {
    setactiveIndex(0);
    onClose();
  };
  return (
    <>
      {show && (
        <div
          className="at-tutorial-wrapper"
          onMouseEnter={() => setisViewing(true)}
          onMouseLeave={() => setisViewing(false)}
        >
          <div className="at-tutorial-content-row">
            {tutorialTexts[activeIndex].icon && tutorialTexts[activeIndex].icon}
            <span>{tutorialTexts[activeIndex].text}</span>
          </div>
          <div className="at-tutorial-content-row">
            <AtButton
              text="Prev"
              disabled={activeIndex === 0}
              style={{ visibility: activeIndex === 0 ? "hidden" : "visible" }}
              minimal={true}
              onClick={handlePrev}
              icon="chevron-left"
            ></AtButton>
            <AtButton text="Skip" minimal={true} onClick={handleClose}></AtButton>
            <AtButton
              text={activeIndex === tutorialTexts.length - 1 ? "Finish" : "Next"}
              minimal={true}
              onClick={handleNext}
            >
              <AtIcon icon="chevron-right"></AtIcon>
            </AtButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Tutorial;
