import React, { useRef } from "react";
import { useMount } from "react-use";
import { overlayTextureinCanvas } from "../../../utils/utils";

const ColorPreview = props => {
  const { style, colorName, color, material, colorTextures, renderTexturedPreview } = props;
  const canvasRef = useRef();
  useMount(() => {
    // console.log(color, colorName);
    const ctx = canvasRef.current.getContext("2d");
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 160, 160);
    if (!renderTexturedPreview) return;
    const img = colorTextures.find(item => item.index === material);
    if (img && img.image)
      overlayTextureinCanvas({
        image: img.image,
        color,
        canvasSize: 160,
        canvas: canvasRef.current
      });
  });
  return (
    <div className="at-texture-sample-area popover" style={style}>
      <canvas className="at-texture-sample-box" width={160} height={160} ref={canvasRef}></canvas>
      <div className="at-texture-sample-colorname">{colorName}</div>
    </div>
  );
};

export default ColorPreview;
